import { Button, Table } from 'antd';
import React from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {PlusOutlined} from "@ant-design/icons"
import { Content } from 'antd/lib/layout/layout';

import moment from 'moment';
import 'moment/locale/th';

const dateFormat = "DD/MM/YYYY";

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        // render: (value: string, item: Application, index: number) => (
        //   index+1
        // ),
    },
    {
        title: "วันที่นำเข้า",
        dataIndex: "applicationDate",
        key: "applicationDate",
        render: (value: string) => moment(value).format(dateFormat),
    },
    {
        title: "ชื่อรายงาน",
        dataIndex: "applicationDate",
        key: "applicationDate",
    },
];
const ListReport = () => {
    const history = useHistory();

    return (
        <div>
            <PageHeader title="Report List">
                <Button
                    size="large"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => history.push("/app/report/manage")}
                >
                    New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div>
                    <Table
                        columns={columns}
                        // dataSource={applications}
                        size="small"
                        // rowKey={(item: Application) => item.applicationNo}
                    />
                </div>
            </Content>
        </div>
    )
}

export default ListReport
