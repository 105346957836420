import { Form, DatePicker, Select, Input, Button, Table, Space } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel'
import { Application } from 'src/domain/application/applicationType'
import PageHeader from 'src/layouts/PageHeader'
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { Branch } from 'src/domain/master/BranchType'
import BranchService from 'src/services/master/BranchService'
import { ClassDetail } from 'src/domain/master/classDetailType'
import classDetailService from 'src/services/master/classDetailService'
import applicationService from 'src/services/application/applicationService'
import { Excel } from 'antd-table-saveas-excel'

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const columns = [
    {
        title: "เลขที่ใบคำขอ",
        dataIndex: "applicationNo",
    },
    {
        title: "วันที่ใบคำขอ",
        dataIndex: "applicationDate",
    },
    {
        title: "คำนำหน้า",
        dataIndex: "prenameThaiName",
        render: (value: string, item: Application, index: number) => (
            item.prenameThaiId === 8 ? item.prenameEtcThai : item.prenameThaiName
        ),
    },
    {
        title: "ชื่อ-นามสกุล",
        dataIndex: "nameThai",
    },
    {
        title: "เลขที่บัตร",
        dataIndex: "idCard",
        render: (value: string, item: Application, index: number) => (
            item.nationalityId === 73 ? item.idCard : item.workPermitNo
        ),
    },
    {
        title: "วงเงินกู้",
        dataIndex: "loanAmt",
        align: "right" as "right",
    },
    {
        title: "จำนวนงวด",
        dataIndex: "loanInstallment",
        align: "right" as "right",
    },
    {
        title: "เลขที่บัญชี",
        dataIndex: "bankAccountNo",
    },
    {
        title: "ธนาคาร",
        dataIndex: "bankName",
    },
    {
        title: "สถานะใบคำร้อง",
        dataIndex: "applicationStatusName",
    },
];

const ListApplicationReport : React.FC<Application> = () => {
    const [form] = Form.useForm();

    const [applications, setApplications] = useState<Application[]>([]);
    const [optionsBranch, setOptionsBranch] = useState<Branch[]>([] as Branch[]);
    const [optionsStatus, setOptionsStatus] = useState<ClassDetail[]>([] as ClassDetail[]);
    const defaultDate = {
        applicationDate: [moment().startOf('month'), moment().endOf('month')]
    };

    useEffect(() => {
        (async () => {
          const res = await BranchService.getBranchUseAll();
          setOptionsBranch(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("06");
          setOptionsStatus(res.data);
        })();
    }, []);

    useEffect(() =>{}, [applications]);

    const handleApplicationNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("applicationNo");
            if(_search) {
                (async () => {
                  const res = await applicationService.getByCondition(0,"","","","",
                    _search === undefined ? "" : _search, 0);
                  setApplications(res.data);
                })();
            }
        }
    }

    const onFinish = (values: any) => {
        const _date = values.applicationDate;
        const _branchId = values.branchCode ?? 0;
        const _appNo = values.applicationNo;
        const _status = values.applicationStatus ?? 0;

        (async () => {
            const res = await applicationService.getByCondition(
                _status,
                _date ? _date[0].format('YYYYMMDD') : "" ,
                _date ? _date[1].format('YYYYMMDD') : "" ,
                "",
                "",
                _appNo === undefined ? "" : _appNo,
                _branchId
            );
            setApplications(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            applicationDate: [moment().startOf('month'), moment().endOf('month')],
            branchCode: null,
            applicationNo: "",
            applicationStatus: null
        });

        setApplications([] as Application[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columns)
            .addDataSource(applications)
            .saveAs("application_report.xlsx");
    }

  return (
    <div>
        <PageHeader title="รายงานการออกสินเชื่อ" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "40%"}}>
                            <Form.Item
                                label="สาขา"
                                name="branchCode"
                                key="branchCode"
                            >
                                <Select
                                    placeholder="เลือกสาขา"
                                    // style={{ width: 470 }}
                                    size='large'
                                >
                                    {optionsBranch.map((values) => (
                                    <Option key={values.id} value={values.id} props={values}>
                                        {values.branchCode}
                                        {values.branchName}
                                    </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "40%"}}>
                            <Form.Item
                                label="เลขที่ใบคำขอ"
                                name="applicationNo"
                                key="applicationNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleApplicationNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "20%"}}>

                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "40%"}}>
                            <Form.Item
                                label="สถานะใบคำขอ"
                                name="applicationStatus"
                                key="applicationStatus"
                            >
                                <Select
                                    placeholder="เลือกสถานะ"
                                    // style={{ width: 470 }}
                                    // onSelect={onSelectBranch}
                                    size='large'
                                >
                                    {optionsStatus.map((values) => (
                                    <Option key={values.id} value={values.id} props={values}>
                                        {values.classCode}
                                        {values.name}
                                    </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "40%"}}>
                            <Form.Item
                                label="วันที่ขอสินเชื่อ"
                                name="applicationDate"
                                key="applicationDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "20%",alignItems:'center'}}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                  columns={columns}
                  dataSource={applications}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                />
            </Panel>
        </Content>
    </div>
  )
}

export default ListApplicationReport