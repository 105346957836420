import { Button, Form, Input, Select } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import {CloseCircleOutlined, SaveOutlined} from '@ant-design/icons'
import classes from "src/views/application/ListApplication.module.css";
import TextArea from 'antd/lib/input/TextArea';
import classDetailService from 'src/services/master/classDetailService';
import { ClassDetail } from 'src/domain/master/classDetailType';
import { Company } from 'src/domain/master/companyType';
import companyService from 'src/services/master/companyService';
import { Account } from 'src/domain/account/accountType';
import accountService from 'src/services/account/accountService';
import { useAppSelector } from "src/store";

const { Option } = Select;

interface IProps {
    id: number;
    mode: string;
}

const AccountForm: React.FC<IProps> = (props) => {
    const _id = props.id;
    const mode = props.mode;
    const history = useHistory();
    const [form] = Form.useForm();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [isCodePass, setCodePass] = useState<boolean>(false);
    const [isAccGroupPass, setAccGroupPass] = useState<boolean>(false);
    const [account, setAccount] = useState<Account>({} as Account);

    const [optionsType, setOptionsType] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [selectType, setSelectType] = useState<string>();

    const [optionsSide, setOptionsSide] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [selectSide, setSelectSide] = useState<string>();

    const [optionsLevel, setOptionsLevel] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [selectLevel, setSelectLevel] = useState<string>();

    const [optionsTax, setOptionsTax] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [selectTax, setSelectTax] = useState<string>({} as string);

    const [optionsCompany, setOptionsCompany] = useState<Company[]>([] as Company[]);
    const [selectCompany, setSelectCompany] = useState<number>(1);

    const [optionsAccountGroup, setOptionsAccountGroup] = useState<Account[]>([] as Account[]);
    const [selectAccountGroup, setSelectAccountGroup] = useState<number>();

    // useEffect(() => {
    //     if (isFinish) saveDataToDB();
    // }, [isFinish]);

    useEffect(() => {
        if (isCodePass && isAccGroupPass) saveDataToDB();
    }, [isCodePass, isAccGroupPass]);

    useEffect(() => {
        if (mode === "EDIT") {
            const res = accountService.getAccountById(_id).then(res=>{
                setAccount(res.data);
            });
        }
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("13");
          setOptionsType(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("14");
          setOptionsSide(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("15");
          setOptionsLevel(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("16");
          setOptionsTax(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await companyService.getCompanyAll();
          setOptionsCompany(res.data);
          if(mode === "NEW") {
            setSelectCompany(1);
            form.setFieldsValue({company_id: 1});
          }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(selectType  && selectSide && selectLevel ){// && selectLevel !== "1") {
                const res = await accountService.getAccountGroup(selectType, selectSide, selectLevel);
                setOptionsAccountGroup(res.data);
                // setSelectAccountGroup(0);
                // form.setFieldsValue({account_group: null});
            }
        })();
        
    }, [selectType, selectSide, selectLevel]);

    useEffect(() => {
        setSelectType(account.type);
        setSelectSide(account.side);
        setSelectLevel(account.accLevel);
        setSelectTax(account.relatedTaxCode);
        setSelectCompany(account.companyId);
        setSelectAccountGroup(account.parentId);

        form.setFieldsValue({
            code : account.code,
            th_name : account.name,
            old_code : account.oldcode,
            company_id : account.companyId,
            type : account.type,
            side : account.side,
            level : account.accLevel,
            account_group : account.parentId === 0 ? null : account.parentId,
            tax : account.relatedTaxCode,
            remark : account.remark,
        });        
    },[account]);

    const accountCodeKeyUp = (value: any) => {
        const _code = form.getFieldValue("code");
        const res = accountService.codeExist(_code, _id).then(res=>{
            console.log(res);
            if(res.data) {
                form.setFields([
                    {
                      name: 'code',
                      errors: ['รหัสนี้ถูกใช้งานแล้ว'],
                    },
                 ]);
            }
        });
    }

    const onSelectType = (value: any) => {
        setSelectType(value);
    }

    const onSelectSide = (value: any) => {
        setSelectSide(value);
    }

    const onSelectLevel = (value: any) => {
        setSelectLevel(value);
    }

    const onSelectCompany = (value: any) => {
        setSelectCompany(value);
    }

    const onSelectTax = (value: any) => {
        setSelectTax(value);
    }

    const onSelectAccountGroup = (value: any) => {
        setSelectAccountGroup(value);

        form.setFields([
            {
              name: 'account_group',
              errors: [],
            },
         ]);
    }

    const onFinish = async (values: any) => {
        const _account : Account = {
            ...account,
            code : values.code,
            name : values.th_name,
            oldcode : values.old_code,
            type : selectType!,
            side : selectSide!,
            accLevel: selectLevel!,
            relatedTaxCode : selectTax,
            companyId : selectCompany,
            remark : values.remark,
            parentId : selectAccountGroup ? selectAccountGroup : 0,
        }
        setAccount(_account);

        let codeExist: boolean = false;
        if(mode === 'NEW') {
            const res = accountService.codeExist(values.code, _id).then(res=>{
                console.log(res.data);
                if(res.data) {
                    setCodePass(false);
                    codeExist = false;
                    form.setFields([
                        {
                        name: 'code',
                        errors: ['รหัสนี้ถูกใช้งานแล้ว'],
                        },
                    ]);
                } else {
                    codeExist = true;
                    setCodePass(true);
                }
            });
        } else {
            setCodePass(true);
        }

        let parent: boolean = false;
        if(selectLevel !== "1" && (selectAccountGroup === 0)) {
            form.setFields([
                {
                  name: 'account_group',
                  errors: ['เลือกรหัสอ้างอิง'],
                },
            ]);
            parent = false;
            setAccGroupPass(false);
        } else {
            parent = true;
            setAccGroupPass(true);
        }

        console.log("code=", codeExist)
        console.log("parent=", parent)
        console.log("value=", values)
        // if(codeExist && parent) {
            
        //     setFinish(true);
        // }
    };

    const saveDataToDB = async () => {
        const _account : Account = {
            ...account,
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
        }
        if(mode === 'NEW') {
            await accountService.addAccount(_account).then((res) => {
                if (res.status === 200) history.goBack();
            }).catch((err) => {
                console.log(err);
            });
        } else if(mode === "EDIT") {
            await accountService.editAccount(_account).then(res => {
                if(res.status === 200)
                    history.replace("/app/account/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> NewAccount:", errorInfo);
    };

    return (
        <div>
            <PageHeader title="สร้าง รายการบัญชี"
                onClose={() => history.goBack()}
            >
                <Button
                    size="large"
                    type="primary"
                    danger
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                    Save
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <div className={classes.dataPanel}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{marginLeft: "10px",width: "50%"}}>
                                <Form.Item
                                    label="CODE"
                                    name="code"
                                    rules={[
                                        { required: true, message: "กรุณาระบุรหัสบัญชี!" },
                                    ]}
                                >
                                    <Input maxLength={10} onKeyUp={accountCodeKeyUp}  />
                                </Form.Item>
                                <Form.Item
                                    label="EXT-ACCOUNT"
                                    name="ext_account"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="THAI NAME"
                                    name="th_name"
                                    rules={[
                                        { required: true, message: "กรุณาระบุชื่อบัญชี!" },
                                    ]}
                                >
                                    <Input maxLength={100} />
                                </Form.Item>
                                <Form.Item
                                    label="OLD CODE"
                                    name="old_code"
                                >
                                    <Input maxLength={255} />
                                </Form.Item>
                                <Form.Item
                                    label="COMPANY"
                                    name="company_id"
                                    rules={[
                                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                                    ]}
                                >
                                    <Select 
                                        placeholder="เลือกข้อมูล" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectCompany}
                                        value={selectCompany}
                                        filterOption={(input, option) => {
                                            return (
                                              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        showSearch
                                    >
                                        {optionsCompany.map((opt_value) => (
                                            <Option title={opt_value.name} key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div style={{marginLeft: "30px",width: "50%"}}>
                                <Form.Item
                                    label="TYPE"
                                    name="type"
                                    rules={[
                                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                                    ]}
                                >
                                    <Select 
                                        placeholder="เลือกข้อมูล" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectType}
                                        value={selectType}
                                        filterOption={true}
                                        showSearch
                                        // filterOption={(input, option) => option?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {optionsType.map((opt_value) => (
                                            <Option key={opt_value.id} value={opt_value.name}>{opt_value.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="SIDE"
                                    name="side"
                                    rules={[
                                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                                    ]}
                                >
                                    <Select 
                                        placeholder="เลือกข้อมูล" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectSide}
                                        value={selectSide}
                                        filterOption={true}
                                        showSearch
                                    >
                                        {optionsSide.map((opt_value) => (
                                            <Option key={opt_value.id} value={opt_value.name}>{opt_value.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="LEVEL"
                                    name="level"
                                    rules={[
                                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                                    ]}
                                >
                                    <Select 
                                        placeholder="เลือกข้อมูล" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectLevel}
                                        // value={selectOccupation?.id}
                                        filterOption={true}
                                        showSearch
                                    >
                                        {optionsLevel.map((opt_value) => (
                                            <Option key={opt_value.id} value={opt_value.name}>{opt_value.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="ACCOUNT GROUP"
                                    name="account_group"
                                >
                                    <Select 
                                        placeholder="เลือก TYPE/SIDE/LEVEL ก่อน" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectAccountGroup}
                                        // value={selectOccupation?.id}
                                        // filterOption={true}
                                        filterOption={(input, option) => {
                                            return (
                                              option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            );
                                        }}
                                        showSearch
                                        allowClear
                                    >
                                        {optionsAccountGroup.map((opt_value) => (
                                            <Option key={opt_value.code} value={opt_value.id} title={opt_value.code}>{opt_value.code}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="TAX"
                                    name="tax"
                                    rules={[
                                        { required: true, message: "กรุณาเลือกข้อมูล!" },
                                    ]}
                                >
                                    <Select 
                                        placeholder="เลือกข้อมูล" 
                                        style={{ width: 400 }}
                                        onSelect={onSelectTax}
                                        // value={selectOccupation?.id}
                                        filterOption={true}
                                        showSearch
                                    >
                                        {optionsTax.map((opt_value) => (
                                            <Option key={opt_value.id} value={opt_value.name}>{opt_value.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                        <Form.Item
                            label="REMARK"
                            name="remark"
                            style={{marginLeft: "10px",}}
                        >
                            <TextArea rows={2} cols={40} style={{width:"90%"}} maxLength={255} />
                        </Form.Item>
                    </div>
                </Form>
            </Content>
        </div>
    );
};

export default AccountForm;
