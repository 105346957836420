import React from 'react'
import { RouteComponentProps } from 'react-router'
import ApplicationForm from './ApplicationForm';

interface IParams {
    id: string;
}

const EditApplication : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);

    return (
        <div>
            <ApplicationForm id={_id} mode={'EDIT'} />
        </div>
    )
}

export default EditApplication
