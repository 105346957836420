import React, { useEffect, useRef, useState } from 'react'
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  Button,
  Radio,
  AutoComplete,
  Typography,
  Space,
} from "antd";
import { RightCircleOutlined } from "@ant-design/icons";

import classDetailService from "src/services/master/classDetailService";
import { ClassDetail } from "src/domain/master/classDetailType";
import locationService from "src/services/master/locationService";
import { Location } from "src/domain/master/locationType";
import branchService from "src/services/master/BranchService";
import { Branch } from "src/domain/master/BranchType";
import moment from "moment";
import "moment/locale/th";
import th_loc from "antd/es/date-picker/locale/th_TH";

const { Title, Paragraph, Text } = Typography;

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

interface IProps {
  id: number;
  application: any;
  addressCurrent: any;
  updatePersonalData: any;
  trigger: any;
  setTrigger: any;
  next: () => void;
  // form:any;
}

const PersonalApplication: React.FC<IProps> = (props) => {
  const {
    id,
    application,
    addressCurrent,
    updatePersonalData,
    trigger,
    setTrigger,
    next,
  } = props;

  const divRef = useRef<HTMLDivElement>(null);

  const [form] = Form.useForm();
  const [optionsProduct, setOptionsProduct] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsPrenameThai, setOptionsPrenameThai] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsPrenameEng, setOptionsPrenameEng] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsMarriageStatus, setOptionsMarriageStatus] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsLivingStatus, setOptionsLivingStatus] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsDocChannal, setOptionsDocChannal] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsNationality, setOptionsNationality] = useState<ClassDetail[]>(
    [] as ClassDetail[]
  );
  const [optionsLocation, setOptionsLocation] = useState<Location[]>(
    [] as Location[]
  );
  const [optionsBranch, setOptionsBranch] = useState<Branch[]>([] as Branch[]);

  const [selectProduct, setSelectProduct] = useState<ClassDetail>();
  const [selectPrenameThai, setSelectPrenameThai] = useState<ClassDetail>();
  // const [selectPrenameThai, setSelectPrenameThai] = useState<number>();
  const [selectPrenameEng, setSelectPrenameEng] = useState<ClassDetail>();
  const [selectMarriageStatus, setSelectMarriageStatus] = useState<ClassDetail>();
  const [selectLivingStatus, setSelectLivingStatus] = useState<ClassDetail>();
  const [selectNationality, setSelectNationality] = useState<ClassDetail>();
  const [selectDocChannal, setSelectDocChannal] = useState<number>(16);
  const [selectLocation, setSelectLocation] = useState<Location>();
  const [selectBranch, setSelectBranch] = useState<Branch>();
  // const [selectBranch, setSelectBranch] = useState<number>();

  const [showPrenameThaiEtc, setShowPrenameThaiEtc] = useState<boolean>(true);
  const [showPrenameEngEtc, setShowPrenameEngEtc] = useState<boolean>(true);
  const [showNationalityEtc, setShowNationalityEtc] = useState<boolean>(true);

  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {}, [showPrenameThaiEtc, showPrenameEngEtc]);

  useEffect(() => {
    (async () => {
      const res = await branchService.getBranchUseAll();
      setOptionsBranch(res.data);
    })();

    scrollToTop();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("08");
      setOptionsProduct(res.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("01");
      setOptionsPrenameThai(res.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("00");
      setOptionsPrenameEng(res.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("10");
      setOptionsMarriageStatus(res.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("18");
      setOptionsNationality(res.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("02");
      setOptionsLivingStatus(res.data);
    })();
    if (application.livingStatusId) {
      const _living: ClassDetail = {
        id: application.livingStatusId,
        name: application.livingStatusName,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectLivingStatus(_living);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const res = await classDetailService.getByClassNo("03");
      setOptionsDocChannal(res.data);

    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await locationService.getLocationAll();
      setOptionsLocation(res.data);
    })();
  }, []);

  useEffect(() => {
    console.log("app show = ", application.branchId)
    form.setFieldsValue({
      product_code: application.productId,
      branch_code: application.branchId,
      ref1: application.ref1,
      prename_thai : application.prenameThaiId,
      prename_etc_thai: application.prenameEtcThai,
      prename_eng: application.prenameEngId,
      prename_etc_eng: application.prenameEtcEng,
      name_thai: application.nameThai,
      name_eng: application.nameEng,
      birthday: application.birthday ? moment(application.birthday, 'YYYY-MM-DD') : "",
      nationality_id: application.nationalityId,
      nationality: application.nationalityId === 73 ? "" : application.nationality,
      idCard: application.idCard,
      id_card_expire_date: application.idCardExpireDate ? moment(application.idCardExpireDate, 'YYYY-MM-DD') : "",
      work_permit_no: application.workPermitNo,
      work_permit_expire_date: application.workPermitExpireDate ? moment(application.workPermitExpireDate, 'YYYY-MM-DD') : "",
      marriageStatusId: application.marriageStatusId,
      livingStatusId: application.livingStatusId,
      mobile: application.mobile,
      phone: application.phone,
      email: application.email,
      lineId: application.lineId,
      facebook: application.facebook,
      decitionDocChannalId: application.decitionDocChannalId,

      building: addressCurrent.building,
      room_no: addressCurrent.roomNo,
      address: addressCurrent.address,
      moo: addressCurrent.moo,
      soi: addressCurrent.soi,
      road: addressCurrent.road,
      addrPath: addressCurrent.provinceCode ? addressCurrent.tambonName + "/" + addressCurrent.amphurName + "/" + addressCurrent.provinceName + "/" + addressCurrent.zipcode : "",
    });

    if(application.decitionDocChannalId) setSelectDocChannal(application.decitionDocChannalId);
    setDataToParam();
  }, [application]);

  const setDataToParam = () => {
    const x: any = {id:application.branchId, branchCode: application.branchCode}
    setSelectBranch(x);

    const p: any = {id: application.productId, code: application.productCode, name: application.productName}
    setSelectProduct(p);

    if(application.prenameThaiId) {
      const _prenameThai: ClassDetail = {
        id: application.prenameThaiId,
        name: application.prenameThaiName,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectPrenameThai(_prenameThai);
      if(application.prenameThaiId === 8) setShowPrenameThaiEtc(false);
    else setShowPrenameThaiEtc(true);
    }

    if(application.prenameEngId) {
      const _prenameEng: ClassDetail = {
        id: application.prenameEngId,
        name: application.prenameEngName,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectPrenameEng(_prenameEng);
      if(application.prenameEngId === 4) setShowPrenameEngEtc(false);
      else setShowPrenameEngEtc(true);
    }

    if(application.marriageStatusId) {
      const _marriage: ClassDetail = {
        id: application.marriageStatusId,
        name: application.marriageStatusName,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectMarriageStatus(_marriage);
    }

    if(application.livingStatusId) {
      const _living: ClassDetail = {
        id: application.livingStatusId,
        name: application.livingStatusName,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectLivingStatus(_living);
    }

    if(application.nationalityId) {
      const _nationality: ClassDetail = {
        id: application.nationalityId,
        name: application.nationality,
        classId: 0,
        code: "",
        useFlg: 0,
      };
      setSelectNationality(_nationality);
      if(application.nationalityId === 74) setShowNationalityEtc(false);
    }

    if (addressCurrent.provinceCode) {
      const _addr: Location = {
        id: 0,
        provinceCode: addressCurrent.provinceCode,
        province: addressCurrent.provinceName,
        amphurCode: addressCurrent.amphurCode,
        amphur: addressCurrent.amphurName,
        tambonCode: addressCurrent.tambonCode,
        tambon: addressCurrent.tambonName,
        zipcode: addressCurrent.zipcode,
        addrPath:
          addressCurrent.tambonName +
          "/" +
          addressCurrent.amphurName +
          "/" +
          addressCurrent.provinceName +
          "/" +
          addressCurrent.zipcode,
      };
      setSelectLocation(_addr);
    }
  }

  useEffect(() => {
    if (trigger) {
      form.submit();
    }
  }, [trigger]);

  const onSelectProduct = (value: any, index: any) => {
    const x: any = { id: value, code: index.children[0], name: index.children[1]};
    setSelectProduct(x);
  }

  const onSelectBranch = (value: any, index: any) => {
    const x: any = { id: value, branchCode: index.children[0] };
    setSelectBranch(index.props);
  };

  const onSelectPrenameThai = (value: any, index: any) => {
    const x: any = { id: value, name: index.children};

    setSelectPrenameThai(x);

    const prename_eng = optionsPrenameEng.find(item => {
      return item.code === index.props.code;
    })

    if(prename_eng) {
      let eng: any = { id: prename_eng.id, name: prename_eng.name };
      setSelectPrenameEng(eng);
      if(prename_eng.id === 4) setShowPrenameEngEtc(false);
      else setShowPrenameEngEtc(true);
      form.setFieldsValue({prename_eng: prename_eng.id,});
    }
    // setSelectPrenameThai(value)
    if(value === 8) setShowPrenameThaiEtc(false);
    else setShowPrenameThaiEtc(true);
  };

  const onSelectPrenameEng = (value: any, index: any) => {
    let x: any = { id: value, name: index.children };
    setSelectPrenameEng(x);
    if(value === 4) setShowPrenameEngEtc(false);
    else setShowPrenameEngEtc(true);
  };

  const onSelectNationality = (value: any, index: any) => {
    let x: any = { id: value, name: index.children };
    setSelectNationality(x);
    if(value === 74) setShowNationalityEtc(false);
    else setShowNationalityEtc(true);
  };

  const onSelectMarriageStatus = (value: any, index: any) => {
    let x: any = { id: value, name: index.children };
    setSelectMarriageStatus(x);
  };

  const onSelectLivingStatus = (value: any, index: any) => {
    let x: any = { id: value, name: index.children };
    setSelectLivingStatus(x);
  };

  const onChangeDocChannal = (values: any) => {

    setSelectDocChannal(values.target.value);
  };

  const onSearchLocation = (values: any) => {
  };

  const onSelectLocation = (values: any, key: any) => {
    setSelectLocation(key.props);
  };

  const onChangeLocation = (values: any) => {
    setSelectLocation(values);
  };

  const onChangeBirthday = (value: any) => {
  };

  const onFinish = (values: any) => {
    let x: any = {
      branchId: selectBranch?.id,
      branchCode: selectBranch?.branchCode,
      branchName: selectBranch?.branchName,

      productId: selectProduct?.id,
      productCode: selectProduct?.code,
      productName: selectProduct?.name,

      ref1: values.ref1,
      prenameThaiId: selectPrenameThai?.id,
      prenameThaiName: selectPrenameThai?.name,
      prenameEtcThai: values.prename_etc_thai,
      prenameEngId: selectPrenameEng?.id,
      prenameEngName: selectPrenameEng?.name,
      prenameEtcEng: values.prename_etc_eng,
      nameThai: values.name_thai,
      accountName : values.name_thai,
      nameEng: values.name_eng,
      birthday: values.birthday ? values.birthday.format("YYYYMMDD") : "",
      nationalityId: selectNationality?.id,
      nationality : selectNationality?.id === 73 ? selectNationality?.name :values.nationality,
      idCard: values.idCard,
      idCardExpireDate: values.id_card_expire_date ? values.id_card_expire_date.format("YYYYMMDD") : "",
      workPermitNo: values.work_permit_no,
      workPermitExpireDate: values.work_permit_expire_date ? values.work_permit_expire_date.format("YYYYMMDD") : "",
      marriageStatusId: selectMarriageStatus?.id,
      marriageStatusName: selectMarriageStatus?.name,
      livingStatusId: selectLivingStatus?.id,
      livingStatusName: selectLivingStatus?.name,
      mobile: values.mobile,
      phone: values.phone,
      email: values.email,
      lineId: values.lineId,
      facebook: values.facebook,
      decitionDocChannalId: selectDocChannal,

      //address
      building: values.building,
      roomNo: values.room_no,
      address: values.address,
      moo: values.moo,
      soi: values.soi,
      road: values.road,
      tambonCode: selectLocation?.tambonCode,
      tambon: selectLocation?.tambon,
      amphurCode: selectLocation?.amphurCode,
      amphur: selectLocation?.amphur,
      provinceCode: selectLocation?.provinceCode,
      province: selectLocation?.province,
      zipcode: selectLocation?.zipcode,
    };
    updatePersonalData(x);
    next();
    // }
  };

  const onFinishFailed = (errorInfo: any) => {
    setTrigger(false);
  };


  const scrollToTop = () => {
    if (divRef.current) {
        window.scrollTo({ behavior: 'smooth', top: divRef.current.offsetTop - 120 })
    }
}

  return (
    <>
      <Form
        // {...layout}
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div ref={divRef}></div>
        <Typography>
          <Title level={2}>ข้อมูล ส่วนตัว</Title>
          <Paragraph>ระบุ ข้อมูลชื่อ ที่อยู่ และ ช่องทางการติดต่อ</Paragraph>
        </Typography>
        <Row style={{ marginBottom: "3em" }}>
          <Col span={24}>
            <Space direction="vertical" />
            <Form.Item
              label="ผลิตภัณฑ์"
              name="product_code"
              key="product_code"
              rules={[
                { required: true, message: "กรุณาผลิตภัณฑ์!",
                },
                { required: true, message: "ไม่สามารถเลือกผลิตภัณฑ์ประเภท 99 Custom ได้!",
                  validator : async (_, value) => {
                    if (value!=null) {
                      if (value === 75) {
                        return Promise.reject(
                          "value can't select 99 Custom"
                        );
                      }
                    }
                  }
                },
                // {
                //   message: "Please select your branch",
                // },
                
              ]}
            >
              <Select
                placeholder="เลือกผลิตภัณฑ์"
                style={{ width: 470 }}
                onSelect={onSelectProduct}
                // value={selectBranch?.id}
                value={selectProduct?.id}
                size='large'
                disabled={id === 0 ? false : true}
              >
                {optionsProduct.map((values) => (
                  <Option key={values.id} value={values.id} props={values}>
                    {values.code}
                    {values.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="สาขา"
              name="branch_code"
              key="branch_code"
              rules={[
                { required: true, message: "กรุณาเลือกสาขา!" },
                // {
                //   message: "Please select your branch",
                // },
              ]}
            >
              <Select
                placeholder="เลือกสาขา"
                style={{ width: 470 }}
                onSelect={onSelectBranch}
                // value={selectBranch?.id}
                value={selectBranch?.id}
                size='large'
                disabled={id === 0 ? false : true}
              >
                {optionsBranch.map((values) => (
                  <Option key={values.id} value={values.id} props={values}>
                    {values.branchCode}
                    {values.branchName}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="เลขที่อ้างอิง"
              name="ref1"
              key="ref1"
            >
              <Input placeholder='รหัสพนักงาน' size='large' showCount maxLength={50} style={{ width: 470 }} />
            </Form.Item>

            <p style={{ marginTop: "40px" }}> ระบุชื่อภาษาไทย และ ภาษาอังกฤษ</p>
            <Form.Item label="คำนำหน้าชื่อ(ไทย)">
              <Row>
                <Col>
                  <Form.Item
                    name="prename_thai"
                    rules={[
                      { required: true, message: "กรุณาเลือกคำนำหน้าชื่อ(ไทย)!" },
                    ]}
                  >
                    <Select
                      placeholder="เลือกคำนำหน้าชื่อ"
                      style={{ width: 200 }}
                      onSelect={onSelectPrenameThai}
                      value={selectPrenameThai?.id}
                      defaultValue={selectPrenameThai?.id}
                      size='large'
                    >
                      {optionsPrenameThai.map((prename) => (
                        <Option key={prename.id} value={prename.id} props={prename}>
                          {prename.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="prename_etc_thai">

                    <Input
                      placeholder="คำนำหน้าชื่อ(ไทย) อื่นๆ ระบุ"
                      style={{marginLeft: "10px" }}
                      disabled={showPrenameThaiEtc}
                      size='large' showCount maxLength={100}
                    />

                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="คำนำหน้าชื่อ(อังกฤษ)">
              <Row>
                <Col>
                  <Form.Item name="prename_eng">
                    <Select
                      placeholder="Select Prename"
                      style={{ width: 200 }}
                      onSelect={onSelectPrenameEng}
                      value={selectPrenameEng?.id}
                      size='large'
                    >
                      {optionsPrenameEng.map((prename) => (
                        <Option key={prename.id} value={prename.id}>
                          {prename.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="prename_etc_eng">
                    <Input
                      placeholder="คำนำหน้าชื่อ(อังกฤษ) อื่นๆ ระบุ"
                      style={{marginLeft: "10px" }}
                      disabled={showPrenameEngEtc}
                      size='large' showCount maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="ชื่อ-นามสกุล ภาษาไทย"
              name="name_thai"
              rules={[
                { required: true, message: "กรุณาระบุชื่อภาษาไทย!" },
                {
                  message: "Please input your name",
                },
              ]}
            >
              <Input size='large' showCount maxLength={100}/>
            </Form.Item>
            <Form.Item
              label="ชื่อ-นามสกุล ภาษาอังกฤษ"
              name="name_eng"
              rules={[
                { required: true, message: "กรุณาระบุชื่อภาษาอังกฤษ!" },
                {
                  message: "Please input your name",
                },
              ]}
            >
              <Input size='large' showCount maxLength={100} />
            </Form.Item>
            <Form.Item label="วันเกิด" name="birthday">
              <DatePicker
                locale={th_loc}
                onChange={onChangeBirthday}
                // defaultValue={moment(moment().format('L'), dateFormat)}
                format={dateFormat}
                size='large'
              />
            </Form.Item>
            <Form.Item label="สัญชาติ">
              <Row>
                <Col>
                  <Form.Item
                    name="nationality_id"
                    rules={[
                      { required: true, message: "กรุณาเลือกสัญชาติ!" },
                    ]}
                  >
                    <Select
                      placeholder="เลือกสัญชาติ (บังคับ)"
                      style={{ width: 200 }}
                      onSelect={onSelectNationality}
                      value={selectNationality?.id}
                      size='large'
                    >
                      {optionsNationality.map((name) => (
                        <Option key={name.id} value={name.id}>
                          {name.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="nationality"
                    rules={[
                      {
                        type: 'date',
                        required: true,
                        message: 'กรุณาระบุสัญชาติ!',
                        whitespace: true,
                        validator : async (_, value) => {
                          const nationality_id = selectNationality?.id;
                          if (nationality_id!=null) {
                            if (nationality_id === 74 && !value) {
                              return Promise.reject(
                                "value can't null"
                              );
                            }
                          }
                        }
                      },
                    ]}
                  >
                    <Input
                      placeholder="อื่นๆ ระบุ"
                      style={{marginLeft: "10px" }}
                      disabled={showNationalityEtc}
                      size='large' showCount maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="เลขบัตรประชาชน">
              <Row>
                <Col>
                  <Form.Item
                    name="idCard"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุเลขบัตรประชาชน!',
                        whitespace: true,
                        validator : async (_, value) => {
                          const nationality_id = selectNationality?.id;
                          if (nationality_id!=null) {
                            if (nationality_id === 73 && !value) {
                              return Promise.reject(
                                "value can't null"
                              );
                            }
                          }
                        }
                      },
                      {
                        min: 13,
                        max: 13,
                        message: "Please input your Id card 13 character",
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: "กรุณาระบุเป็นตัวเลขเท่านั้น"
                      }
                    ]}
                  >
                    <Input size='large' showCount maxLength={13} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="id_card_expire_date"
                    rules={[
                      {
                        type: 'date',
                        required: true,
                        message: 'กรุณาเลือกวันที่หมดอายุของบัตรประชาชน!',
                        whitespace: true,
                        validator : async (_, value) => {
                          const nationality_id = selectNationality?.id;
                          if (nationality_id!=null) {
                            if (nationality_id === 73 && !value) {
                              return Promise.reject(
                                "value can't null"
                              );
                            }
                          }
                        }
                      },
                    ]}
                  >
                    <DatePicker
                      style={{marginLeft: "10px" }}
                      placeholder="วันหมดอายุ"
                      locale={th_loc}
                      format={dateFormat}
                      size='large'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="เลขที่ใบอนุญาตทำงาน">
              <Row>
                <Col>
                  <Form.Item
                    name="work_permit_no"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาระบุเลขที่ใบอนุญาตทำงาน!',
                        whitespace: true,
                        validator : async (_, value) => {
                          const nationality_id = selectNationality?.id;
                          if (nationality_id!=null) {
                            if (nationality_id === 74 && !value) {
                              return Promise.reject(
                                "value can't null"
                              );
                            }
                          }
                        }
                      },
                    ]}
                  >
                    <Input size='large' showCount maxLength={20} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="work_permit_expire_date"
                    rules={[
                      {
                        type: 'date',
                        required: true,
                        message: 'กรุณาเลือกวันที่หมดอายุของใบอนุญาตทำงาน!',
                        whitespace: true,
                        validator : async (_, value) => {
                          const nationality_id = selectNationality?.id;
                          if (nationality_id!=null) {
                            if (nationality_id === 74 && !value) {
                              return Promise.reject(
                                "value can't null"
                              );
                            }
                          }
                        }
                      },
                    ]}
                  >
                    <DatePicker
                      style={{marginLeft: "10px" }}
                      placeholder="วันหมดอายุ"
                      locale={th_loc}
                      format={dateFormat}
                      size='large'
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="สถานภาพ" name="marriageStatusId">
              <Select
                placeholder="เลือก"
                style={{ width: 200 }}
                onSelect={onSelectMarriageStatus}
                value={selectMarriageStatus?.id}
                size='large'
              >
                {optionsMarriageStatus.map((opt_value) => (
                  <Option key={opt_value.id} value={opt_value.id}>
                    {opt_value.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="สถานะของการอยู่อาศัย" name="livingStatusId">
              <Select
                placeholder="เลือก"
                style={{ width: 200 }}
                onSelect={onSelectLivingStatus}
                value={selectLivingStatus?.id}
                size='large'
              >
                {optionsLivingStatus.map((living) => (
                  <Option key={living.id} value={living.id}>
                    {living.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="ชื่อหมู่บ้าน/อาคารชุด" name="building">
              <Input size='large' showCount maxLength={100} />
            </Form.Item>
            <Form.Item label="เลขที่">
              <Row>
                <Col>
                  <Form.Item name="room_no">
                    <Input size='large' showCount maxLength={50}
                      style={{ width: 150 }}
                      placeholder="เลขที่ห้องชั้น"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="address">
                    <Input size='large' showCount maxLength={100}
                      placeholder="บ้านเลขที่"
                      style={{ width: 150, marginLeft: "10px" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="moo">
                    <Input size='large' showCount maxLength={50}
                      placeholder="หมู่"
                      style={{ width: 150, marginLeft: "10px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item label="ซอย/ถนน">
              <Row>
                <Col>
                  <Form.Item name="soi">
                    <Input size='large' showCount maxLength={50}
                      placeholder="ซอย"
                      name="soi"
                      style={{ width: 150 }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="road">
                    <Input size='large' showCount maxLength={100}
                      placeholder="ถนน"
                      name="road"
                      style={{ width: 300, marginLeft: "10px" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item
              label="ที่อยู่"
              name="addrPath"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุชื่อตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์!",
                },
                {
                  message: "Please input your address name",
                },
              ]}
            >
              <AutoComplete
                placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                onSearch={onSearchLocation}
                onSelect={onSelectLocation}
                onChange={onChangeLocation}
                value={selectLocation?.addrPath}
                options={optionsLocation.map((d) => ({
                  value: d.addrPath,
                  label: d.addrPath,
                  key: d.id,
                  props: d,
                }))}
                filterOption={true}
                allowClear
                size='large'
              >
                <Input.Search size='large' enterButton />
              </AutoComplete>
            </Form.Item>
            <Form.Item label="โทรศัพท์มือถือ" name="mobile"
              rules={[
                { required: true, message: "กรุณาระบุโทรศัพท์มือถือ!" },
                {
                  pattern: /^\d{3}-\d{7}$/,
                  message: "must be a valid phone number"
                }
              ]}

            >
              <Input size='large' showCount maxLength={11} placeholder="090-0000000"/>
            </Form.Item>
            <Form.Item label="เบอร์โทรศัพท์(สำรอง)" name="phone"
            rules= {[
              { required: false },
              {
                pattern: /^\d{3}-\d{7}$/,
                message: "must be a valid phone number"
              }
            ]}
            >
              <Input size='large' showCount maxLength={11} placeholder="090-0000000"/>
            </Form.Item>
            <Form.Item label="อีเมล" name="email"
              rules= {[
                {
                  required: false,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
              ]}
            >
              <Input size='large' showCount maxLength={100}/>
            </Form.Item>
            <Form.Item label="LINE ID" name="lineId">
              <Input size='large' showCount maxLength={100} />
            </Form.Item>
            <Form.Item label="FACEBOOK" name="facebook">
              <Input size='large' showCount maxLength={100} />
            </Form.Item>
            <Form.Item
              label="การรับเอกสาร"
              name="decitionDocChannalId"
              // rules={[
              //     { required: true, message: "กรุณาระบุการรับเอกสาร!" },
              //     {
              //       message: "Please select your decition doc channal",
              //     },
              // ]}
            >
              <Radio.Group
                onChange={onChangeDocChannal}
                value={selectDocChannal}
                defaultValue={selectDocChannal}
                size='large'

              >
                {optionsDocChannal.map((opt_value) => (
                  <Radio key={opt_value.id} value={opt_value.id}>
                    {opt_value.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 22, span: 2 }}>
              <Button
                size="large"
                type="primary"
                name="per"
                icon={<RightCircleOutlined />}
                onClick={() => {
                  form.submit();
                }}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PersonalApplication;
