import http from "../../lib/http";
import configs  from 'src/constants/config';
// const url = "http://localhost:8080/bookCode";
const url = `${configs.urlRoot}/bookCode`;

const getBookCodeByBookTypeId = (typeId: number) => {
    return http.get(url+"/ByBookTypeId?bookTypeId="+typeId);
}

const getBookCodeById = (id: number) => {
    return http.get(url+"/"+id);
}

export default {
    getBookCodeByBookTypeId,
    getBookCodeById
}