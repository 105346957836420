import { Button, Form, DatePicker, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useState } from 'react'
import Panel from 'src/components/container/Panel';
import PageHeader from 'src/layouts/PageHeader';
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import contractService from 'src/services/contract/contractService';
import { Excel } from 'antd-table-saveas-excel';

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ListContractDuedateReport : React.FC<ContractDebit> = () =>{
    const [form] = Form.useForm();
    const [contractDebits, setContractDebits] = useState<ContractDebit[]>([]);

    const defaultDate = {
        duedateDate: [moment().startOf('month'), moment().endOf('month')]
    };

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
        console.log('page property', page, pageSize);
        setOffset((page-1) * pageSize);
    }

    const handleContractNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("contractNo");
            if(_search) {
                (async () => {
                    const res = await contractService.searchForDuedateReport(_search, "", "")
                    setContractDebits(res.data);
                })();
            }
        }
    }

    const onFinish = (values: any) => {
        const _date = values.duedateDate;
        const _contractNo = values.contractNo;

        (async () => {
            const res = await contractService.searchForDuedateReport(
                _contractNo === undefined ? "" : _contractNo,
                _date ? _date[0].format('YYYYMMDD') : "" ,
                _date ? _date[1].format('YYYYMMDD') : ""                 
            );
            setContractDebits(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            duedateDate: [moment().startOf('month'), moment().endOf('month')],
            contractNo: ""
        });

        setContractDebits([] as ContractDebit[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columns)
            .addDataSource(contractDebits)
            .saveAs("duedate_report.xlsx");
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: ContractDebit, index: number) => (
              offset + index+ 1
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
        },
        {
            title: "วันที่สัญญา",
            dataIndex: "contractDate",
            render: (value: string) => moment(value).format(dateFormat),
        },
        {
            title: "ชื่อผู้ขอสินเชื่อ",
            dataIndex: "name",
        },
        {
            title: "วงเงินกู้",
            dataIndex: "loanAmt",
            align: "right" as "right",
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "จำนวนงวด",
            dataIndex: "tenor",
            align: "right" as "right",
        },
        {
            title: "วันที่ครบกำหนดชำระ",
            dataIndex: "duedate",
            render: (value: string) => moment(value).format(dateFormat),
        },
        {
            title: "งวดที่จ่าย",
            dataIndex: "month",
            align: "right" as "right",
        },
        {
            title: "ยอดเงิน",
            dataIndex: "installment",
            align: "right" as "right",
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "วันที่รับชำระ",
            dataIndex: "paymentDate",
            render: (value: string) => value === null ? "" : moment(value).format(dateFormat),
        },
    ];

    return (
      <div>
        <PageHeader title="รายงานครบกำหนดชำระ" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>   
                        <div style={{marginLeft: "20px",width: "50%"}}>
                            <Form.Item
                                label="วันที่กำหนดชำระ"
                                name="duedateDate"
                                key="duedateDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "30%"}}>
                            <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหาเลขที่สัญญา' onKeyDown={handleContractNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{width: "20%",alignItems:'center'}}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                  columns={columns}
                  dataSource={contractDebits}
                  size="small"
                  rowKey={(item: ContractDebit) => item.id}
                  scroll={{ x: true }}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 20,
                  }}
                />
            </Panel>
        </Content>
      </div>
    )
}

export default ListContractDuedateReport