import { Button, Form, Select, DatePicker, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';
import PageHeader from 'src/layouts/PageHeader';
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { Excel } from 'antd-table-saveas-excel';
import { Receipt } from 'src/domain/payment/receiptType';
import receiptSrevice from 'src/services/payment/receiptSrevice';
import Item from 'antd/lib/list/Item';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ListReceiptReport: React.FC<Receipt> = () => {
    const [form] = Form.useForm();

    const [receipts, setReceipts] = useState<Receipt[]>([]);

    const defaultDate = {
        receiptDate: [moment().startOf('month'), moment().endOf('month')]
    };

    useEffect(() => {}, [receipts]);

    const handleContractNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("contractNo");
            if(_search) {
                (async () => {
                    const res = await receiptSrevice.searchForReport("", "", _search, "");
                    setReceipts(res.data)
                })();
            }
        }
    }

    const handleReceiptNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("receiptNo");
            if(_search) {
                (async () => {
                    const res = await receiptSrevice.searchForReport("", "", "", _search);
                    setReceipts(res.data)
                })();
            }
        }
    }

    const onFinish = (values: any) => {
        const _date = values.receiptDate;
        const _contractNo = values.contractNo;
        const _receiptNo = values.receiptNo;

        (async () => {
            const res = await receiptSrevice.searchForReport(
                _date ? _date[0].format('YYYYMMDD') : "" ,
                _date ? _date[1].format('YYYYMMDD') : "" ,
                _contractNo === undefined ? "" : _contractNo,
                _receiptNo === undefined ? "" : _receiptNo
                
            );
            setReceipts(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            receiptDate: [moment().startOf('month'), moment().endOf('month')],
            contractNo: "",
            receiptNo: "",
            name: ""
        });

        setReceipts([] as Receipt[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columns)
            .addDataSource(receipts)
            .saveAs("receipts_report.xlsx");
    }

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
      console.log('page property', page, pageSize);
      setOffset((page-1) * pageSize);
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: Receipt, index: number) => (
              offset + index+ 1
            ),
          },
        {
            title: "วันที่ใบเสร็จ",
            dataIndex: "receiptDate",
        },
        {
            title: "เลขที่ใบเสร็จ",
            dataIndex: "receiptNo",
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
        },
        {
            title: "เลขที่รับชำระ",
            dataIndex: "paymentNo",
        },
        {
            title: "ชื่อที่ออกใบเสร็จ",
            dataIndex: "name",
            render: (value: string, item: Receipt, index: number) => (
                item.prename + ' ' + item.name
            ),
        },
        {
            title: "จำนวนเงิน",
            dataIndex: "totalAmt",
            align: "right" as "right",
        },
        {
            title: "วันที่ยกเลิก",
            dataIndex: "cancelDate",
        },
    ];

  return (
    <div>
        <PageHeader title="รายงานใบเสร็จ" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>   
                        <div style={{marginLeft: "20px",width: "70%"}}>
                            <Form.Item
                                label="วันที่รับชำระ"
                                name="receiptDate"
                                key="receiptDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "30%"}}>

                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "35%"}}>
                            <Form.Item
                                label="เลขที่ใบเสร็จ"
                                name="receiptNo"
                                key="receiptNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหาเลขที่ใบเสร็จ' onKeyDown={handleReceiptNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "35%"}}>
                            <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหาเลขที่สัญญา' onKeyDown={handleContractNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{width: "30%",alignItems:'center'}}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                  columns={columns}
                  dataSource={receipts}
                  size="small"
                  rowKey={(item: Receipt) => item.receiptNo}
                  scroll={{ x: true }}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 20,
                  }}
                />
            </Panel>
        </Content>
    </div>
  )
}

export default ListReceiptReport