import { Button, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader'
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Account } from 'src/domain/account/accountType';
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import classes from "src/views/application/ListApplication.module.css";
import SearchAccountPopup from './SearchAccountPopup';
import accountService from 'src/services/account/accountService';
import {UnlockTwoTone, LockFilled} from '@ant-design/icons'

const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: Account, index: number) => (
        index+1
      ),
    },
    {
        title: "รหัส",
        dataIndex: "code",
        key: "code",
        render: (value: string, item: Account, index: number) => (
            <Link to={`/app/account/view/${item.id}`}>{value}</Link>
        ),
    },
    {
        title: "ชื่อ",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "ประเภท",
        dataIndex: "type",
        key: "type",
        
    },
    {
        title: "ด้าน",
        dataIndex: "side",
        key: "side",
    },
    {
        title: "LEVEL",
        dataIndex: "accLevel",
        key: "accLevel",
    },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
    },
    {
        title: "สถานะ",
        dataIndex: "useFlg",
        key: "useFlg",
        // render: (value: number, item: Account, index: number) => (
        //     value === 0 ? "ใช้งาน" : "ยกเลิก"
        // ),
        align: 'center' as 'center',
        render : (value : number) => (
            value === 0 ? "ใช้งาน" : <LockFilled label='ยกเลิก' capture='ยกเลิก'/>
        )
    },
];

const ListAccount: React.FC<Account> = () => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<Account[]>([] as Account[]);
    const [textSearch, setTextSearch] = useState<string>();

    useEffect(() => {
        (async () => {
          const res = await accountService.getAccountsUseOnly();
          setAccounts(res.data);
        })();
    },[])

    useEffect(() => {
        (async () => {
          const res = await accountService.searchByCodeOrName(textSearch ?? '');
          setAccounts(res.data);
        })();
    },[textSearch])

    const showModal = () => {
        setModalVisible(true);
    }

    const onSearch = (value: string) => {
        setModalVisible(false);
        setTextSearch(value);
    }

    return (
        <div>
            <PageHeader title="กำหนดรายการบัญชี">
                <Button
                    size="large"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => history.push("/app/account/new")}
                    >
                    New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div style={{display: "block", width: "100%", textAlign:"end" }}>
                    <Button
                        size="large"
                        shape="circle"
                        icon={<SearchOutlined />}
                        onClick={() => showModal()}
                    />
                </div>
                <div className={classes.dataPanel} style={{ marginTop: "10px" }}>
                    <Table 
                        columns={columns}
                        dataSource={accounts}
                        size="small"
                        rowKey={(item: Account) => item.code}
                    />
                </div>
            </Content>

            <SearchAccountPopup
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onSearch={onSearch}
            />
        </div>
    );
};

export default ListAccount;
