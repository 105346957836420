import http, { authConfig } from "../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/application";
// const url = "http://192.168.1.37:8080/application";
const url = `${configs.urlRoot}/dashboard`;

const forDashboard = (startDate: string, endDate: string, branchCode: string) => {
    let search = {startDate, endDate, branchCode};
    return http.post(`${url}`, {}, {params: search});
}

const forDashboardChart = (startDate: string, endDate: string, branchCode : string) => {
    let search = {startDate, endDate, branchCode};
    return http.post(`${url}/forChart`, {}, {params: search});
}

export default {
    forDashboard,
    forDashboardChart
}