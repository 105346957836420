import React, { useState } from 'react'
import CampaignForm from './CampaignForm';

interface IProps {
    handleOk : () => void
}
const NewCampaign : React.FC<IProps> = (props) => {
    const [trigger, setTrigger] = useState<boolean>(false);
    const _handleOk = props.handleOk;

    const updateData = (data : any) => {
        // console.log("new campaign", data)
        // _handleOk();
    }

    return (
        <div>
            <CampaignForm id={0} mode='NEW' />
        </div>
    );
};

export default NewCampaign;