import { Descriptions, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';

import moment from 'moment';
import 'moment/locale/th';
import { Campaign } from 'src/domain/master/CampaignType';
import campaignService from 'src/services/master/campaignService';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import { Deposit } from 'src/domain/payment/depositType';
import { PaymentModel } from 'src/domain/payment/paymentModelType';

const { Title} = Typography;
const dateFormat = "DD/MM/YYYY";

interface IProps {
    payment: PaymentModel;
    contractDebit: ContractDebit;
    campaign: Campaign;
    campaignHead: CampaignHead;
    deposit: Deposit;
}

const ShowContract : React.FC<IProps> = (props) => {
    const {payment, contractDebit, campaign, campaignHead, deposit} = props;    

    return (
        <div>
            <Content className="app-page-content">
                {/* <div style={{ padding: "0px 20px" }}> */}
                    <Descriptions
                        column={1}
                        // size="middle"
                        bordered
                        // layout="vertical"
                    >
                        <Descriptions.Item
                            label="เลขที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.contractNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.contractDate ? moment(payment.contractDate).format(dateFormat) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="CAMPAIGN :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.campaignName}
                            {/* {campaign && campaign.campaignName} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ยอดจัด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {Number(Number(payment.loanAmt).toFixed(2)).toLocaleString('th-TH')}
                            {/* {campaignHead && Number(Number(campaignHead.loanAmt).toFixed(2)).toLocaleString('th-TH')} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ยอดทั้งหมด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {Number(Number(payment.principalInterst).toFixed(2)).toLocaleString('th-TH')}
                            {/* {campaignHead && Number(Number(campaignHead.principalInterst).toFixed(2)).toLocaleString('th-TH')} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="จำนวนงวด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {/* {campaignHead && campaignHead.tenor} */}
                            {payment.tenor}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ชำระ/งวด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {Number(Number(payment.loanInstallment).toFixed(2)).toLocaleString('th-TH')}
                            {/* {campaignHead && Number(Number(campaignHead.installment).toFixed(2)).toLocaleString('th-TH')} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="งวดค้างชำระ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {(payment.tenor - contractDebit.month+1)}
                            {/* {campaignHead && (campaignHead.tenor - contractDebit.month+1)} */}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="งวดปัจจุบัน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractDebit.month}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ชำระทุกวันที่ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.contractDate ? payment.contractDate.substring(6,8) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่ชำระล่าสุด :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.lastPaymentDate ? moment(payment.lastPaymentDate).format(dateFormat) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="รับฝากคงเหลือ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {deposit ? Number(Number(deposit.totalAmt).toFixed(2)).toLocaleString('th-TH') : 0}
                        </Descriptions.Item>
                    </Descriptions>
                    <br/>
                    <hr/>
                    <Typography>
                        <Title level={4}>ข้อมูลชำระแล้ว</Title>
                    </Typography>
                    <Descriptions
                        column={1}
                        bordered
                    >
                        <Descriptions.Item
                            label="เงินต้น :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.totalPrincPepaid ? Number(Number(payment.totalPrincPepaid).toFixed(2)).toLocaleString('th-TH') : 0}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ดอกเบี้ย :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.totalInterestRevenues ? Number(Number(payment.totalInterestRevenues).toFixed(2)).toLocaleString('th-TH') : 0}
                        </Descriptions.Item>
                    </Descriptions>
                {/* </div> */}
            </Content>
        </div>
    )
}

export default ShowContract
