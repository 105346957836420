import React, { useEffect, useState } from "react";
import classes from "./ModernAppLayout.module.css";
import styled from "styled-components";
import SideNavModule from "./SideNavModule";
import SideNavMenu from "./SideNavMenu";
import Text from "antd/lib/typography/Text";
import { MessageSquare, Volume2 } from "react-feather";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useAppSelector } from "src/store";

interface IProps {
  children?: React.ReactNode;
}

const menuModules = [
  // { id: 1, name: "overview", icon: "BlockOutlined", code: "001" },
  { id: 2, name: "Post", icon: "BookOutlined", code: "002" },
  { id: 3, name: "Counter", icon: "TableOutlined", code: "003" },
  { id: 4, name: "School", icon: "TeamOutlined", code: "004" },
  { id: 5, name: "Security", icon: "SecurityOutlined", code: "005" },
];

const menus = [
  // {
  //   moduleCode: "001",
  //   moduleMenus: [{ id: 1, name: "Overview", path: "/app/dashboard" }],
  // },
  {
    moduleCode: "002",
    moduleMenus: [
      { id: 2, name: "Application", path: "/app/application" , roles:["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"]},
      { id: 3, name: "Contract", path: "/app/contract", roles: ["SUPERVISOR", "ADMIN"] },
      { id: 13, name: "BankTransfer", path: "/app/banktransfer", roles: ["SUPERVISOR", "ADMIN"] },
      { id: 4, name: "Payment", path: "/app/payment", roles: ["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"] },
      { id: 5, name: "Contract Redebt", path: "/app/contractredebt" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 6, name: "Application Report", path: "/app/report/application" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 7, name: "Contract Report", path: "/app/report/contract" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 8, name: "Contract Duedate Report", path: "/app/report/contractDuedate" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 9, name: "Contract At Date Report", path: "/app/report/contractAtDate" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 10, name: "Payment Report", path: "/app/report/payment" , roles:["SUPERVISOR", "ADMIN"]},
      { id: 11, name: "Receipt Report", path: "/app/report/receipt" , roles:["SUPERVISOR", "ADMIN"]},
    ],
  },
  {
    moduleCode: "003",
    moduleMenus: [
      // { id: 5, name: "Counter", path: "/app/counter" }
      { id: 5, name: "Campaign", path: "/app/campaign", roles: ["SUPERVISOR", "ADMIN"] },
      { id: 6, name: "Branch", path: "/app/master/branch", roles: ["SUPERVISOR", "ADMIN"]},
      { id: 7, name: "Bank", path: "/app/master/bank", roles: ["SUPERVISOR", "ADMIN"] },
      { id: 8, name: "Class", path: "/app/master/class", roles: ["ADMIN"] },
    ],
  },
  {
    moduleCode: "004",
    moduleMenus: [
      // { id: 6, name: "Teacher", path: "/app/teacher" },
      // { id: 7, name: "Student", path: "/app/student" },
      // { id: 8, name: "Subject", path: "/app/subject" },
      // { id: 9, name: "Officer", path: "/app/officers" },
      { id: 9, name: "Account", path: "/app/account", roles: ["SUPERVISOR", "ADMIN"]  },
      { id: 10, name: "Book", path: "/app/accbook" , roles: ["SUPERVISOR", "ADMIN"]},
    ],
  },
  {
    moduleCode: "005",
    moduleMenus: [{ id: 11, name: "User", path: "/app/security/user", roles: ["ADMIN"] }],
  },
  {
    moduleCode: '999',
    moduleMenus: [{ id: 12, name: 'Change password', path: '/app/profile/changepassword', roles: ["BRANCH_USER", "BRANCH_MANAGER","SUPERVISOR", "ADMIN"]}]
  }
];

const ModernAppLayout: React.FC<IProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any>([]);
  const { sessionUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    const moduleItem = menus.find((module) => module.moduleCode === "002");
    setMenuItems(moduleItem?.moduleMenus);
  },[])

  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };

  const handleMenuClick = () => {
    setShowMenu(false);
  }

  const handleSelectModule = (moduleCode: string) => {
    console.log("module code", moduleCode);
    const moduleItem = menus.find((module) => module.moduleCode === moduleCode);
    setMenuItems(moduleItem?.moduleMenus);
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.sideNav}>
        <SideNavModule
          showMenu={showHandler}
          onSelectModule={handleSelectModule}
          menuModules={menuModules}
          menus={menus}
        />
        {showMenu && <SideNavMenu menuItems={menuItems} onMenuClick={handleMenuClick} />}
      </div>

      <div
        style={{
          backgroundColor: "#f1f2f7",
          width: "100%",
          // overflow: "hidden",
          height: "100%",
          paddingLeft: showMenu ? "285px" : "60px",
        }}
      >
         {
          (process.env.REACT_APP_BUILD_LABEL === 'Dev' || process.env.REACT_APP_BUILD_LABEL === 'Staging') && (
            <div style={{position: "sticky", zIndex: 1031, top: '0px', display: 'flex', justifyContent: 'center', width: '100%', height: '3px',  backgroundColor: '#e91437'}}>
              <div style={{ position: 'relative',top: '0px', textAlign: 'center', lineHeight: '25px', height: '25px', width: '100px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', color: '#ffffff', backgroundColor: '#e91437'}} >
                {
                  process.env.REACT_APP_BUILD_LABEL === 'Dev'
                    ? "ทดสอบ:DEV"
                    : "ทดสอบ"}
              </div>
            </div>
          )
        }

        <div className={classes.notificationBar}>
          <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <Text>Branch: <span style={{fontWeight:700 }}>{sessionUser.branch}</span></Text>
            <Text>User: <span style={{fontWeight:700 }}>{sessionUser.displayName}</span></Text>
          </div>
          <div>
            <ul className={classes.notificationContainer}>
              <li className={classes.notificationItem}>
                <Volume2 strokeWidth={1} />
              </li>
              <li className={classes.notificationItem}>
                <MessageSquare strokeWidth={1} />
              </li>
            </ul>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            padding: "0px 50px 0px 50px",
          }}
        >
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModernAppLayout;
