import { Button, Form, Table, DatePicker, Space } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import { BankTransferHead } from 'src/domain/banktransfer/bankTransferHeadType'
import { useHistory } from 'react-router-dom';
import classes from "src/views/application/ListApplication.module.css";
import { Link } from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import bankTransferService from 'src/services/banktransfer/bankTransferService';
import { ColumnsType } from 'antd/lib/table';
import Panel from 'src/components/container/Panel';


const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";



const ListBankTransfer: React.FC<BankTransferHead> = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [bankTransfer, setBankTransfer] = useState<BankTransferHead[]>([]);

    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    const columns: ColumnsType<BankTransferHead> = [
        {
          title: "ลำดับ",
          dataIndex: "id",
          key: "id",
          render: (value: string, item: BankTransferHead, index: number) => (
            offset + index+ 1
          ),
        },
        {
          title: "วันที่โอนเงิน",
          dataIndex: "transferDate",
          key: "transferDate",
          render: (value: string, item: BankTransferHead, index: number) => (
            <Link to={`/app/banktransfer/view/${item.id}`}>{value ? moment(value).format(dateFormat) : ""}</Link>
          ),
          sorter: {
            compare: (a: BankTransferHead, b: BankTransferHead) => a.transferDate.localeCompare(b.transferDate),
          },
          defaultSortOrder: 'ascend',
        },
        {
          title: "จำนวนรายการที่โอน",
          dataIndex: "itemCount",
          key: "itemCount",
          align: "right" as "right",
        },
        {
          title: "ยอดเงินโอน",
          dataIndex: "transferAmt",
          key: "transferAmt",
          align: "right" as "right",
          render: (value: number) => Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
          sorter: {
            compare: (a: BankTransferHead, b: BankTransferHead) => a.transferAmt - b.transferAmt,
          },
        },
        {
          title: "ผู้โอน",
          dataIndex: "createUserName",
          key: "createUserName",
        },
    ];

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
        console.log('page property', page, pageSize);
        setOffset((page-1) * pageSize);
    }

    useEffect(() => {
        (async () => {
            const momentStartDate = moment().startOf('month');
            const momentEndDate = moment().endOf('month');

            setStartDate(momentStartDate.format(dateFormat));
            setEndDate(momentEndDate.format(dateFormat));

            const res = await bankTransferService.getHeadDataByDate(
                momentStartDate.format('YYYYMMDD'),
                momentEndDate.format('YYYYMMDD'));

            form.setFieldsValue({
                transferDate: [
                    moment(momentStartDate.format(dateFormat), dateFormat),
                    moment(momentEndDate.format(dateFormat), dateFormat),
                ]
            });


    // const defaultDate = {
    //     transferDate: [moment().startOf('month'), moment().endOf('month')]
    // };

//     useEffect(() => {
//         (async () => {
//             const startDate = moment().startOf('month').format('YYYYMMDD');
//             const endDate = moment().endOf('month').format('YYYYMMDD');
//             const res = await bankTransferService.getHeadDataByDate(startDate, endDate);
// >>>>>>> main
            setBankTransfer(res.data);
        })();

    }, []);

    useEffect(() => {},[bankTransfer]);

    const handleReset = async () => {
        const momentStartDate = moment().startOf('month');
        const momentEndDate = moment().endOf('month');

        setStartDate(momentStartDate.format(dateFormat));
        setEndDate(momentEndDate.format(dateFormat));

        form.setFieldsValue({
            transferDate: [
                moment(momentStartDate.format(dateFormat), dateFormat),
                moment(momentEndDate.format(dateFormat), dateFormat),
            ]
        });

        const res = await bankTransferService.getHeadDataByDate(
            momentStartDate.format('YYYYMMDD'),
            momentEndDate.format('YYYYMMDD'));
        setBankTransfer(res.data);
    }

    const onFinish = async (values: any) => {
        if(values.transferDate !== undefined) {
            const _startDate = values.transferDate[0].format("YYYYMMDD");
            const _endDate = values.transferDate[1].format("YYYYMMDD");

            (async () => {
                const res = await bankTransferService.getHeadDataByDate(_startDate, _endDate);
                setBankTransfer(res.data);
            })();
        }
    }

  return (

		<div>
			<PageHeader title="รายการโอนเงิน" showSubtitle={false}>
				<Button
					size="large"
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => history.push("/app/banktransfer/new")}
				>
					New
				</Button>
			</PageHeader>
			<Content className="app-page-content">
				<Form
					form={form}
					name="basic"
					layout="vertical"
					onFinish={onFinish}
					size="large"
					// onFinishFailed={onFinishFailed}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							width: "100%",
							marginBottom: "20px",
						}}
					>
						<Form.Item
							label="วันที่โอนเงิน"
							name="transferDate"
							key="transferDate"
						>
							{(startDate || endDate) && (
								<RangePicker
									locale={th_loc}
									format={dateFormat}
								/>
							)}
						</Form.Item>
                        <Space size={'large'} style={{marginLeft: 20}}>
                            <Button
                                icon={<SearchOutlined />}
                                size="large"
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                ค้นหา
                            </Button>
                            <Button type="link" onClick={handleReset}>
                                Reset
                            </Button>
                        </Space>
					</div>
				</Form>
				<div
					className={classes.dataPanel}
					style={{ marginTop: "10px" }}
				>
                    <h4>รายการ</h4>
					<Table
						columns={columns}
						dataSource={bankTransfer}
						size="small"
						rowKey={(item: BankTransferHead) => item.id}
                        pagination={{
                            onChange: handlePageChange,
                            pageSize: 20,
                          }}
					/>
				</div>
			</Content>
		</div>
  );

}

export default ListBankTransfer