import React from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { Button, Space, Tabs } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Key, Lock, UserX, ArrowLeft, X } from "react-feather";
//
import classes from "./UserDetail.module.css";
import PageHeader from "src/layouts/PageHeader";
import Panel from "../../../components/container/Panel";
import UserDetailHeader from "./UserDetailHeader";
import UserAccountPane from "./UserAccountPane";

interface IParams {
  userId: string;
}

const { TabPane } = Tabs;

const UserDetail: React.FC<RouteComponentProps<IParams>> = (props) => {
  const userId = props.match.params.userId;
  const history = useHistory();

  return (
    <div>
      <div className={classes.container}>
        <UserDetailHeader />
        {/* ******* Content ******* */}
        <div style={{ marginTop: 20, marginLeft: 120 }}>
          <Tabs defaultActiveKey="1" tabBarStyle={{ fontWeight: 500 }}>
            <TabPane tab="Account" key="1">
              <UserAccountPane />
            </TabPane>
            <TabPane tab="Station" key="2">
              <Panel style={{ minHeight: 400 }}>Station Register</Panel>
            </TabPane>
            <TabPane tab="Login Activity" key="3">
              <Panel style={{ minHeight: 400 }}>Login History Pane</Panel>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
