import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Descriptions, Form, InputNumber, Radio, Row, Space, Typography, DatePicker} from 'antd'
import {RightCircleOutlined} from '@ant-design/icons'
import Panel from 'src/components/container/Panel'
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import { PaymentModel } from 'src/domain/payment/paymentModelType';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import DFlex from '../../components/container/DFlex';
import paymentService from 'src/services/payment/paymentService';

const { Title, Paragraph, Text } = Typography;
const RadioButton = Radio.Button;

const dateFormat = "DD/MM/YYYY";

const color_bg_readonly = "#EAECEE"

interface IProps {
    next: () => void;
    payment: PaymentModel;
    setPayment: any;
    contractDebit: ContractDebit;
    updateฺBalance: any;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

const ContractDetail : React.FC<IProps> = (props) => {
    const {next, payment, setPayment, contractDebit, updateฺBalance} = props;
    const [form] = Form.useForm();

    const [counter, setCounter] = useState<number>(1);
    const [disableMinus, setDisableMinus] = useState<boolean>(false);
    const [disablePlus, setDisablePlus] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [penaltyAmount, setPenaltyAmount] = useState<number>(0);
    // const [feeWaiver, setFeeWaiver] = useState<boolean>(false);
    const [fee, setFee] = useState<boolean>();
    const [_feePenalty, set_FeePenalty] = useState<boolean>();
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const options_paytypes = [{label:'เงินสด',value:'CASH'}, {label:'QR',value:'QR'}]

    useEffect(() => {
        setDisableMinus(counter <= 1);
        setDisablePlus(counter >= 1);
        // if (onChange !== undefined) onChange(counter);
        if(contractDebit && contractDebit.installment) setTotalAmount(counter * contractDebit.installment);
    }, [counter]);

    useEffect(() => {
        setFee(payment.feeWaiver);
        set_FeePenalty(payment.feePenalty);
        setPenaltyAmount(payment.penaltyAmt);
        setIsVisible(payment.paymentInstallmentType === 'normal');
        calTotalAmt();
    },[payment]);

    useEffect(() => {},[isVisible]);

    useEffect(() => {
        if(contractDebit) {
            calTotalAmt();
        }
        form.setFieldsValue({
            month: contractDebit.month,
            installment_amt: payment.loanInstallment,
            accrued_interest: payment.accruedInterest,
            penalty_amt: penaltyAmount,
            total_amt: totalAmount,
            fee_waiver: fee,
            fee_Penalty: _feePenalty,
            payment_date: payment.paymentDate ? moment(payment.paymentDate, 'YYYY-MM-DD') : "",
            remaining_principal: payment.remainingPrincipal,
            close_interest: payment.closeInterest,
        });
    },[contractDebit, totalAmount, payment]);

    useEffect(() => {
        calTotalAmt();
    }, [penaltyAmount, fee, _feePenalty]);

    const minus = () => {
        setCounter((x) => x - 1);
    };

    const plus = () => {
        setCounter((x) => x + 1);
    };

    const onPenaltyKeyDown = (event: any) => {
        const _value = form.getFieldValue("penalty_amt");

        setPenaltyAmount(_value);
    }

    const onChangeFeeWaiver = (e: CheckboxChangeEvent) => {
        setFee(e.target.checked);
    }

    const onChangeFeePenalty = (e: CheckboxChangeEvent) => {
        set_FeePenalty(e.target.checked);
    }

    const calTotalAmt = () => {
        const _fee_waiver = form.getFieldValue("fee_waiver");
        const _fee_amt = _fee_waiver ? (payment.accruedInterest*-1) : 0;

        if(payment.paymentInstallmentType === 'normal') {
            setTotalAmount((counter * payment.loanInstallment) + payment.accruedInterest + penaltyAmount + _fee_amt);
        } else {
            const _fee_Penalty = form.getFieldValue("fee_Penalty");
            const _fee_PenaltyAmt = _fee_Penalty ? (payment.penaltyAmt*-1) : 0;
            setTotalAmount(payment.remainingPrincipal + payment.closeInterest + payment.accruedInterest + _fee_amt + penaltyAmount + _fee_PenaltyAmt)
        }
    }

    const onChangePaymentDate = async (event: any) => {
        const value = event.format("YYYYMMDD");

        const startDatetime = moment(payment.lastPaymentDate, 'YYYY-MM-DD')
        if (startDatetime!=null) {
            if (event < startDatetime) {
                form.setFields([
                    {
                        name: 'payment_date',
                        errors: ['ไม่สามารถระบุวันที่ย้อนหลังเกินวันที่ชำระล่าสุดหรือวันที่สัญญาได้'],
                    },
                ]);
            } else {
                const _pay = {
                    ...payment,
                    paymentDate: value,
                }
                paymentService.calculateOverDue(_pay).then(res=>{
                    if (res.status === 200) {
                        setPayment(res.data);
                    }
                });
            }
        }
        
    }

    const onFinish = (values: any) => {
        let x: any = {
            paymentType: values.pay_type,
            penaltyAmt : values.penalty_amt,
            amount: totalAmount,
            feeWaiver : fee,//values.fee_waiver !== undefined ?? values.fee_waiver
            feePenalty : _feePenalty,
            paymentDate: values.payment_date.format('YYYYMMDD'),
        }
        updateฺBalance(x);
        next();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> ContractDetail:", errorInfo);
    }

    return (
        <div>
            <Form
                {...layout}
                form={form}
                name="basic"
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{pay_type: 'CASH'}}
            >
                <Panel>
                    <Typography>
                        <Title level={3}>ข้อมูลส่วนบุคล</Title>
                    </Typography>
                    <Descriptions
                        column={2}
                        size="middle"
                        labelStyle={{ width: 120, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            span={2}
                            label="ชื่อ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.preName} {payment.name}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เลขที่บัตร :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.idCard}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เบอร์โทร :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.mobile}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label="ที่อยู่ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {payment.addressText}
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>

                <Panel style={{marginTop: 10,}}>
                    <Typography>
                        <Title level={3}>ข้อมูลงวด</Title>
                        {isVisible ?
                        <Paragraph style={{textAlign:'right'}}>ชำระปกติ</Paragraph>
                        : 
                        payment.paymentInstallmentType === 'close' ?
                            <Paragraph style={{textAlign:'right', fontSize:'18px', fontWeight:'bold'}} type='danger'>ปิดสัญญา</Paragraph>
                            :
                            <Paragraph style={{textAlign:'right', fontSize:'18px', fontWeight:'bold'}} type='danger'>ปิดสัญญา (ปรับโครงสร้างหนี้)</Paragraph>
                        }
                        
                    </Typography>
                    <Form.Item
                        label="จำนวนงวดที่จ่าย"
                        name="installment_pay"
                        key="installment_pay"
                        style={{ display: isVisible ? '' : 'none' }}
                    >
                        <Row justify="center" >
                            <Col span={22} style={{ textAlign: "center", display: "flex" }}>
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    danger
                                    onClick={() => minus()}
                                    disabled={disableMinus}
                                >
                                    -
                                </Button>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                    <Typography.Title level={3}>{counter}</Typography.Title>
                                </div>
                                <Button
                                    shape="circle"
                                    type="primary"
                                    size="small"
                                    onClick={() => plus()}
                                    disabled={disablePlus}
                                >
                                    +
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item
                        label="วันที่รับชำระ"
                        name="payment_date"
                        key="payment_date"
                        rules={[
                            {
                                type: 'date',
                                required: true,
                                message: 'กรุณาระบุวันที่สัญญา!',
                                whitespace: true,
                            },
                            {
                                type: 'date',
                                required: true,
                                message: 'ไม่สามารถระบุวันที่ย้อนหลังเกินวันที่ชำระล่าสุดหรือวันที่สัญญาได้!',
                                whitespace: true,
                                validator : async (_, endDatetime) => {
                                    const startDatetime = moment(payment.lastPaymentDate, 'YYYY-MM-DD')
                                    if (startDatetime!=null)
                                      if (endDatetime < startDatetime) {
                                        return Promise.reject(
                                          "Must be greater than Start Date"
                                        );
                                      }
                                    }
                            },
                        ]}
                    >
                        <DatePicker
                            locale={th_loc}
                            format={dateFormat}
                            size="large"
                            onChange={onChangePaymentDate}
                        />
                    </Form.Item>
                    <Form.Item 
                        label="วันครบกำหนดชำระ"
                        style={{fontWeight:"bold"}}
                    >
                        {payment.dueDate ? moment(payment.dueDate).format(dateFormat) : ""}
                    </Form.Item>
                    
                    <Form.Item 
                        label="งวดที่" 
                        name="month"
                        key="month"
                        style={{ display: isVisible ? '' : 'none' }}
                    >
                        {/* <Row>
                            <Col>
                                <Form.Item
                                    name="month"
                                    key="month"
                                > */}
                                    <InputNumber
                                        readOnly={true}
                                        style={{ width: 150, backgroundColor:color_bg_readonly}}
                                        precision={0}
                                        size={'large'}
                                    />
                                {/* </Form.Item>
                            </Col>
                            <Col style={{marginLeft:"30px", fontWeight:"bold"}}>
                                <label>วันครบกำหนดชำระ {payment.dueDate ? moment(payment.dueDate).format(dateFormat) : ""}</label>
                            </Col>
                        </Row> */}
                    </Form.Item>

                    <Form.Item
                        label="ค่างวด"
                        name="installment_amt"
                        key="installment_amt"
                        style={{ display: isVisible ? '' : 'none' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size={'large'}
                        />
                    </Form.Item>
                    <Form.Item
                        label="เงินต้น"
                        name="remaining_principal"
                        key="remaining_principal"
                        style={{ display: isVisible ? 'none' : '' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size={'large'}
                        />
                    </Form.Item>
                    <Form.Item
                        label="ดอกเบี้ย"
                        name="close_interest"
                        key="close_interest"
                        style={{ display: isVisible ? 'none' : '' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size={'large'}
                        />
                    </Form.Item>
                    <Form.Item label="ดอกเบี้ยค้างชำระ">
                        <Row>
                            <Col>
                                <Form.Item name="accrued_interest">
                                    <InputNumber
                                        readOnly={true}
                                        style={{ width: 150, backgroundColor:color_bg_readonly}}
                                        precision={2}
                                        size={'large'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{marginLeft:"30px", fontWeight:"bold"}}>

                                <Form.Item name="fee_waiver" valuePropName='checked'>
                                    {payment.accruedInterest !== 0 &&
                                        <Checkbox onChange={onChangeFeeWaiver}>ยกเว้นดอกเบี้ยค้างชำระ</Checkbox>
                                    }
                                </Form.Item>

                            </Col>
                        </Row>
                        {payment.overdue > 0 &&
                            <Row>
                                <Col><Space /></Col>
                                <Col>
                                    <label style={{color:payment.overdue < 3 ? "" : "red", fontWeight:"bold"}} >**เกินกำหนดชำระ {payment.overdue} วัน**</label>
                                </Col>
                            </Row>
                        }
                    </Form.Item>

                    <Form.Item
                        label="ค่าปรับ"
                    >
                        <Row>
                            <Col>
                                <Form.Item
                                    name="penalty_amt"
                                    key="penalty_amt"
                                >
                                    <InputNumber
                                        readOnly={!isVisible}
                                        // style={{ width: 150, backgroundColor: isVisible ? color_bg_readonly : ''}}
                                        style={{ width: 150, backgroundColor: (!isVisible ? color_bg_readonly : '')}}
                                        precision={2}
                                        onChange={onPenaltyKeyDown}
                                        size={'large'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{marginLeft:"30px", fontWeight:"bold"}}>

                                <Form.Item name="fee_Penalty" valuePropName='checked'>
                                    {!isVisible && payment.penaltyAmt !== 0 &&
                                        <Checkbox onChange={onChangeFeePenalty}>ยกเว้นค่าปรับ</Checkbox>
                                    }
                                </Form.Item>

                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item
                        label="ยอดหนี้สุทธิ"
                        name="total_amt"
                        key="total_amt"
                        style={{fontWeight:"bold"}}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly, fontWeight:"bold"}}
                            precision={2}
                            size={'large'}
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                </Panel>

                <Panel style={{marginTop: 10, alignItems:"center"}}>
                    <Typography>
                        <Title level={3}>ประเภทการชำระ</Title>
                    </Typography>
                    <Form.Item
                        label="การรับเงิน"
                        name="pay_type"
                        rules={[
                            { required: true, message: "กรุณาเลือกประเภทการรับเงิน!" },
                        ]}
                    >
                        <Radio.Group
                            buttonStyle="solid"
                            size="large"
                        >
                            {options_paytypes.map((opt_value) => (
                                <RadioButton
                                    key={opt_value.value}
                                    value={opt_value.value}
                                    disabled={(opt_value.value === 'QR') ? true : false}
                                >
                                    {opt_value.label}
                                </RadioButton>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </Panel>
                <DFlex type="row" justifyContent='flex-end' style={{ marginTop: '20px'}}>
                	<Button
											className='outline'
											size="large"
											name="next"
											icon={<RightCircleOutlined />}
											onClick={() => {
													form.submit();
											}}
									>
											Next
									</Button>
                </DFlex>
            </Form>
        </div>
    )
}

export default ContractDetail
