import { Descriptions } from 'antd';
import React from 'react'
import Panel from 'src/components/container/Panel';
import { ApplicationGuarantor } from 'src/domain/application/applicationGuarantorType'

interface IProps {
    applicationGuarntor: ApplicationGuarantor;
}

const ViewGuarantorComponent: React.FC<IProps> = (props) => {
    const {applicationGuarntor} = props;

  return (
    <div>
        <Panel>
            <Descriptions
                // style={{ marginTop: "20px" }}
                column={2}
                size="middle"
                labelStyle={{ width: 200, fontWeight: "bold" }}
                bordered
            >
                <Descriptions.Item
                    label="คำนำหน้าชื่อ:"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    span={2}
                >
                    {applicationGuarntor.prenameEtcThai !== '' ? applicationGuarntor.prenameEtcThai : applicationGuarntor.prenameThaiName}
                </Descriptions.Item>
                <Descriptions.Item
                    label="ชื่อ-นามสกุล :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.nameThai}
                </Descriptions.Item>
                <Descriptions.Item
                    label="อายุ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.age}
                </Descriptions.Item>
                <Descriptions.Item
                    label="เชื้อชาติ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.origin}
                </Descriptions.Item>
                <Descriptions.Item
                    label="สัญชาติ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.nationality}
                </Descriptions.Item>
                <Descriptions.Item
                    label="เลขบัตร :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.idCard}
                </Descriptions.Item>
                <Descriptions.Item
                    label="ชื่อหมู่บ้าน/อาคารชุด :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.building}
                </Descriptions.Item>

                <Descriptions.Item
                    label="เลขที่ห้องชั้น"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.roomNo}
                </Descriptions.Item>
                <Descriptions.Item
                    label="บ้านเลขที่"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.address} 
                </Descriptions.Item>

                <Descriptions.Item
                    label="หมู่"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.moo} 
                </Descriptions.Item>

                <Descriptions.Item
                    label="ซอย :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.soi}
                </Descriptions.Item>
                <Descriptions.Item
                    label="ถนน :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {applicationGuarntor.road}
                </Descriptions.Item>

                <Descriptions.Item
                    label="ที่อยู่ :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                >
                    {"ตำบล"+applicationGuarntor.tambonName + " " + applicationGuarntor.amphurName + " จังหวัด" + applicationGuarntor.provinceName + " " + applicationGuarntor.zipcode}
                </Descriptions.Item>
            </Descriptions>
        </Panel>
        <br />
    </div>
  )
}

export default ViewGuarantorComponent