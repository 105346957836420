import PageHeader from 'src/layouts/PageHeader';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Book } from 'src/domain/account/book';
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import bookService from 'src/services/account/bookService';
import { Space, Tabs } from 'antd';
import ViewAccBookDoc from './ViewAccBookDoc';
import ViewAccBookPayee from './ViewAccBookPayee';
import ViewAccBookItem from './ViewAccBookItem';

const { TabPane } = Tabs;

interface IParams {
    id: string;
}

const ViewAccBook: React.FC<RouteComponentProps<IParams>> = (props) => {
    const _bookId = Number(props.match.params.id);
    const history = useHistory();

    const [book, setBook] = useState<Book>({} as Book);

    useEffect(() => {
        bookService.getBookById(_bookId).then(res => {
            setBook(res.data)
        });
    }, []);

  return (
    <div>
        <PageHeader title={'BOOK NO. '+book.docNo} onClose={() => history.push("/app/accbook")}>

        </PageHeader>
        <Content className="app-page-content">
            <Tabs defaultActiveKey="0">
                <TabPane tab="ข้อมูลเอกสาร" key="0" >
                    <Panel>
                        <ViewAccBookDoc book={book} />
                    </Panel>
                </TabPane>
                <TabPane tab="ข้อมูลผู้รับเงิน" key="1" >
                    <Panel>
                        <ViewAccBookPayee book={book} />
                    </Panel>
                </TabPane>
            </Tabs>
            <div style={{marginTop:8}}>
                <ViewAccBookItem bookId={_bookId} />
            </div>
        </Content>
    </div>
  )
}

export default ViewAccBook