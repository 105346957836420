import React from "react";
import { Button } from "antd";
import { Key, Lock, UserX, ArrowLeft, X } from "react-feather";
//
import classes from "./UserDetail.module.css";

const UserDetailHeader = () => {
  return (
    <div className={classes.userTitleContainer}>
      <div className={classes.userImage}>KW</div>
      <div className={classes.displayNameContainer}>
        <h3 className="font-weight-semibold" style={{ paddingLeft: 7 }}>
          Kumpu Wangboon
        </h3>
        <div className={classes.userTitleActionContainer}>
          <Button
            className="icon-feature bg-dark-gray"
            type="text"
            icon={<Key strokeWidth="2" size={14} style={{ marginRight: 5 }} />}
          >
            Reset Password
          </Button>
          <Button
            className="icon-feature bg-dark-gray"
            type="text"
            icon={<Lock strokeWidth="2" size={14} style={{ marginRight: 5 }} />}
          >
            Lock user
          </Button>
          <Button
            className="icon-feature bg-dark-gray"
            type="text"
            icon={
              <UserX strokeWidth="2" size={14} style={{ marginRight: 5 }} />
            }
          >
            Delete user
          </Button>
        </div>
      </div>
      <div style={{ justifySelf: "end" }}>
        <Button
          type="default"
          className="icon-feature"
          size="large"
          icon={<X strokeWidth="2" size={18} style={{ marginRight: 5 }} />}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UserDetailHeader;
