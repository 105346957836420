import { Button, DatePicker, Descriptions, Form, Input, message, Space, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {SearchOutlined, CloseCircleOutlined, SaveOutlined} from '@ant-design/icons';
import PageHeader from 'src/layouts/PageHeader';
import BankTransferForm from './BankTransferForm'
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import bankTransferService from 'src/services/banktransfer/bankTransferService';
import { useAppSelector } from "src/store";
import { BankTransferDetail } from 'src/domain/banktransfer/bankTransferDetailType';
import { BankTransferHead } from 'src/domain/banktransfer/bankTransferHeadType';

import moment from 'moment';
import 'moment/locale/th';
import { ColumnsType } from 'antd/lib/table';
import DFlex from '../../components/container/DFlex';
import loggingService from 'src/services/security/loggingService';
import messageUtils from 'src/utils/messageUtils';

const dateFormat = "DD/MM/YYYY";

interface IParams {
    id: string;
}

const EditBankTransfer : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);
    const history = useHistory();
    const [form] = Form.useForm();

    const [bankTransferHead, setBankTransferHead] = useState<BankTransferHead>({} as BankTransferHead);
    const [bankTransferDetails, setBankTransferDetails] = useState<BankTransferDetail[]>([]);
    const { sessionUser } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if(_id) {
            bankTransferService.getById(_id).then(res => {
                const _bankTransferHead = res.data;
                setBankTransferHead(_bankTransferHead);
                setBankTransferDetails(_bankTransferHead.details);
            });
        }
    },[_id]);

    const onFinish = async (values: any) => {
        if(bankTransferDetails.length > 0) {
            const res = await bankTransferService.updateBankTransactionNo( sessionUser.userId,
                sessionUser.username, sessionUser.branch, bankTransferDetails);
            if(!res.data.status) {
                message.error(res.data.message, 2);
                return;
            } else {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: "UPDATE",
                    target: "bank transfer",
                    systemRemark: 'banktransfer-edit-transactionno',
                    targetRef: ''+ _id,
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                });
                history.replace("/app/banktransfer/view/"+_id);
            }
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> EditBankTransfer:", errorInfo);
    }

    const columns: ColumnsType<BankTransferDetail> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: BankTransferDetail, index: number) => (
                index+1
            ),
            width: '50px'
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "reference",
            key: "reference",
            width: '160px'
        },
        {
            title: "เลขที่บัญชี",
            dataIndex: "bankAccountNo",
            key: "bankAccountNo",
            width: '120px'
        },
        {
            title: "ชื่อบัญชี",
            dataIndex: "accountName",
            key: "accountName",
            width: '120px'
        },
        {
            title: "ธนาคาร",
            dataIndex: "bankName",
            key: "bankName",
            width: '200px'
        },
        {
            title: "สาขา",
            dataIndex: "branchCode",
            key: "branchCode",
            width: '80px',
            align: 'center'
        },
        {
            title: "ยอดเงินโอน",
            dataIndex: "transferAmt",
            key: "transferAmt",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            ),
            width: '120px'
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            key: "email",
            width: '180px',
            ellipsis: true
        },
        {
            title: "เลขที่การโอน",
            dataIndex: "bankTransactionNo",
            key: "bankTransactionNo",
            render: (value: string, item: BankTransferDetail, index: number) => (
                <Form.Item
                    style={{
                        margin: 0,
                    }}
                    name={index}
                    rules={[
                        { required: true, message: "กรุณาระบุข้อมูล!" },
                    ]}
                >
                    {/* <Tooltip placement="topLeft" title={value}> */}
                        <Input
                            maxLength={20}
                            value={item.bankTransactionNo}
                            onBlur={(event: any) => {
                                const newData = [...bankTransferDetails];
                                const item = newData[index];
                                newData.splice(index, 1, { ...item, bankTransactionNo : event.target.value });
                                setBankTransferDetails(newData);
                            }}
                        />
                    {/* </Tooltip> */}
                </Form.Item>
            )
        },
    ];

    const onRowSummary = () => {
        if(bankTransferDetails !== undefined) {
            let total = 0;

            if(bankTransferDetails !== null && bankTransferDetails.length > 0) {
                bankTransferDetails.forEach(({transferAmt}) => {
                    total = total + (transferAmt ? transferAmt : 0);
                });
            }

            return (
                <>
                <Table.Summary.Row style={{fontSize:"16px", fontWeight:'bold'}}>
                    <Table.Summary.Cell index={0} colSpan={2} align={'right'}>รวมจำนวน</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{(bankTransferDetails !== null && bankTransferDetails.length > 0) ? bankTransferDetails.length : 0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} >รายการ</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} colSpan={2} align={'right'}>รวมเงินโอน</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{Number(Number(total).toFixed(2)).toLocaleString('th-TH')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} colSpan={2}>บาท</Table.Summary.Cell>
                </Table.Summary.Row>
                </>
            );
        }
    }

  return (
    <div>
        <PageHeader title="ระบุเลขที่การโอนเงิน" showSubtitle={false}>
            <Button
            size="large"
            icon={<CloseCircleOutlined />}
            onClick={() => history.goBack()}
            >
                Cancel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel style={{width: "100%"}}>
                <Descriptions
                    style={{ marginTop: "20px",  marginBottom: '20px' }}
                    column={1}
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        label="วันที่โอนเงิน :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {moment(bankTransferHead.transferDate).format(dateFormat)}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="หมายเหตุ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {bankTransferHead.remark}
                    </Descriptions.Item>
                </Descriptions>
                <Form
                    form={form}
                    name="basic"
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                <Table
                    size='small'
                    dataSource={bankTransferDetails}
                    columns={columns}
                    summary={onRowSummary}
                    rowKey={(item: BankTransferDetail) => item.id}
                    pagination={false}
                />

                <DFlex type='row' justifyContent='flex-end' style={{ marginTop: '20px'}}>
                  <Form.Item>
                      <Button
                          size="large"
                          type="primary"
                          name="save"
                          icon={<SaveOutlined />}
                          onClick={() => {
                              form.submit();
                          }}
                      >
                          Save
                      </Button>
                  </Form.Item>
                </DFlex>
            </Form>
            </Panel>
        </Content>
    </div>
  )
}

export default EditBankTransfer