import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/users";
const url = `${configs.urlRoot}/login`;

const login = async (username: string, password: string) => {
    return http.post(url, {username, password});
};
  
export default {
    login,
};