import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { ApplicationGuarantor } from 'src/domain/application/applicationGuarantorType';
import ViewGuarantorComponent from './ViewGuarantorComponent';

interface IProps {
    application : any;
}

const ViewGuarantorApplication : React.FC<IProps> = (props) => {
    const {application} = props;
    const [guarantorItems, setGuarantorItems] = useState<ApplicationGuarantor[]>([] as ApplicationGuarantor[]);
   
    useEffect(() => {
        if(application.guarantors != undefined) {
            setGuarantorItems(application.guarantors);
        }
    }, [application]);

  return (
    <div>
        {/* <Content className="app-page-content"> */}
            {guarantorItems.map((item, index, arrays) => (
                <ViewGuarantorComponent applicationGuarntor={item} />
            ))}
        {/* </Content> */}
    </div>
  )
}

export default ViewGuarantorApplication