export interface NcBank {
    id:number;
    bankCode:string;
    bankName:string;
    useFlg:number;
}

export const NcBankUseFlg = {
    NOT_USE: -1,
    USE: 0,
    SEARCH_ALL: 2,
}