import { Alert, DatePicker, Descriptions, Form, InputNumber, message, Modal, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { Application } from 'src/domain/application/applicationType'
import { Contract } from 'src/domain/contract/contractType'

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { ContractTransferDetail } from 'src/domain/contract/contractTransferDetailType';
import { ContractTransferHead } from 'src/domain/contract/contractTransferHeadType';
import contractTransferService from 'src/services/contract/contractTransferService';
import { useAppSelector } from "src/store";
import { useHistory } from 'react-router-dom';

import loggingService from '../../services/security/loggingService';
import messageUtils from '../../utils/messageUtils';

const {Title } = Typography;
const dateFormat = "DD/MM/YYYY";

interface IProps {
    modalVisible: boolean;
    contractId: number;
    onCancel: () => void;
}

const ContractTranferPopup: React.FC<IProps> = (props) => {
    const { modalVisible, contractId, onCancel } = props;
    const [form] = Form.useForm();
    const history = useHistory();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [contractTransfer, setContanctTransfer] = useState<ContractTransferHead>({} as ContractTransferHead);
    const [contractTransferDetails, setContractTransferDetails] = useState<ContractTransferDetail[]>([]);
    const [isNull, setIsNull] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);

    useEffect(() => {
        if(contractId && modalVisible) {
            (async () => {
                const res = await contractTransferService.calculateStampDuty(contractId);
                if(res.data === '') {
                    setIsNull(true);
                    onCancel();
                    messageUtils.errorMessage("No data found.")

                }
                else setIsNull(false);

                const _transfer = res.data;
                setContanctTransfer(_transfer);
                setContractTransferDetails(_transfer.details)
            })();
        }
    }, [contractId, modalVisible]);

    const onFinish = async (values: any) => {
        // console.log("contract transfer popup onFinish", values)
        // console.log("pay date", values.pay_date.format('YYYYMMDD'))
        setConfirmLoading(true);

        const _transfer : ContractTransferHead = {
            ...contractTransfer,
            payDate : values.pay_date.format('YYYYMMDD'),
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
            details : contractTransferDetails,
        }

        await contractTransferService.addContractTransfer(_transfer).then((res) => {
            // console.log("save=", res.status)
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'update-contract-transfer',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({
                        contactNo: _transfer.contractNo,
                        contactTransferDate: _transfer.payDate,
                    }),
                    target: 'contract',
                    targetRef: contractTransfer.contractNo
                });
                onCancel();
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err));
        });

        setConfirmLoading(false);
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: ContractTransferDetail, index: number) => (
                index+1
            ),
        },
        {
            title: "รายการ",
            dataIndex: "itemText",
            key: "itemText",
            render: (value: number, item: ContractTransferDetail, index: number) => (
                item.itemName !== 'loan' ? item.itemText + ' (' + item.rate + ' %)' : item.itemText
            ),
        },
        {
            title: "ยอดเงิน",
            dataIndex: "itemAmt",
            key: "itemAmt",
            align: "right" as "right",
            // render: (value: number) => Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
            render: (value: number, item: ContractTransferDetail, index: number) => (
                item.itemName === 'usage_fee' ? (
                <Form.Item
                    style={{
                    margin: 0,
                    }}
                >
                    <InputNumber
                        defaultValue={0}
                        value={value}
                        onBlur={(event: any) => {
                            let _feeAmt = event.target.value;
                            let _transferAmt = contractTransfer.loanAmt - contractTransfer.stampDuty - _feeAmt;
                            const _transfer = {
                                ...contractTransfer,
                                usageFee: _feeAmt,
                                transferAmt: _transferAmt
                            }

                            const newData = [...contractTransferDetails];
                            const _item = newData[index];
                            newData.splice(index, 1, {..._item, itemAmt: _feeAmt});
                            setContanctTransfer(_transfer);
                            setContractTransferDetails(newData);
                        }}  
                    />
                </Form.Item>
                ) 
                :
                Number(Number(value).toFixed(2)).toLocaleString("th-TH")
            )
        },
    ]

  return (
    <>
    {!isNull &&
    <Modal
        title="ข้อมูลการโอนเงิน"
        style={{ top: 100 }}
        width="60%"
        visible={modalVisible}
        // onOk={handleOK}
        confirmLoading={confirmLoading}
        okButtonProps={{form:'save-paydate-popup-form',  htmlType: 'submit'}}
        onCancel={() => onCancel()}
        destroyOnClose={true}
    >
        <Form
            form={form}
            layout="vertical"
            style={{ display: "flex", justifyContent: "center" }}
            id='save-paydate-popup-form'  onFinish={onFinish}
        >
            <Space size={1} direction="vertical">
                <Typography>
                    <Title level={3}>ข้อมูลสัญญา</Title>
                </Typography>
                <Descriptions
                    column={2}
                    size="middle"
                    labelStyle={{ width: 120, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        label="เลขที่สัญญา :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {contract.contractNo} */}
                        {contractTransfer.contractNo}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วันที่สัญญา :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {contract.contractDate ? moment(contract.contractDate).format(dateFormat) : ""} */}
                        {contractTransfer.contractDate ? moment(contractTransfer.contractDate).format(dateFormat) : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ชื่อบัญชี :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {application.accountName} */}
                        {contractTransfer.accountName}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="เลขที่บัญชี :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {application.bankAccountNo} */}
                        {contractTransfer.bankAccountNo}
                    </Descriptions.Item>
                    <Descriptions.Item
                        span={2}
                        label="ธนาคาร :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {application.bankCode} {application.bankName} */}
                        {contractTransfer.transferCode} {contractTransfer.transferName}
                    </Descriptions.Item>
                </Descriptions>
                <Table
                    columns={columns}
                    dataSource={contractTransferDetails}
                    size="small"
                    rowKey={(item: ContractTransferDetail) => item.itemName}
                    pagination={false}
                />

                <hr/>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "40%", flexDirection:"column"}}>
                        <Form.Item
                            label="วันที่โอนเงิน"
                            name="pay_date"
                            key="pay_date"
                            rules={[
                                {
                                    type: 'object',
                                    required: true,
                                    message: 'กรุณาระบุวันที่โอนเงิน!',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <DatePicker
                                locale={th_loc}
                                // onChange={onChangeContractDate}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </div>
                    <div style={{width: "60%",}}>
                        <Descriptions
                            column={1}
                            size="middle"
                            labelStyle={{ width: 120, fontWeight: "bold" }}
                            bordered
                        >
                            <Descriptions.Item
                                label="ยอดเงินโอน"
                                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff", fontSize:"16px" , color:"red"}}
                            >
                                <span style={{fontWeight: "bold", fontSize:"16px", color:"red"}}>
                                    {/* {Number(Number(transferAmt).toFixed(2)).toLocaleString('uth-TH')} */}
                                    {Number(Number(contractTransfer.transferAmt).toFixed(2)).toLocaleString('uth-TH')}
                                    <label style={{fontWeight: "bold", fontSize:"16px", color:"red", marginLeft:"10px"}}>บาท</label>
                                </span>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </div>
            </Space>
        </Form>
    </Modal>
}
    </>
  )

}

export default ContractTranferPopup