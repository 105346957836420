import http from "../../lib/http";
import configs from "src/constants/config";
// const url = "http://localhost:8080/deposit";
const url = `${configs.urlRoot}/deposit`;

const addDeposit = (deposit : any) => {
    return http.post(url, deposit);
}

const editDeposit = (deposit: any) => {
    return http.put(url+"/update", deposit);
}

const getByContract = (contractNo: string) => {
    return http.get(url+"/byContract?no=" + contractNo);
}

const getByPaymentNoAndType = (paymentNo: string, type: string) => {
    return http.get(url+"/byPayandType/"+paymentNo+"/"+type);
}

export default {
    addDeposit,
    editDeposit,
    getByContract,
    getByPaymentNoAndType,
}