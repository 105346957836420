import { Descriptions } from 'antd';
import React from 'react'
import moment from 'moment';
import 'moment/locale/th';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    book : any;
}

const ViewAccBookDoc: React.FC<IProps> = (props) => {
    const {book} = props;
  return (
    <div>
        <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            // title="Personal Information"
            size="middle"
            labelStyle={{ width: 200, fontWeight: "bold" }}
            bordered
        >
            <Descriptions.Item
                label="วันที่สร้าง :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {moment(book.docDate).format(dateFormat) ?? ''}
            </Descriptions.Item>
            <Descriptions.Item
                label="บริษัท :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.companyName}
            </Descriptions.Item>

            <Descriptions.Item
                label="วันที่บันทึกบัญชี :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.accSaveDate ? moment(book.accSaveDate).format(dateFormat) : ''}
            </Descriptions.Item>
            <Descriptions.Item
                label="ประเภทสมุด :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.bookTypeCode} {book.bookTypeName}
            </Descriptions.Item>

            <Descriptions.Item
                label="เลขที่อ้างอิง :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.refDocNo}
            </Descriptions.Item>
            <Descriptions.Item
                label="ชื่อสมุด :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.bookCodeCode} {book.bookCodeName}
            </Descriptions.Item>

            <Descriptions.Item
                label="หมายเหตุ :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.remark}
            </Descriptions.Item>
        </Descriptions>
    </div>
  )
}

export default ViewAccBookDoc