import React, { useState, useEffect } from "react";
import { Input, Modal, Space } from "antd";
import { Form } from "antd";
import { CSSProperties } from "styled-components";
import { useDispatch } from "react-redux";
import { thunkPromise, useAppDispatch, useAppSelector } from "../../../store";
import {
  // createUser,
  CreateUserCommand,
  createUser,
} from "../../../store/user/action";

interface IProps {
  style?: CSSProperties;
  modalVisible: boolean;
  onOK: (value: any) => void;
  onCancel: () => void;
}

interface IFormModel {
  username: string;
  password: string;
  confirmPassword: string;
  name: string;
  email: string;
}

const CreateUserModal: React.FC<IProps> = (props) => {
  const { modalVisible, onOK, onCancel } = props;
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.userState);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!modalVisible) return;

    const formModel: IFormModel = {
      username: "admin",
      password: "adminadmin",
      confirmPassword: "adminadmin",
      name: "Administrator",
      email: "admin@mail.com",
    };

    form.setFieldsValue(formModel);
  }, [modalVisible]);

  const handleFinish = (values: any) => {
    console.log("submit values ; ", values);
    const command: CreateUserCommand = {
      username: values.username,
      password: values.password,
      confirmPassword: values.confirmPassword,
      name: values.name,
      email: values.email,
    };

    dispatch(
      thunkPromise<number, CreateUserCommand>(createUser, command)
    ).then((res) => {
      onOK({ id: res });
    });

    // dispatch(createUser(command)).then((res) => {
    //   console.log("Promise ", res);
    //   onOK({ id: res });
    // });
    // setLoading(userState.processing);
  };

  const handleOK = () => {
    form.submit();
    // setLoading(true);
    // setTimeout(() => {
    //   onOK({ id: 1 });
    //   setLoading(false);
    // }, 2000);
  };

  return (
    <Modal
      title="New user"
      style={{ top: 70 }}
      visible={modalVisible}
      confirmLoading={userState.processing}
      onOk={handleOK}
      onCancel={() => onCancel()}
    >
      <Form
        form={form}
        name="form-user"
        layout="vertical"
        onFinish={handleFinish}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Space size="small" direction="vertical">
          <Form.Item label="Username" name="username">
            <Input placeholder="Enter username" />
          </Form.Item>

          <Space size={10}>
            <Form.Item label="Password" name="password">
              <Input placeholder="Enter password" />
            </Form.Item>
            <Form.Item label="Confirm Password" name="confirmPassword">
              <Input placeholder="Confirm password" />
            </Form.Item>
          </Space>

          <Form.Item label="Name" name="name">
            <Input placeholder="Enter fullname / displayname" />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input placeholder="Enter email for contract" />
          </Form.Item>

          <span>users : {userState.users.length}</span>
        </Space>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
