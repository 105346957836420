import React from 'react'
import { RouteComponentProps } from 'react-router';
import CampaignHeadForm from './CampaignHeadForm';

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IParams {
    id : string;
}
const NewCampaignHead : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _campaignId = Number(props.match.params.id);
    
    return (
        <div>
            <CampaignHeadForm mode='NEW' campId={_campaignId} headId={0}/>
        </div>
    )
}

export default NewCampaignHead
