import { Button, Descriptions, message, Space, Table, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { BankTransferHead } from 'src/domain/banktransfer/bankTransferHeadType';
import PageHeader from 'src/layouts/PageHeader'
import {FileExcelOutlined, DollarCircleOutlined, TableOutlined, FileExcelFilled} from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';
import { Excel } from "antd-table-saveas-excel";

import moment from 'moment';
import 'moment/locale/th';
import { BankTransferDetail } from 'src/domain/banktransfer/bankTransferDetailType';
import ListViewBankTransferDetail from './ListViewBankTransferDetail';
import bankTransferService from 'src/services/banktransfer/bankTransferService';
import Panel from 'src/components/container/Panel';
import { useAppSelector } from "src/store";
import { ColumnsType } from 'antd/lib/table';
import RequestRemarkModel from './RequestRemarkModel';
import loggingService from 'src/services/security/loggingService';


const dateFormat = "DD/MM/YYYY";

interface IParams {
    id: string;
}

const columns: ColumnsType<BankTransferDetail> = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: BankTransferDetail, index: number) => (
            index+1
        ),
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "reference",
        key: "reference",
        width: '300px'
    },
    {
        title: "เลขที่บัญชี",
        dataIndex: "bankAccountNo",
        key: "bankAccountNo",
    },
    {
        title: "ชื่อบัญชี",
        dataIndex: "accountName",
        key: "accountName",
    },
    {
        title: "ธนาคาร",
        dataIndex: "bankName",
        key: "bankName",
    },
    {
        title: "รหัสธนาคาร",
        dataIndex: "branchCode",
        key: "branchCode",
    },
    {
        title: "ยอดเงินโอน",
        dataIndex: "transferAmt",
        key: "transferAmt",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "เลขที่การโอน",
        dataIndex: "bankTransactionNo",
        key: "bankTransactionNo",
    },
];

const columnsToExcel = [
    {
        title: "No.",
        dataIndex: "id",
        render: (value: string, item: BankTransferDetail, index: number) => (
            index+1
        ),
    },
    {
        title: "Account Number (11 หลัก)",
        dataIndex: "bankAccountNo",
    },
    {
        title: "Account Name",
        dataIndex: "accountName",
    },
    {
        title: "Bank Name",
        dataIndex: "bankName",
    },
    {
        title: "Branch Code(4 digits)",
        dataIndex: "branchCode",
    },
    {
        title: "Amount",
        dataIndex: "transferAmt",
    },
    {
        title: "Reference",
        dataIndex: "reference",
    },
    {
        title: "E-mail Address",
        dataIndex: "email",
    },

];

const ViewBankTransfer : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _bankTransferId = Number(props.match.params.id);
    const history = useHistory();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [bankTransferHead, setBankTransferHead] = useState<BankTransferHead>({} as BankTransferHead);
    const [bankTransferDetails, setBankTransferDetails] = useState<BankTransferDetail[]>([]);

    const [_showEditTransaction, _setShowEditTransaction] = useState<boolean>(true);
    const [_showPrint, _setShowPrint] = useState<boolean>(true);

    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        bankTransferService.getById(_bankTransferId).then(res => {
            const _bankTransferHead = res.data;

            setBankTransferHead(_bankTransferHead);
            _setShowEditTransaction(_bankTransferHead.hasTransactionNo);

            setBankTransferDetails(_bankTransferHead.details);

            loggingService.logging({
                event: "VIEW",
                target: "bank transfer",
                targetRef: res.data.id,
                eventUserId: sessionUser.userId,
                eventUsername: sessionUser.username,
            });
        });
    }, []);

    useEffect(() => {}, [bankTransferHead, bankTransferDetails]);

    const onClickExport = async (remark: string) => {
        setModalVisible(false);
        const res = await bankTransferService.saveExport(sessionUser.userId,
            sessionUser.username, sessionUser.branch, _bankTransferId, bankTransferDetails,
            remark);
        if(!res.data.status) {
            message.error(res.data.message, 2);
            return;
        } else {
            loggingService.logging({
                event: "EXPORT",
                target: "bank transfer",
                systemRemark: 'banktransfer-export-to-excel',
                targetRef: ''+ _bankTransferId,
                eventUserId: sessionUser.userId,
                eventUsername: sessionUser.username,
            });
            const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columnsToExcel)
            .addDataSource(bankTransferDetails)
            .saveAs("bank_transfer.xlsx");
        }
    }

  return (
    <div>
        <PageHeader title="NIMCAP - รายการโอนเงิน" onClose={() => history.push("/app/banktransfer")} showSubtitle={false}>
            <Space>
                {/* {!_showEditTransaction ? */}
                <Button
                    size="large"
                    icon={<DollarCircleOutlined />}
                    onClick={() => history.push("/app/banktransfer/edit/" + _bankTransferId)}
                    >
                    ระบุเลขที่การโอนเงิน
                </Button>
                {/* :""}
                {_showPrint ? */}
                <Tooltip placement="bottom" title="ส่งออก Excel">
                <Button
                    size="large"
                    icon={<FileExcelFilled />}
                    onClick={() => setModalVisible(true)}// onClickExport()}
                    value="EXCEL"
                >ส่งออก Excel</Button>
                </Tooltip>
                {/* :""} */}
            </Space>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Descriptions
                    style={{ marginTop: "20px" }}
                    column={1}
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        label="วันที่โอนเงิน :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {moment(bankTransferHead.transferDate).format(dateFormat)}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="หมายเหตุ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {bankTransferHead.remark}
                    </Descriptions.Item>
                </Descriptions>

                <ListViewBankTransferDetail details={bankTransferDetails} />
            </Panel>
        </Content>

        <RequestRemarkModel
            modalVisible={modalVisible}
            onOK={onClickExport}
            onCancel={() => setModalVisible(false)}
        />
    </div>
  )
}

export default ViewBankTransfer