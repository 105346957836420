import { Button, Descriptions, Popconfirm, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import { PlusOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import contractRedebtService from 'src/services/contract/contractRedebtService';
import { ContractRedebt } from 'src/domain/contract/contractRedebtType';
import loggingService from 'src/services/security/loggingService';
import useSessionUser from 'src/hooks/useSessionUser';
import { ContractDebitRedebt } from 'src/domain/contract/contractDebitRedebtType';
import DFlex from 'src/components/container/DFlex';
import messageUtils from 'src/utils/messageUtils';
import moment from 'moment';
import 'moment/locale/th';

const { Title,} = Typography;

const dateFormat = "DD/MM/YYYY";

interface IParams {
    id: string;
}

const ViewContractRedebt: React.FC<RouteComponentProps<IParams>> = (props)  => {
    const _id = Number(props.match.params.id);
    const history = useHistory();
    const sessionUser = useSessionUser();

    const [redebt, setRedebt] = useState<ContractRedebt>({} as ContractRedebt);
    const [debitRedebts, setDebitRedebts] = useState<ContractDebitRedebt[]>([] as ContractDebitRedebt[]);

    useEffect(() => {
        contractRedebtService.getRedebtById(_id).then(res => {
            const _data = res.data;
            if(_data) setDebitRedebts(_data.debitRedebts)
            else setDebitRedebts([] as ContractDebitRedebt[])

            setRedebt(res.data);

            loggingService.logging({
                event: "VIEW",
                target: "contract",
                targetRef: res.data.contractNo,
                eventUserId: sessionUser.userId,
                eventUsername: sessionUser.username,
            });
        })
    }, [_id]);

    const onClickDelete = (e: any) => {
        const _data : ContractRedebt = {
            ...redebt,
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
        }

        contractRedebtService.deleteRedebt(_data).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Delete success !!!")

                loggingService.logging({
                    event: 'DELETE',
                    systemRemark: 'delete-contract redebt',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    target: 'contract redebt',
                    targetRef: redebt.contractNo
                });

                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err));
        });
    }

    const columns = [
        {
            title: "Mth",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Interest Revenues",
            dataIndex: "interestRevenues",
            key: "interestRevenues",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Princ Repaid",
            dataIndex: "princPepaid",
            key: "princPepaid",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Ending Bal.",
            dataIndex: "endingBal",
            key: "endingBal",
            align: 'right' as 'right',
            render : (value : number) => (
                new Intl.NumberFormat('th-TH', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }).format(value)
            )
        },
        {
            title: "CF",
            dataIndex: "cf",
            key: "cf",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "% ตัดต้น",
            dataIndex: "principlePer",
            key: "principlePer",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
    ]


  return (
    <div>
        <PageHeader title="ปรับโครงสร้างหนี้" onClose={() => history.goBack()}>
            {redebt.manageable && (
                <Space>
                    <Button
                        size="large"
                        icon={<FormOutlined />}
                        onClick={() => history.push("/app/contractredebt/edit/" + _id)}
                    >
                        แก้ไข
                    </Button>
                    
                    <Popconfirm
                        title="ต้องการลบข้อมูลปรับโครงสร้างหนี้ใช่หรือไม่?"
                        onConfirm={onClickDelete}
                        // onCancel={cancel}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                        <Button
                            size="large"
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger
                        >
                            ลบ
                        </Button>
                    </Popconfirm>
                </Space>
            )}
        </PageHeader>
        <Content className="app-page-content">
            <Panel style={{  width: "100%",}}>
                <DFlex type="row" justifyContent='space-between' alignItems='flex-start' style={{ marginBottom: '20px'}}>
                    <div style={{ flex: 1, maxWidth: '600px'}}></div>
                    <div style={{ width: '300px'}}>
                        <Descriptions layout="horizontal" column={1} style={{marginLeft:30}}>
                            <Descriptions.Item
                                label="เลขที่สัญญา "
                                labelStyle={{ width: '100px',display: 'flex', justifyContent:'right', textAlign: "right", fontWeight: 600, fontSize:"medium"}}
                                contentStyle={{ display: 'flex', justifyContent:'right'}}
                            >
                                <label style={{fontWeight: 600, fontSize:"medium"}}>{redebt.contractNo}</label>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                </DFlex>
                <Typography>
                    <Title level={4}>ข้อมูลสัญญาเดิม</Title>
                </Typography>
                <div style={{ display: "flex", width: "100%", border: "1px solid gray", }}>
                    <Descriptions
                        style={{ marginTop: "0px" }}
                        column={2}
                        size="middle"
                        labelStyle={{ width: 220,  }}
                        bordered
                    >
                        <Descriptions.Item
                            label="เลขที่ใบคำขอสินเชื่อ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.applicationNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่ใบคำขอสินเชื่อ :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.applicationDate ? moment(redebt.applicationDate).format(dateFormat) : ""}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เลขที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.prevContractNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.prevContractDate ? moment(redebt.prevContractDate).format(dateFormat) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ผู้ขอสินเชื่อ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.nameThai}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="วงเงินกู้ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.appLoanAmt}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="จำนวนงวด :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {redebt.appLoanInstallment}
                        </Descriptions.Item>
                    </Descriptions>
                </div>

                <Descriptions
                    style={{ marginTop: "0px" }}
                    column={2}
                    size="middle"
                    labelStyle={{ width: 220,  }}
                    bordered
                >
                    <Descriptions.Item
                        label="วงเงินกู้ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(redebt.loanAmt).toFixed(2)).toLocaleString('uth-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="อัตราค่าธรรมเนียมการใช้วงเงิน % :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {redebt.rateUsageFee ? Number(Number(redebt.rateUsageFee).toFixed(2)).toLocaleString('uth-TH') : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="จ่ายต่องวด :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(redebt.installment).toFixed(2)).toLocaleString('uth-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="อัตราอาการแสตมป์ % :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {redebt.rateStampDuty ? Number(Number(redebt.rateStampDuty).toFixed(2)).toLocaleString('uth-TH') : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ระยะเวลาผ่อนชำระ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {redebt.tenor}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="อัตราดอกเบี้ยค้างชำระ % :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {redebt.rateAccruedInterest ? Number(Number(redebt.rateAccruedInterest).toFixed(2)).toLocaleString('uth-TH') : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="EFFECTIVE INTEREST RATE % (ทั้งหมด) :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(redebt.effInterestAnnum).toFixed(2)).toLocaleString('uth-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วิธีการคำนวณ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {redebt.paymentCalName}
                    </Descriptions.Item>
                </Descriptions>

                <Table
                    columns={columns}
                    dataSource={debitRedebts}
                    size="small"
                    rowKey={(item: ContractDebitRedebt) => item.id}
                    pagination={false}
                    style={{ marginTop: "20px" }}
                />
            </Panel>
        </Content>
    </div>
  )
}

export default ViewContractRedebt