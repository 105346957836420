import { Descriptions, Table, Collapse } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import campaignService from 'src/services/master/campaignService';
import { CampaignDetail } from 'src/domain/master/CampaignDetailType';
import { ApplicationLoan } from 'src/domain/application/applicationLoanType';
import ViewLoanComponent from './ViewLoanComponent';

const { Panel } = Collapse;

interface IProps {
    application : any;
}

const ViewLoanApplication : React.FC<IProps> = (props) => {
    const {application} = props;
    const history = useHistory();
    const [loanItems, setLoanItems] = useState<ApplicationLoan[]>([] as ApplicationLoan[]);
    const [defe, setDefe] = useState<string>();

    useEffect(() => {
        if(application.loans != undefined) {
            setLoanItems(application.loans);
        }
    }, [application]);

    // const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([] as CampaignDetail[]);

    // useEffect(() => {
    //     (async () => {
    //         if(application.campaignHeadId) {
    //             const res = await campaignService.getCampaignDetailByHeadId(application.campaignHeadId);
    //             setCampaignDetails(res.data);
    //         }
    //     })();
    // }, []);

    const numberFormat = (value: number) => {
        new Intl.NumberFormat('th-TH', {
            style: 'currency',
            currency: 'THB'
        }).format(value);
    }

    return (
        <div>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    {loanItems.map((item, index, arrays) => (
                        <Collapse accordion>
                            <Panel header={item.contractNo ? item.contractNo : "สัญญาปกติ"} key={index+1}>
                                <ViewLoanComponent applicationLoan={item} application={application} />
                            </Panel>
                        </Collapse>
                    ))}
                </div>
            </Content>
        </div>
    )
}

export default ViewLoanApplication
