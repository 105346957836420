import axios from "axios";
import AxiosMockAdapter from "axios-mock-adapter";
import { SessionUser } from '../store/authen/types';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("axios >> " + error);
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

// export const mock = new AxiosMockAdapter(axiosInstance, { delayResponse: 0 });

export const authConfig = () => {
  const localSessionUser = localStorage.getItem("sessionUser");

  if (!localSessionUser) return { headers : { actionby : "" } }

  const user:SessionUser = JSON.parse(localSessionUser);
  if (!user.username) return { headers : { actionby : ""} }

  return {
    headers : { actionby : `${user.userId}::${user.branch}::${user.scope}` }
  }
}


export default axiosInstance;
