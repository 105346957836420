import { CreateUserCommand } from "../../store/user/action";
import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/users";
const url = `${configs.urlRoot}/user`;

const createUser = (command: CreateUserCommand) => {
	return http.post(url + "/create", command);
};

const changePassword = (command: {
	userId: number;
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
	actionBy: { userId: number, username: string}
}) => {
	console.log(command);
	return http.post(url + "/changepassword", command);
};

export default {
	createUser,
  changePassword,
};
