import { Button, Form, Input, Table, Tabs, DatePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

import applicationService from "src/services/application/applicationService"
import { Application } from "src/domain/application/applicationType"
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";
import { ColumnsType } from 'antd/lib/table';

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";
const { TabPane } = Tabs;



const ListApplication: React.FC<Application> = () => {


  const history = useHistory();
  const [applications, setApplications] = useState<Application[]>([]);
  const [appSendDoc, setAppSendDoc] = useState<Application[]>([]);
  const [appDocReject, setAppDocReject] = useState<Application[]>([]);
  const [appWaiting, setAppWaiting] = useState<Application[]>([]);
  const [appApproved, setAppApproved] = useState<Application[]>([]);
  const [appDisapproval, setAppDisapproval] = useState<Application[]>([]);
  const [appCancel, setAppCancel] = useState<Application[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currTab, setCurrTab] = useState<string>("1");
  const [tabPage, setTabPage] = useState<Record<string, number>>({
    "1": 1,
    "2": 1,
    "3": 1,
    "4": 1,
    "5": 1,
    "6": 1,
    "7": 1
  });
  const [form] = Form.useForm();


  const defaultDate = {
    applicationDate: [moment().startOf('month'), moment().endOf('month')]
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(30)
      setApplications(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(71)
      setAppSendDoc(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(72)
      setAppDocReject(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(31)
      setAppWaiting(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
        const _date = form.getFieldValue("applicationDate")
        const res = await applicationService.getByCondition(33,
          _date === undefined ? moment().startOf('month').format('YYYYMMDD') : _date[0].format('YYYYMMDD'),
          _date === undefined ? moment().endOf('month').format('YYYYMMDD') : _date[1].format('YYYYMMDD'),
          "", "", "", 0);

      setAppApproved(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(32)
      setAppDisapproval(res.data);
      setLoading(false);
    })();

    (async () => {
      setLoading(true);
      const res = await applicationService.getApplicationByStatus(40)
      setAppCancel(res.data);
      setLoading(false);
    })();

    // const defaultTab: Record<string, number> = {
    //   "1": 0,
    //   "2": 0,
    //   "3": 0,
    //   "4": 0,
    //   "5": 0
    // }

  }, []);

  useEffect(() => {}, [applications]);

  const columns: ColumnsType<Application> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: Application, index: number) => (
        ( (tabPage[currTab] - 1)* 10) + index + 1
      ),
    },
    {
      title: "Application No",
      dataIndex: "applicationNo",
      key: "applicationNo",
      //   width: 90,
      render: (value: string, item: Application, index: number) => (
        <Link to={`/app/application/view/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "applicationDate",
      key: "applicationDate",
      render: (value: string) => moment(value).format(dateFormat),
    },
    {
      title: "ชื่อลูกค้า",
      dataIndex: "nameThai",
      key: "nameThai",
    },
    {
      title: "วงเงินกู้",
      dataIndex: "loanAmt",
      key: "loanAmt",
      align: "right" as "right",
      render: (value: number) =>
        Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
    },
    {
      title: "จำนวนงวด",
      dataIndex: "loanInstallment",
      key: "loanInstallment",
      align: "right" as "right",
    },
  ];

  const handlePageChange = (pageNumber: number, pageSize: number) => {
    const _tabPage = {...tabPage };
    _tabPage[currTab] = pageNumber;

    setTabPage(_tabPage);
  }

  const handleNameKeyDown = (event: any) => {
    if (event.key === 'Enter') {
        const _search = form.getFieldValue("name")
        if(_search) {
          (async () => {
            const res = await applicationService.getByCondition(33,"","",
              _search === undefined ? "" : _search,
              "", "", 0);
            setAppApproved(res.data);
          })();
        }
    }
  }

  const handleNoKeyDown = (event: any) => {
    if (event.key === 'Enter') {
        const _search = form.getFieldValue("no")
        if(_search) {
          (async () => {
            const res = await applicationService.getByCondition(33,"","","",
              _search === undefined ? "" : _search, "", 0);
            setAppApproved(res.data);
          })();
        }
    }
  }

  const onFinish = (values: any) => {
    const _date = values.applicationDate;
    const _name = values.name;
    const _no = values.no;

    (async () => {
      const res = await applicationService.getByCondition(33,
        _date === undefined ? "" : _date[0].format('YYYYMMDD'),
        _date === undefined ? "" : _date[1].format('YYYYMMDD'),
        _name === undefined ? "" : _name,
        _no === undefined ? "" : _no,
        "", 0);
      setAppApproved(res.data);
    })();
  }

  const onChangeTabs = (value: any) => {
    setCurrTab(value);
  }

  return (
    <div>
      <PageHeader title="Application List" showSubtitle={false} >
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/application/new")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div style={{ marginTop: '-10px', position: 'relative'}}>
          <Tabs defaultActiveKey="1" onChange={onChangeTabs} size={'small'} tabBarGutter={20} >

            <TabPane tab="สร้าง" key="1" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <h4>รายการ</h4>
                <Table
                  columns={columns}
                  dataSource={applications}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}

                />
              </div>
            </TabPane>

            {/* <TabPane tab="รออนุมัติ" key="2" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <h4>รายการ</h4>
                <Table
                    columns={columns}
                    dataSource={appWaiting}
                    size="small"
                    rowKey={(item: Application) => item.applicationNo}
                    pagination={{
                      onChange: handlePageChange,
                      pageSize: 10,
                      showSizeChanger:false
                    }}
                  />
              </div>
            </TabPane>
            <TabPane tab="อนุมัติแล้ว" key="3" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <h4>รายการ</h4> */}

            <TabPane tab="รอตรวจสอบ" key="2" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={appSendDoc}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>

            <TabPane tab="เอกสารรอแก้ไข" key="3" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={appDocReject}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>

            <TabPane tab="รออนุมัติ" key="4" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={appWaiting}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>

            {/*
            </TabPane>
             <TabPane tab="ไม่อนุมัติ" key="4" style={{ marginTop: '30px'}}>
               <div className={classes.dataPanel}>
                 <h4>รายการ</h4> */
            }

            <TabPane tab="อนุมัติแล้ว" key="5" style={{ marginTop: '0px'}}>
            <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{width: "30%"}}>
                      <Form.Item
                        label="วันที่ขอสินเชื่อ"
                        name="applicationDate"
                        key="applicationDate"
                      >
                        <RangePicker size="large" locale={th_loc} format={dateFormat} />
                      </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px",width: "30%"}}>
                      <Form.Item
                          label="ชื่อลูกค้า"
                          name="name"
                          key="name"
                      >
                        <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleNameKeyDown}/>
                      </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px",width: "30%"}}>
                      <Form.Item
                          label="เลขบัตร"
                          name="no"
                          key="no"
                      >
                        <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleNoKeyDown}/>
                      </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px",width: "10%"}}>
                      <Form.Item
                        label=" "
                      >
                        <Button
                          size="large"
                          icon={<SearchOutlined/>}
                          onClick={() => {
                            form.submit();
                          }}
                          // onClick={findByParam}
                        >
                          ค้นหา
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </Form>
              <div className={classes.dataPanel}>

                <Table
                  columns={columns}
                  dataSource={appApproved}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>

            <TabPane tab="ไม่อนุมัติ" key="6" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={appDisapproval}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>

{/*
            <TabPane tab="ยกเลิก" key="5" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <h4>รายการ</h4> */}

            <TabPane tab="ยกเลิก" key="7" style={{ marginTop: '30px'}}>
              <div className={classes.dataPanel}>
                <Table
                  columns={columns}
                  dataSource={appCancel}
                  size="small"
                  rowKey={(item: Application) => item.applicationNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 10,
                    showSizeChanger:false
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  );
};

export default ListApplication
