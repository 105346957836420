import { Button, Col, DatePicker, Form, Input, Modal, Radio, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { Company } from 'src/domain/master/companyType';
import companyService from 'src/services/master/companyService';
import { BookType } from 'src/domain/account/booktypeType';
import { BookCode } from 'src/domain/account/bookCodeType';
import bookTypeService from 'src/services/account/bookTypeService';
import bookCodeService from 'src/services/account/bookCodeService';
import bookService from 'src/services/account/bookService';

const { Option } = Select;

const dateFormat = 'DD/MM/YYYY';

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

interface IProps {
    modalVisible: boolean;
    onCancel: () => void;
    onSearch: (params: any) => void;
    onSearchCode: (value: string) => void;
}

const options_date = [{label:'วันที่สร้าง', value:'doc_date'},{label:'วันที่บันทึกบัญชี', value:'acc_save_date'}]

const SearchAccBookPopup: React.FC<IProps> = (props) => {
    const {modalVisible, onCancel, onSearch, onSearchCode} = props;
    const [form] = Form.useForm();

    const [optionsCompany, setOptionsCompany] = useState<Company[]>([] as Company[]);
    const [selectCompany, setSelectCompany] = useState<number>(0);

    const [optionsBookType, setOptionsBookType] = useState<BookType[]>([] as BookType[]);
    const [selectBookType, setSelectBookType] = useState<BookType>();

    const [optionsBookCode, setOptionsBookCode] = useState<BookCode[]>([] as BookCode[]);
    const [selectBookCode, setSelectBookCode] = useState<BookCode>();

    const [selectDateType, setSelectDateType] = useState<string>("doc_date");

    useEffect(() =>  {
        form.setFieldsValue({date_type: "doc_date"});
    }, [])

    useEffect(() => {
        (async () => {
            const res = await companyService.getCompanyAll();
            setOptionsCompany(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await bookTypeService.getBookTypeAll();
            setOptionsBookType(res.data);
        })();
    }, []);

    useEffect(() => {
        if(selectBookType) {
            (async () => {
                const res = await bookCodeService.getBookCodeByBookTypeId(selectBookType.id);
                setOptionsBookCode(res.data);
            })();
        }
    }, [selectBookType]);

    const onSelectBookType = (value: any, event: any) => {
        setSelectBookType(event.props);
        setSelectBookCode({} as BookCode);
        form.setFieldsValue({bookCode : selectBookCode?.id});
    }

    const onSelectBookCode = (value: any, event: any) => {
        setSelectBookCode(event.props);
    }

    const onClickSearch = (values: any) => {
        const _field = form.getFieldsValue();
        console.log("_field", _field)
        onSearch(_field);
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const code_search = form.getFieldValue("doc_no")
            onSearchCode(code_search);
        }
    }
    
    return (
        <div>
            <Modal
                title="ค้นหา"
                style={{ top: 110 }}
                visible={modalVisible}
                onCancel={() => onCancel()}
                footer={[
                    <Button key="search" onClick={onClickSearch}>
                        ค้นหา
                    </Button>
                ]}
            >
                <Form
                    {...layout}
                    form={form}
                    name="basic"
                    layout="horizontal"
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                >
                    <Row style={{ margin: "0px" }}>
                        <Col span="24">
                            <Form.Item
                                label="เลขที่"
                                name="doc_no"
                            >
                                <Input placeholder='เลขที่/เลขที่เอกสารอ้างอิง' onKeyDown={handleKeyDown} />
                            </Form.Item>
                            <Form.Item
                                label="ผู้รับเงิน"
                                name="payee_name"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="ผู้จัดทำ"
                                name="create_name"
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="วันที่"
                                name="date"
                            >
                                <DatePicker 
                                    locale={th_loc}
                                    // onChange={onChangeStartDate}
                                    // defaultValue={moment(moment().format('L'), dateFormat)}
                                    format={dateFormat}
                                />
                            </Form.Item>
                            <Form.Item
                                label=" "
                                name="date_type"
                            >
                                <Radio.Group
                                    // onChange={onChangeDocChannal}
                                    value={selectDateType}
                                    defaultValue={selectDateType}
                                >
                                    {options_date.map((opt_value) => (
                                    <Radio key={opt_value.value} value={opt_value.value}>
                                        {opt_value.label}
                                    </Radio>
                                    ))}
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="บริษัท"
                                name="company"
                            >
                                <Select
                                    placeholder="เลือกบริษัท"
                                    style={{ width: "80%" }}
                                    // onSelect={onSelectCompany}
                                    value={selectCompany}
                                    // defaultValue={selectCompany}
                                    filterOption={(input, option) => {
                                        return (
                                            option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        );
                                    }}
                                    showSearch
                                >
                                    {optionsCompany.map((opt_value) => (
                                        <Option title={opt_value.name} key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="ประเภทสมุด"
                                name="book_type"
                            >
                                <Select 
                                    placeholder="เลือกประเภทสมุด" 
                                    style={{ width: "80%" }}
                                    onSelect={onSelectBookType}
                                    value={selectBookType?.id}
                                >
                                {optionsBookType.map((values) => (
                                    <Option key={values.id} value={values.id} props={values}>
                                        {values.name}
                                    </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="ชื่อสมุด"
                                name="book_code"
                            >
                                <Select 
                                    placeholder="เลือกชื่อสมุด" 
                                    style={{ width: "80%" }}
                                    onSelect={onSelectBookCode}
                                    value={selectBookCode?.id}
                                >
                                    {optionsBookCode.map((values) => (
                                        <Option key={values.id} value={values.id} props={values}>
                                            {values.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default SearchAccBookPopup
