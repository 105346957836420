import { notification, Button, FormInstance, Radio, RadioChangeEvent, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { 
    Affix,
    Form,
    Input, 
} from "antd";

import applicationService from "src/services/application/applicationService"
import { Branch } from "src/domain/master/BranchType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";

import Panel from "src/components/container/Panel";
import { NcBank, NcBankUseFlg } from "src/domain/master/bankType";
import bankService from 'src/services/master/bankService';
import { MasterResponse } from 'src/domain/master/MasterResponse';

interface IParams {
    id: string;
}
const ManageBankForm: React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _id: number = Number(props.match.params.id);
    const [form] = Form.useForm();
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentId, setCurrentId] = useState<number | null>(null);

    useEffect(() => {
        (async() => {
            form.setFieldsValue({
                bankCode: '',
                bankName: '',
                useFlag: NcBankUseFlg.USE,
            })
            if(!Number.isNaN(_id)){
                let res = await bankService.getBankById(_id);
                let bankData: NcBank = res.data;
                setCurrentId(_id);
                form.setFieldsValue({
                    bankCode: bankData.bankCode,
                    bankName: bankData.bankName,
                    useFlag: bankData.useFlg,
                })
            }
        })();
    }, []);
    const doAfterFinish = (response: MasterResponse) => {
        console.log(response);
        if(response.status == "success"){
            notification["success"]({message: "จัดการข้อมูลธนาคาร", description: "บันทึกสำเร็จ", duration: 2})
            history.replace("/app/master/bank/list");
        }else{
            notification["error"]({message: "จัดการข้อมูลธนาคาร", description: "บันทึกไม่สำเร็จ", duration: 2})
        }
    }
    const onFinish = async(value: {bankCode: string, bankName: string, useFlag: number}) => {
        console.log("finished: ", value);
        let submitData: NcBank = {id: _id, bankCode: value.bankCode, bankName: value.bankName, useFlg: value.useFlag};
        if(!Number.isNaN(_id)){
            // update
            let res = await bankService.updateBank(submitData);
            doAfterFinish(res.data);
        }else{
            // add
            let res = await bankService.addBank(submitData);
            doAfterFinish(res.data);
        }
    }
    const onUseFlatChange = (e: RadioChangeEvent) => {
        form.setFieldsValue({
            useFlag: e.target.value,
        })
    }
    const renderUseFlagRadio = () => {
        return(
            <Radio.Group onChange={onUseFlatChange}>
                <Radio value={NcBankUseFlg.USE}>ใช้งาน</Radio>
                <Radio value={NcBankUseFlg.NOT_USE}>ไม่ใช้งาน</Radio>
            </Radio.Group>
        )
    }
    return (
        <div>
            <PageHeader title="กำหนดข้อมูลธนาคาร">
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.replace("/app/master/bank/list")}
                >
                    Cancel
                </Button>
                {/* <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/application/new")}
                >
                New
                </Button> */}
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ width: "100%", padding: "40px" }}>
                <Form form={form} name="control-hooks" onFinish={onFinish}>
                    <Form.Item name="bankCode" rules={[{ required: true, message: 'กรุณาระบุรหัสธนาคาร!' }]}>
                        <Input 
                            placeholder="รหัสธนาคาร"
                            // style={{marginLeft: "10px" }}
                            disabled={currentId != null}
                        />
                    </Form.Item>
                    <Form.Item 
                        name="bankName" 
                        rules={[{ required: true, message: 'กรุณาระบุชื่อธนาคาร!' }]}
                    >
                        <Input 
                            placeholder="ชื่อธนาคาร"
                            // style={{marginLeft: "10px" }}
                            // disabled={showPrenameThaiEtc}
                        />
                    </Form.Item>
                    <Form.Item name="useFlag" style={{textAlign: "center"}}>
                        {renderUseFlagRadio()}
                    </Form.Item>
                    <Form.Item style={{textAlign: 'right'}}>
                        <Button
                            danger
                            size="small"
                            type="primary"
                            name="per"
                            icon={<SaveOutlined />}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            บันทึก
                        </Button>
                    </Form.Item>
                </Form>
                </Panel>
            </div>
        </div>
    );
}

export default ManageBankForm;