import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { ApplicationMovement } from 'src/domain/application/applicationMovementType';
import applicationMovementService from 'src/services/application/applicationMovementService';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    id: number;
}

const columns = [
    {
        title: "สถานะ",
        dataIndex: "applicationStatusName",
        key: "applicationStatusName",
    },
    {
        title: "หมายเหตุ",
        dataIndex: "systemRemark",
        key: "systemRemark",
    },
    {
        title: "หมายเหตุผู้ใช้",
        dataIndex: "userRemark",
        key: "userRemark",
    },
    {
        title: "วันที่",
        dataIndex: "createDate",
        key: "createDate",
        render: (value: string) => moment(value).format(dateFormat),
    },
    {
        title: "เวลา",
        dataIndex: "createTime",
        key: "createTime",
    },
    {
        title: "user",
        dataIndex: "createUserName",
        key: "createUserName",
    },
]

const ViewApplicationMovement : React.FC<IProps> = (props) => {
    const _applicationId = props.id;
    const [applicationMovements, setApplicationMovements] = useState<ApplicationMovement[]>([] as ApplicationMovement[]);

    useEffect(() => {
        (async () => {
            const res = await applicationMovementService.getMovementByApplicationId(_applicationId);
            setApplicationMovements(res.data);
        })();
    }, [])
    return (
        <div>
            <Table
                columns={columns}
                dataSource={applicationMovements}
                size="small"
                rowKey={(item: ApplicationMovement) => item.id}
                pagination={false}
            />
        </div>
    )
}

export default ViewApplicationMovement
