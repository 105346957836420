import { Col, Descriptions, Row, Space } from 'antd'
import React from 'react'
import Panel from 'src/components/container/Panel'
import { DashboardModel } from 'src/domain/dashboardModelType'
import DBPaymentDetailView from './DBPaymentDetailView'

interface IProps {
    dashboard: DashboardModel;
}

const DBPaymentView : React.FC<IProps> = (props) => {
    const {dashboard} = props;
  return (
    <div>
        <Panel style={{padding:'5px', borderRadius:10}}>
            <Row>
                <Col>
                <Descriptions
                    column={1}
                    size="middle"
                    labelStyle={{ width: 0,  }}
                    bordered
                >
                    <Descriptions.Item
                        label="PAYMENT"
                        labelStyle={{ textAlign: "left", width:120, backgroundColor:"#ffffff" }}
                    >
                    </Descriptions.Item>
                </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <div style={{height:100, margin:2}}>
                        <DBPaymentDetailView text='ครบกำหนดชำระ' qty={dashboard.paymentDueQty} amount={dashboard.paymentDueAmount} color='blue' />
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{height:100, margin:2}}>
                        <DBPaymentDetailView text='รับชำระ' qty={dashboard.paymentReceiveQty} amount={dashboard.paymentReceiveAmount} color='#23B70B' />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={8}></Col>
                <Col span={10} style={{textAlign:'center'}}>
                    <div style={{height:100, margin:2}}>
                        <DBPaymentDetailView text='ค้างชำระ' qty={dashboard.overdueQty} amount={dashboard.overdueAmount} color='red' />
                    </div>
                </Col>
                <Col span={6}></Col>
            </Row>
        </Panel>
    </div>
  )
}

export default DBPaymentView