import { Button, Form, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import Panel from 'src/components/container/Panel';
import { ApplicationGuarantor } from 'src/domain/application/applicationGuarantorType';
import GuarantorComponent from './GuarantorComponent';
import {PlusOutlined, SaveOutlined} from '@ant-design/icons'

interface IProps {
    application : any;
    setApplication: any;
    updateGuarantorData: any;
    trigger: any;
    setTrigger: any;
}
const GuarantorApplication: React.FC<IProps> = (props) => {
    const {application, setApplication, updateGuarantorData, trigger, setTrigger} = props;
    const divRef = useRef<HTMLDivElement>(null);
    const [form] = Form.useForm();

    const [guarantorItems, setGuarantorItems] = useState<ApplicationGuarantor[]>([] as ApplicationGuarantor[]);
    
    useEffect(() => {},[guarantorItems]);

    useEffect(() => {
        if(application.guarantors === undefined) {

        // }
        // if(mode === 'NEW') {
            if(guarantorItems && guarantorItems.length === 0) {
                const _guarantorItems = [];
                let _gua = {} as ApplicationGuarantor;
                // _gua.id = 1;
                _gua.useFlg = 0;
                _guarantorItems.push(_gua);
                setGuarantorItems(_guarantorItems);
            }
        } else {
            setGuarantorItems(application.guarantors);
        }

        scrollToTop();
    }, []);

    const addNewGuarantorItem = () => {
        const newData = [...guarantorItems];
        let _gua = {} as ApplicationGuarantor;
        // _gua.id = newData.length+1;
        _gua.useFlg = 0;
        newData.push(_gua);
        setGuarantorItems(newData);
    }

    const setDataGuarantorItem = (datas : ApplicationGuarantor, index: number) => {
        //ลบข้อมูลออกจาก list เมื่อ userflg เป็น -1 และยังไม่เคยบันทึกใน db
        if(datas.useFlg === -1 && (datas.id === undefined || datas.id === 0)) {
            const newData = [...guarantorItems];
            newData.splice(index, 1);
            setGuarantorItems(newData);
            const _app = {
                ...application,
                guarantors : [],
            }
            _app.guarantors = newData;
            setApplication(_app);
        } else {
            const newData = [...guarantorItems];
            newData.splice(index, 1, datas);
            setGuarantorItems(newData);
            const _app = {
                ...application,
                guarantors : [],
            }
            _app.guarantors = newData;
            setApplication(_app);
        }   
    }

    useEffect(() => {
        if (trigger) {
          form.submit();
        }
    }, [trigger]);

    const onFinish = (values: any) => {
        updateGuarantorData(guarantorItems);
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> GuarantorApplication:", errorInfo);
        setTrigger(false);
    };

    const scrollToTop = () => {
        if (divRef.current) {
            window.scrollTo({ behavior: 'smooth', top: divRef.current.offsetTop - 120 })
        }
    }

    return (
    <>
        <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <div ref={divRef}></div>
            {guarantorItems.map((item, index) => (
                item.useFlg === 0 &&
                    <GuarantorComponent
                        applicationGuarntor={item}
                        rowIndex={index}
                        setDataGuarantorItem={setDataGuarantorItem}
                    />
            ))}

            <Button
                size="large"
                shape="circle"
                type='primary'
                icon={<PlusOutlined />}
                style={{marginTop:5}}
                onClick={() => (addNewGuarantorItem())}
            />

                    
            <Form.Item
                wrapperCol={{ offset: 22, span: 2 }}
                style={{marginTop:50}}
            >
                <Button
                    danger
                    size="large"
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                    Save
                </Button>
            </Form.Item>
        </Form>
    </>
  )
}

export default GuarantorApplication