export const inputDecimalFormatter = (value: any) => {
    console.log('formatter', value);

    // if ( value!.includes('#')) {
    //     const s = value.replace('#','');
    //     if (s.trim() === '') {
    //         return "";
    //     }

    //    return `${s}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }

    // if (value!.includes('!')) {
    //     const s = value.replace('!','');
    //     if (s.trim() === '') {
    //         return "";
    //     }
    //     let a = parseFloat(s!.replace(/\$\s?|(,*)/g, '')).toFixed(2);
    //     if (a === 'NaN') return "";
    //     return `${a}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // }

    if (value.includes('.')) {
        let x = value.split('.');
        let f = `${x[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return f + "." + x[1];
    }

    let s = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return s;
}

export const inputDecimalPaser = (v: any) => {
    return v!.replace(/\$\s?|(,*)/g, '');
}