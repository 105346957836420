import { Button, Form, DatePicker, Input, Table } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useHistory } from 'react-router-dom';
import DFlex from 'src/components/container/DFlex';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import Panel from 'src/components/container/Panel';
import { ColumnsType } from 'antd/lib/table';
import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';
import { ContractRedebt } from 'src/domain/contract/contractRedebtType';
import UseFlgIcon from 'src/components/container/UseFlgIcon';
import contractRedebtService from 'src/services/contract/contractRedebtService';

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ListContractRedebt: React.FC = () => {
    const [form] = Form.useForm();
    const history = useHistory();

    const [redebts, setRedebts] = useState<ContractRedebt[]>([] as ContractRedebt[]);
    
    const defaultDate = {
        createDate: [moment().startOf('month'), moment().endOf('month')]
    };

    const [offset, setOffset] = useState<number>(0);
    const handlePageChange = (page:number, pageSize: number) => {
        console.log('page property', page, pageSize);
        setOffset((page-1) * pageSize);
    }

    useEffect(() => {}, [redebts]);

    useEffect(() => {
        (async () => {
            const _createDate = form.getFieldValue("createDate")
            const res = await contractRedebtService.searchRedebtLists(
                "",
                _createDate === undefined ? moment().startOf('month').format('YYYYMMDD') : _createDate[0].format('YYYYMMDD'),
                _createDate === undefined ? moment().endOf('month').format('YYYYMMDD') : _createDate[1].format('YYYYMMDD')
                );
          setRedebts(res.data);
        })();
    },[]);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const code_search = form.getFieldValue("contractNo")
            (async () => {
                const res = await contractRedebtService.searchRedebtLists(
                    code_search,
                    "",
                    ""
                );
                setRedebts(res.data);
            })();
        }
    }

    const onFinish = (values: any) => {
        const _createDate = values.createDate;
        const code_search = values.contractNo;

        (async () => {
            const res = await contractRedebtService.searchRedebtLists(
                    code_search === undefined ? "" : code_search,
                    _createDate === undefined ? "" : _createDate[0].format('YYYYMMDD'),
                    _createDate === undefined ? "" : _createDate[1].format('YYYYMMDD')
                );
          setRedebts(res.data);
        })();
    }

    const columns: ColumnsType<ContractRedebt> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: ContractRedebt, index: number) => (
                offset + index+ 1
            ),
        },
        {
            title: "วันที่สร้างเอกสาร",
            dataIndex: "createDate",
            key: "createDate",
            render: (value: string) => moment(value).format(dateFormat),
            sorter: {
                compare: (a: ContractRedebt, b: ContractRedebt) => moment(a.createDate).unix()-moment(b.createDate).unix(),
                multiple: 1 },
            defaultSortOrder: 'ascend',
        },
        {
            title: "เลขที่ใบคำขอ",
            dataIndex: "applicationNo",
            key: "applicationNo",
        },
        {
            title: "เลขสัญญา(เดิม)",
            dataIndex: "prevContractNo",
            key: "prevContractNo",
        },
        {
            title: "วันที่สัญญา(เดิม)",
            dataIndex: "prevContractDate",
            key: "prevContractDate",
            render: (value: string) => moment(value).format(dateFormat),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
            key: "contractNo",
            render: (value: string, item: ContractRedebt, index: number) => (
                <Link to={`/app/contractredebt/view/${item.id}`}>{value}</Link>
            ),
            sorter: {
                compare: (a: ContractRedebt, b: ContractRedebt) => a.contractNo.localeCompare(b.contractNo),
                multiple: 2 },
        },
        {
            title: "ผู้ขอสินเชื่อ",
            dataIndex: "nameThai",
            key: "nameThai",
        },
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            width: "5%",
            render: (value: number) => {
              return (
                  <UseFlgIcon useFlg={value === -1 ? false : true}/>
              )
            },
          },
    ];

  return (
    <div>
        <PageHeader title="ปรับโครงสร้างหนี้" showSubtitle={false} >
            <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => history.push("/app/contractredebt/new")}
            >
                New
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Form
                form={form}
                size="large"
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                initialValues={defaultDate}
                // onFinishFailed={onFinishFailed}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent:'space-between',
                            alignItems:"center", textAlign:"center", width:"100%",
                            marginBottom: '10px',  }}>
                    <DFlex type="row" gap={20}>
                        <Form.Item
                            label="วันที่สร้างเอกสาร"
                            name="createDate"
                            key="createDate"
                        >
                            <RangePicker locale={th_loc} format={dateFormat} />
                        </Form.Item>

                        <Form.Item
                            label="เลขที่สัญญา"
                            name="contractNo"
                            key="contractNo"
                        >
                            <Input placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleKeyDown}/>
                        </Form.Item>
                    </DFlex>

                    <Form.Item
                        label=""
                    >
                        <Button
                            icon={<SearchOutlined/>}
                            size="large"
                            onClick={() => {
                            form.submit();
                            }}
                        >
                            ค้นหา
                        </Button>
                    </Form.Item>
                </div>
            </Form>

            <Panel>
                <div>
					<h4>รายการ</h4>
                    <Table
                    columns={columns}
                    dataSource={redebts}
                    size="small"
                    rowKey={(item: ContractRedebt) => item.contractNo}
                    // summary={onRowSummary}
                    pagination={{
                        onChange: handlePageChange,
                        pageSize: 10,
                    }}
                    />
                </div>
            </Panel>
        </Content>
    </div>
  )
}

export default ListContractRedebt