import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/contract";
const url = `${configs.urlRoot}/contractTransfer`;

const getContractTransferById = (id: number) => {
    return http.get(url+"/"+id);
}

const calculateStampDuty = (contractId: number) => {
    return http.get(url+"/calStampDuty?contractId="+contractId);
}

const addContractTransfer = (trans : any) => {
    return http.post(url, trans);
} 

export default {
	    getContractTransferById,
    calculateStampDuty,
    addContractTransfer
}