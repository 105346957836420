import React from 'react';
import AccountForm from './AccountForm';

const NewAccount = () => {
    return (
        <div>
            <AccountForm id={0} mode={'NEW'} />
        </div>
    );
};

export default NewAccount;
