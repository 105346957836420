import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Typography, Row, Col, Select, Button, Radio, Table, Space,  } from "antd";
import { SaveOutlined, RightCircleOutlined } from "@ant-design/icons";
import bankService from "src/services/master/bankService";
import { NcBank } from "src/domain/master/bankType";
import campaignService from 'src/services/master/campaignService';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignDetail } from 'src/domain/master/CampaignDetailType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import campaignHeadService from 'src/services/master/campaignHeadService';

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IProps {
    application : any;
    setApplication : any;
    trigger: any;
    setTrigger: any;
    updateLoanData : any;
    next: () => void;
}

const options_period = [{ label:'6 เดือน', value:6}, {label:'12 เดือน', value:12}]
const options_campaign = ['0.6% ต่อเดือน', '0.9% ต่อเดือน']

const LoanApplication : React.FC<IProps> = (props) => {
    const {application, setApplication, trigger, setTrigger, updateLoanData, next } = props;
    const divRef = useRef<HTMLDivElement>(null);
    const [form] = Form.useForm();
    const [optionsbanks, setOptionsBanks] = useState<NcBank[]>([] as NcBank[]);
    const [optionsLoansAmt, setOptionsLoansAmt] = useState<number[]>([]);
    const [optionsPeriod, setOptionsPeriod] = useState<number[]>([]);
    const [optionsCampaign, setOptionsCampaign] = useState<Campaign[]>([] as Campaign[]);
    const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([] as CampaignDetail[]);

    const [selectBank, setSelectBank] = useState<NcBank>();
    const [selectLoans, setSelectLoans] = useState<number>(0);
    const [selectPeriod, setSelectPeriod] = useState<number>(0);
    const [selectCampaign, setSelectCampaign] = useState<Campaign>();
    const [selectCampaignHead, setSelectCampaignHead] = useState<CampaignHead>();

    useEffect(() => {
        (async () => {
            const res = await bankService.getBankAll();
            setOptionsBanks(res.data);
        })();

        scrollToTop();
    }, []);

    // useEffect(() => {
    //     (async () => {
    //         const res = await campaignHeadService.getLoanAmtByCurrDate();
    //         setOptionsLoansAmt(res.data);
    //     })();
    // }, []);

    useEffect(() => {
        (async () => {
            const res = await campaignService.getCampaignByCurrentDate();
            setOptionsCampaign(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(selectCampaign) {
                const res = await campaignHeadService.getLoanAmtByCampaignId(selectCampaign.id);
                setOptionsLoansAmt(res.data);
            }
        })();

    }, [selectCampaign]);

    useEffect(() => {
        if(selectLoans > 0) {
            (async () => {
                if(selectCampaign) {
                    const res = await campaignHeadService.getTenorByLoanAmtAndCampaignId(selectCampaign?.id, selectLoans)
                    setOptionsPeriod(res.data);
                }
            })();
        }
    }, [selectLoans]);

    const optionsLoans = optionsLoansAmt.map((d) => ({
        "value" : d,
        "label" : Number(Number(d).toFixed(2)).toLocaleString("th-TH"),
    }))

    // useEffect(() => {
    //     console.log("loans useeff", application.loan_amt)

    //     if(application.loan_amt) {
    //         const _loans = {...optionsLoansAmt}
    //         console.log("loans", _loans[0])
    //         setSelectLoans(_loans[0]);
    //     }
    // }, [optionsLoansAmt]);

    // useEffect(() => {
    //     (async () => {
    //         const res = await campaignHeadService.getTenorByLoanAmtAndCurrentDate(selectLoans);
    //         setOptionsPeriod(res.data);
    //     })();

    // }, [selectLoans]);

    // useEffect(() => {
    //     (async () => {
    //         const res = await campaignService.getCampaign(selectLoans, selectPeriod);
    //         setOptionsCampaign(res.data);
    //     })();
    // }, [selectPeriod]);

    useEffect(() => {
        if(selectPeriod > 0) {
            (async () => {
                if(selectCampaign) {
                    const _campH = await campaignService.getCampaignHead(selectCampaign?.id, selectLoans, selectPeriod);
                    if(_campH) {
                        setSelectCampaignHead(_campH.data)
                        if(selectCampaignHead) {
                            const res = await campaignService.getCampaignDetailByHeadId(_campH.data.id);
                            setCampaignDetails(res.data);
                        }
                    }
                }
            })();
        }
    }, [selectPeriod]);

    useEffect(() => {
        if(selectCampaignHead) {
            (async () => {
                if(selectCampaignHead) {
                    const res = await campaignService.getCampaignDetailByHeadId(selectCampaignHead?.id);
                    setCampaignDetails(res.data);
                }
            })();
        }
    }, [selectCampaignHead]);

    useEffect(() => {
        form.setFieldsValue({
            campaign_id: application.campaignId,
            loan_amt: application.loanAmt,
            loan_installment: application.loanInstallment,
            account_name: application.accountName,
            bank_code: application.bankId,
            back_account_code: application.bankAccountNo,
        });

        const x : any  = {id :application.bankId, bankCode : application.bankCode, bankName: application.bankName};
        setSelectBank(x);

        const _campaign : Campaign = {
            id: application.campaignId,
            campaignCode: "",
            campaignName: "",
            startDate: "",
            endDate: "",
            remark: "",
            useFlg : 0,
            userId : 0,
            userName : "",
            savStation : "",
            rateUsageFee : 0,
            rateStampDuty : 0,
            rateAccruedInterest : 0,
            paymentCalId : 0,
            paymentCalName : "",
        }
        setSelectCampaign(_campaign);
        setSelectLoans(application.loanAmt);
        setSelectPeriod(application.loanInstallment);
    }, [application]);

    useEffect(() => {
        if (trigger) {
          form.submit();
        }
    }, [trigger]);

    const onSelectBank = (value: any, index: any) => {
        const x : any  = {id :value, bankCode : index.children[0], bankName: index.children[2]};
        setSelectBank(x);
    }

    const onChangeLoans = (values: any) => {
        setSelectLoans(values.target.value);

        setOptionsPeriod([]);
        setSelectPeriod(0);
        form.setFieldsValue({
            loan_installment: null,
        });
        setCampaignDetails([]);
        // setSelectPeriod({} as number);
        // setOptionsPeriod([]);
        // setSelectCampaignHead({} as CampaignHead);
        // setCampaignDetails([]);
    }

    const onSelectPeriod = (value : any) => {
        setSelectPeriod(value);
    }

    const onSelectCampaign = (value : any, index : any) => {
        const x : any  = {id :value, campaignName: index.children[2]};
        setSelectCampaign(x);
    }

    const onFinish = (values: any) => {
        let x : any = {
            loanAmt: values.loan_amt,
            loanInstallment: values.loan_installment,
            campaignId: selectCampaign?.id,
            campaignHeadId: selectCampaignHead?.id,
            accountName: values.account_name,
            bankId : selectBank?.id,
            bankCode : selectBank?.bankCode,
            bankName : selectBank?.bankName,
            bankAccountNo: values.back_account_code,
        };
        updateLoanData(x);
        next();
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> PersonalApplication:", errorInfo);
        setTrigger(false);
    };

    const columns = [
        {
            title: "Mth",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Interest Revenues",
            dataIndex: "interestRevenues",
            key: "interestRevenues",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Princ Repaid",
            dataIndex: "princPepaid",
            key: "princPepaid",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Ending Bal.",
            dataIndex: "endingBal",
            key: "endingBal",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "CF",
            dataIndex: "cf",
            key: "cf",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "% ตัดต้น",
            dataIndex: "principlePer",
            key: "principlePer",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
    ]

    const scrollToTop = () => {
        if (divRef.current) {
            window.scrollTo({ behavior: 'smooth', top: divRef.current.offsetTop - 120 })
        }
    }

    return (
        <>
            <Form
                // {...layout}
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                 <div ref={divRef}></div>
                <Typography>
                    <Title level={2}>วงเงินกู้</Title>
                    <Paragraph>ระบุ ข้อมูลวงเงินกู้ และบัญชีธนาคาร</Paragraph>
                </Typography>
                <Row style={{ marginBottom: "3em" }}>
                    <Col span={24}>
                    <Space direction="vertical" />
                        <Form.Item
                            label="CAMPAIGN"
                            name="campaign_id"
                        >
                            <Select
                                placeholder="เลือกรายการ"
                                onSelect={onSelectCampaign}
                                allowClear
                                size='large'
                            >
                                {optionsCampaign.map((opt_value) => (
                                    <Option
                                        key={opt_value.id}
                                        value={opt_value.id}
                                        name={opt_value.id}
                                    >
                                        {opt_value.campaignCode} {opt_value.campaignName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="จำนวนเงินที่ขอกู้ (บาท)"
                            name="loan_amt"
                        >
                            {/* <Radio.Group
                                options={optionsLoans}
                                optionType="button"
                                buttonStyle="solid"
                                name="loan_amt"
                                value={selectLoans}
                                defaultValue={selectLoans}
                                onChange={onChangeLoans}
                            />  */}
                            <Radio.Group
                                optionType="button"
                                buttonStyle="solid"
                                name="loan_amt"
                                value={selectLoans}
                                defaultValue={selectLoans}
                                onChange={onChangeLoans}
                                size='large'
                            >
                                {optionsLoansAmt.map((opt_value, index) => {
                                    return (<>
                                        {index !==0 && index % 5 === 0 &&<br></br>}
                                        <Radio.Button style={{width:'80px', marginTop:'3px', marginRight:'3px'}} value={opt_value}>{Number(Number(opt_value).toFixed(2)).toLocaleString("th-TH")}</Radio.Button>
                                        </>
                                    );
                                })}
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="ระยะเวลาผ่อนชำระ (เดือน)"
                            name="loan_installment"
                        >
                            <Select
                                placeholder="ระยะเวลาผ่อนชำระ (เดือน)"
                                style={{ width: 200 }}
                                onSelect={onSelectPeriod}
                                value={selectPeriod}
                                allowClear
                                size='large'
                            >
                                {optionsPeriod.map((opt_value) => (
                                    <Option key={opt_value} value={opt_value}>{opt_value}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="ชื่อบัญชี"
                            name="account_name"
                            rules={[
                                { required: true, message: "กรุณาระบุชื่อบัญชี!" },
                                {
                                    message: "Please input your account name",
                                },
                            ]}
                        >
                            <Input size='large' showCount maxLength={100} />
                        </Form.Item>

                        <Form.Item
                            label="ธนาคาร"
                            name="bank_code"
                            rules={[
                                { required: true, message: "กรุณาเลือกธนาคาร!" },
                            ]}
                        >
                            <Select id="bank"
                                placeholder="เลือกธนาคาร"
                                onSelect={onSelectBank}
                                size='large'
                            >
                                {optionsbanks.map((opt_value) => (
                                    <Option
                                        key={opt_value.bankCode}
                                        name={opt_value.bankCode}
                                        value={opt_value.id}
                                    >
                                        {opt_value.bankCode} {opt_value.bankName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="เลขที่บัญชี"
                            name="back_account_code"
                            rules={[
                                { required: true, message: "กรุณาระบุเลขที่บัญชี!" },
                                {
                                  message: "Please input your account code",
                                },
                            ]}
                        >
                            <Input size='large' showCount maxLength={20}/>
                        </Form.Item>

                        <Form.Item
                            // wrapperCol={{ offset: 2, span: 20}}
                        >
                            <Table
                                columns={columns}
                                dataSource={campaignDetails}
                                size="small"
                                rowKey={(item: CampaignDetail) => item.id}
                                pagination={false}
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{ offset: 22, span: 2 }}
                        >
                            {/* <Button
                                danger
                                size="large"
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    form.submit();
                                    setTrigger(true);
                                }}
                            >
                                Save
                            </Button> */}
                            <Button
                                size="large"
                                type="primary"
                                icon={<RightCircleOutlined />}
                                onClick={() => {form.submit()}}
                            >
                                Next
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default LoanApplication
