import React from "react";
import { Button, Space, Typography } from "antd";

const { Title, Paragraph, Text, Link } = Typography;

const DescriptionBox = () => {
  return (
    <Typography>
      <Title level={4}>Username & Email</Title>
      <Space direction="vertical">
        <Text>tunnarok</Text>
        <Text>tunnarok@gmail.com</Text>
        <Button type="link">แก้ไข username หรือ email</Button>
      </Space>
    </Typography>
  );
};

export default DescriptionBox;
