import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "src/store/authen/slice";
import { reducer as userReducer } from "src/store/user/slice";
import { reducer as logReducer } from "src/store/log/slice";
import { reducer as paymentReducer} from "src/store/payment/slice";

const rootReducer = combineReducers({
  auth: authReducer,
  userState: userReducer,
  logState: logReducer,
  payment: paymentReducer,
});

export default rootReducer;
