import React from 'react'

import moment from 'moment';
import 'moment/locale/th';
import { Descriptions } from 'antd';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    book : any;
}

const ViewAccBookPayee: React.FC<IProps> = (props) => {
    const {book} = props;
  return (
    <div>
        <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            // title="Personal Information"
            size="middle"
            labelStyle={{ width: 200, fontWeight: "bold" }}
            bordered
        >
            <Descriptions.Item
                label="ผู้รับเงิน/ลูกค้า :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.payeeName}
            </Descriptions.Item>
            <Descriptions.Item
                label="ประเภทการจ่าย :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.payType}
            </Descriptions.Item>

            <Descriptions.Item
                label="เลขที่บัญชี :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.accountNo}
            </Descriptions.Item>

            <Descriptions.Item
                label="ธนาคาร :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.bankName}
            </Descriptions.Item>
            <Descriptions.Item
                label="สาขา :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.branchName}
            </Descriptions.Item>

            <Descriptions.Item
                label="เลขที่เช็ค :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.chequeNo}
            </Descriptions.Item>
            <Descriptions.Item
                label="สั่งจ่ายเช็คในนาม :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.chequeInName}
            </Descriptions.Item>

            <Descriptions.Item
                label="วันที่จ่ายเช้ค :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                
                {book.chequeDate ? moment(book.chequeDate).format(dateFormat) : ''}
            </Descriptions.Item>
        </Descriptions>
    </div>
  )
}

export default ViewAccBookPayee