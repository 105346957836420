import { Button, Steps, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {CloseCircleOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel';
import FindContract from './FindContract';
import ContractDetail from './ContractDetail';
import PaymentDetail from './PaymentDetail';
import ShowContract from './ShowContract';
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import campaignService from 'src/services/master/campaignService';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import { Deposit } from 'src/domain/payment/depositType';
import paymentService from 'src/services/payment/paymentService';
import { useAppSelector } from "src/store";
import { PaymentModel } from 'src/domain/payment/paymentModelType';
import messageUtils from 'src/utils/messageUtils';
import loggingService from 'src/services/security/loggingService';
import DFlex from '../../components/container/DFlex';

const Step = Steps.Step;

const NewPayment = () => {
    const history = useHistory();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [currentState, setCurrentState] = useState<number>(0);
    const [selectContractId, setSelectContranctId] = useState<number>(0);
    const [selectPaymentType, setSelectPaymentType] = useState<string>();
    const [balance, setBalance] = useState<number>(0);

    const [payment, setPayment] = useState<PaymentModel>({} as PaymentModel);
    const [contractDebit, setContractDebit] = useState<ContractDebit>({} as ContractDebit);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);
    const [deposit, setDeposit] = useState<Deposit>({} as Deposit);

    useEffect(() => {
        if(selectContractId && selectPaymentType) {
            paymentService.getDataForPayment(selectContractId, selectPaymentType!).then(res => {
                const _payment = res.data.body;
                // if(_payment && _payment.campaignId) {
                //     campaignService.getCampaignById(_payment.campaignId).then(res => {
                //         setCampaign(res.data);
                //     });
                // }

                // if(_payment && _payment.campaignHeadId) {
                //     campaignHeadService.getCampaignHeadById(_payment.campaignHeadId).then(res => {
                //         setCampaignHead(res.data);
                //     });
                // }

                setCampaign(_payment.campaign);
                setCampaignHead(_payment.campaignHead);
                setDeposit(_payment.deposit);
                setContractDebit(_payment.contractDebit);
                setPayment(_payment);
            });
        }
    }, [selectContractId, selectPaymentType]);

    useEffect(() => {}, [balance]);

    useEffect(() => {
        if (payment.isFinish) saveDataToDB();
    }, [payment]);

    const updateSelectContract = (id: number) => {
        setSelectContranctId(id);
    }

    const updateSelectPaymentType = (paymentType: string) => {
        setSelectPaymentType(paymentType);
    }

    const updateฺBalance = (data: any) => {
        const _payment = {
            ...payment,
            paymentType: data.paymentType,
            penaltyAmt: data.penaltyAmt,
            balanceAmt: data.amount,
            feeWaiver: data.feeWaiver,
            feePenalty: data.feePenalty,
            paymentDate: data.paymentDate,
        }
        setBalance(data.amount);
        setPayment(_payment);
    }

    const updatePayment = (data: any) => {
        const _payment = {
            ...payment,
            // depositForwardAmt: data.depositForwardAmt,
            // paymentDate: data.paymentDate,
            payAmt: data.paymentAmt,
            balanceAmt: data.balanceAmt,
            // penaltyAmt: data.penaltyAmt,
            remainAmt: data.remainAmt,
            changeDepositType: data.remainType,
            receivedAmt: data.receivedAmt,
            debtForwardAmt: data.debtForwardAmt,
            isFinish: true,
        }
        setPayment(_payment);
    }

    const saveDataToDB = async () => {
        const _payment = {
            ...payment,
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
        }

        await paymentService.addPayment(_payment).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'CREATE',
                    systemRemark: 'create payment',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    // eventPayload: JSON.stringify({
                    //     paymentNo: res.data.paymentNo,
                    //     paymentDate: res.data.paymentDate
                    // }),
                    target: 'payment',
                    targetRef: res.data.paymentNo
                });

                history.goBack();
            } else {
                const _payment = {
                    ...payment,
                    isFinish: false,
                }
                setPayment(_payment);
                messageUtils.errorMessage(res.data.message);
            }
        }).catch((err) => {
            console.log(err);
            const _payment = {
                ...payment,
                isFinish: false,
            }
            setPayment(_payment);
            messageUtils.errorMessage(JSON.stringify(err));
        });
    }

    const next = () => {
        setCurrentState((prev) => prev + 1);
    };
    const prev = () => {
        setCurrentState((prev) => prev - 1);
    };
    const changeSteps = (curr: number) => {
        setCurrentState(curr);
    };

    const steps = [
        {
            title: "เลือกสัญญา",
            content: <FindContract
				next={next}
				updateSelectContract={updateSelectContract} 
                updateSelectPaymentType={updateSelectPaymentType} />,
        },
        {
            title: "รายละเอียด",
            content: (<ContractDetail
                next={next}
                payment={payment}
                setPayment={setPayment}
                contractDebit={contractDebit}
                updateฺBalance={updateฺBalance}
            />),
        },
        {
            title: "รับชำระ",
            content: (<PaymentDetail
                        payment={payment}
                        contractDebit={contractDebit}
                        deposit={deposit}
                        balance={balance}
                        updatePayment={updatePayment}
                    />),
        },
    ];

    return (
        <div>
            <PageHeader title="สร้าง ใบรับชำระ" showSubtitle={false} >
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.goBack()}
                >
                    Cancel
                </Button>
            </PageHeader>
            <DFlex type='row' justifyContent='center' full style={{ marginBottom: '40px'}}>
                    <Steps
                        size='default'
                        current={currentState}
                        direction="horizontal"
                        onChange={(e) => changeSteps(e)}
                        style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        width: '60%'
                        }}
                    >
                        {steps.map((item) => (
                            <Step key={item.title} title={item.title} />
                        ))}
                </Steps>
            </DFlex>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{  width: "100%", padding: "30px" }}>

                    <div style={{ width: "100%", paddingTop: "30px" }}>
                        {steps[currentState].content}
                    </div>
                </Panel>

                {currentState > 0 ?
                    <Panel style={{marginLeft:"10px"}}>
                        <div style={{ display: "flex", width: "300px" }}>
                            <ShowContract
                                payment={payment}
                                contractDebit={contractDebit}
                                campaign={campaign}
                                campaignHead={campaignHead}
                                deposit={deposit}
                            />
                        </div>
                    </Panel>
                : ""}
            </div>
        </div>
    )
}

export default NewPayment
