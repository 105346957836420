import { NcClass } from "./classType";

export interface ClassDetail {
    id : number;
    classId : number;
    classCode? : string;
    code : string;
    name : string;
    useFlg : number;
}

export const NcClassDetailUseFlg = {
    NOT_USE: -1,
    USE: 0,
    SEARCH_ALL: 2,
}