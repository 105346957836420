import React, { useEffect, useState } from 'react'
import { Button, Descriptions, Table } from 'antd';
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { RouteComponentProps, useHistory } from 'react-router'
import { FormOutlined } from "@ant-design/icons";

import { CampaignDetail } from 'src/domain/master/CampaignDetailType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';

import campaignHeadService from 'src/services/master/campaignHeadService';
import campaignService from 'src/services/master/campaignService';
import ListCampaignDetail from './ListCampaignDetail';

interface IParams {
    headId: string;
}

const ViewCampaignHead : React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _campaignHeadId = Number(props.match.params.headId);
    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);

    // useEffect(() => {
    //     campaignHeadService.getCampaignHeadById(_campaignHeadId).then(res=>{
    //         setCampaignHead(res.data);
    //     });
    // }, []);
    useEffect(() => {
        (async () => {
            const res = await campaignHeadService.getCampaignHeadById(_campaignHeadId);
            setCampaignHead(res.data);
        })();
    }, []);

    return (
        <div>
            <PageHeader title="NIMCAP - กำหนด CAMPAIGN" onClose={() => history.goBack()}>
            <Button
                size="large"
                icon={<FormOutlined />}
                onClick={() => history.push("/app/campaign/edit/campaignHead/"+ _campaignHeadId + "/"+campaignHead.campaignId)}
                >
                แก้ไข
            </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        size="middle"
                        labelStyle={{ width: 200, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="รหัส :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {campaignHead.campaignHeadNo}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ยอดจัด (บาท) :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.loanAmt).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="FLAT INTEREST RATE % (ต่องวด) :"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.flatInterestMth).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="(ทั้งหมด):"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.flatInterestAnnum).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="จำนวนงวด :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.tenor).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="EFFECTIVE INTEREST RATE % (ต่องวด) :"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.effInterestMth).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="(ทั้งหมด):"
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.effInterestAnnum).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ดอกเบี้ย (บาท) :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.interest).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เงินต้น+ดอกเบี้ย (บาท) :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.principalInterst).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="จ่ายต่องวด (บาท) :"
                            span={2}
                            labelStyle={{ textAlign: "right" }}
                        >
                            {Number(Number(campaignHead.installment).toFixed(2)).toLocaleString('uth-TH')}
                        </Descriptions.Item>
                    </Descriptions>

                    <ListCampaignDetail headId={_campaignHeadId} />
                    
                </div>
            </Content>
        </div>
    )
}
export default ViewCampaignHead
