import React from 'react'
import AccBookForm from './AccBookForm'

const NewAccBook = () => {
    return (
        <div>
            <AccBookForm id={0} mode={'NEW'} />
        </div>
    )
}

export default NewAccBook
