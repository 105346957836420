import { Descriptions, Modal, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';
import { ContractTransferDetail } from 'src/domain/contract/contractTransferDetailType';
import { ContractTransferHead } from 'src/domain/contract/contractTransferHeadType';
import contractTransferService from 'src/services/contract/contractTransferService';

import moment from 'moment';
import 'moment/locale/th';
import loggingService from 'src/services/security/loggingService';
import useSessionUser from 'src/hooks/useSessionUser';

const {Title } = Typography;
const dateFormat = "DD/MM/YYYY";

interface IProps {
    transferId: number;
    modalViewVisible: boolean;
    onCancel: () => void;
}

const columns = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: ContractTransferDetail, index: number) => (
            index+1
        ),
    },
    {
        title: "รายการ",
        dataIndex: "itemText",
        key: "itemText",
        render: (value: number, item: ContractTransferDetail, index: number) => (
            item.itemName !== 'loan' ? item.itemText + ' (' + (item.rate ? item.rate : 0) + ' %)' : item.itemText
        ),
    },
    {
        title: "ยอดเงิน",
        dataIndex: "itemAmt",
        key: "itemAmt",
        align: "right" as "right",
        render: (value: number) => Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
    },
]

const ViewContractTranferPopup : React.FC<IProps> = (props) => {
    const {transferId, modalViewVisible, onCancel} = props;
    const [contractTransferHead, setContransferHead] = useState<ContractTransferHead>({} as ContractTransferHead);
    const [contractTransferDetails, setContractTransferDetails] = useState<ContractTransferDetail[]>([]);

    const sessionUser = useSessionUser();

    useEffect(() => {
        if(transferId && modalViewVisible) {
            contractTransferService.getContractTransferById(transferId).then(res => {
                // if(res.status == 200) {
                    const _transfer = res.data;
                    setContransferHead(_transfer);
                    setContractTransferDetails(_transfer.details);

                    loggingService.logging({
                        event: "VIEW",
                        systemRemark:`view contract transfer : ${transferId}`,
                        target: "contract",
                        targetRef: res.data.contractNo,
                        eventUserId: sessionUser.userId,
                        eventUsername: sessionUser.username,
                    });
                // }
            });

            
        }
    }, [transferId, modalViewVisible]);

    // useEffect(() => {}, [contractTransferHead]);

  return (
    <>
        <Modal
            title="ข้อมูลการโอนเงิน"
            style={{ top: 100 }}
            width="60%"
            visible={modalViewVisible}
            // onOk={handleOK}
            okButtonProps={{form:'view-contracttransfer-popup-form',  htmlType: 'submit'}}
            onCancel={() => onCancel()}
            destroyOnClose={true}
        >
            {/* <Panel style={{marginTop: 10,}}> */}
                {/* <Space size={1} direction="vertical"> */}
                    <Typography>
                        <Title level={3}>ข้อมูลสัญญา</Title>
                    </Typography>
                    <Descriptions
                        column={2}
                        size="middle"
                        labelStyle={{ width: 150, fontWeight: "bold" }}
                        bordered
                    >
                        <Descriptions.Item
                            label="เลขที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.contractNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="วันที่สัญญา :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.contractDate ? moment(contractTransferHead.contractDate).format(dateFormat) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="ชื่อบัญชี :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.accountName}
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="เลขที่บัญชี :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.bankAccountNo}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label="ธนาคาร :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.transferCode} {contractTransferHead.transferName}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label="วันที่โอนเงิน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {contractTransferHead.payDate ? moment(contractTransferHead.payDate).format(dateFormat) : ""}
                        </Descriptions.Item>
                        <Descriptions.Item
                            span={2}
                            label="จำนวนเงินที่โอน :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff", fontSize:"16px" , color:"red" }}
                        >
                            <span style={{fontWeight: "bold", fontSize:"16px", color:"red"}}>
                            {Number(Number(contractTransferHead.transferAmt).toFixed(2)).toLocaleString("th-TH")}
                            </span>
                            <label style={{fontWeight: "bold", fontSize:"16px", color:"red", marginLeft:"10px"}}>บาท</label>
                        </Descriptions.Item>
                    </Descriptions>
                    <Table
                        columns={columns}
                        dataSource={contractTransferDetails}
                        size="small"
                        rowKey={(item: ContractTransferDetail) => item.itemName}
                        pagination={false}
                    />
                {/* </Space> */}
            {/* </Panel> */}
        </Modal>
    </>
  )
}

export default ViewContractTranferPopup