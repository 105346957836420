import React, { useEffect, useState } from 'react'
import {Table, Tabs, Form, Input, Button, DatePicker} from 'antd'
import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import {SearchOutlined} from '@ant-design/icons';


import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';

import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';

import classes from "src/views/application/ListApplication.module.css";

import { ColumnsType } from 'antd/lib/table';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const dateFormat = "DD/MM/YYYY";


const ListContract: React.FC<Contract> = () => {
    const [form] = Form.useForm();
    const [contracts, setContracts] = useState<Contract[]>([]);
    const [waitingContDates, setWaitingContDates] = useState<Contract[]>([]);
    const [waitingPayDates, setWaitingPayDates] = useState<Contract[]>([]);

    const defaultDate = {
      contractDate: [moment().startOf('month'), moment().endOf('month')]
  };

    useEffect(() => {
        (async () => {
            const res = await contractService.getContractCurrentDate();
            setContracts(res.data);
        })();
    }, []);

    useEffect(() => {
      (async () => {
          const res = await contractService.getWaitingPayDates();
          setWaitingPayDates(res.data);
      })();
    }, []);

    useEffect(() => {
      (async () => {
          const res = await contractService.getWaitingContDates();
          setWaitingContDates(res.data);
      })();
    }, []);

    useEffect(() => {}, [contracts]);

    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter') {
          const code_search = form.getFieldValue("contractNo")
          if(code_search) {
            (async () => {
              const res = await contractService.searchContractLists(
                code_search === undefined ? "" : code_search,
                "", "");
              setContracts(res.data);
            })();
          }
      }
  }

    const onFinish = (values: any) => {
      // console.log("onFinish id", values)
      // console.log("onFinish code", values.contractNo)

      const _contractNo = values.contractNo;
      const _contractDate = values.contractDate;
      if(_contractNo || _contractDate) {
        (async () => {
          const res = await contractService.searchContractLists(
            _contractNo === undefined ? "" : _contractNo,
            _contractDate === undefined ? "" : _contractDate[0].format('YYYYMMDD'),
            _contractDate === undefined ? "" : _contractDate[1].format('YYYYMMDD'));

          setContracts(res.data);
        })();
      }
    }

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
      console.log('page property', page, pageSize);
      setOffset((page-1) * pageSize);
    }

    const columns: ColumnsType<Contract> = [
      {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: Contract, index: number) => (
          offset + index+ 1
        ),
      },
      {
        title: "เลขที่สัญญา",
        dataIndex: "contractNo",
        key: "contractNo",
        render: (value: string, item: Contract, index: number) => (
          <Link to={`/app/contract/view/${item.id}`}>{value}</Link>
        ),
        sorter: {
          compare: (a: Contract, b: Contract) => a.contractNo.localeCompare(b.contractNo),
          multiple: 1 },
        defaultSortOrder: 'ascend',

      },
      {
        title: "ผู้ขอสินเชื่อ",
        dataIndex: "name",
        key: "name",
        sorter: { compare: (a: Contract, b: Contract) => a.name!.localeCompare(b.name!),
          multiple: 2}

      },
      {
        title: "เลขที่ใบคำขอสินเชื่อ",
        dataIndex: "applicationNo",
        key: "applicationNo",
      },
      {
        title: "วันที่อนุมัติ",
        dataIndex: "approveDate",
        key: "approveDate",
        render: (value: string) => value ? moment(value).format(dateFormat) : "",
      },
      {
        title: "วันที่สัญญา",
        dataIndex: "contractDate",
        key: "contractDate",
        render: (value: string) => value ? moment(value).format(dateFormat) : "",
      },
      {
        title: "วันที่โอนเงิน",
        dataIndex: "payDate",
        key: "payDate",
        render: (value: string) => value ? moment(value).format(dateFormat) : "",
      },
      {
        title: "สถานะสัญญา",
        dataIndex: "contractStatusName",
        key: "contractStatusName",
        render: (value: string, item: Contract, index: number) => {
          return (
            item.contractStatusId == 77 ? 
              <label style={{ color:'red', fontWeight: "bold" }}>{value}</label> :
              <label style={{ fontWeight: "bold" }}>{value}</label>
          )
        },
      },
    ];


    return (
        <div>
            <PageHeader title="Contract List"/>
            <Content className="app-page-content">
              <div style={{ marginTop: '-10px', position: 'relative'}}>
                <Tabs defaultActiveKey="1" size={'small'} tabBarGutter={20}>
                  <TabPane tab="ทั้งหมด" key="1" style={{ marginTop: '10px'}}>
                  <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={defaultDate}
                        size="large"
                        // onFinishFailed={onFinishFailed}
                      >
                        <div style={{ display: "flex", flexDirection: "row", gap:'20px', marginTop: '10px', marginBottom: '10px' }}>
                          <div style={{width: '250px'}}>
                            <Form.Item
                              label="วันที่สัญญา"
                              name="contractDate"
                              key="contractDate"
                            >
                              <RangePicker locale={th_loc} format={dateFormat} />
                            </Form.Item>
                          </div>

                          <div style={{width: '250px'}} >
                            <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                            >
                              <Input onKeyDown={handleKeyDown}/>
                            </Form.Item>
                          </div>

                          <div style={{flex: 1, display:'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                              <Button
                                  icon={<SearchOutlined/>}
                                  size="large"
                                  onClick={() => {
                                    form.submit();
                                  }}
                                  style={{ marginTop: '15px'}}
                                  // onClick={findByParam}
                              >
                                ค้นหา
                              </Button>
                          </div>
                        </div>
                      </Form>
                    <div className={classes.dataPanel}>
                      <h4>รายการ</h4>
                      <Table
                        columns={columns}
                        dataSource={contracts}
                        size="small"
                        rowKey={(item: Contract) => item.contractNo}
                        pagination={{
                          onChange: handlePageChange,
                          pageSize: 20,
                        }}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="รอลงวันที่สัญญา" key="2" style={{ marginTop: '30px'}}>
                    <div className={classes.dataPanel}>
                      <h4>รายการ</h4>
                      <Table
                        columns={columns}
                        dataSource={waitingContDates}
                        size="small"
                        rowKey={(item: Contract) => item.contractNo}
                      />
                    </div>
                  </TabPane>
                  <TabPane tab="รอลงวันที่โอน" key="3" style={{ marginTop: '30px'}}>
                    <div className={classes.dataPanel}>
                    <h4>รายการ</h4>
                      <Table
                        columns={columns}
                        dataSource={waitingPayDates}
                        size="small"
                        rowKey={(item: Contract) => item.contractNo}
                      />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Content>
        </div>
    )
}

export default ListContract
