export interface Branch {
    id:number;
    branchCode:string;
    branchName:string;
    useFlg:number;
}

export const BranchUseFlag = {
    NOT_USE: -1,
    USE: 0,
    SEARCH_ALL: 2
}