import { log } from "console";
import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/campaign";
const url = `${configs.urlRoot}/campaign`;

const getCampaign = (amt: number, period: number) => {
    return http.get(url+"/param?loanAmt="+amt+ "&tenor="+period);
}

const getCampaignByCurrentDate = () => {
    return http.get(url+"/ByCurrentDate");
}

const getCampaignDetail = (campId: number, amt: number, period: number) => {
    return http.get(url+"/campDetail?campId="+campId+ "&loanAmt="+amt+ "&tenor="+period);
}

const getCampaignHead = (campId: number, amt: number, period: number) => {
    return http.get(url+"/campH?campId="+campId+ "&loanAmt="+amt+ "&tenor="+period);
}

const getCampaignDetailByHeadId = (headId: number) => {
    return http.get(url+"/campDetail/"+headId);
}

const isExistCode = (code: string) => {
    return http.get(url+"/isExist/"+code);
}

const getCampaignAll = () => {
    return http.get(url);
}

const addCampaign = (camp : any) => {
    return http.post(url, camp);
} 

const getCampaignById = (id: number) => {
    return http.get(url+"/"+id);
}

const editCampaign = (camp : any) => {
    return http.put(url+"/"+camp.id, camp);
} 

export default {
    getCampaign,
    getCampaignAll,
    addCampaign,
    getCampaignById,
    getCampaignByCurrentDate,
    getCampaignDetail,
    getCampaignHead,
    getCampaignDetailByHeadId,
    editCampaign,
    isExistCode,
}