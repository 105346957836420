import { log } from "console";
import http from "../../lib/http";
import configs from "src/constants/config";
import { NcBank } from "src/domain/master/bankType";

// const url = "http://localhost:8080/bank";
const url = `${configs.urlRoot}/bank`;

const datas = [
    {id:1, bankCode:"BKK", bankName:"ธนาคารกรุงเทพ จำกัด (มหาชน)"},
    {id:2, bankCode:"KTB", bankName:"ธนาคารกรุงไทย จำกัด (มหาชน)"},
    {id:3, bankCode:"BAY", bankName:"ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)"}
]

const getBankAll = () => {
    const datas_param = http.get(url);
    console.log(datas_param);
    return datas_param;
    // return datas;
}

const getBankById = (id: number) => {
    return http.get(url+"/"+id);
}

const searchBank = (keyword: string, useFlg: number) => {
    let searchData = {keyword: keyword, useFlg: useFlg};
    return http.post(`${url}/search`, {}, {params: searchData});
}

const addBank = (submitData: NcBank) => {
    return http.post(`${url}/add`, submitData);
}

const updateBank = (submitData: NcBank) => {
    return http.post(`${url}/update`, submitData);
}

export default {
    getBankAll,
    getBankById,
    searchBank,
    addBank,
    updateBank
}