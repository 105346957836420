import { Modal, Form } from 'antd';
import TextArea from "antd/lib/input/TextArea";
import React, { useState } from 'react'

interface IProps {
    modalVisible: boolean;
    onOK: (value: string) => void;
    onCancel: () => void;
}

const RemarkModel : React.FC<IProps> = (props) => {
    const { modalVisible, onOK, onCancel } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        setLoading(true);
        const remark = values.remark;
        console.log(remark)
        onOK(remark === undefined ? null : remark);
        setLoading(false);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> RemarkModel:", errorInfo);
    };

    return (
        <div>
            <Modal 
                title="Remark" 
                visible={modalVisible} 
                confirmLoading={loading}
                onOk={form.submit}
                onCancel={() => onCancel()}
                width="40%"
            >
                    <Form
                        form={form}
                        layout="vertical"
                        style={{ display: "flex", justifyContent: "center" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item label="ระบุหมายเหตุ :" name="remark">
                            <TextArea rows={5} cols={50} />
                        </Form.Item>
                    </Form>
            </Modal>
        </div>
    )
}

export default RemarkModel
