import React, { useEffect, useState } from 'react'
import {Table} from 'antd'
import contractService from 'src/services/contract/contractService';
import { ContractDebit } from 'src/domain/contract/contractDebitType';

import moment from 'moment';
import 'moment/locale/th';
import { Link } from 'react-router-dom';
import ViewPaymentPopup from '../payment/ViewPaymentPopup';
import { Payment } from 'src/domain/payment/paymentType';
import paymentService from 'src/services/payment/paymentService';
import { PaymentDetail } from 'src/domain/payment/paymentDetailType';
import { DepositDetail } from 'src/domain/payment/depositDetailType';
import depositService from 'src/services/payment/depositService';
import { useAppDispatch, useAppSelector } from "src/store";

import {
    getPayment,
  } from "src/store/payment/action";

const dateFormat = 'DD/MM/YYYY';

interface IProps {
    id: number;
}

const ListContractDebit : React.FC<IProps> = (props) => {
    const _id = props.id;
    const dispatch = useAppDispatch();
    // const { payment } = useAppSelector((state) => state.payment);


    const [contractDebitLists, setContractDebitLists] = useState<ContractDebit[]>([] as ContractDebit[]);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [paymentNo, setPaymentNo] = useState<string>();
    const [payment, setPayment] = useState<Payment>({} as Payment);
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
    const [itemDeposit, setItemDeposit] = useState<PaymentDetail>({} as PaymentDetail);
    const [depositDetails, setDepositDetails] = useState<DepositDetail[]>([]);

    const columns = [
        {
            title: "Mth",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "วันที่กำหนดชำระ",
            dataIndex: "duedate",
            key: "duedate",
            render: (value: string) => value ? moment(value).format(dateFormat) : "",
        },
        {
            title: "เลขที่รับชำระ",
            dataIndex: "paymentNo",
            key: "paymentNo",
            render: (value: string, item: ContractDebit, index: number) => (
                <a onClick={() => item.paymentNo ? showModal(item.paymentNo) : ""}>{value}</a>
              ),
        },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Interest Revenues",
            dataIndex: "interestRevenues",
            key: "interestRevenues",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Princ Repaid",
            dataIndex: "princPepaid",
            key: "princPepaid",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Ending Bal.",
            dataIndex: "endingBal",
            key: "endingBal",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "CF",
            dataIndex: "cf",
            key: "cf",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "% ตัดต้น",
            dataIndex: "principlePer",
            key: "principlePer",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
    ]

    const showModal = (_patmentNo: string) => {
        // paymentService.getPaymentByNo(_patmentNo).then(res => {
        //     const _payment = res.data;
        //     const _paymentDetails = [];

        //     if(_payment) {
        //         for(let x=0; x<_payment.paymentDetails.length; x++) {
        //             const _detail = _payment.paymentDetails[x];
        //             if(_detail.paymentItemId === 48) setItemDeposit(_detail);
        //             else _paymentDetails.push(_detail);
        //         }
        //         setPaymentDetails(_paymentDetails);
    
        //         depositService.getByPaymentNoAndType(_payment.paymentNo, "1").then(res => {
        //             setDepositDetails(res.data);
        //         });
        //     }
        //     setPayment(_payment);
        // });
        // setPaymentNo(_patmentNo);
        dispatch(getPayment(_patmentNo));
        setModalVisible(true);
    };

    useEffect(() => {
        // (async () => {
            contractService.getDebitbyContractId(_id).then(res => {
                setContractDebitLists(res.data);
            });
        // })
    }, []);
    
    return (
        <div>
            <Table
                columns={columns}
                dataSource={contractDebitLists}
                size="small"
                rowKey={(item: ContractDebit) => item.id}
                pagination={false}
            />

            <ViewPaymentPopup 
                // paymentNo={paymentNo}
                // payment={payment}
                // paymentDetails={paymentDetails}
                // itemDeposit={itemDeposit}
                // depositDetails={depositDetails}
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
            />
        </div>
    )
}

export default ListContractDebit
