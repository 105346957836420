import http from "../../lib/http";
import configs from "src/constants/config";
import { BankTransferDetail } from "src/domain/banktransfer/bankTransferDetailType";

// const url = "http://localhost:8080/banktransfer";
const url = `${configs.urlRoot}/banktransfer`;

const getById = (id: number) => {
    return http.get(url+"/"+id);
};

const getBankTransfer = () => {
    return http.get(url);
};

const getContractTransferByPayDate = (payDate: string) => {
    return http.get(url+"/byPayDate?payDate="+payDate);
};

const getHeadDataByDate = (startDate: string, endDate: string) => {
    return http.get(url+"/list/"+startDate+"/"+endDate);
};

const addBankTransfer = (transferDate : string, remark: string, 
    userId: number, username: string, savStation: string, 
    details: BankTransferDetail[]) => {
        let data = {transferDate, remark, userId, username, savStation, details};
    return http.post(url, data);
} 

const saveExport = (userId: number, username: string, savStation: string, 
    bankTransferHeadId: number, details: BankTransferDetail[], remark: string) => {
        let data = {userId, username, savStation, bankTransferHeadId, details, remark};
    return http.post(url+"/export", data);
} 

const updateBankTransactionNo = (userId: number, username: string, savStation: string, 
    details: BankTransferDetail[]) => {
    return http.put(url, {userId: userId, username: username, savStation: savStation, details: details});
}

export default {
    getById,
    getBankTransfer,
    getContractTransferByPayDate,
    getHeadDataByDate,
    addBankTransfer,
    updateBankTransactionNo,
    saveExport
}