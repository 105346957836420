import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Input,
  Menu,
  Select,
  Space,
  Table,
} from "antd";
import classes from "./UserList.module.css";
import PageHeader from "src/layouts/PageHeader";
import CreateUserModal from "./CreateUserModal";
import { thunkPromise, useAppDispatch, useAppSelector } from "../../../store";
import { User } from "../../../store/user/types";

const { Option } = Select;

const menu = (
  <Menu>
    <Menu.Item key="0">Last 1 days</Menu.Item>
    <Menu.Item key="1">Last 7 days</Menu.Item>
    <Menu.Item key="1">Last 15 days</Menu.Item>
    <Menu.Item key="1">Last 1 month</Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3" contentEditable="true">
      <DatePicker.RangePicker />
    </Menu.Item>
  </Menu>
);

let index = 0;

const UserList = () => {
  const history = useHistory();
  const userState = useAppSelector((state) => state.userState);

  const [items, setItems] = useState<string[]>(["Jack", "Lucy"]);
  const [name, setName] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    console.log("users : ", userState.users);
  }, [userState.users]);

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const addItem = () => {
    console.log("addItem");
    setItems([...items, name || `New item ${index++}`]);
    setName("");
  };

  const handleOK = (value: any) => {
    console.log("user id", value.id);
    history.push("/app/security/user/" + value.id);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: User, index: number) => index + 1,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "userName",
      width: 90,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  return (
    <div>
      <PageHeader title="Users">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setModalVisible(true)}
        >
          New
        </Button>
      </PageHeader>

      <div className={classes.searchPanel}>
        <div className={classes.searchText}>
          <Input.Search
            placeholder="username or email"
            allowClear
            style={{ width: 300 }}
          />
        </div>

        <Space>
          <div className={classes.dateRange}>
            <Select
              style={{ width: 200 }}
              placeholder="เลือก ช่วงเวลา"
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    <Input
                      style={{ flex: "auto" }}
                      value={name}
                      onChange={onNameChange}
                    />
                    <a
                      style={{
                        flex: "none",
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={addItem}
                    >
                      <PlusOutlined /> Add item
                    </a>
                  </div>
                </div>
              )}
            >
              {items.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
          <Select style={{ width: 200 }} placeholder="Status">
            <Option key={"001"} value="001">
              New
            </Option>
            <Option key={"001"} value="001">
              Process
            </Option>
          </Select>
        </Space>
      </div>
      <div className={classes.dataPanel}>
        <Table
          columns={columns}
          dataSource={userState.users}
          size="small"
          rowKey={(item: User) => item.username}
        />
      </div>
      <CreateUserModal
        modalVisible={modalVisible}
        onOK={handleOK}
        onCancel={() => setModalVisible(false)}
      />
    </div>
  );
};

export default UserList;
