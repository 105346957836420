import http from "../../lib/http";
import configs from "src/constants/config";
import { Contract } from "src/domain/contract/contractType";

// const url = "http://localhost:8080/contract";
const url = `${configs.urlRoot}/contract`;

const getContracts = () => {
    return http.get(url);
};

const getContractCurrentDate = () => {
    return http.get(url+"/contractCurrentDate");
};

const getWaitingContDates = () => {
    return http.get(url+"/waitingContDates");
};

const getWaitingPayDates = () => {
    return http.get(url+"/waitingPayDates");
};

const getDataForRedebt = () => {
    return http.get(url+"/forRedebt");
}

const searchContractLists = (contractNo: string, startDate: string, endDate: string) => {
    return http.get(url+"/search?contractNo="+contractNo+"&startDate="+startDate+"&endDate="+endDate);
}

const getContractById = (id: number) => {
    return http.get(url+"/"+id);
}

const getContractByNo = (no: string) => {
    return http.get(url+"/byNo?no="+no);
}

const getContractByApplicationId = (applicationId: number) => {
    return http.get(url+"/application/" + applicationId);
}

const getDebitbyContractId = (id: number) => {
    return http.get(url+"/getDebitbyContractId/"+id);
}

const getForPaymentByContractId = (id: number) => {
    return http.get(url+"/getForPaymentByContractId?contractId="+id);
}

const getByParam = (contractNo: string, idCard: string) => {
    return http.get(url+"/param?contractNo="+contractNo+"&idCard="+idCard);
}

const searchForReport = (branchId: number, contractNo: string, no: string, 
        name: string,startDate: string, endDate: string, 
) => {
    let searchData = {branchId: branchId, contractNo: contractNo, cardNo: no,name: name,
                        startDate: startDate, endDate: endDate, 
                    };
    return http.post(`${url}/searchForReport`, {}, {params: searchData});
}

const searchForDuedateReport = (contractNo: string, startDate: string, endDate: string, 
) => {
    let searchData = {contractNo: contractNo, startDate: startDate, endDate: endDate, };
    return http.post(`${url}/searchForDuedateReport`, {}, {params: searchData});
}

const searchForAtDateReport = (startDate: string, endDate: string, atDate: string
    ) => {
    let searchData = {startDate: startDate, endDate: endDate, atDate };
    return http.post(`${url}/searchForAtDateReport`, {}, {params: searchData});
}

const getLastPay = (contractId: number) => {
    return http.get(url+"/lastPay?contractId=" + contractId);
}

const calculateDueDate = (id: number, contractDate: string) => {
    return http.get(url+"/calculateDueDate?id="+id+"&contractDate="+contractDate);
}

const updateContractDate = (contract: any) => {
    return http.put(url, contract);
}

const cancelContract = (model: Contract) => {
    return http.put(url+"/cancelContract", model);
}

const urlContractPeriodReport = (contractId: number) => {
    return `${url}/${contractId}/report`;
}

export default {
    getContracts,
    getContractById,
    getContractByNo,
    getContractByApplicationId,
    getDebitbyContractId,
    getForPaymentByContractId,
    getByParam,
    calculateDueDate,
    updateContractDate,
    cancelContract,
    getWaitingContDates,
    getWaitingPayDates,
    getContractCurrentDate,
    getDataForRedebt,
    searchContractLists,
    searchForReport,
    searchForDuedateReport,
    searchForAtDateReport,
    getLastPay,
    urlContractPeriodReport
}