import React from 'react'
import { RouteComponentProps } from 'react-router-dom';
import ContractRedebtForm from './ContractRedebtForm';

interface IParams {
    id: string;
}

const EditContractRedebt: React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);

  return (
    <div>
        <ContractRedebtForm id={_id} mode='EDIT' />
    </div>
  )
}

export default EditContractRedebt