import { Descriptions } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { Contract } from 'src/domain/contract/contractType'

import moment from 'moment';
import 'moment/locale/th';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';

const dateFormat = 'DD/MM/YYYY';

interface IProps {
    contract: Contract;
    campaign: Campaign;
    campaignHead: CampaignHead;
}

const ViewPaymentContract : React.FC<IProps> = (props) => {
    const {contract, campaign, campaignHead} = props;

    return (
        <div>
            <Content className="app-page-content">
                <Descriptions
                    column={1}
                    bordered
                >
                    <Descriptions.Item
                        label="เลขที่สัญญา :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {contract.contractNo}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วันที่สัญญา :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {contract.contractDate ? moment(contract.contractDate).format(dateFormat) : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="CAMPAIGN :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {campaign.campaignName}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ยอดจัด :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(campaignHead.loanAmt).toFixed(2)).toLocaleString('th-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ยอดทั้งหมด :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(campaignHead.principalInterst).toFixed(2)).toLocaleString('th-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="จำนวนงวด :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {campaignHead.tenor}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ชำระ/งวด :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {Number(Number(campaignHead.installment).toFixed(2)).toLocaleString('th-TH')}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ชำระทุกวันที่ :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {contract.contractDate ? contract.contractDate.substring(6,8) : ""}
                    </Descriptions.Item>
                </Descriptions>
            </Content>
        </div>
    )
}

export default ViewPaymentContract;
