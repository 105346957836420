import React from 'react'
import { useAppSelector } from "src/store";

const useSessionUser = () => {

  const { sessionUser} = useAppSelector((store) => store.auth);

  return sessionUser;
}

export default useSessionUser