import { Button, Table, Radio, Modal, Form, Input, RadioChangeEvent } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, SearchOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

import applicationService from "src/services/application/applicationService"
import BranchService from 'src/services/master/BranchService';
import { Branch, BranchUseFlag } from "src/domain/master/BranchType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";
import UseFlgIcon from 'src/components/container/UseFlgIcon';
import { ColumnsType } from 'antd/lib/table';

const dateFormat = "DD/MM/YYYY";
// const { TabPane } = Tabs;

const ListBranch: React.FC<Branch> = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [branchs, setBranchs] = useState<Branch[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [searchUseFlag, setSearchUseFlag] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const columns: ColumnsType<Branch> = [
    {
      title: "ลำดับ",
      dataIndex: "id",
      key: "id",
      width: 90,
      render: (value: string, item: Branch, index: number) => (
          ((currentPage - 1) * pageSize) + (index+1)
      ),
    },
    {
      title: "รหัส",
      dataIndex: "branchCode",
      key: "branchCode",
      width: 200,
      sorter: (a: Branch, b: Branch) => {return a.branchCode.localeCompare(b.branchCode)},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: Branch, index: number) => (
        <Link to={`/app/master/branch/manage/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "สาขา",
      dataIndex: "branchName",
      key: "branchName",
      sorter: (a: Branch, b: Branch) => {return a.branchName.localeCompare(b.branchName)},
      sortDirections: ['ascend', "descend"],
      render: (value: string) => value,
    },
    {
      title: "การใช้งาน",
      dataIndex: "useFlg",
      key: "useFlg",
      width: 120,
      sorter: (a: Branch, b: Branch) => {return a.useFlg - b.useFlg},
      sortDirections: ['ascend', "descend"],
      // align: "right" as "right",
      render: (value: number) => (
        <UseFlgIcon useFlg={value == BranchUseFlag.USE}/>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await BranchService.searchBranch('', 2);
      setBranchs(res.data);
      setLoading(false);
    })();
    form.setFieldsValue({
        branchKeyword: '',
        useFlag: 2
    });
  }, []);

  useEffect(() => {}, [branchs]);

  const onChangeTabs = (value: any) => {

  }

  const handleOk = async (value: {branchKeyword: string, useFlag: number}) => {
    setLoading(true);
    const res = await BranchService.searchBranch(value.branchKeyword, value.useFlag);
    setBranchs(res.data);
    setCurrentPage(1);
    setLoading(false);
    setIsModalVisible(false);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  }
  const onUseFlatChange = (e: RadioChangeEvent) => {
      form.setFieldsValue({useFlag: e.target.value});
  }

  const footerBtnList = [
    <Form.Item name="search_submit" style={{textAlign: 'center'}}>
        <Button
            size="small"
            type="primary"
            name="per"
            icon={<SearchOutlined />}
            onClick={() => {
                form.submit();
            }}
        >
            ค้นหา
        </Button>
    </Form.Item>,
  ]
  const renderUseFlagRadio = () => {
    return(
        <Radio.Group onChange={onUseFlatChange}>
            <Radio value={BranchUseFlag.USE}>ใช้งาน</Radio>
            <Radio value={BranchUseFlag.NOT_USE}>ไม่ใช้งาน</Radio>
            <Radio value={BranchUseFlag.SEARCH_ALL}>ทั้งหมด</Radio>
        </Radio.Group>
    )
  }
  const renderSearchModal = () => {
    return (
      <Modal title="ค้นหา" visible={isModalVisible} footer={footerBtnList} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
            <Form.Item name="branchKeyword">
                <Input 
                    placeholder="รหัส/ชื่อสาขา"
                    style={{marginLeft: "10px" }}
                    // disabled={showPrenameThaiEtc}
                />
            </Form.Item>
            <Form.Item name="useFlag" style={{textAlign: 'center'}}>
                {renderUseFlagRadio()}
            </Form.Item>
        </Form>
      </Modal>
    );
  }
 
  return (
    <div>
      <PageHeader title="รายการสาขา">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/master/branch/manage")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
            <div style={{marginRight: 5, marginBottom: 10, textAlign: 'right'}}>
                <Button
                    size="large"
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() => {setIsModalVisible(true);}}
                ></Button>
            </div>
            <Table
                columns={columns}
                dataSource={branchs}
                size="small"
                rowKey={(item: Branch) => item.id}
                pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                    setCurrentPage(page);
                }}}
            />
        </div>
      </Content>
      {renderSearchModal()}
    </div>
  );
};

export default ListBranch
