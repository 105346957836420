import http from "../../lib/http";
import configs from "src/constants/config";
import { NcClass } from "src/domain/master/classType";
import { ClassDetail } from "src/domain/master/classDetailType";

// const url = "http://localhost:8080/classDetail";
const url = `${configs.urlRoot}/classDetail`;

const getByClassNo = (classNo : string) => {
    const datas_param = http.get(url+"/param?classNo="+classNo);
    return datas_param;
}

const getClassById = (classId: number) => {
    return http.get(`${url}/getClassById/${classId}`);
}

const searchClass = (keyword: string, useFlg: number) => {
    let searchData = {keyword: keyword, useFlg: useFlg};
    return http.post(`${url}/search`, {}, {params: searchData});
}

const updateClass = (submitData: NcClass) => {
    return http.post(`${url}/updateClass`, submitData );
}

const addClass = (submitData: NcClass) => {
    return http.post(`${url}/addClass`, submitData);
}

const getClassDetailById = (classDetailId: number) => {
    return http.get(`${url}/${classDetailId}`);
}

const updateClassDetail = (submitData: ClassDetail) => {
    return http.post(`${url}/updateClassDetail`, submitData );
}

const addClassDetail = (submitData: ClassDetail) => {
    return http.post(`${url}/addClassDetail`, submitData);
}

export default {
    getByClassNo,
    searchClass,
    getClassById,
    updateClass,
    addClass,
    getClassDetailById,
    updateClassDetail,
    addClassDetail
}