import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from 'src/layouts/PageHeader';
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import { Button, Col, Row, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import classes from "src/views/application/ListApplication.module.css";
import SearchAccBookPopup from './SearchAccBookPopup';
import { Book } from 'src/domain/account/book';
import bookService from 'src/services/account/bookService';
import moment from 'moment';
import 'moment/locale/th';

const dateFormat = "DD/MM/YYYY";

const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: Book, index: number) => (
        index+1
      ),
    },
    {
      title: "ประเภท",
      dataIndex: "bookTypeCode",
      key: "bookTypeCode",
    },
    {
        title: "บริษัท",
        dataIndex: "companyName",
        key: "companyName",
    },
    {
        title: "เลขที่",
        dataIndex: "docNo",
        key: "docNo",
        //   width: 90,
        render: (value: string, item: Book, index: number) => (
          <Link to={`/app/accbook/view/${item.id}`}>{value}</Link>
        ),
    },
    {
        title: "ผู้รับเงิน",
        dataIndex: "payeeName",
        key: "payeeName",
    },
    {
      title: "วันที่สร้าง",
      dataIndex: "docDate",
      key: "docDate",
      render: (value: string) => value ? moment(value).format(dateFormat) : "",
    },
    {
        title: "วันที่บันทึกบัญชี",
        dataIndex: "accSaveDate",
        key: "accSaveDate",
        render: (value: string) => value ? moment(value).format(dateFormat) : "",
      },
    {
      title: "หมายเหตุ",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "ผู้จัดทำ",
      dataIndex: "createUserName",
      key: "createUserName",
    },
];

const ListAccBook = () => {
    const history = useHistory();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [books, setBooks] = useState<Book[]>();
    const [params, setParams] = useState<any>();

    useEffect(() => {
        if(params) {
            (async () => {
                console.log("params=", params)
                const _doc_no = params.doc_no ? params.doc_no : null;
                const _companyId = params.company?  params.company : null;
                const _paynee_name = params.payee_name? params.payee_name : null;
                const _create_name =  params.create_name ? params.create_name : null;
                const _date = params.date ? params.date.format('YYYYMMDD') : null;
                const _date_type = params.date_type ? params.date_type : null;
                const _book_type = params.book_type ? params.book_type : null;
                const _book_code = params.book_code ? params.book_code : null;
                
                const res = await bookService.getBookbyCondition(_doc_no, _companyId, _paynee_name, _create_name,
                _date,_date_type,_book_type,_book_code);

                setBooks(res.data);
            })();
        }
    },[params])

    const showModal = () => {
        setModalVisible(true);
    }

    const onSearch = (params: Map<string, string>) => {
        setModalVisible(false);
        setParams(params);
    }

    const onSearchCode = (value: string) => {
        (async () => {
            const res = await bookService.getBookByCodeOrRefCode(value);
            setBooks(res.data);
        })();
        setModalVisible(false);
    }

    return (
        <div>
            <PageHeader title="สมุดบันทึกบัญชี">
                <Button
                    size="large"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => history.push("/app/accbook/new")}
                    >
                    New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
                <div style={{display: "block", width: "100%", textAlign:"end" }}>
                    <Button
                        size="large"
                        shape="circle"
                        icon={<SearchOutlined />}
                        onClick={() => showModal()}
                    />
                </div>
                <div className={classes.dataPanel} style={{ marginTop: "10px" }}>
                    <Table 
                        columns={columns}
                        dataSource={books}
                        size="small"
                        rowKey={(item: Book) => item.docNo}
                        pagination={false}
                        
                    />
                </div>
            </Content>

            <SearchAccBookPopup
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onSearch={onSearch}
                onSearchCode={onSearchCode}
            />
        </div>
    )
}

export default ListAccBook
