import React, { useState, useEffect } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import Panel from 'src/components/container/Panel';
import { type } from 'os';

interface IProps {
  dashboardProducts: any[];
  qty: any;
}

const DBChartView : React.FC<IProps> = (props) => {
  const {dashboardProducts, qty} = props;

  const data = [
    {
      type: 'DUMMY_DATA_PROD1',
      value: 60,
    },
    {
      type: 'DUMMY_DATA_PROD2',
      value: 15,
    },
    {
      type: 'DUMMY_DATA_PROD3',
      value: 5,
    },
  ];
  const config:PieConfig = {
    appendPadding: 10,
    // data:dashboardProducts === undefined || dashboardProducts.length ===0 ? data : dashboardProducts,
    data:dashboardProducts,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    // legend: [{
    //     // layout: 'vertical',
    //     position: 'top',
    //     offset:0,
    //     // offsetY:0,
    // }],
    statistic: {
    //   title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        content: qty,
      },
    },
  };
  return (
    <Panel style={{borderRadius:10, height:580}}>
        <div style={{ textAlign: "left", width:"100%",}}>
            APPLICATION : PRODUCT
        </div>
        <Pie {...config}/>
    </Panel>
  );
};
export default DBChartView;