import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Typography, Row, Col, Select, Button, AutoComplete, Space } from "antd";
import { RightCircleOutlined } from "@ant-design/icons";
import classDetailService from 'src/services/master/classDetailService';
import { ClassDetail } from 'src/domain/master/classDetailType';
import locationService from 'src/services/master/locationService';
import { Location } from 'src/domain/master/locationType';

const { Title, Paragraph, Text } = Typography;
const { Option } = Select;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IProps {
    addressOffice : any;
    setAddressOffice : any;
    trigger: any;
    setTrigger: any;
    updateOccupationData : any;
    next: () => void;
    active?: boolean;
    // form: any;
}

const options_occupation = ['รับราชการ', 'พนักงานบริษัท', 'เจ้าของกิจการ']

const OccupationApplication : React.FC<IProps> = (props) => {
    const divRef = useRef<HTMLDivElement>(null);

    const [form] = Form.useForm();
    const {addressOffice, setAddressOffice, trigger, setTrigger, updateOccupationData, next, active } = props;

    const [optionsOccupation, setOpteionsOccupation] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [optionsLocation, setOptionsLocation] = useState<Location[]>([] as Location[]);

    const [selectOccupation, setSelectOccupation] = useState<ClassDetail>();
    const [selectLocation, setSelectLocation] = useState<Location>();

    useEffect(() => {
        (async () => {
            const res = await classDetailService.getByClassNo("04");
            setOpteionsOccupation(res.data);
        })();
        if(addressOffice.occupationId) {
            const _addr : ClassDetail = {
                id:addressOffice.occupationId,
                name : addressOffice.occupationName,
                classId : 0,
                code : "",
                useFlg : 0
            }
            setSelectOccupation(_addr)
        }

        scrollToTop();
    }, []);

    const scrollToTop = () => {
        if (divRef.current) {
            window.scrollTo({ behavior: 'smooth', top: divRef.current.offsetTop - 120 })
        }
    }

    useEffect(() => {
        (async () => {
            const res = await locationService.getLocationAll();
            setOptionsLocation(res.data);
        })();
        if(addressOffice.provinceCode) {
            const _addr : Location = {
                id : 0,
                provinceCode : addressOffice.provinceCode,
                province : addressOffice.provinceName,
                amphurCode : addressOffice.amphurCode,
                amphur : addressOffice.amphurName,
                tambonCode : addressOffice.tambonCode,
                tambon : addressOffice.tambonName,
                zipcode : addressOffice.zipcode,
                addrPath : addressOffice.tambonName+"/"+addressOffice.amphurName+"/"+addressOffice.provinceName+"/"+addressOffice.zipcode,
            }
            setSelectLocation(_addr);
        }
    }, []);



    useEffect(() => {
        if (trigger) {
          form.submit();
        }
    }, [trigger]);

    useEffect(() => {
        form.setFieldsValue({
            occupation: addressOffice.occupationId,
            office_name: addressOffice.officeName,
            business_type: addressOffice.businessType,
            building : addressOffice.building,
            room_no : addressOffice.roomNo,
            address : addressOffice.address,
            moo : addressOffice.moo,
            soi : addressOffice.soi,
            road : addressOffice.road,
            phone : addressOffice.phone,
            address_work: addressOffice.provinceCode ? addressOffice.tambonName + "/" + addressOffice.amphurName + "/" + addressOffice.provinceName + "/" + addressOffice.zipcode : "",
        });
    }, [addressOffice]);

    const onSelectOccupation = (value : any, index : any) => {
        const x : any  = {id :value, name: index.children};
        setSelectOccupation(x);
    }

    const onSelectLocation = (values: any, key: any) => {
        setSelectLocation(key.props);
    }

    const onChangeLocation = (values: any) => {
        setSelectLocation(values);
    };

    const onSearchLocation = (values: any) => {
    };

    const onFinish = (values: any) => {
        const _addr : any = {

            officeName: values.office_name,
            businessType :values.business_type,
            building : values.building,
            roomNo : values.room_no,
            address : values.address,
            moo : values.moo,
            soi : values.soi,
            road : values.road,
            tambonCode : selectLocation?.tambonCode,
            tambon : selectLocation?.tambon,
            amphurCode : selectLocation?.amphurCode,
            amphur : selectLocation?.amphur,
            provinceCode : selectLocation?.provinceCode,
            province : selectLocation?.province,
            zipcode : selectLocation?.zipcode,
            phone : values.phone,
            occupationId : selectOccupation?.id,
            occupationName : selectOccupation?.name,
        };

        updateOccupationData(_addr);
        next();
    }

    const onFinishFailed = (errorInfo: any) => {
        setTrigger(false);
    };

    return (
        <>
            <Form

                // {...layout}
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div ref={divRef}></div>
                <Typography>
                    <Title level={2}>ข้อมูล อาชีพ/สถานที่ทำงาน</Title>
                    <Paragraph>ระบุ ข้อมูลอาชีพ และสถานที่ทำงาน</Paragraph>
                </Typography>
                <Row style={{ marginBottom: "3em" }}>
                    <Col span={24}>
                        <Space direction="vertical" />
                        <Form.Item
                            label="อาชีพ"
                            name="occupation"
                            rules={[
                                { required: true, message: "กรุณาเลือกอาชีพ!" },
                            ]}
                        >
                            <Select
                                placeholder="เลือกอาชีพ"
                                style={{ width: 400 }}
                                onSelect={onSelectOccupation}
                                value={selectOccupation?.id}
                                size='large'
                            >
                                {optionsOccupation.map((opt_value) => (
                                    <Option key={opt_value.id} value={opt_value.id}>{opt_value.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="ชื่อสถานที่ทำงาน"
                            name="office_name"
                            rules={[
                                { required: true, message: "กรุณาระบุชื่อสถานที่ทำงาน!" },
                                {
                                  message: "Please input your office name",
                                },
                            ]}
                        >
                            <Input size='large' showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item
                            label="ชื่อหมู่บ้าน/อาคารชุด"
                            name="building"
                        >
                            <Input size='large' showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item
                            label="เลขที่"
                        >
                            <Row>
                                <Col>
                                    <Form.Item name="room_no">
                                        <Input size='large' showCount maxLength={50}
                                            style={{ width: 150}}
                                            placeholder="เลขที่ห้องชั้น"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="address">
                                        <Input size='large' showCount maxLength={100}  placeholder="บ้านเลขที่" style={{ width: 150, marginLeft: "10px"}}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="moo">
                                        <Input size='large' showCount maxLength={50} placeholder="หมู่" style={{ width: 150, marginLeft: "10px"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="ซอย/ถนน"
                        >
                            <Row>
                                <Col>
                                    <Form.Item name="soi">
                                        <Input size='large' showCount maxLength={50} placeholder="ซอย" name="soi" style={{ width: 150}}/>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="road">
                                        <Input size='large' showCount maxLength={100} placeholder="ถนน" name="road" style={{ width: 300, marginLeft: "10px"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="ที่อยู่"
                            name="address_work"
                            rules={[
                                { required: true, message: "กรุณาระบุชื่อตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์!" },
                                {
                                  message: "Please input your address name",
                                },
                            ]}
                        >
                            <AutoComplete
                                placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                                onSearch={onSearchLocation}
                                onSelect={onSelectLocation}
                                onChange={onChangeLocation}
                                value={selectLocation?.addrPath}
                                options={
                                    optionsLocation.map((d) => ({
                                        "value" : d.addrPath,
                                        "label" : d.addrPath,
                                        "key" : d.id,
                                        "props" : d,
                                    }))
                                }
                                filterOption={true}
                                size='large'
                                // allowClear
                            >
                                <Input.Search size='large' enterButton />
                            </AutoComplete>
                        </Form.Item>
                        <Form.Item
                            label="โทรศัพท์"
                            name="phone"
                            rules={[
                                { required: true, message: "กรุณาระบุโทรศัพท์มือถือ!" },
                              ]}
                        >
                            <Input size='large' showCount maxLength={100} />
                        </Form.Item>
                        <Form.Item
                            label="ประเภทธุรกิจ"
                            name="business_type"
                        >
                            <Input size='large' showCount maxLength={255}/>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{ offset: 21, span: 2 }}
                        >
                            <Button
                                size="large"
                                type="primary"
                                name="occ"
                                icon={<RightCircleOutlined />}
                                onClick={() => {form.submit()}}
                                // onClick={onClickNext}
                            >
                                Next
                            </Button>
                            {/* <Button onClick={scrollToTop} >scroll</Button> */}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </>
    )
}

OccupationApplication.defaultProps = {
    active: false
}

export default OccupationApplication
