import React from 'react'
import ApplicationForm from './ApplicationForm'

const NewApplication = () => {
    return (
        <div>
            <ApplicationForm id={0} mode={'NEW'} />
        </div>
    )
}

export default NewApplication
