import { notification, Button, FormInstance, Radio, RadioChangeEvent, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { 
    Affix,
    Form,
    Input, 
} from "antd";

import applicationService from "src/services/application/applicationService"
import { Branch } from "src/domain/master/BranchType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";

import Panel from "src/components/container/Panel";
import classDetailService from 'src/services/master/classDetailService';
import { NcClass, NcClassUseFlg } from "src/domain/master/classType";
import { ClassDetail, NcClassDetailUseFlg } from "src/domain/master/classDetailType";
import { ColumnsType } from 'antd/lib/table';
import UseFlgIcon from 'src/components/container/UseFlgIcon';
import { MasterResponse } from 'src/domain/master/MasterResponse';

interface IParams {
    id: string;
    classId: string;
}
const ManageClassDetailForm: React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _id: number = Number(props.match.params.id);
    const _classId: number = Number(props.match.params.classId);
    const [form] = Form.useForm();
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentId, setCurrentId] = useState<number | null>(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currentNcClass, setCurrentNcClass] = useState<NcClass>();

    useEffect(() => {
        (async() => {
            form.setFieldsValue({
                code: '',
                name: '',
                useFlag: NcClassDetailUseFlg.USE,
            })
            let classRes = await classDetailService.getClassById(_classId);
            let classData: NcClass = classRes.data;
            setCurrentNcClass(classData);
            if(!Number.isNaN(_id)){
                let res = await classDetailService.getClassDetailById(_id);
                let data: ClassDetail = res.data;
                if(data.classId != _classId){
                    history.replace("/app/master/class/list");
                }
                setCurrentId(_id);
                form.setFieldsValue({
                    code: data.code,
                    name: data.name,
                    useFlag: data.useFlg,
                })
            }
        })();
    }, []);
    const doAfterFinish = (response: MasterResponse) => {
        console.log(response);
        if(response.status == "success"){
            notification["success"]({message: "จัดการข้อมูลหลัก", description: "บันทึกสำเร็จ", duration: 2})
            history.replace(`/app/master/class/manage/${_classId}`);
        }else{
            notification["error"]({message: "จัดการข้อมูลหลัก", description: "บันทึกไม่สำเร็จ", duration: 2})
        }
    }
    const onFinish = async(value: {code: string, name: string, useFlag: number}) => {
        console.log("finished: ", value);
        let classCode: string = currentNcClass == null ? "" : currentNcClass.classNo;
        let submitData: ClassDetail = {id: _id, classId: _classId, classCode: classCode, code: value.code, name: value.name, useFlg: value.useFlag};
        if(!Number.isNaN(_id)){
            // update
            let res = await classDetailService.updateClassDetail(submitData);
            doAfterFinish(res.data);
        }else{
            // add
            let res = await classDetailService.addClassDetail(submitData);
            doAfterFinish(res.data);
        }
    }
    const onUseFlatChange = (e: RadioChangeEvent) => {
        form.setFieldsValue({
            useFlag: e.target.value,
        })
    }
    const renderUseFlagRadio = () => {
        return(
            <Radio.Group onChange={onUseFlatChange}>
                <Radio value={NcClassUseFlg.USE}>ใช้งาน</Radio>
                <Radio value={NcClassUseFlg.NOT_USE}>ไม่ใช้งาน</Radio>
            </Radio.Group>
        )
    }
    return (
        <div>
            <PageHeader title={`กำหนดข้อมูลย่อย${currentNcClass != null ? `(${currentNcClass.className})` : ""}`}>
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.replace(`/app/master/class/manage/${_classId}`)}
                >
                    Cancel
                </Button>
                {/* <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/application/new")}
                >
                New
                </Button> */}
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ flex: 1, padding: "40px" }}>
                    <Form form={form} name="control-hooks" onFinish={onFinish}>
                        <Form.Item name="code" rules={[{ required: true, message: 'กรุณาระบุรหัสข้อมูลย่อย!' }]}>
                            <Input 
                                placeholder="รหัสข้อมูลย่อย"
                                // style={{marginLeft: "10px" }}
                                disabled={currentId != null}
                            />
                        </Form.Item>
                        <Form.Item 
                            name="name" 
                            rules={[{ required: true, message: 'กรุณาระบุชื่อข้อมูลย่อย!' }]}
                        >
                            <Input 
                                placeholder="ชื่อข้อมูลย่อย"
                                // style={{marginLeft: "10px" }}
                                // disabled={showPrenameThaiEtc}
                            />
                        </Form.Item>
                        <Form.Item name="useFlag" style={{textAlign: "center"}}>
                            {renderUseFlagRadio()}
                        </Form.Item>
                        <Form.Item style={{textAlign: 'right'}}>
                            <Button
                                danger
                                size="small"
                                type="primary"
                                name="per"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                บันทึก
                            </Button>
                        </Form.Item>
                    </Form>
                </Panel>
            </div>
        </div>
    );
}

export default ManageClassDetailForm;