import { notification, Button, FormInstance, Radio, RadioChangeEvent, Table, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { CloseCircleOutlined, SaveOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { 
    Affix,
    Form,
    Input, 
} from "antd";

import applicationService from "src/services/application/applicationService"
import { Branch } from "src/domain/master/BranchType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";

import Panel from "src/components/container/Panel";
import classDetailService from 'src/services/master/classDetailService';
import { NcClass, NcClassUseFlg } from "src/domain/master/classType";
import { ClassDetail, NcClassDetailUseFlg } from "src/domain/master/classDetailType";
import { ColumnsType } from 'antd/lib/table';
import UseFlgIcon from 'src/components/container/UseFlgIcon';
import { MasterResponse } from 'src/domain/master/MasterResponse';

interface IParams {
    id: string;
}
const ManageClassForm: React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _id: number = Number(props.match.params.id);
    const [form] = Form.useForm();
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentId, setCurrentId] = useState<number | null>(null);
    const [pageSize, setPageSize] = useState<number>(10);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [classDetails, setClassDetails] = useState<ClassDetail[]>([]);

    const columns: ColumnsType<ClassDetail> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            width: 70,
            render: (value: string, item: ClassDetail, index: number) => (
                ((currentPage - 1) * pageSize) + (index+1)
            ),
        },
        {
            title: "รหัส",
            dataIndex: "code",
            key: "code",
            width: 70,
            sorter: (a: ClassDetail, b: ClassDetail) => {return a.code.localeCompare(b.code)},
            sortDirections: ['ascend', "descend"],
            render: (value: string, item: ClassDetail, index: number) => (
                <Link to={`/app/master/class/manage/classdetail/${_id}/${item.id}`}>{value}</Link>
            ),
        },
        {
            title: "ชื่อข้อมูลย่อย",
            dataIndex: "name",
            key: "name",
            sorter: (a: ClassDetail, b: ClassDetail) => {return a.name.localeCompare(b.name)},
            sortDirections: ['ascend', "descend"],
            render: (value: string) => value,
        },
        {
            title: "การใช้งาน",
            dataIndex: "useFlg",
            key: "useFlg",
            width: 90,
            sorter: (a: ClassDetail, b: ClassDetail) => {return a.useFlg - b.useFlg},
            sortDirections: ['ascend', "descend"],
            // align: "right" as "right",
            render: (value: number) => {
                // console.log("Value: ", value, ", enumValue: ", NcBankUseFlg.USE, ", flag: ", (value == NcBankUseFlg.NOT_USE));
                return (
                <UseFlgIcon useFlg={value == NcClassUseFlg.USE}/>
                    // value
                )
            },
        },
    ];

    useEffect(() => {
        (async() => {
            form.setFieldsValue({
                classNo: '',
                className: '',
                useFlag: NcClassUseFlg.USE,
                remark: '',
            })
            if(!Number.isNaN(_id)){
                let res = await classDetailService.getClassById(_id);
                let data: NcClass = res.data;
                setCurrentId(_id);
                form.setFieldsValue({
                    classNo: data.classNo,
                    className: data.className,
                    useFlag: data.useFlg,
                    remark: data.remark,
                })

                let detailRes = await classDetailService.getByClassNo(data.classNo);
                let detailDatas: ClassDetail[] = detailRes.data;
                setClassDetails(detailDatas);
            }
        })();
    }, []);
    const doAfterFinish = (response: MasterResponse) => {
        console.log(response);
        if(response.status == "success"){
            notification["success"]({message: "จัดการข้อมูลหลัก", description: "บันทึกสำเร็จ", duration: 2})
            history.replace("/app/master/class/list");
        }else{
            notification["error"]({message: "จัดการข้อมูลหลัก", description: "บันทึกไม่สำเร็จ", duration: 2})
        }
    }
    const onFinish = async(value: {classNo: string, className: string, useFlag: number, remark: string}) => {
        console.log("finished: ", value);
        let submitData: NcClass = {id: _id, classNo: value.classNo, className: value.className, useFlg: value.useFlag, remark: value.remark};
        if(!Number.isNaN(_id)){
            // update
            let res = await classDetailService.updateClass(submitData);
            doAfterFinish(res.data);
        }else{
            // add
            let res = await classDetailService.addClass(submitData);
            doAfterFinish(res.data);
        }
    }
    const onUseFlatChange = (e: RadioChangeEvent) => {
        form.setFieldsValue({
            useFlag: e.target.value,
        })
    }
    const renderUseFlagRadio = () => {
        return(
            <Radio.Group onChange={onUseFlatChange}>
                <Radio value={NcClassUseFlg.USE}>ใช้งาน</Radio>
                <Radio value={NcClassUseFlg.NOT_USE}>ไม่ใช้งาน</Radio>
            </Radio.Group>
        )
    }
    const renderClassDetail = () => {
        if(currentId != null){
            return(
                <Panel style={{ flex: 1, padding: "40px" }}>
                    <Button
                        size="large"
                        shape='circle'
                        icon={<PlusCircleTwoTone twoToneColor={"#0003DB"} />}
                        style={{borderColor: "#0003DB", backgroundColor: "#E9EBFF"}}
                        onClick={() => {history.push(`/app/master/class/manage/classdetail/${_id}`)}}
                    ></Button>
                    <Table
                        columns={columns}
                        dataSource={classDetails}
                        size="small"
                        rowKey={(item: ClassDetail) => item.id}
                        pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                            setCurrentPage(page);
                        }}}
                    />
                </Panel>
            );
        }
    }
    return (
        <div>
            <PageHeader title="กำหนดข้อมูลหลัก">
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.replace("/app/master/class/list")}
                >
                    Cancel
                </Button>
                {/* <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/application/new")}
                >
                New
                </Button> */}
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ flex: 1, padding: "40px" }}>
                    <Form form={form} name="control-hooks" onFinish={onFinish}>
                        <Form.Item name="classNo" rules={[{ required: true, message: 'กรุณาระบุรหัสข้อมูลหลัก!' }]}>
                            <Input 
                                placeholder="รหัสข้อมูลหลัก"
                                // style={{marginLeft: "10px" }}
                                disabled={currentId != null}
                            />
                        </Form.Item>
                        <Form.Item 
                            name="className" 
                            rules={[{ required: true, message: 'กรุณาระบุชื่อข้อมูลหลัก!' }]}
                        >
                            <Input 
                                placeholder="ชื่อข้อมูลหลัก"
                                // style={{marginLeft: "10px" }}
                                // disabled={showPrenameThaiEtc}
                            />
                        </Form.Item>
                        <Form.Item name="useFlag" style={{textAlign: "center"}}>
                            {renderUseFlagRadio()}
                        </Form.Item>
                        <Form.Item name="remark">
                            <Input 
                                placeholder="คำอธิบาย"
                                // style={{marginLeft: "10px" }}
                                // disabled={showPrenameThaiEtc}
                            />
                        </Form.Item>
                        <Form.Item style={{textAlign: 'right'}}>
                            <Button
                                danger
                                size="small"
                                type="primary"
                                name="per"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                บันทึก
                            </Button>
                        </Form.Item>
                    </Form>
                </Panel>
                { renderClassDetail() }
            </div>
        </div>
    );
}

export default ManageClassForm;