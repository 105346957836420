export interface NcClass {
    id : number;
    classNo : string;
    className : string;
    remark: string;
    useFlg : number;
}

export const NcClassUseFlg = {
    NOT_USE: -1,
    USE: 0,
    SEARCH_ALL: 2,
}