import { Descriptions } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React from 'react'
import { useHistory } from 'react-router';
import classes from "./ListApplication.module.css";

interface IProps {
    addressOffice : any;
}

const ViewOccupationApplication : React.FC<IProps> = (props) => {
    const {addressOffice} = props;
    const history = useHistory();

    return (
        <div >
            <Content className="app-page-content">
                <div style={{ padding: "0px 20px" }}>
                    <Descriptions
                        style={{ marginTop: "20px" }}
                        column={2}
                        // title="Personal Information"
                        size="middle"
                        labelStyle={{ width: 200,  }}
                        bordered
                    >
                        <Descriptions.Item
                            label="อาชีพ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.occupationName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อสถานที่ทำงาน :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.officeName}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ชื่อหมู่บ้าน/อาคารชุด :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.building}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="เลขที่ห้องชั้น :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.roomNo}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="บ้านเลขที่"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.address}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="หมู่"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.moo}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ซอย :"
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.soi}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ถนน :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.road}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ที่อยู่ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {"ตำบล" +addressOffice.tambonName + " " + addressOffice.amphurName + " จังหวัด" + addressOffice.provinceName + " " + addressOffice.zipcode}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="โทรศัพท์ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.phone}
                        </Descriptions.Item>

                        <Descriptions.Item
                            label="ประเภทธุรกิจ :"
                            span={2}
                            labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                        >
                            {addressOffice.businessType}
                        </Descriptions.Item>

                    </Descriptions>
                </div>
            </Content>
        </div>
    )
}

export default ViewOccupationApplication
