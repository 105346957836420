import { Descriptions, Space } from 'antd'
import React from 'react'

interface IProps {
    text: string;
    qty: number;
    amount: number;
    color:any;
}

const DBPaymentDetailView : React.FC<IProps> = (props) => {
    const {text, qty, amount, color} = props;

  return (
    <div>
        <Descriptions
            column={1}
            size="middle"
            labelStyle={{ width: 0,  }}
            bordered
        >
            <Descriptions.Item
                label={text}
                labelStyle={{ textAlign: "left", width:500, backgroundColor:"#ffffff" }}
            >
            </Descriptions.Item>

            <Descriptions.Item
                label={(<div style={{ textAlign: "left", width:"100%", fontSize:"32px", fontWeight:600, color:color}}>
                        {qty === undefined ? 0 : Number(Number(qty).toFixed(2)).toLocaleString('uth-TH')}
                        <Space style={{ fontSize:"32px", fontWeight:400, color:'#5F5A59', margin:5}}>|</Space>
                        {amount === undefined ? 0 : Number(Number(amount).toFixed(2)).toLocaleString('uth-TH')}
                        </div>)
                      }
                labelStyle={{ textAlign: "left", width:500, backgroundColor:"#ffffff" }}
            >
            </Descriptions.Item>
        </Descriptions>
    </div>
  )
}

export default DBPaymentDetailView