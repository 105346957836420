import { Form, Input, Table, Tooltip } from 'antd';
import React, { useEffect } from 'react'
import { BankTransferDetail } from 'src/domain/banktransfer/bankTransferDetailType'
import { ColumnsType } from 'antd/lib/table';

interface IProps {
    details: BankTransferDetail[];
    mode? : string;
}

const columns: ColumnsType<BankTransferDetail> = [
    {
        title: "ลำดับ",
        dataIndex: "id",
        key: "id",
        render: (value: string, item: BankTransferDetail, index: number) => (
            index+1
        ),
        width: '50px'
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "reference",
        key: "reference",
        width: '160px'
    },
    {
        title: "เลขที่บัญชี",
        dataIndex: "bankAccountNo",
        key: "bankAccountNo",
        width: '120px'
    },
    {
        title: "ชื่อบัญชี",
        dataIndex: "accountName",
        key: "accountName",
        width: '120px',
        ellipsis: true
    },
    {
        title: "ธนาคาร",
        dataIndex: "bankName",
        key: "bankName",
        width: '200px'
    },
    {
        title: "สาขา",
        dataIndex: "branchCode",
        key: "branchCode",
        width: '80px'
    },
    {
        title: "ยอดเงินโอน",
        dataIndex: "transferAmt",
        key: "transferAmt",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        ),
        width: '120px'
    },
    {
        title: "EMAIL",
        dataIndex: "email",
        key: "email",
        width: '180px',
        ellipsis: true
    },
    {
        title: "เลขที่การโอน",
        dataIndex: "bankTransactionNo",
        key: "bankTransactionNo",
    },
];

const ListViewBankTransferDetail : React.FC<IProps> = (props) => {
    const { details, mode} = props;

    useEffect(() => {}, [details]);

    const onRowSummary = () => {

        if(details !== undefined) {
            let total = 0;

            if(details !== null && details.length > 0) {
                details.forEach(({transferAmt}) => {
                    total = total + (transferAmt ? transferAmt : 0);
                });
            }

            return (
                <>
                <Table.Summary.Row style={{fontSize:"16px", fontWeight:'bold'}}>
                    <Table.Summary.Cell index={0} colSpan={2} align={'right'}>รวมจำนวน</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{(details !== null && details.length > 0) ? details.length : 0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} >รายการ</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} colSpan={2} align={'right'}>รวมเงินโอน</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{Number(Number(total).toFixed(2)).toLocaleString('th-TH')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} colSpan={2}>บาท</Table.Summary.Cell>
                </Table.Summary.Row>
                </>
            );
        }
    }

  return (
    <div>
        <Table
            size='small'
            sticky
            dataSource={details}
            columns={columns}
            summary={onRowSummary}
            pagination={false}
        />
    </div>
  )
}

export default ListViewBankTransferDetail