import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: string[] = [];

const logSlice = createSlice({
  name: "log",
  initialState: initialState,
  reducers: {
    addLog: (state: string[], action: PayloadAction<string>) => {
      state.push(action.payload);
      console.log("add log: ", action.payload);
    },
  },
});

export const { reducer } = logSlice;

export const addLogAction = logSlice.actions.addLog;
