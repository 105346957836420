import { generateOrderNumber } from "src/utils";
import configs from "src/constants/config";


const teacherData =  [
    {
      "id": 1,
      "name": "Karnchana",
      "lastname": "Pangwan",
      "gender": "Female",
      "education": "Information Technology"
    },
    {
        "id": 2,
        "name": "Weeraya",
        "lastname": "Tha-In",
        "gender": "Female",
        "education": "Information Technology"
    },
    {
        "id": 3,
        "name": "Kumpu",
        "lastname": "Wangboon",
        "gender": "Male",
        "education": "Software Engineering"
    },
]

const getTeachers = () => {
  // return http.get(url);
  return teacherData;
};

const getTeacherById = (id: number) => {
    // return http.get(`${url}/${id}`);
    return teacherData.filter(item=> item.id === id);
}

const updateTeacher = (Teacher: any) => {
    // return http.put(url + "/" + Teacher.id, Teacher);
    const datas = getTeacherById(Teacher.id);
    const data = datas[0];
    data.name = Teacher.name;
    data.lastname = Teacher.lastname;
    data.gender = Teacher.gender;
    data.education = Teacher.education;
    return data;
}

const addTeacher = (Teacher: any) => {
    // return http.post(url, Teacher);
    const newTeacher = { ...Teacher }
    newTeacher.id = generateOrderNumber();
    teacherData.push(newTeacher);
    return newTeacher;
}

const deleteTeacher = (id: number) => {
    //const datas = getTeacherById(Teacher.id);
    //const id = datas[0].id;

    const index = teacherData.findIndex(item => item.id === id);
    teacherData.splice(index,1);
}

export default {
    getTeachers,
    getTeacherById,
    updateTeacher,
    addTeacher,
    deleteTeacher
}