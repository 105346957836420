import React from 'react'
import { RouteComponentProps } from 'react-router'
import CampaignHeadForm from './CampaignHeadForm';

interface IParams {
    id: string;
    campId: string;
}

const EditCampaignHead : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);
    const _campId = Number(props.match.params.campId);
    
    return (
        <div>
            <CampaignHeadForm mode='EDIT' campId={_campId} headId={_id}/>
        </div>
    )
}

export default EditCampaignHead
