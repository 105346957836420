import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col, Input, InputNumber, Table, Descriptions, message } from 'antd';
import PageHeader from 'src/layouts/PageHeader'
import { SaveOutlined, CloseCircleOutlined, CalculatorOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';

import campaignService from 'src/services/master/campaignService';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignDetail } from 'src/domain/master/CampaignDetailType';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import { useAppSelector } from "src/store";
import { inputDecimalFormatter, inputDecimalPaser } from '../../utils/formatter';

interface IProps {
    mode: string;
    campId: number;
    headId: number;
}

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

const columns = [
    {
        title: "Mth",
        dataIndex: "month",
        key: "month",
    },
    {
        title: "Installment",
        dataIndex: "installment",
        key: "installment",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Interest Revenues",
        dataIndex: "interestRevenues",
        key: "interestRevenues",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Princ Repaid",
        dataIndex: "princPepaid",
        key: "princPepaid",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Ending Bal.",
        dataIndex: "endingBal",
        key: "endingBal",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "CF",
        dataIndex: "cf",
        key: "cf",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "% ตัดต้น",
        dataIndex: "principlePer",
        key: "principlePer",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
]

const CampaignHeadForm : React.FC<IProps> = (props) => {
    const _campaignId = props.campId;
    const _headId = props.headId;
    const mode = props.mode;
    const history = useHistory();
    const [form] = Form.useForm();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);
    const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([] as CampaignDetail[]);

    const [isSave, setSave] = useState<boolean>(false);
    const [canCal, setCanCal] = useState<boolean>(false);
    const [tenor, setTenor] = useState<number>(0);

    useEffect(() => {
        if(mode === 'EDIT') {
            campaignHeadService.getCampaignHeadById(_headId).then(res=>{
                setCampaignHead(res.data);
            });

            campaignService.getCampaignDetailByHeadId(_headId).then(res=>{
                setCampaignDetails(res.data);
            });
        }
    }, []);

    // useEffect(() => {
    //     if(_headId) {
    //         campaignService.getCampaignDetailByHeadId(_headId).then(res=>{
    //             setCampaignDetails(res.data);
    //         });
    //     }
    // }, [])

    useEffect(() => {
    }, [isSave]);

    useEffect(() => {
        form.setFieldsValue({
            campaignHeadNo: campaignHead.campaignHeadNo,
            loanAmt: campaignHead.loanAmt,
            flatInterestMth : campaignHead.flatInterestMth,
            flatInterestAnnum: campaignHead.flatInterestAnnum,
            effInterestMth:campaignHead.effInterestMth,
            effInterestAnnum:campaignHead.effInterestAnnum,
            tenor:campaignHead.tenor,
            interest:campaignHead.interest,
            principalInterst:campaignHead.principalInterst,
            installment:campaignHead.installment,
        });
    }, [campaignHead]);

    useEffect(() => {
        if(canCal) {
            const _loanAmt = form.getFieldValue("loanAmt")
            const _tenor = form.getFieldValue("tenor")
            const _interest = form.getFieldValue("flatInterestMth")
            campaignHeadService.calculate(_loanAmt, _tenor, _interest).then(res=>{
                if (res.status === 200) {
                    const campH = {...res.data,
                        campaignHeadNo : form.getFieldValue("campaignHeadNo"),
                        campaignId : _campaignId,
                        id: _headId,
                    };

                    setCampaignHead(campH);

                    setCampaignDetails(campH.details);
                    setSave(true);
                    setCanCal(false);
                }
            });
        }

    }, [canCal, tenor])

    useEffect(() => {

    }, );

    const onClickCalculate = (value: any) => {
        const _loanAmt = form.getFieldValue("loanAmt")
        const _tenor = form.getFieldValue("tenor")
        const _interest = form.getFieldValue("flatInterestMth")

        campaignHeadService.isExist(_loanAmt, _tenor, _interest, _headId, _campaignId).then(res => {
            const _isExist = res.data;
            if(_isExist) {
                message.error('มีข้อมูลจำนวนเงินกู้, ดอกเบี้ย, จำนวนงวดนี้แล้ว ไม่สามารถเพิ่ม/แก้ไขข้อมูลซ้ำได้', 2);
                setSave(false);
                setCanCal(false);
                form.setFields([
                    {
                      name: 'loanAmt',
                      errors: ['มีข้อมูลจำนวนเงินกู้, ดอกเบี้ย, จำนวนงวดนี้แล้ว ไม่สามารถเพิ่ม/แก้ไขข้อมูลซ้ำได้'],
                    },
                ]);
                // form.setFieldsValue()
                return;
            } else {
                setCanCal(true);
                form.setFields([
                    {
                      name: 'loanAmt',
                      errors: [],
                    },
                ]);
            }
        })


    }

    const onChangeLoanAmt = (value : any) => {
        setSave(false);
    }

    const onChangeInterest = (value : any) => {
        setSave(false);
    }

    const onChangeRate = (value : any) => {
        const _loanAmt = form.getFieldValue("loanAmt")
        const _tenor = form.getFieldValue("tenor")
        const _rate = form.getFieldValue("effrate")
        if(_loanAmt && _tenor && _rate) {
            form.setFieldsValue({
                flatInterestMth : ((_rate/1.8)/_tenor),
            });
        }
    }

    const onChangeTenor = (value : any) => {
        setTenor(value);
        setSave(false);
    }

    const onFinish = async (values: any) => {
        const _campaignHead : CampaignHead = {
            ...campaignHead,
            campaignId : _campaignId,
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
        }
        setCampaignHead(_campaignHead);

        if (mode === "NEW") {
            await campaignHeadService.addCampaignHead(_campaignHead).then(res => {
                if(res.status === 200)
                history.replace("/app/campaign/view/"+_campaignId);
            }).catch(err => {
                console.log(err)
            });
        } else {
            await campaignHeadService.editCampaignHead(_campaignHead).then(res => {
                if(res.status === 200)
                    history.replace("/app/campaign/view/"+_campaignId);
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> NewCampaign:", errorInfo);
        // setTrigger(false);
    };

    return (
        <>
            <PageHeader title={mode + " CAMPAIGN"} onClose={() => history.goBack()}>
                {isSave ?
                <Button
                    size="large"
                    type="primary"
                    danger
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                    Save
                </Button>
                :""}
            </PageHeader>
            {/* <PageHeader
                title={mode + " CAMPAIGN"}
                extra={[
                <Button
                    size="large"
                    type="primary"
                    danger
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                    Save
                </Button>,
                <Button
                    size="large"
                    type="default"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.goBack()}
                >
                    Cancel
                </Button>,
                ]}

            >
            </PageHeader> */}

            <Form
                {...layout}
                form={form}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row style={{ marginBottom: "3em" }}>
                    <Col span={24} style={{ marginTop: "2em", border: "1px solid white", padding: "10px"}} >
                        <Form.Item
                            label="รหัส"
                            name="campaignHeadNo"
                            key="campaignHeadNo"
                            rules={[
                                { required: true, message: "กรุณาระบุรหัส!" },
                                {
                                  message: "Please input code",
                                },
                            ]}
                        >
                            <Input size='large' showCount maxLength={15}/>
                        </Form.Item>
                        <Form.Item
                            label="ยอดจัด (บาท)"
                            name="loanAmt"
                            key="loanAmt"
                            rules={ [
                                {
                                  type: 'number',
                                  required: true,
                                  message: 'กรุณาระบุยอดจัด (บาท)!',
                                  whitespace: true,
                                },
                            ]}
                        >
                            <InputNumber
                                formatter={inputDecimalFormatter}
                                parser={inputDecimalPaser}
                                onChange={onChangeLoanAmt}
                                precision={0}
                                style={{ width: 150, textAlign: "right", alignItems:"end"}}
                                size='large'
                            />
                        </Form.Item>

                        {/* <Form.Item
                            label="EFFECTIVE RATE % "
                             name="effrate"
                             key="effrate"
                             rules={ [
                                 {
                                   type: 'number',
                                   required: true,
                                   message: 'กรุณาระบุ RATE!',
                                 },
                             ]}
                        >
                            <InputNumber
                                style={{ width: 150}}
                                placeholder=""
                                onChange={onChangeRate}
                                precision={4}
                            />
                        </Form.Item> */}

                        <Form.Item
                            label="FLAT INTEREST RATE % "
                        >
                            {/* <Row>
                                <Col
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                    }}
                                > */}
                                <div
                                    style={{
                                        // border: "1px solid red",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        marginBottom: "0px"
                                    }}
                                >
                                    <Form.Item
                                        name="flatInterestMth"
                                        key="flatInterestMth"
                                        rules={ [
                                            {
                                              type: 'number',
                                              required: true,
                                              message: 'กรุณาระบุ FLAT INTEREST RATE!',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: 150}}
                                            placeholder="ต่องวด"
                                            onChange={onChangeInterest}
                                            precision={5}
                                            step={0.00001}
                                            size='large'
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="flatInterestAnnum"
                                        key="flatInterestAnnum"
                                        style={{marginLeft: "10px"}}
                                    >
                                        <InputNumber
                                            readOnly={true}
                                            style={{ width: 150, backgroundColor:"#EAECEE"}}
                                            placeholder="ทั้งหมด"
                                            precision={4}
                                            size='large'
                                        />
                                    </Form.Item>
                                </div>
                                {/* </Col>
                            </Row> */}
                        </Form.Item>
                        <Form.Item
                            label="จำนวนงวด"
                        >
                            <div
                                style={{
                                    // border: "1px solid red",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    marginBottom: "0px"
                                }}
                            >
                                <Form.Item
                                    name="tenor"
                                    key="tenor"
                                    rules={ [
                                        {
                                        type: 'number',
                                        required: true,
                                        message: 'กรุณาระบุ จำนวนงวด!',
                                        whitespace: true,
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        onChange={onChangeTenor}
                                        precision={0}
                                        style={{ width: 150}}
                                        size='large'
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        size="middle"
                                        type="primary"
                                        icon={<CalculatorOutlined />}
                                        onClick={onClickCalculate}
                                        style={{marginLeft: "10px"}}
                                    >
                                        คำนวณ
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="EFFECTIVE INTEREST RATE % "
                        >
                            <Row>
                                <Col style={{
                                        display: "flex",
                                        // flexDirection: "row",
                                    }}
                                >
                                    <Form.Item
                                        name="effInterestMth"
                                        key="effInterestMth"
                                    >
                                        <InputNumber
                                            readOnly={true}
                                            style={{ width: 150, backgroundColor:"#EAECEE"}}
                                            placeholder="ต่องวด"
                                            precision={2}
                                            size='large'
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="effInterestAnnum"
                                        key="effInterestAnnum"
                                        style={{marginLeft: "10px"}}
                                    >
                                        <InputNumber
                                            readOnly={true}
                                            style={{ width: 150, backgroundColor:"#EAECEE"}}
                                            placeholder="ทั้งหมด"
                                            precision={4}
                                            size='large'
                                            // disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="ดอกเบี้ย (บาท)"
                            name="interest"
                            key="interest"
                        >
                            <InputNumber size='large' readOnly={true} style={{ width: 150}} precision={2} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
                        </Form.Item>
                        <Form.Item
                            label="เงินต้น+ดอกเบี้ย (บาท)"
                            name="principalInterst"
                            key="principalInterst"
                        >
                            <InputNumber size='large' readOnly={true} style={{ width: 150}} precision={2} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
                        </Form.Item>
                        <Form.Item
                            label="จ่ายต่องวด (บาท)"
                            name="installment"
                            key="installment"
                        >
                            <InputNumber size='large' readOnly={true} style={{ width: 150}} precision={0} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Descriptions
                style={{ marginTop: "20px" }}
                // title="Interest Revenues Calculation"
                size="middle"
                labelStyle={{ width: 200, fontWeight: "bold" }}
                bordered
                layout="vertical"
            >
                <Descriptions.Item
                    label="Interest Revenues Calculation"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                >
                    <Table
                        columns={columns}
                        dataSource={campaignDetails}
                        size="small"
                        rowKey={(item: CampaignDetail) => item.month}
                        pagination={false}
                    />
                </Descriptions.Item>
            </Descriptions>
        </>
    )
}

export default CampaignHeadForm
