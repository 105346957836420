import React, { useEffect, useState } from 'react'
import {Button, Checkbox, Col, DatePicker, Descriptions, Form, Input, InputNumber, Radio, Row, Typography, } from 'antd'
import {SaveOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel'
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import { Deposit } from 'src/domain/payment/depositType';
import { Contract } from 'src/domain/contract/contractType';
import { PaymentModel } from 'src/domain/payment/paymentModelType';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import DFlex from '../../components/container/DFlex';

const { Title, Paragraph, Text } = Typography;
const RadioButton = Radio.Button;
const dateFormat = 'DD/MM/YYYY';

const color_bg_readonly = "#EAECEE"

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
};

interface IProps {
    payment: PaymentModel;
    contractDebit: ContractDebit;
    deposit: Deposit;
    balance: number;
    updatePayment: any;
}

const options_change_deposit_types = [{label:'ทอน', value:'CHANGE'},{label:'ฝาก', value:'DEPOSIT'}]

const PaymentDetail : React.FC<IProps> = (props) => {
    const {payment, contractDebit, deposit, balance, updatePayment} = props;
    const [form] = Form.useForm();

    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [debtForwardAmount, setDebtForwardAmount] = useState<number>(0);
    const [isVisible, setIsVisible] = useState<boolean>(true);

    useEffect(() => {},[isVisible]);

    useEffect(() => {
        form.setFieldsValue({debt_forward_amt: debtForwardAmount,});
    },[debtForwardAmount]);

    useEffect(() => {
        setIsVisible(payment.paymentInstallmentType === 'normal');
        form.setFieldsValue({
            payment_date: payment.paymentDate ? moment(moment(payment.paymentDate , 'YYYY-MM-DD')).format(dateFormat) : "",
            deposit_amt: deposit ? deposit.totalAmt : 0,
            balance_amt: balance,
            accrued_interest: payment.feeWaiver === true ? 0 : payment.accruedInterest,
            penalty_amt: payment.feePenalty === true ? 0 : payment.penaltyAmt,
            installment_amt: contractDebit.installment,
            remaining_principal: payment.remainingPrincipal,
            close_interest: payment.closeInterest,
            total_amt: totalAmount,
        });

        if(payment.paymentInstallmentType !== 'normal') {
            if(totalAmount < 0) {
                form.setFields([
                    {
                      name: 'total_amt',
                      errors: ['การปิดสัญญา คงเหลือ ต้องไม่น้อยกว่า 0 !!'],
                    },
                ]);
            } else {
                form.setFields([
                    {
                      name: 'total_amt',
                      errors: [],
                    },
                ]);
                
            }
        }
    },[totalAmount, payment]);

    const onChangePay = (value: any) => {
        calculateTotalAmount(value);
    }

    const onChangeReceived = (value: any) => {
        const _balance = balance ? Number(Number(balance).toFixed(2))  : 0;

        const _debt_forward_amt = Number(Number(_balance - value).toFixed(2));
        setDebtForwardAmount(_debt_forward_amt);
    }

    const calculateTotalAmount = (value: any) => {
        const _deposit_amt = deposit ? Number(Number(deposit.totalAmt).toFixed(2))  : 0;
        const _balance = balance ? Number(Number(balance).toFixed(2))  : 0;
        const _penalty_amt = 0;

        const _total_amt = Number(Number((_deposit_amt+value) - _balance - _penalty_amt).toFixed(2));

        setTotalAmount(_total_amt);
        
    }

    const onFinish = async (values: any) => {
        let x : any = {
            // depositForwardAmt: values.deposit_amt,
            // paymentDate: values.payment_date.format('YYYYMMDD'),
            paymentAmt: values.pay_amt,
            balanceAmt: values.balance_amt,
            // penaltyAmt: values.penalty_amt,
            // installmentAmt: values.installment_amt,
            remainAmt: values.total_amt,
            remainType: values.change_deposit_type,
            receivedAmt: payment.paymentInstallmentType === 'refinance' ? values.received_amt : 0,
            debtForwardAmt: payment.paymentInstallmentType === 'refinance' ? values.debt_forward_amt : 0,
        }

        updatePayment(x);
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> PaymentDetail:", errorInfo);
    }

    return (
        <div>
            <Form
                {...layout}
                form={form}
                name="basic"
                layout="horizontal"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{change_deposit_type:'CHANGE'}}
            >
                <Panel>
                    <Typography>
                        <Title level={2}>รับชำระ</Title>
                        {isVisible ?
                        <Paragraph style={{textAlign:'right'}}>ชำระปกติ</Paragraph>
                        : 
                        payment.paymentInstallmentType === 'close' ?
                            <Paragraph style={{textAlign:'right', fontSize:'18px', fontWeight:'bold'}} type='danger'>ปิดสัญญา</Paragraph>
                            :
                            <Paragraph style={{textAlign:'right', fontSize:'18px', fontWeight:'bold'}} type='danger'>ปิดสัญญา (ปรับโครงสร้างหนี้)</Paragraph>
                        }
                    </Typography>
                    <Form.Item
                        label="วันที่รับชำระ"
                        name="payment_date"
                        key="payment_date"
                    >
                        <Input size='large' readOnly={true} style={{ width: 150, backgroundColor:color_bg_readonly}}></Input>
                    </Form.Item>
                    <Form.Item
                        label="รับฝากคงเหลือ"
                        name="deposit_amt"
                        key="deposit_amt"
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="จำนวนเงินรับชำระ"
                        name="pay_amt"
                        key="pay_amt"
                        rules={[
                            { required: true, message: "กรุณาระบุจำนวนเงินรับชำระ!" },
                        ]}
                    >
                        <InputNumber
                            style={{ width: 150, textAlign: "right",}}
                            precision={2}
                            onChange={onChangePay}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>

                    {payment.paymentInstallmentType === 'refinance' && (<>
                        <hr/>
                    <Form.Item
                        label="จำนวนเงินรับชำระจริง"
                        name="received_amt"
                        key="received_amt"
                        rules={[
                            { required: true, message: "กรุณาระบุจำนวนเงินรับชำระจริง!" },
                        ]}
                        style={{color:'red'}}
                    >
                        <InputNumber
                            style={{ width: 150, textAlign: "right",}}
                            precision={2}
                            onChange={onChangeReceived}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        label="หนี้ยกไป"
                        name="debt_forward_amt"
                        key="debt_forward_amt"
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                    </Form.Item>
                    </>)}
                    <hr/>
                    <Form.Item
                        label="ยอดหนี้สุทธิ"
                        name="balance_amt"
                        key="balance_amt"
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="เงินต้น"
                        name="remaining_principal"
                        key="remaining_principal"
                        style={{ display: isVisible ? 'none' : '' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        label="ดอกเบี้ย"
                        name="close_interest"
                        key="close_interest"
                        style={{ display: isVisible ? 'none' : '' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                    </Form.Item>

                    <Form.Item
                        label="ดอกเบี้ยค้างชำระ"
                        name="accrued_interest"
                        key="accrued_interest"

                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                    </Form.Item>
                    <Form.Item
                        label="ตัดค่าปรับ"
                        name="penalty_amt"
                        key="penalty_amt"
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ตัดค่างวด"
                        name="installment_amt"
                        key="installment_amt"
                        style={{ display: isVisible ? '' : 'none' }}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <hr/>
                    <Form.Item
                        label="คงเหลือ"
                        name="total_amt"
                        key="total_amt"
                        rules={[
                            {
                              required: false,
                              message: 'การปิดสัญญา คงเหลือ ต้องไม่น้อยกว่า 0 !!',
                              whitespace: true,
                              validator : async (_, value) => {
                                if (payment.paymentInstallmentType !== 'normal') {
                                  if (value < 0) {
                                    return Promise.reject(
                                      "value less than 0"
                                    );
                                  }
                                }
                              }
                            },
                          ]}
                    >
                        <InputNumber
                            readOnly={true}
                            style={{ width: 150, backgroundColor:color_bg_readonly}}
                            precision={2}
                            size="large"
                        />
                        {/* <label style={{marginLeft:20}}>บาท</label> */}
                    </Form.Item>
                    <Form.Item
                        label="ทอน/ฝาก"
                        name="change_deposit_type"
                        rules={[
                            { required: true, message: "กรุณาเลือกข้อมูล!" },
                        ]}
                    >
                        <Radio.Group
                            buttonStyle="solid"
                            size="large"
                        >
                            {options_change_deposit_types.map((opt_value) => (
                                <RadioButton
                                    key={opt_value.value}
                                    value={opt_value.value}
                                    disabled={((totalAmount < 0 && opt_value.value === 'CHANGE') || (payment.paymentInstallmentType !== 'normal' && opt_value.value === 'DEPOSIT')) ? true : false}
                                >
                                    {opt_value.label}
                                </RadioButton>
                            ))}
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 20, span: 2 }}>

                    </Form.Item>
                </Panel>
                <DFlex type="row" justifyContent='flex-end' style={{ marginTop: '20px'}}>
                	<Button
                            size="large"
                            type="primary"
                            icon={<SaveOutlined />}
                            onClick={() => form.submit()}
                    >
                            Save
                    </Button>
                </DFlex>
            </Form>
        </div>
    )
}

export default PaymentDetail
