import { Button, Table, Radio, RadioChangeEvent, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { CloseCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { 
    Affix,
    Form,
    Input, 
} from "antd";

import branchService from "src/services/master/BranchService"
import { Branch, BranchUseFlag } from "src/domain/master/BranchType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";

import Panel from "src/components/container/Panel";
import { MasterResponse } from 'src/domain/master/MasterResponse';

interface IParams {
    id: string;
}
const ManageBranchForm: React.FC<RouteComponentProps<IParams>> = (props) => {
    const history = useHistory();
    const _id: number = Number(props.match.params.id);
    const [form] = Form.useForm();
    const [searchUseFlag, setSearchUseFlag] = useState<number>(0);
    const [currentId, setCurrentId] = useState<number | null>(null);

    useEffect(() => {
        (async() => {
            form.setFieldsValue({
                bankCode: '',
                bankName: '',
                useFlag: BranchUseFlag.USE,
            })
            if(!Number.isNaN(_id)){
                let res = await branchService.getById(_id);
                let branchData: Branch = res.data;
                console.log("branchData: ", branchData);
                setCurrentId(_id);
                form.setFieldsValue({
                    branchCode: branchData.branchCode,
                    branchName: branchData.branchName,
                    useFlag: branchData.useFlg,
                })
            }
        })();
    }, []);

    const onUseFlatChange = (e: RadioChangeEvent) => {
        console.log("useFlagChangeTo: ", e.target.value);
        setSearchUseFlag(e.target.value);
      }
      const doAfterFinish = (response: MasterResponse) => {
          console.log(response);
          if(response.status == "success"){
              notification["success"]({message: "จัดการข้อมูลสาขา", description: "บันทึกสำเร็จ", duration: 2})
              history.replace("/app/master/branch/list");
          }else{
              notification["error"]({message: "จัดการข้อมูลสาขา", description: "บันทึกไม่สำเร็จ", duration: 2})
          }
      }
      const onFinish = async(value: {branchCode: string, branchName: string, useFlag: number}) => {
          console.log("finished: ", value);
          let submitData: Branch = {id: _id, branchCode: value.branchCode, branchName: value.branchName, useFlg: value.useFlag};
          if(!Number.isNaN(_id)){
              // update
              let res = await branchService.update(submitData);
              doAfterFinish(res.data);
          }else{
              // add
              let res = await branchService.add(submitData);
              doAfterFinish(res.data);
          }
      }
    const renderUseFlagRadio = () => {
        return(
            <Radio.Group onChange={onUseFlatChange}>
                <Radio value={BranchUseFlag.USE}>ใช้งาน</Radio>
                <Radio value={BranchUseFlag.NOT_USE}>ไม่ใช้งาน</Radio>
            </Radio.Group>
        )
    }
    return (
        <div>
            <PageHeader title="กำหนดข้อมูลสาขา">
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.replace("/app/master/branch/list")}
                >
                    Cancel
                </Button>
                {/* <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/application/new")}
                >
                New
                </Button> */}
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ width: "100%", padding: "40px" }}>
                    <Form form={form} name="control-hooks" onFinish={onFinish}>
                        <Form.Item 
                            name="branchCode"
                            rules={[{ required: true, message: 'กรุณาระบุรหัสสาขา!' }]}
                        >
                            <Input 
                                placeholder="รหัสสาขา"
                                // style={{marginLeft: "10px" }}
                                disabled={currentId != null}
                            />
                        </Form.Item>
                        <Form.Item 
                            name="branchName"
                            rules={[{ required: true, message: 'กรุณาระบุชื่อสาขา!' }]}
                        >
                            <Input 
                                placeholder="ชื่อสาขา"
                                // style={{marginLeft: "10px" }}
                            />
                        </Form.Item>
                        <Form.Item name="useFlag" style={{textAlign: "center"}}>
                            {renderUseFlagRadio()}
                        </Form.Item >
                        <Form.Item style={{textAlign: 'right'}}>
                            <Button
                                danger
                                size="small"
                                type="primary"
                                name="per"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    form.submit();
                                }}
                            >
                                บันทึก
                            </Button>
                        </Form.Item>
                    </Form>
                </Panel>
            </div>
        </div>
    );
}

export default ManageBranchForm;