import http from "../../lib/http";

const url = "http://localhost:8080/company";

const getCompanyAll = () => {
    return http.get(url);
}

const getCompanyById = (id: number) => {
    return http.get(url+"/"+id);
}

export default {
    getCompanyAll,
    getCompanyById
}