import { AutoComplete, Button, Col, Form, Input, message, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import {DeleteOutlined} from '@ant-design/icons'
import Panel from 'src/components/container/Panel';
import { ClassDetail } from 'src/domain/master/classDetailType';
import classDetailService from 'src/services/master/classDetailService';
import { Location } from "src/domain/master/locationType";
import { ApplicationGuarantor } from 'src/domain/application/applicationGuarantorType';
import locationService from 'src/services/master/locationService';
import Schema from 'async-validator';
import { Party } from 'src/domain/application/partyType';
import partyService from 'src/services/application/partyService';
const { Option } = Select;

interface IProps {
    applicationGuarntor: ApplicationGuarantor;
    rowIndex: number;
    setDataGuarantorItem: any;
}

const GuarantorComponent: React.FC<IProps> = (props) => {
    const {applicationGuarntor, rowIndex, setDataGuarantorItem} = props;
    const [form] = Form.useForm();

    const [optionsPrenameThai, setOptionsPrenameThai] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [optionsNationality, setOptionsNationality] = useState<ClassDetail[]>([] as ClassDetail[]);
    const [optionsLocation, setOptionsLocation] = useState<Location[]>([] as Location[]);
    const [parties, setParties] = useState<Party[]>([] as Party[]);

    const [showPrenameThaiEtc, setShowPrenameThaiEtc] = useState<boolean>(true);
    const [showNationalityEtc, setShowNationalityEtc] = useState<boolean>(true);

    useEffect(() => {}, [showPrenameThaiEtc, showNationalityEtc]);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("01");
          setOptionsPrenameThai(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("18");
          setOptionsNationality(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
          const res = await locationService.getLocationAll();
          setOptionsLocation(res.data);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const res = await partyService.getPartyAlls();
            setParties(res.data);
          })();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            prename_thai : applicationGuarntor.prenameThaiId,
            prename_etc_thai: applicationGuarntor.prenameEtcThai,
            name_thai: applicationGuarntor.nameThai,
            age: applicationGuarntor.age,
            origin: applicationGuarntor.origin,
            nationality_id: applicationGuarntor.nationalityId,
            nationality: applicationGuarntor.nationalityId === 73 ? "" : applicationGuarntor.nationality,
            idCard: applicationGuarntor.idCard,

            building: applicationGuarntor.building,
            room_no: applicationGuarntor.roomNo,
            address: applicationGuarntor.address,
            moo: applicationGuarntor.moo,
            soi: applicationGuarntor.soi,
            road: applicationGuarntor.road,
            addrPath: applicationGuarntor.provinceCode ? applicationGuarntor.tambonName + "/" + applicationGuarntor.amphurName + "/" + applicationGuarntor.provinceName + "/" + applicationGuarntor.zipcode : "",
            idCardFind: ""
        });

        if(applicationGuarntor.prenameThaiId === 8) setShowPrenameThaiEtc(false);
        if(applicationGuarntor.nationalityId === 74) setShowNationalityEtc(false);
        form.validateFields();
    }, [applicationGuarntor]);

    const onSelectIdCardFind = (values: any, key: any) => {
        const _party = key.props;
        let _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            prenameThaiId: _party.prenameThaiId,
            prenameThaiName: _party.prenameThaiName,
            prenameEtcThai: _party.prenameEtcThai, 
            nameThai: _party.nameThai,
            age: _party.age,
            origin: _party.origin,
            nationalityId: _party.nationalityId,
            nationality: _party.nationality,
            idCard: _party.idCard,
            building: _party.building,
            roomNo: _party.roomNo,
            address: _party.address,
            moo: _party.moo,
            soi: _party.soi,
            road: _party.road,
            tambonCode: _party.tambonCode,
            tambonName: _party.tambonName,
            amphurCode: _party.amphurCode,
            amphurName: _party.amphurName,
            provinceCode: _party.provinceCode,
            provinceName: _party.provinceName,
            zipcode: _party.zipcode,
        }

        // if(_party.prenameThaiId === 8) setShowPrenameThaiEtc(false);
        // if(_party.nationalityId === 74) setShowNationalityEtc(false);
        // form.setFieldsValue({idCardFind: ""});
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onSelectPrenameThai = (value: any, index: any) => {
        const x: any = { id: value, name: index.children};
        let show = true;
        if(value === 8) setShowPrenameThaiEtc(false);
        else setShowPrenameThaiEtc(true);

        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            prenameThaiId: x.id,
            prenameThaiName: x.name
        }

        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangePrename = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            prenameEtcThai: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeName = (event: any) => {
        form.validateFields();
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            nameThai: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeAge = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            age: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeOrigin = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            origin: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onSelectNationality = (value: any, index: any) => {
        let x: any = { id: value, name: index.children };
        // setSelectNationality(x);
        if(value === 74) setShowNationalityEtc(false);
        else setShowNationalityEtc(true);

        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            nationalityId: value,
            nationality: index.children
        }

        setDataGuarantorItem(_guarantor, rowIndex);
    };

    const onChangeNationality = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            nationality: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeBuilding = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            building: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeRoom = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            roomNo: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeAddress = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            address: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeMoo = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            moo: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeSoi = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            soi: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onChangeRoad = (event: any) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            road: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const onSearchLocation = (values: any) => {
    };

    const onSelectLocation = (values: any, key: any) => {
        setLocationDate(key.props);
    };
    
    const onChangeLocation = (values: any, key: any) => {
        // console.log("key", key)
        // console.log("values", values)
        // if (key.key === 'Enter') {
        //     setLocationDate(key.props);
        // }
    };
    
    const onChangeIdCard = (event: any) => {
        form.validateFields();
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            idCard: event.target.value,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const setLocationDate = (datas: Location) => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            tambonCode: datas.tambonCode,
            tambonName: datas.tambon,
            amphurCode: datas.amphurCode,
            amphurName: datas.amphur,
            provinceCode: datas.provinceCode,
            provinceName: datas.province,
            zipcode: datas.zipcode,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const delItem =() => {
        const _guarantor : ApplicationGuarantor = {
            ...applicationGuarntor,
            useFlg : -1,
        }
        setDataGuarantorItem(_guarantor, rowIndex);
    }

    const renderTitle = (title: string, subTitle: string) => (
        <span>
          {title}
          <br/>
          {subTitle}
        </span>
      );

  return (
    <>
        <Form
            form={form}
            name="basic"
            layout="vertical"
            key={rowIndex}
            // onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
        >
            <Panel key={rowIndex}>
                <Form.Item
                    wrapperCol={{ offset: 23, span: 2 }}
                >
                    {/* <Button
                        danger
                        size="large"
                        // type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => delItem}
                    >
                        ลบ
                    </Button> */}
                    <Button
                        size="large"
                        shape="circle"
                        // type='primary'
                        danger
                        icon={<DeleteOutlined />}
                        // style={{marginTop:5}}
                        onClick={() => (delItem())}
                    />
                </Form.Item>
            <Form.Item
                    label="ค้นหา"
                    name="idCardFind"
                  >
                    <AutoComplete
                        placeholder="เลขที่บัตร"
                        // onSearch={onSearchLocation}
                        onSelect={onSelectIdCardFind}
                        onChange={onSelectIdCardFind}
                        // value={selectLocation?.addrPath}
                        options={parties.map((d) => ({
                        value: d.idCard,
                        label: renderTitle(d.idCard, d.nameThai),
                        key: d.id,
                        props: d,
                        }))}
                        filterOption={true}
                        allowClear
                        size='large'
                    >
                        <Input.Search size='large' enterButton />
                    </AutoComplete>
                </Form.Item>
                <hr></hr>
                <Form.Item label="คำนำหน้า">
                    <Row>
                        <Col>
                            <Form.Item
                                name="prename_thai"
                                rules={[
                                    {
                                        required: false,
                                        message: "กรุณาเลือกคำนำหน้าชื่อ!",
                                        validator : async (_, value) => {
                                            if (!value && (applicationGuarntor.idCard || applicationGuarntor.nameThai)) {
                                                return Promise.reject(
                                                  "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                                );
                                            }
                                        }
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="เลือกคำนำหน้าชื่อ"
                                    style={{ width: 200 }}
                                    onSelect={onSelectPrenameThai}
                                    size='large'
                                >
                                    {optionsPrenameThai.map((prename) => (
                                        <Option key={prename.id} value={prename.id} props={prename}>
                                        {prename.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="prename_etc_thai">
                                <Input
                                    placeholder="คำนำหน้าชื่อ อื่นๆ ระบุ"
                                    style={{marginLeft: "10px" }}
                                    disabled={showPrenameThaiEtc}
                                    hidden={showPrenameThaiEtc}
                                    size='large' showCount maxLength={100}
                                    onChange={onChangePrename}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Form.Item
                    label="ชื่อ-นามสกุลผู้ค้ำ"
                    name="name_thai"
                    rules={[
                        { required: false, message: "กรุณาระบุชื่อ-นามสกุลผู้ค้ำ!" },
                        { message: "Please input your name",},
                    ]}
                >
                    <Input size='large' showCount maxLength={100}
                        onChange={onChangeName}
                    />
                </Form.Item>
                <Form.Item label="อายุ/เชื้อชาติ">
                    <Row>
                        <Col>
                            <Form.Item name="age"
                                rules={[
                                    {
                                        required: false,
                                        message: "กรุณาระบุอายุ!",
                                        validator : async (_, value) => {
                                            if (!value && (applicationGuarntor.idCard || applicationGuarntor.nameThai)) {
                                                return Promise.reject(
                                                  "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                                );
                                            }
                                        }
                                    },
                                ]}
                            >
                                <Input size='large' showCount maxLength={50}
                                    placeholder="อายุ"
                                    name="age"
                                    style={{ width: 150 }}
                                    onChange={onChangeAge}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="origin"
                                rules={[
                                    {
                                        required: false,
                                        message: "กรุณาระบุเชื้อชาติ!",
                                        validator : async (_, value) => {
                                            if (!value && (applicationGuarntor.idCard || applicationGuarntor.nameThai)) {
                                                return Promise.reject(
                                                  "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                                );
                                            }
                                        }
                                    },
                                ]}
                            >
                                <Input size='large' showCount maxLength={100}
                                    placeholder="เชื้อชาติ"
                                    name="origin"
                                    style={{ width: 300, marginLeft: "10px" }}
                                    onChange={onChangeOrigin}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="สัญชาติ">
                <Row>
                    <Col>
                    <Form.Item 
                        name="nationality_id"
                        rules={[
                            {
                                required: false,
                                message: "กรุณาเลือกสัญชาติ!",
                                validator : async (_, value) => {
                                    if (!value && (applicationGuarntor.idCard || applicationGuarntor.nameThai)) {
                                        return Promise.reject(
                                          "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                        );
                                    }
                                }
                            },
                        ]}
                    >
                        <Select
                            placeholder="เลือกสัญชาติ"
                            style={{ width: 200 }}
                            onSelect={onSelectNationality}
                            // value={selectNationality?.id}
                            size='large'
                        >
                            {optionsNationality.map((name) => (
                                <Option key={name.id} value={name.id}>
                                {name.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col>
                    <Form.Item 
                        name="nationality"
                        rules={[
                        {
                            type: 'date',
                            required: true,
                            message: 'กรุณาระบุสัญชาติ!',
                            whitespace: true,
                            validator : async (_, value) => {
                            const nationality_id = applicationGuarntor.nationalityId;
                            if (nationality_id!=null) {
                                if (nationality_id === 74 && !value) {
                                return Promise.reject(
                                    "value can't null"
                                );
                                }
                            }
                            }
                        },
                        ]}
                    >
                        <Input
                            placeholder="อื่นๆ ระบุ"
                            style={{marginLeft: "10px" }}
                            disabled={showNationalityEtc}
                            hidden={showNationalityEtc}
                            size='large' showCount maxLength={100}
                            onChange={onChangeNationality}
                        />
                    </Form.Item>
                    </Col>
                </Row>
                </Form.Item>
                <Form.Item label="ชื่อหมู่บ้าน/อาคารชุด" name="building">
                    <Input size='large' showCount maxLength={100} 
                        onChange={onChangeBuilding}
                    />
                </Form.Item>
                <Form.Item label="เลขที่">
                    <Row>
                        <Col>
                            <Form.Item name="room_no">
                                <Input size='large' showCount maxLength={50}
                                    style={{ width: 150 }}
                                    placeholder="เลขที่ห้องชั้น"
                                    onChange={onChangeRoom}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="address">
                                <Input size='large' showCount maxLength={100}
                                    placeholder="บ้านเลขที่"
                                    style={{ width: 150, marginLeft: "10px" }}
                                    onChange={onChangeAddress}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="moo">
                                <Input size='large' showCount maxLength={50}
                                    placeholder="หมู่"
                                    style={{ width: 150, marginLeft: "10px" }}
                                    onChange={onChangeMoo}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="ซอย/ถนน">
                    <Row>
                        <Col>
                            <Form.Item name="soi">
                                <Input size='large' showCount maxLength={50}
                                    placeholder="ซอย"
                                    name="soi"
                                    style={{ width: 150 }}
                                    onChange={onChangeSoi}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="road">
                                <Input size='large' showCount maxLength={100}
                                    placeholder="ถนน"
                                    name="road"
                                    style={{ width: 300, marginLeft: "10px" }}
                                    onChange={onChangeRoad}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item
                    label="ที่อยู่"
                    name="addrPath"
                    rules={[
                        {
                            required: false,
                            message: "กรุณาระบุชื่อตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์!",
                            validator : async (_, value) => {
                                if ((applicationGuarntor.idCard || applicationGuarntor.nameThai) && !value) {
                                    return Promise.reject(
                                      "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                    );
                                }
                            }
                        },
                    ]}
                >
                    <AutoComplete
                        placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                        onSearch={onSearchLocation}
                        onSelect={onSelectLocation}
                        onChange={onChangeLocation}
                        // value={selectLocation?.addrPath}
                        options={optionsLocation.map((d) => ({
                            value: d.addrPath,
                            label: d.addrPath,
                            key: d.id,
                            props: d,
                        }))}
                        filterOption={true}
                        allowClear
                        size='large'
                    >
                        <Input.Search size='large' enterButton />
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    label="เลขบัตร"
                    name="idCard"
                    rules={[
                        {
                            required: false,
                            message: "กรุณาระบุเลขบัตร!",
                            validator : async (_, value) => {
                                if ((applicationGuarntor.idCard || applicationGuarntor.nameThai) && !value) {
                                    return Promise.reject(
                                    "มีการระบุชื่อหรือเลขบัตร กรุณาระบุข้อมูลให้ครบทุกช่อง"
                                    );
                                }
                            }
                        },
                        {
                            required: false,
                            message: "เลขบัตรประชาชน ต้องระบุ 13 ตัว",
                            validator : async (_, value) => {
                                const nationality_id = applicationGuarntor.nationalityId;
                                if (nationality_id!=null) {
                                  if (nationality_id === 73 && value.length != 13) {
                                    return Promise.reject(
                                        "Please input your Id card 13 character"
                                      );
                                  }
                                }
                              }
                        },
                    ]}
                  >
                    <Input size='large' showCount maxLength={20} 
                        onChange={onChangeIdCard}
                    />
                </Form.Item>
            </Panel>
        </Form>
        <br></br>
    </>
  )
}

export default GuarantorComponent