import { message } from "antd";

export function showMessage(content: string | any) {
  message.error({
    content: content,
    duration: 1.8,
    style: {
      maxWidth: "450px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  });
}

export const round2DecimalPoint = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const generateOrderNumber = () =>
  Math.floor(100000 + Math.random() * 900000).toString();
