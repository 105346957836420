import { Col, Row, DatePicker, Form } from "antd";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import DBDocumentView from "./DBDocumentView";
import DBInterestView from "./DBInterestView";
import DBPaymentView from "./DBPaymentView";
import DBChartView from "./DBChartView";
import { useEffect, useState } from "react";
import { DashboardModel } from "src/domain/dashboardModelType";
import dashboardService from "src/services/dashboardService";
import { DashboardProduct } from "src/domain/dashboardProductModelType";
import { useAppSelector } from "src/store";

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const Dashboard : React.FC<DashboardModel> = () =>  {
  const [form] = Form.useForm();
  const { sessionUser } = useAppSelector((state) => state.auth);
  const [dashboard, setDashboard] = useState<DashboardModel>({} as DashboardModel);
  const [dashboardProducts, setDashboardProducts] = useState<DashboardProduct[]>([] as DashboardProduct[]);

  

  const defaultDate = {
    date: [moment().startOf('month'), moment().endOf('month')]
  };

  useEffect(() => {}, [dashboard, dashboardProducts]);

  useEffect(() => {
    (async () => {
      const _date = form.getFieldValue("date")

      getData(_date === undefined ? moment().startOf('month') : _date[0].format('YYYYMMDD'),
        _date === undefined ? moment().endOf('month') : _date[1].format('YYYYMMDD'),);
      
    })();
  },[]);

  const onFinish = (values: any) => {

  }

  const onChangeDate = (event : any) => {
    const _dateStart = event[0] === undefined ? moment().startOf('month') : event[0].format('YYYYMMDD');
    const _dateEnd = event[1] === undefined ? moment().endOf('month') : event[1].format('YYYYMMDD');
    console.log('start date',_dateStart); 
    console.log("end date",_dateEnd);

    getData(_dateStart, _dateEnd);
  }

  const getData = (_dateStart : any, _dateEnd: any) => {

    dashboardService.forDashboard(_dateStart,_dateEnd, 
      sessionUser.scope === 'BRANCH' ? sessionUser.branch : ""
      ).then(res => {
      setDashboard(res.data);
    });

    dashboardService.forDashboardChart(_dateStart,_dateEnd,
      sessionUser.scope === 'BRANCH' ? sessionUser.branch : ""
      ).then(res => {
      setDashboardProducts(res.data);
    });

    // setDashboard(res.data);
    // setDashboardProducts(resProduct.data);
  }

  return (
  <div style={{marginTop:50}}>
    <Form
      form={form}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      initialValues={defaultDate}
    >
      <Row >
      <Col span={8}>
        <div style={{margin:5, marginLeft:10, fontWeight:500, fontSize:"28px"}}>
          VIEW YOUR CURRENT
        </div>
      </Col>
      <Col span={16}>
        <div style={{margin:5, textAlign:"right", width:"100%"}}>
          <Form.Item
            name="date"
            key="date"
            style={{marginRight:12}}
          >
            <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:385}} onChange={onChangeDate} />
          </Form.Item>
        </div>
      </Col>
      </Row>
      <Row>
        <Col span={16}>
          <Row>
            <Col span={12}>
              <div style={{height:150, margin:5}}>
                <DBDocumentView text="APPLICATION" qty={dashboard.applicationQty} amount={dashboard.applicationAmount} />
              </div>
            </Col>
            <Col span={12}>
              <div style={{backgroundColor:"", height:150, margin:5}}>
              <DBDocumentView text="CONTRACT" qty={dashboard.contractQty} amount={dashboard.contractAmount} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{backgroundColor:"", height:150, margin:5}}>
                <DBInterestView amount={dashboard.interestDueAmount} text={"ดอกเบี้ยครบกำหนดชำระ"} color={"blue"}/>
              </div>
            </Col>
            <Col span={12}>
              <div style={{backgroundColor:"", height:150, margin:5}}>
                <DBInterestView amount={dashboard.interestPaidAmount} text={"ดอกเบี้ยที่ชำระแล้ว"} color={"#23B70B"}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div style={{backgroundColor:"", height:300, margin:5}}>
                <DBPaymentView dashboard={dashboard} />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <div style={{margin:5}}>
            <DBChartView dashboardProducts={dashboardProducts} qty={dashboard.applicationQty} />
          </div>
        </Col>
      </Row>
    </Form>
  </div>);
}

export default Dashboard;
