import { Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';

import { BookItem } from 'src/domain/account/bookItemType';
import bookService from 'src/services/account/bookService';

interface IProps {
    bookId : number;
}

const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (value: string, item: BookItem, index: number) => (
        index+1
      ),
    },
    {
        title: "รหัสบัญชี/ชื่อบัญชี",
        dataIndex: "accountCode",
    },
    {
        title: "เดบิต",
        dataIndex: "debit",
        align: "right" as "right",
        render: (value: number) =>
            Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
    },
    {
        title: "เครดิต",
        dataIndex: "credit",
        align: "right" as "right",
        render: (value: number) =>
            Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
    },
    {
        title: "หมายเหตุ",
        dataIndex: "description",
        key: "description",
    },
    {
        title: "สาขา",
        dataIndex: "",
        key: "",
      },
    {
        title: "เลขที่เอกสารอ้างอิง",
        dataIndex: "",
        key: "",
    },
    {
        title: "วันที่เอกสารอ้างอิง",
        dataIndex: "",
        key: "",
    },
];
const ViewAccBookItem: React.FC<IProps> = (props) => {
    const {bookId} = props;
    const [bookItems, setBookItems] = useState<BookItem[]>([] as BookItem[]);

    useEffect(() => {
        if(bookId) {
            bookService.getBookItemsByBookId(bookId).then(res => {
                setBookItems(res.data);
            })
        }
    }, [bookId]);

  return (
    <div>
        <Panel>
            <Table 
                columns={columns}
                dataSource={bookItems}
                size="small"
                rowKey="id"
                pagination={false}
                
            />
        </Panel>
    </div>
  )
}

export default ViewAccBookItem