import { Button, Descriptions, Popconfirm, Space, Switch } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {FormOutlined, DeleteOutlined} from '@ant-design/icons'
import Panel from 'src/components/container/Panel';
import { Account } from 'src/domain/account/accountType';
import PageHeader from 'src/layouts/PageHeader';
import accountService from 'src/services/account/accountService';

interface IParams {
    id: string;
}

const ViewAccount: React.FC<RouteComponentProps<IParams>> = (props) => {
    const _accountId = Number(props.match.params.id);
    const history = useHistory();

    const [account, setAccount] = useState<Account>({} as Account);
    const [switchFlg, setSwitchFlg] = useState<boolean>();

    useEffect(() => {
        accountService.getAccountById(_accountId).then(res => {
            setAccount(res.data);
        })
    }, []);

    useEffect(() => {
        if(account.useFlg === -1) setSwitchFlg(false);
        else setSwitchFlg(true);
    },[account]);

    const onSwitchStatus = (value: any) => {
        console.log("switch=", value)
        accountService.editUseFlg(_accountId, value ? 0 : -1).then((res) => {
            if (res.status === 200) {
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
        });
    }

  return (
      <>
        <PageHeader title={'ACCOUNT NO. '+account.code} onClose={() => history.push("/app/account")}>
        <Space>
            <Button
                size="large"
                icon={<FormOutlined />}
                onClick={() => history.push("/app/account/edit/" + _accountId)}
                >
                แก้ไข
            </Button>
            {/* <Popconfirm
                title="ต้องการลบรหัสบัญชีใช่หรือไม่?"
                // onConfirm={onClickDelete}
                // onCancel={cancel}
                okText="ใช่"
                cancelText="ไม่"
            >
                <Button
                    size="large"
                    type="primary"
                    icon={<DeleteOutlined />}
                    danger
                    // onClick={onClickDelete}
                    >
                    ลบ
                </Button>
            </Popconfirm> */}
            <Switch 
                // defaultChecked={account.useFlg === 0 ? true : false} 
                checked={switchFlg} 
                checkedChildren={"เปิดใช้งาน"} 
                unCheckedChildren={"ปิดใช้งาน"} 
                onClick={onSwitchStatus}
            />
        </Space>
        </PageHeader>
        <Content className="app-page-content">
            <Panel style={{marginLeft:"10px"}}>
                <Descriptions
                    column={2}
                    bordered
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                >
                    <Descriptions.Item
                        label="CODE :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.code}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="TYPE :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.type}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="EXT-ACCOUNT :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {account.} */}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="SIDE :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.side}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="THAI NAME :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="LEVEL :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.accLevel}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="OLD CODE :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.oldcode}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ACCOUNT GROUP :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {account.accLevel} */}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="COMPANY :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {/* {account.oldcode} */}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="TAX :"
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.relatedTaxCode}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="REMARK :"
                        span={2}
                        labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                    >
                        {account.remark}
                    </Descriptions.Item>
                </Descriptions>
            </Panel>
        </Content>
      </>
  );
};

export default ViewAccount;
