import { Button, DatePicker, Form, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { BankTransferDetail } from 'src/domain/banktransfer/bankTransferDetailType';
import {SearchOutlined, CloseCircleFilled, SaveOutlined} from '@ant-design/icons';
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import PageHeader from 'src/layouts/PageHeader';
import { useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import bankTransferService from 'src/services/banktransfer/bankTransferService';
import { useAppSelector } from "src/store";
import ListViewBankTransferDetail from './ListViewBankTransferDetail';
import DFlex from '../../components/container/DFlex';
import messageUtils from 'src/utils/messageUtils';
import loggingService from 'src/services/security/loggingService';

const dateFormat = 'DD/MM/YYYY';

const BankTransferForm = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [bankTransferDetails, setBankTransferDetails] = useState<BankTransferDetail[]>([]);
    const { sessionUser } = useAppSelector((state) => state.auth);

    const defaultDate = {
        transferDate: moment()
    };

    useEffect(()=> {}, [bankTransferDetails]);

    const findByParam = (values: any) => {
        const _transferDate = form.getFieldValue("transferDate");
        if(_transferDate) {
            bankTransferService.getContractTransferByPayDate(_transferDate.format('YYYYMMDD')).then(res => {
                setBankTransferDetails(res.data);
            })
        }
    }

    const onFinish = async (values: any) => {
        const _contractDate = values.transferDate;
        let _date = _contractDate.format('YYYYMMDD');

        const _remark = values.remark;
        if(bankTransferDetails.length > 0) {
            await bankTransferService.addBankTransfer(_date, _remark, sessionUser.userId,
                    sessionUser.username, sessionUser.branch, bankTransferDetails).then(res => {
                    if(res.status === 200) {
                        messageUtils.successMessage("Save success !!!")

                        loggingService.logging({
                            event: 'CREATE',
                            systemRemark: 'create bank transfer',
                            eventUserId: sessionUser.userId,
                            eventUsername: sessionUser.username,
                            target: 'bank transfer',
                            targetRef: res.data.id
                        });
                        history.replace("/app/banktransfer/view/"+res.data.id);
                    }
                        
                }).catch(err => {
                    console.log(err)
                });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> BankTransferForm:", errorInfo);
    }

  return (
    <div>
        <PageHeader title="สร้าง รายการโอนเงิน" showSubtitle={false}>
            <Button
            size="large"
            icon={<CloseCircleFilled />}
            onClick={() => history.goBack()}
            >
                Cancel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel style={{width: "100%"}}>
                <Form
                    form={form}
                    name="basic"
                    layout="horizontal"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={defaultDate}
                >
                <div style={{ display: "flex", flexDirection: "row", width:"100%", textAlign:"left" }}>
                    <div>
                        <Form.Item
                            label={(<div style={{ width: '100px'}}>วันที่โอนเงิน</div>)}
                            name="transferDate"
                            key="transferDate"
                        >
                            <DatePicker
                                locale={th_loc}
                                format={dateFormat}
                            />
                        </Form.Item>
                    </div>
                    <div style={{marginLeft: "20px"}}>
                        <Form.Item label="">
                            <Button
                                icon={<SearchOutlined/>}
                                size="middle"
                                onClick={findByParam}
                            >
                                ค้นหา
                            </Button>
                        </Form.Item>
                    </div>

                </div>

                <div style={{ display: "flex", flexDirection: "row", width:"100%", textAlign:"left"}}>
                    <div >
                        <Form.Item
                            label={(<div style={{ width: '100px'}}>หมายเหตุ</div>)}
                            name="remark"
                            key="remark"
                        >
                            <TextArea rows={3} cols={70} maxLength={255} showCount />
                        </Form.Item>
                    </div>

                </div>

                <ListViewBankTransferDetail details={bankTransferDetails} />

                <DFlex type='row' justifyContent='flex-end' style={{ marginTop: '20px'}}>
                    <Form.Item>
                        <Button
                            size="large"
                            type="primary"
                            name="save"
                            icon={<SaveOutlined />}
                            onClick={() => {
                                form.submit();
                            }}
                        >
                            Save
                        </Button>
                    </Form.Item>
                </DFlex>
            </Form>
            </Panel>
        </Content>
    </div>
  )
}

export default BankTransferForm