import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store";
import classes from "./SideNavModule.module.css";
import {
  Camera,
  Command,
  BarChart,
  Layers,
  Copy,
  Menu,
  LogOut,
  Shield,
} from "react-feather";
import profile from "../assets/images/pexels-mikhail-nilov-8297156.jpeg";
import { removeSessionUser } from "../store/authen/action";
import { useHistory } from "react-router-dom";
import { Item } from "rc-menu";
import loggingService from "../services/security/loggingService";

interface IProps {
  onSelectModule?: (code: string) => void;
  showMenu: () => void;
  menuModules: { id: number; name: string; icon: string; code: string }[];
  menus: any;
}

const iconRecords: Record<string, any> = {
  BlockOutlined: BarChart,
  BookOutlined: Command,
  TableOutlined: Layers,
  TeamOutlined: Copy,
  SecurityOutlined: Shield,
};

const SideNavModule: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { sessionUser } = useAppSelector((state) => state.auth);
  const [selectedItem, setSelectedItem] = useState<string>("002");

  const getIcon = (item: any): any => {

    const Component = iconRecords[item.icon];

    return <Component strokeWidth="1.5" color="#fff" size={20} />;
  };

  const onSelectModule = (item: any) => {
    console.log(item.code);
    setSelectedItem(item.code);
    if (props.onSelectModule) props.onSelectModule(item.code);
  };

  const handleLogout = () => {
    loggingService.logging({event: "LOGOUT", eventUserId: sessionUser.userId, eventUsername: sessionUser.username});
    dispatch(removeSessionUser({userId:0, username: "", email: "" , roles: [], branch: "", displayName: "", scope: ""}));
    history.replace("/login");
  };

  const showModule = (moduleCode: string):boolean => {
    const menuModule = props.menus.filter((item:any) => item.moduleCode === moduleCode);
    if (menuModule.length === 0) return false;

    const result = menuModule[0].moduleMenus.reduce((acc:boolean, item:any)=> {


      const haveRole = item.roles.reduce((acc:boolean, role:string) => {
        // console.log("user", sessionUser, "roles ", sessionUser.roles, "role", role);
        const result = sessionUser.roles.includes(role);
        return acc || result;
      }, false);

      return acc || haveRole;
    }, false);

    return result;
  }

  return (
    <div className={classes.sideNavModule}>
      <div className={classes.sideNavModuleMenuWrapper}>
        <div className={classes.sideNavModuleMenuIcon}>
          <Menu
            strokeWidth="1.5"
            size={18}
            // color="#3d87db"
            color="#fff"
            onClick={() => props.showMenu()}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {props.menuModules.map((item) => (
          showModule(item.code) &&
          <div
            className={
              selectedItem === item.code
                ? classes.sideNavModuleIconActive
                : classes.sideNavModuleIcon
            }
            onClick={() => onSelectModule(item)}
            key={item.code}
          >
            {getIcon(item)}
          </div>
        ))}
      </div>
      <div className={classes.sideNavModuleFooterWrapper}>
        <div style={{ borderRadius: "50%" }}>
          <img
            src={profile}
            width="40px"
            height="40px"
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "20px",
              cursor: 'pointer'
            }}
            onClick={() => onSelectModule({ id: 99, name: "Account", icon: "SecurityOutlined", code: "999" })}
          />
        </div>
        <div className={classes.sideNavModuleIcon}>
          <LogOut
            strokeWidth="1"
            color="#fff"
            size={20}
            onClick={handleLogout}

          />
        </div>
      </div>
    </div>
  );
};

export default SideNavModule;
