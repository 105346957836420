import { AutoComplete, Button, Col, Descriptions, Form, Input, InputNumber, Radio, Row, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import PageHeader from 'src/layouts/PageHeader';
import { CloseCircleOutlined, CalculatorOutlined, SaveOutlined} from "@ant-design/icons";
import { useHistory } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import { inputDecimalFormatter, inputDecimalPaser } from '../../utils/formatter';
import DFlex from 'src/components/container/DFlex';
import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';
import contractRedebtService from 'src/services/contract/contractRedebtService';
import { ContractRedebt } from 'src/domain/contract/contractRedebtType';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { ContractDebitRedebt } from 'src/domain/contract/contractDebitRedebtType';
import { useAppSelector } from 'src/store';
import messageUtils from 'src/utils/messageUtils';
import loggingService from 'src/services/security/loggingService';
import { ClassDetail } from 'src/domain/master/classDetailType';
import classDetailService from 'src/services/master/classDetailService';

const dateFormat = "DD/MM/YYYY";

const color_bg_readonly = "#EAECEE"

interface IProps {
    id: number;
    mode: string;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 10 },
};

const columns = [
  {
      title: "Mth",
      dataIndex: "month",
      key: "month",
  },
  {
      title: "Installment",
      dataIndex: "installment",
      key: "installment",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
  {
      title: "Interest Revenues",
      dataIndex: "interestRevenues",
      key: "interestRevenues",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
  {
      title: "Princ Repaid",
      dataIndex: "princPepaid",
      key: "princPepaid",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
  {
      title: "Ending Bal.",
      dataIndex: "endingBal",
      key: "endingBal",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
  {
      title: "CF",
      dataIndex: "cf",
      key: "cf",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
  {
      title: "% ตัดต้น",
      dataIndex: "principlePer",
      key: "principlePer",
      align: 'right' as 'right',
      render : (value : number) => (
          Number(Number(value).toFixed(2)).toLocaleString('th-TH')
      )
  },
]

const ContractRedebtForm : React.FC<IProps> = (props) => {
    const id = props.id;
    const mode = props.mode;
    const history = useHistory();
    const [form] = Form.useForm();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [contracts, setContracts] = useState<Contract[]>([] as Contract[]);
    const [contractRedebt, setContractRedebt] = useState<ContractRedebt>({} as ContractRedebt);
    const [contractDebitRedebts, setContractDebitRedebts] = useState<ContractDebitRedebt[]>([] as ContractDebitRedebt[]);
    
    const [optionsPaymentCal, setOptionsPaymentCal] = useState<ClassDetail[]>(
      [] as ClassDetail[]
    );
    const [selectPaymentCal, setSelectPaymentCal] = useState<number>(0);

    useEffect(() => {}, [contracts]);

    useEffect(() => {
      if(mode === 'NEW') {
        (async () => {
          const res = await contractService.getDataForRedebt();
          setContracts(res.data);
        })();
      } else {
        (async () => {
          const res = await contractRedebtService.getRedebtById(id);
          setContractDebitRedebts(res.data.debitRedebts);
          setContractRedebt(res.data);
        })();
      }
    }, []);

    useEffect(() => {
      (async () => {
        const res = await classDetailService.getByClassNo("32");
        setOptionsPaymentCal(res.data);
      })();
    }, []);

    const onChangePaymentCal = (values: any) => {
      setSelectPaymentCal(values.target.value);
    };

    useEffect(() => {
      form.setFieldsValue({
          no: contractRedebt.prevContractNo,
          loanAmt: contractRedebt.loanAmt,
          flatInterestMth : contractRedebt.flatInterestMth,
          flatInterestAnnum: contractRedebt.flatInterestAnnum,
          effInterestMth:contractRedebt.effInterestMth,
          effInterestAnnum:contractRedebt.effInterestAnnum,
          tenor:contractRedebt.tenor,
          interest:contractRedebt.interest,
          principalInterst:contractRedebt.principalInterst,
          installment:contractRedebt.installment,
          rateUsageFee : contractRedebt.rateUsageFee,
          rateStampDuty : contractRedebt.rateStampDuty,
          rateAccruedInterest : contractRedebt.rateAccruedInterest,
          paymentCal : contractRedebt.paymentCalId,
      });

      if(contractRedebt.paymentCalId) {
        setSelectPaymentCal(contractRedebt.paymentCalId);
      }
  }, [contractRedebt]);

    const renderTitle = (title: string, subTitle: string) => (
      <span>
        {title}
        <br/>
        {subTitle}
      </span>
    );

    const onSelectContractFind = (values: any, key: any) => {
      findData(values)
    }

    const onKeyContractFind = (event: any) => {
      // if (event.key === 'Enter') {
      //    findData(event.value)
      // } else if(event.key === 'Backspace') {
      //   setErrorBlank();
      // }
    }

    const findData = (value: string) => {
      if(value !== undefined) {
        contractRedebtService.getDataAddNew(value).then(res => {
          const _data = res.data;
          if(_data === '' || _data === null) {
            form.setFields([
              {
                name: 'no',
                errors: ['ไม่พบข้อมูลสัญญา'],
              },
            ]);
          } else if(_data.contractStatusId !== 76) {
            form.setFields([
              {
                name: 'no',
                errors: ['สถานะสัญญา "' + _data.contractStatusName + '" ไม่สามารถดำเนินการได้!!'],
              },
            ]);
          } else {
            setErrorBlank();
          }

          setContractRedebt(_data)
        })
      }
    }

    const setErrorBlank = () => {
      form.setFields([
        {
          name: 'no',
          errors: [],
        },
      ]);
    }

    const doCalculate = () => {
      onClickCalculate();
    }

    const onClickCalculate = () => {
      const _loanAmt = form.getFieldValue("loanAmt")
      const _tenor = form.getFieldValue("tenor")
      const _interest = form.getFieldValue("flatInterestMth")

      const redebts = {...contractRedebt,
        loanAmt: _loanAmt,
        flatInterestMth : _interest,
        tenor: _tenor,
      };

      contractRedebtService.calculateRedebt(redebts).then(res=>{
        setContractDebitRedebts(res.data.debitRedebts);
        setContractRedebt(res.data);
      });
    }

    const onFinish = async (values: any)  => {
      const _data : ContractRedebt = {
        ...contractRedebt,
        rateUsageFee : values.rateUsageFee,
        rateStampDuty : values.rateStampDuty,
        rateAccruedInterest : values.rateAccruedInterest,
        paymentCalId : selectPaymentCal,
        userId : sessionUser.userId,
        userName : sessionUser.username,
        savStation : sessionUser.branch,
      }

      if(mode === 'NEW') {
        await contractRedebtService.addRedebt(_data).then((res) => {
          if(res.data.status) {
            messageUtils.successMessage("Save success !!!")

            loggingService.logging({
              event: "CREATE",
              target: "contract redebt",
              targetRef: res.data.contract_no,
              eventUserId: sessionUser.userId,
              eventUsername: sessionUser.username,
            });

            history.goBack();
          } else {
            messageUtils.errorMessage(res.data.message);
          }
        })
        .catch((err) => {
          console.log("save error", err);
          messageUtils.errorMessage(JSON.stringify(err));
        });
      } else {
        await contractRedebtService.updateRedebt(_data).then((res) => {
        if(res.data.status) {
          messageUtils.successMessage("Save success !!!")

          loggingService.logging({
            event: "UPDATE",
            target: "contract redebt",
            targetRef: res.data.contract_no,
            eventUserId: sessionUser.userId,
            eventUsername: sessionUser.username,
          });

          history.goBack();
        } else {
          messageUtils.errorMessage(res.data.message);
        }
      })
      .catch((err) => {
        console.log("save edit error", err);
        messageUtils.errorMessage(JSON.stringify(err));
      });
      }
    }

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed ==> ContractRedebt:", errorInfo);
    }

  return (
    <div>
      <PageHeader title="สร้าง ปรับโครงสร้างหนี้" showSubtitle={false}>
        <Button
          size="large"
          icon={<CloseCircleOutlined />}
          onClick={() => history.goBack()}
        >
          Cancel
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <Panel style={{width: "100%",}}>
          <Form
            {...layout}
            form={form}
            name="basic"
            layout="horizontal"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // initialValues={defaultDate}
          >
            <Space direction='vertical' size={'middle'} style={{display:'flex'}} >
              <Form.Item
                label="เลขที่สัญญาที่ต้องการปรับโครงสร้าง"
                name="no"
              >
                <AutoComplete
                    placeholder="เลขที่สัญญา"
                    onSelect={onSelectContractFind}
                    onChange={onSelectContractFind}
                    onKeyDown={onKeyContractFind}
                    options={contracts.map((d) => ({
                    value: d.contractNo,
                    label: renderTitle(d.contractNo, d.name!),
                    key: d.id,
                    props: d,
                    }))}
                    filterOption={true}
                    allowClear
                    size='large'
                    disabled={id !== 0}
                >
                    <Input.Search size='large' enterButton />
                </AutoComplete>
              </Form.Item>

              <Panel style={{  width: "100%",}}>
                <Descriptions
                  style={{ marginTop: "0px" }}
                  column={2}
                  size="middle"
                  labelStyle={{ width: 200,  }}
                  bordered
                >
                  {mode === 'EDIT' &&
                  <Descriptions.Item
                    label="เลขที่สัญญาใหม่ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff", fontWeight: "bold", fontSize:"medium", color:'red'}}
                    span={2}
                  >
                    <label style={{fontWeight: 600, fontSize:"medium", color:'red'}}>{contractRedebt.contractNo}</label>
                  </Descriptions.Item>
                  }
                  <Descriptions.Item
                    label="เลขที่ใบคำขอสินเชื่อ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.applicationNo}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="วันที่ใบคำขอสินเชื่อ :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.applicationDate ? moment(contractRedebt.applicationDate).format(dateFormat) : ""}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="เลขที่สัญญา :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.prevContractNo}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="วันที่สัญญา :"
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.prevContractDate ? moment(contractRedebt.prevContractDate).format(dateFormat) : ""}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="ผู้ขอสินเชื่อ :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.nameThai}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="วงเงินกู้ :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.appLoanAmt && Number(Number(contractRedebt.appLoanAmt).toFixed(2)).toLocaleString('uth-TH')} <label>บาท</label>
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="จำนวนงวด :"
                    span={2}
                    labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
                  >
                    {contractRedebt.appLoanInstallment} <label>เดือน</label>
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
              </Space>
              <div style={{display: contractRedebt.prevContractNo ? '' : 'none', marginTop: 20}}>
              <Form.Item
                label="ยอดจัด (บาท)"
                name="loanAmt"
                key="loanAmt"
                rules={ [
                    {
                      type: 'number',
                      required: true,
                      message: 'กรุณาระบุยอดจัด (บาท)!',
                      whitespace: true,
                    },
                ]}
              >
                <InputNumber
                    formatter={inputDecimalFormatter}
                    parser={inputDecimalPaser}
                    onBlur={doCalculate}
                    precision={0}
                    style={{ width: 150, textAlign: "right", alignItems:"end"}}
                    size='large'
                />
              </Form.Item>
            
            <Form.Item
              label="FLAT INTEREST RATE % "
            >
              <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "0px"
                }}
              >
                <Form.Item
                  name="flatInterestMth"
                  key="flatInterestMth"
                  rules={ [
                      {
                        type: 'number',
                        required: true,
                        message: 'กรุณาระบุ FLAT INTEREST RATE!',
                      },
                  ]}
                >
                  <InputNumber
                      style={{ width: 150}}
                      placeholder="ต่องวด"
                      onBlur={doCalculate}
                      precision={5}
                      step={0.00001}
                      size='large'
                  />
                </Form.Item>
                <Form.Item
                  name="flatInterestAnnum"
                  key="flatInterestAnnum"
                  style={{marginLeft: "10px"}}
                >
                  <InputNumber
                    readOnly={true}
                    style={{ width: 150, backgroundColor:"#EAECEE"}}
                    placeholder="ทั้งหมด"
                    precision={4}
                    size='large'
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="จำนวนงวด"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "0px"
                }}
              >
                <Form.Item
                  name="tenor"
                  key="tenor"
                  rules={ [
                    {
                    type: 'number',
                    required: true,
                    message: 'กรุณาระบุ จำนวนงวด!',
                    whitespace: true,
                    },
                  ]}
                >
                  <InputNumber
                    onBlur={doCalculate}
                    precision={0}
                    style={{ width: 150}}
                    size='large'
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    size="middle"
                    type="primary"
                    icon={<CalculatorOutlined />}
                    onClick={onClickCalculate}
                    style={{marginLeft: "10px"}}
                  >
                    คำนวณ
                  </Button>
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item
              label="EFFECTIVE INTEREST RATE % "
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "0px"
                }}
              >
                <Form.Item
                  name="effInterestMth"
                  key="effInterestMth"
                >
                  <InputNumber
                    readOnly={true}
                    style={{ width: 150, backgroundColor:"#EAECEE"}}
                    placeholder="ต่องวด"
                    precision={2}
                    size='large'
                  />
                </Form.Item>
                <Form.Item
                  name="effInterestAnnum"
                  key="effInterestAnnum"
                  style={{marginLeft: "10px"}}
                >
                  <InputNumber
                    readOnly={true}
                    style={{ width: 150, backgroundColor:"#EAECEE"}}
                    placeholder="ทั้งหมด"
                    precision={4}
                    size='large'
                    // disabled
                  />
                </Form.Item>
              </div>
            </Form.Item>

            <Form.Item
              label="ดอกเบี้ย (บาท)"
              name="interest"
              key="interest"
            >
              <InputNumber size='large' readOnly={true} style={{ width: 150, backgroundColor:"#EAECEE"}} precision={2} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
            </Form.Item>

            <Form.Item
              label="เงินต้น+ดอกเบี้ย (บาท)"
              name="principalInterst"
              key="principalInterst"
            >
              <InputNumber size='large' readOnly={true} style={{ width: 150, backgroundColor:"#EAECEE"}} precision={2} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
            </Form.Item>

            <Form.Item
              label="จ่ายต่องวด (บาท)"
              name="installment"
              key="installment"
            >
              <InputNumber size='large' readOnly={true} style={{ width: 150, backgroundColor:"#EAECEE"}} precision={0} formatter={inputDecimalFormatter} parser={inputDecimalPaser} />
            </Form.Item>

            <Form.Item
              label="อัตราค่าธรรมเนียมการใช้วงเงิน %"
              name="rateUsageFee"
              key="rateUsageFee"
              rules={ [
                  {
                    type: 'number',
                    required: true,
                    message: 'กรุณาระบุอัตราค่าธรรมเนียมการใช้วงเงิน %!',
                    whitespace: true,
                  },
              ]}
            >
              <InputNumber
                  formatter={inputDecimalFormatter}
                  parser={inputDecimalPaser}
                  precision={2}
                  style={{ width: 150, textAlign: "right", alignItems:"end"}}
                  size='large'
              />
            </Form.Item>

            <Form.Item
              label="อัตราอาการแสตมป์ %"
              name="rateStampDuty"
              key="rateStampDuty"
              rules={ [
                  {
                      type: 'number',
                      required: true,
                      message: 'กรุณาระบุอัตราอาการแสตมป์ %!',
                      whitespace: true,
                  },
              ]}
            >
              <InputNumber
                formatter={inputDecimalFormatter}
                parser={inputDecimalPaser}
                precision={2}
                style={{ width: 150, textAlign: "right", alignItems:"end"}}
                size='large'
              />
            </Form.Item>

            <Form.Item
              label="อัตราดอกเบี้ยค้างชำระ %"
              name="rateAccruedInterest"
              key="rateAccruedInterest"
              rules={ [
                  {
                    type: 'number',
                    required: true,
                    message: 'กรุณาระบุอัตราดอกเบี้ยค้างชำระ %!',
                    whitespace: true,
                  },
              ]}
            >
              <InputNumber
                  formatter={inputDecimalFormatter}
                  parser={inputDecimalPaser}
                  precision={2}
                  style={{ width: 150, textAlign: "right", alignItems:"end"}}
                  size='large'
              />
            </Form.Item>

            <Form.Item
              label="วิธีการคำนวณ"
              name="paymentCal"
              rules={[
                      { required: true, message: "กรุณาเลือกวิธีการคำนวณ!",}
                    ]}
            >
              <Radio.Group
                onChange={onChangePaymentCal}
                value={selectPaymentCal}
                defaultValue={selectPaymentCal}
                size='large'
              >
                {optionsPaymentCal.map((opt_value) => (
                  <Radio key={opt_value.id} value={opt_value.id}>
                      {opt_value.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>

            <Descriptions
              style={{ marginTop: "20px" }}
              // title="Interest Revenues Calculation"
              size="middle"
              labelStyle={{ width: 200, fontWeight: "bold" }}
              bordered
              layout="vertical"
            >
              <Descriptions.Item
                  label="Interest Revenues Calculation"
                  labelStyle={{ width: 200, fontWeight: "bold" }}
              >
                <Table
                  columns={columns}
                  dataSource={contractDebitRedebts}
                  size="small"
                  rowKey={(item: ContractDebitRedebt) => item.month}
                  pagination={false}
                />
              </Descriptions.Item>
            </Descriptions>

            <DFlex type="row" gap={20} justifyContent='flex-end' full style={{ marginTop: '20px'}}>
              <Form.Item>
                <Button
                    danger
                    size="large"
                    type="primary"
                    icon={<SaveOutlined />}
                    onClick={() => form.submit()}
                >
                  Save
                </Button>
              </Form.Item>
            </DFlex>
            </div>
          </Form>
        </Panel>
      </Content>
    </div>
  )
}

export default ContractRedebtForm