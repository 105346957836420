class Configs {
    // urlRoot: string = "http://localhost:8080";
    // urlRoot: string = "https://api2.nimsoft.xyz";
    // urlRoot: string = "https://api2.nimsoft.net";
    urlRoot = process.env.REACT_APP_BASEURL;

    getResourceUrl = (resourceId: number) => {
        return `${this.urlRoot}/file/getResource/${resourceId}`;
    }
}

export default new Configs();