import http from "../../lib/http";
import configs from "src/constants/config";

const url = `${configs.urlRoot}/party`;

const getPartyAlls = () => {
    return http.get(url);
};

export default {
    getPartyAlls,
}