import http, { authConfig } from "../../lib/http";
import configs from "src/constants/config";

const url = `${configs.urlRoot}/receipt`;

const searchForReport = (startDate: string, endDate: string, contractNo: string, receiptNo: string,
) => {
    let searchData = {startDate: startDate, endDate: endDate, contractNo: contractNo, receiptNo: receiptNo};
    return http.post(`${url}/searchForReport`, {}, {params: searchData});
}

export default {
    searchForReport
}