import React from 'react'
import ContractRedebtForm from './ContractRedebtForm'

const NewContractRedebt = () => {
  return (
    <div>
        <ContractRedebtForm id={0} mode={'NEW'} />
    </div>
  )
}

export default NewContractRedebt