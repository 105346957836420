import React, { useEffect, useState } from "react";
import { Button, Upload, Steps, Table, UploadProps, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import classes from "./Panel.module.css";
import ResourceFileResponse from 'src/models/ResourceFileResponse';
import axios from 'axios';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { render } from "@testing-library/react";
import { UploadFile } from "antd/lib/upload/interface";
import Resource from "../container/Resource";
import configs from "src/constants/config";

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  uploadPath?: string;
  acceptExtension?: string;
  uploadProps?: UploadProps;
  fileType?: "PIC" | "DOC" | "ALL";
  resourceId?: number;
  filename?: string;
  containerPath?: string;
  onUploaded: (uploadStatus: string, resourceFile: ResourceFileResponse, file: UploadFile) => void;
  onRemoved: (file: {name: string, status: string, uid: number, url: string}) => void;
}

const FileUpload: React.FC<IProps> = (props) => {
    // const extall = "jpg ,jpeg ,gif ,png, pdf";
    const defaultContainerPath = "unknown";
    const defaulUploadPath = `${configs.urlRoot}/file/upload`;
    const [extall, setExtall] = useState<string | null | undefined>(null);
    const [uploadPath, setUploadPath] = useState<string>(defaulUploadPath);
    const [fileList, setFileList] = useState<Array<any>>([]);
    const [children, setChildren] = useState<React.ReactNode>();
    const [fileType, setFileType] = useState<"PIC" | "DOC" | "ALL">("DOC");
    const [resourceId, setResourceId] = useState<number | null>();
    const [filename, setFilename] = useState<string | null>();
    const [containerPath, setContainerPath] = useState<string>(defaultContainerPath);

    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>("");
    const [previewTitle, setPreviewTitle] = useState<string>("");

    const [fileUpload, setFileUpload] = useState<string>();
    const [progress, setProgress] = useState<number>();

    // UseEffect
    useEffect(() => {
        setExtall(props.acceptExtension);
    }, [props.acceptExtension]);

    useEffect(() => {
        if(props.uploadPath){
            setUploadPath(props.uploadPath);
        }else{
            setUploadPath(defaulUploadPath);
        }
    }, [props.uploadPath]);

    useEffect(() => {
        if(props.children){
            setChildren(props.children);
        }else{
            setChildren(null);
        }
    }, [props.children]);

    useEffect(() => {
        if(props.containerPath && props.containerPath != null && props.containerPath.trim() != ""){
            setContainerPath(props.containerPath);
        }else{
            setContainerPath(defaultContainerPath);
        }
    }, [props.containerPath])

    useEffect(() => {
        if(props.fileType){
            setFileType(props.fileType);
        }else{
            setFileType("DOC");
        }
    }, [props.fileType])

    useEffect(() => {
        switch(fileType){
            case "PIC": setExtall("jpg ,jpeg ,png"); break;
            case "DOC": setExtall("pdf"); break;
            case "ALL": setExtall("jpg ,jpeg ,png, pdf"); break;
            default: setExtall("jpg ,jpeg ,png, pdf"); break;
        }
    }, [fileType])

    useEffect(() => {
        setResourceId(props.resourceId);
    }, [props.resourceId])

    useEffect(() => {
        if(resourceId == null){
            setFileList([]);
        }else{
            setFileList([
                {
                    uid: resourceId,
                    status: 'done',
                    name: filename,
                    url: `${configs.getResourceUrl(resourceId)}`
                }
            ])
        }
    }, [resourceId])

    useEffect(() => {
        setFilename(props.filename);
    }, [props.filename])

    // Upload properties setup
    const uploadProps = {
        name: "image",
        // action: (file: RcFile) => {
        //     console.log("action file", file);
        //     return 'http://localhost:8080/file/upload'
        // },
        customRequest: (options: any) => {
            console.log("Custom Request");
            const { onSuccess, onError, file, onProgress } = options;
            const formData = new FormData();

            const httpConfig = {
                headers: { "content-type": "multipart/form-data" },
                onUploadProgress: (event: any) => {
                    const percent = Math.floor((event.loaded / event.total) * 100);
                    setProgress(percent);
                    if (percent === 100) {
                        setTimeout(() => setProgress(0), 1000);
                    }
                    onProgress({ percent: (event.loaded / event.total) * 100 });
                }
            };

            formData.append("image", file);
            formData.append("fileType", fileType);
            formData.append("userId", "1");
            formData.append("containerPath", containerPath);
            axios.post(uploadPath, formData, httpConfig).then((res: any) => {
                onSuccess(file, res);
                console.log("post: ", res);
                let response: ResourceFileResponse = res.data;
                if(response.success){
                    props.onUploaded("success", res.data, file);
                }else{
                    props.onUploaded("error", res.data, file);
                }
            }).catch((err: Error) => {
                console.log("err: ", err);
                console.log("err stack: ", err.stack);
                // const error = new Error('Some error');
                onError({event: err});
                props.onUploaded("error", new ResourceFileResponse(false, err.message, null), file);
            });

        },
        beforeUpload: (file: any) => {
            console.log("FileUpload.beforeUpload file: ", file);
            return checkExtension(file.name) ? true : Upload.LIST_IGNORE;
        },
        // onChange({ file, fileList }: {file: any, fileList: any}) {
        onChange(res: UploadChangeParam) {
            // console.log("onChange: ", res);
            console.log("FileUpload.file.status: ", res.file.status);
            switch (res.file.status) {
                case "uploading":
                    // nextState.selectedFileList = [info.file];
                    break;
                case "done":
                    // nextState.selectedFile = info.file;
                    // nextState.selectedFileList = [info.file];
                    console.log("res: ", res);
                    setFileList(res.fileList);
                    break;

                case "error":
                    // props.onUploaded();
                    break;
            
                default:
                    // error or removed
                    // nextState.selectedFile = null;
                    // nextState.selectedFileList = [];
                    setFileList(res.fileList);
            }

            // if (res.file.status !== 'uploading') {
            //     console.log("file: ", res.file, "fileList: ", fileList);
            //     setFileList(fileList);
            // }
        },
        onRemove: (file: any) => {
            let fileObj: {name: string, status: string, uid: number, url: string} = file;
            props.onRemoved(fileObj);

        },
        defaultFileList: [

        ],
    };

    const checkExtension = (filename: string) => {
        if(filename){
            let extList = filename.split('.');
            if(extList){
                let extOri = extList.pop();
                if(extOri){
                    let ext:string = extOri.toLowerCase();
                    if(extall != null && extall.indexOf(ext) < 0){
                        alert('Extension support : ' + extall);
                        return false;
                    }
                    return true;
                }
            }
        }
        return false;
    }
    const handlePreview = async (file: any) => {
        console.log("handlePreview: ", file)
          setPreviewImage(file.url);
          setPreviewVisible(true);
          setPreviewTitle(filename == null ? "" : filename);
      };
    const handleCancel = () => {
        setPreviewVisible(false);
    }
    const renderDefaulChildren = () => {
        return fileList.length < 1 ? (<Button icon={<UploadOutlined />}>Upload</Button>) : null;
    }
    const renderExample = async () => {
        const res = await axios.get(previewImage);

        if(res.status != 200){
            return (<img alt="example" style={{ width: '100%' }} src={previewImage} />)
        }
        let contentType = res.headers['content-type']; // 'text/html; charset=utf-8'

        if(contentType.includes("image")){
            return (<img alt="example" style={{ width: '100%' }} src={previewImage} />)
        }else{
            return (<iframe width={"100%"} height={400} src={previewImage}/>)
        }
    }
    return (
        <>
            <Upload 
                {...uploadProps} 
                {...props.uploadProps} 
                listType={fileType == "PIC" ? "picture-card" : "text"} 
                fileList={fileList} 
                onPreview={handlePreview}
            >
                {children == null ? renderDefaulChildren() : children}
            </Upload>
            <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
            >
                {renderExample()}
            </Modal>
        </>
    );
};

export default FileUpload;
