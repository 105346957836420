import { Button, Form, Select, DatePicker, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';
import PageHeader from 'src/layouts/PageHeader';
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";
import { Branch } from 'src/domain/master/BranchType';
import BranchService from 'src/services/master/BranchService';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { PaymentForReport } from 'src/domain/payment/paymentForReportType';
import { Excel } from 'antd-table-saveas-excel';
import paymentService from 'src/services/payment/paymentService';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const columns = [
    {
        title: "วันที่รับชำระ",
        dataIndex: "paymentDate",
    },
    {
        title: "เลขที่รับชำระ",
        dataIndex: "paymentNo",
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "contractNo",
        width: 190,
    },
    {
        title: "วันที่สัญญา",
        dataIndex: "contractDate",
    },
    {
        title: "เลขที่บัตร",
        dataIndex: "idCard",
    },
    {
        title: "คำนำหน้า",
        dataIndex: "prenameThaiName",
    },
    {
        title: "ชื่อผู้ขอสินเชื่อ",
        dataIndex: "name",
        width: 290,
    },
    {
        title: "วงเงินกู้",
        dataIndex: "loanAmt",
        align: "right" as "right",
    },
    {
        title: "จำนวนงวด",
        dataIndex: "loanInstallment",
        align: "right" as "right",
    },
    {
        title: "จ่ายต่องวด",
        dataIndex: "installment",
        align: "right" as "right",
    },
    {
        title: "งวดที่ชำระ",
        dataIndex: "month",
        align: "right" as "right",
    },
    {
        title: "เงินต้น",
        dataIndex: "principal",
        align: "right" as "right",
    },
    {
        title: "ดอกเบี้ย",
        dataIndex: "interest",
        align: "right" as "right",
    },
    {
        title: "ค่าปรับ",
        dataIndex: "penalty",
        align: "right" as "right",
    },
    {
        title: "ส่วนลด",
        dataIndex: "discount",
        align: "right" as "right",
    },
    {
        title: "เงินฝาก",
        dataIndex: "deposit",
        align: "right" as "right",
    },
    {
        title: "เงินทอน",
        dataIndex: "change",
        align: "right" as "right",
    },
    {
        title: "ดอกเบี้ยค้างชำระ",
        dataIndex: "accruedInterest",
        align: "right" as "right",
    },
];

const ListPaymentReport: React.FC<PaymentForReport> = () => {
    const [form] = Form.useForm();

    const [optionsBranch, setOptionsBranch] = useState<Branch[]>([] as Branch[]);
    const [payments, setPayments] = useState<PaymentForReport[]>([]);

    const defaultDate = {
        paymentDate: [moment().startOf('month'), moment().endOf('month')]
    };

    useEffect(() => {}, [payments]);

    useEffect(() => {
        (async () => {
          const res = await BranchService.getBranchUseAll();
          setOptionsBranch(res.data);
        })();
    }, []);

    const handleContractNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("contractNo");
            if(_search) {
                (async () => {
                    const res = await paymentService.searchForReport(0, _search, "", "", "", "");
                    setPayments(res.data);
                })();
            }
        }
    }

    const handleIdCardKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("idCard");
            if(_search) {
                (async () => {
                    const res = await paymentService.searchForReport(0, "", _search, "", "", "");
                    setPayments(res.data);
                })();
            }
        }
    }

    const onFinish = (values: any) => {
        const _date = values.paymentDate;
        const _branchId = values.branchCode ?? 0;
        const _contractNo = values.contractNo;
        const _name = values.name;
        const _idcard = values.idCard;

        (async () => {
            const res = await paymentService.searchForReport(
                _branchId,
                _contractNo === undefined ? "" : _contractNo,
                _idcard === undefined ? "" : _idcard,
                _name === undefined ? "" : _name,
                _date ? _date[0].format('YYYYMMDD') : "" ,
                _date ? _date[1].format('YYYYMMDD') : "" 
            );
            setPayments(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            paymentDate: [moment().startOf('month'), moment().endOf('month')],
            branchCode: null,
            contractNo: "",
            idCard: "",
            name: ""
        });

        setPayments([] as PaymentForReport[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columns)
            .addDataSource(payments)
            .saveAs("payments_report.xlsx");
    }

  return (
    <div>
        <PageHeader title="รายงานการรับชำระ" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "30%"}}>
                            <Form.Item
                                label="สาขา"
                                name="branchCode"
                                key="branchCode"
                            >
                                <Select
                                    placeholder="เลือกสาขา"
                                    // style={{ width: 470 }}
                                    size='large'
                                >
                                    {optionsBranch.map((values) => (
                                    <Option key={values.id} value={values.id} props={values}>
                                        {values.branchCode}
                                        {values.branchName}
                                    </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "60%"}}>
                            <Form.Item
                                label="วันที่รับชำระ"
                                name="paymentDate"
                                key="paymentDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "10%"}}>

                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "30%"}}>
                            <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleContractNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "25%"}}>
                            <Form.Item
                                label="เลขที่บัตรประชาชน"
                                name="idCard"
                                key="idCard"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleIdCardKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "25%"}}>
                            <Form.Item
                                label="ชื่อผู้ขอสินเชื่อ"
                                name="name"
                                key="name"
                            >
                                <Input size="large"
                                    // onKeyDown={handleNameKeyDown}
                                />
                            </Form.Item>
                        </div>
                        <div style={{width: "20%",alignItems:'center'}}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                  columns={columns}
                  dataSource={payments}
                  size="small"
                  rowKey={(item: PaymentForReport) => item.paymentNo}
                  scroll={{ x: true }}
                />
            </Panel>
        </Content>
    </div>
  )
}

export default ListPaymentReport