import { Form, DatePicker, Button, Row, Col, Space, Table } from 'antd';
import React, { useState } from 'react'
import { ContractForReport } from 'src/domain/contract/contractForReportType'

import PageHeader from 'src/layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import Panel from 'src/components/container/Panel';
import contractService from 'src/services/contract/contractService';
import { Excel } from 'antd-table-saveas-excel';

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const ListContractAtDateReport : React.FC<ContractForReport> = () => {
    const [form] = Form.useForm();
    const [contracts, setContracts] = useState<ContractForReport[]>([]);

    const defaultDate = {
        contractDate: [moment().startOf('month'), moment().endOf('month')],
        atDate: moment()
    };

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
        console.log('page property', page, pageSize);
        setOffset((page-1) * pageSize);
    }

    const onFinish = (values: any) => {
        const _contractDate = values.contractDate;
        const _atDate = values.atDate;

        (async () => {
            const res = await contractService.searchForAtDateReport(
            _contractDate ? _contractDate[0].format('YYYYMMDD') : "" ,
            _contractDate ? _contractDate[1].format('YYYYMMDD') : "" ,
            _atDate ? _atDate.format('YYYYMMDD') : ""
            );
            setContracts(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            contractDate: [moment().startOf('month'), moment().endOf('month')],
            atDate: moment(),
        });

        setContracts([] as ContractForReport[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columnsToExcel)
            .addDataSource(contracts)
            .saveAs("contract_report.xlsx");
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: ContractForReport, index: number) => (
              offset + index+ 1
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
        },
        {
            title: "วันที่สัญญา",
            dataIndex: "contractDate",
        },
        {
            title: "ชื่อ-นามสกุล",
            dataIndex: "name",
        },
        {
            title: "วงเงินกู้",
            dataIndex: "loanAmt",
            align: "right" as "right",
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "จำนวนงวด",
            dataIndex: "loanInstallment",
            align: "right" as "right",
        },
        {
            title: "จ่ายต่องวด",
            dataIndex: "installment",
            align: "right" as "right",
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "สถานะสัญญา",
            dataIndex: "contractStatusName",
        },
    ];

    const columnsToExcel = [
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
        },
        {
            title: "วันที่สัญญา",
            dataIndex: "contractDate",
        },
        {
            title: "ชื่อ-นามสกุล",
            dataIndex: "name",
        },
        {
            title: "วงเงินกู้",
            dataIndex: "loanAmt",
            align: "right" as "right",
        },
        {
            title: "จำนวนงวด",
            dataIndex: "loanInstallment",
            align: "right" as "right",
        },
        {
            title: "จ่ายต่องวด",
            dataIndex: "installment",
            align: "right" as "right",
        },
        {
            title: "รวมเงินต้น",
            dataIndex: "sumPrincPepaid",
            align: "right" as "right",
        },
        {
            title: "รวมดอกเบี้ย",
            dataIndex: "sumInterestRevenues",
            align: "right" as "right",
        },
        {
            title: "จำนวนงวดที่ชำระแล้ว ณ วันที่",
            dataIndex: "installmentsPaid",
            align: "right" as "right",
        },
        {
            title: "เงินต้นที่ชำระแล้ว ณ วันที่",
            dataIndex: "principalPaid",
            align: "right" as "right",
        },
        {
            title: "รวมดอกเบี้ยที่ชำระแล้ว ณ วันที่",
            dataIndex: "interestPaid",
            align: "right" as "right",
        },
        {
            title: "เงินต้นคงค้าง ณ วันที่",
            dataIndex: "outstandingPrincipal",
            align: "right" as "right",
        },
        {
            title: "รวมดอกเบี้ยคงค้าง ณ วันที่",
            dataIndex: "accruedInterest",
            align: "right" as "right",
        },
        {
            title: "Due date ณ วันที่",
            dataIndex: "endDueDate",
        },
        {
            title: "วันที่รับชำระล่าสุด ณ วันที่",
            dataIndex: "paymentDate",
        },
        {
            title: "สถานะสัญญา ปัจจุบัน",
            dataIndex: "contractStatusName",
        },
    ];

  return (
    <div>
        <PageHeader title="รายงานข้อมูลสัญญา ณ วันที่" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                    form={form}
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={defaultDate}
                >
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                label="วันที่สัญญา"
                                name="contractDate"
                                key="contractDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="ข้อมูล ณ วันที่"
                                name="atDate"
                                key="atDate"
                                rules={[
                                    {
                                        type: 'object',
                                        required: true,
                                        message: 'กรุณาระบุ ณ วันที่!',
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <DatePicker size="large"
                                    locale={th_loc}
                                    format={dateFormat}
                                    style={{width:250}}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Table
                  columns={columns}
                  dataSource={contracts}
                  size="small"
                  rowKey={(item: ContractForReport) => item.contractNo}
                  pagination={{
                    onChange: handlePageChange,
                    pageSize: 20,
                  }}
                />
            </Panel>
        </Content>
    </div>
  )
}

export default ListContractAtDateReport