import { DepositDetail } from "src/domain/payment/depositDetailType";
import { PaymentDetail } from "src/domain/payment/paymentDetailType";
import depositService from "src/services/payment/depositService";
import paymentService from "src/services/payment/paymentService";
import type { AppThunk } from "../index";
import {
  getPayment as getPaymentAction,
} from "./slice";

export const getPayment =
  (paymentNo: string): AppThunk<void> =>
  async (dispatch): Promise<void> => {
    paymentService.getPaymentByNo(paymentNo).then((res) => {
      const _payment = res.data;
      const _paymentDetails = [];
      let itemDeposit = {} as PaymentDetail;
      let depositDetails: DepositDetail[] = [];

      if (_payment) {
        for (let x = 0; x < _payment.paymentDetails.length; x++) {
          const _detail = _payment.paymentDetails[x];
          if (_detail.paymentItemId === 48) itemDeposit = _detail;
          else _paymentDetails.push(_detail);
        }

        depositService
          .getByPaymentNoAndType(_payment.paymentNo, "1")
          .then((res) => {
            depositDetails = res.data;
          });
      }

      dispatch(
        getPaymentAction({
          payment: _payment,
          paymentDetails: _paymentDetails,
          itemDeposit: itemDeposit,
          depositDetails: depositDetails,
        })
      );
    });
  };