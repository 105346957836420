import React, { CSSProperties, FC } from 'react'

interface IProps {
    type: 'row' | 'column',
    alignItems?: 'center' | 'baseline' | 'end' | 'flex-end' | 'flex-start';
    justifyContent?: 'center' | 'space-between' | 'end' | 'flex-end' | 'flex-start' | 'space-evenly' | 'space-around';
    gap?: number;
    style?: CSSProperties;
    children: any;
    showBorder?: boolean;
    full?: boolean;
    showShadow?: boolean;
    className?: string;
}

const DFlex:FC<IProps> = ({type, children, className, style, showBorder, showShadow, full, ...props}:any) => {

  const border = showBorder ? { border: "1px solid red"} : null;
  const width = full ? { width: '100%' } : null;
  const shadow = showShadow ? { boxShadow: '0 0 1px #aaa'} : null;
  const clazzName = className ? { className } : null;

  return (
    <div
      className={clazzName ? className : null}
      style={{ display: 'flex', flexDirection: type,   ...props,  ...shadow, ...border, ...width,...style }}>
        {children}
    </div>
  )
}

export default DFlex