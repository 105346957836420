import { message } from 'antd';

const successMessage = (msg: string, duration:number = 2) => {
    message.success({
        content: msg,
        className: 'msgSuccess',
        duration: duration
    })
}

const errorMessage = (msg: string, duration:number = 2) => {
    message.error({
        content: msg,
        className: 'msgError',
        duration: duration
    })
}

export default {
    successMessage,
    errorMessage
}

