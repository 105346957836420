import { AutoComplete, Checkbox, Form, Input, Modal, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import locationService from 'src/services/master/locationService';
import { Location } from "src/domain/master/locationType";
import { Payee } from 'src/domain/account/payeeType';
import payeeService from 'src/services/account/payeeService';

const { Option } = Select;

interface IProps {
    modalVisible: boolean;
    onOkNewPayee: (value: Payee) => void;
    onCancel: () => void;
}

const NewPayeePopup: React.FC<IProps> = (props) => {
    const { modalVisible, onOkNewPayee, onCancel } = props;
    const [form] = Form.useForm();

    const [payee, setPayee] = useState<Payee>({} as Payee);
    const [optionsLocation, setOptionsLocation] = useState<Location[]>([] as Location[]);
    const [selectLocation, setSelectLocation] = useState<Location>();

    const [isPersonal, setIsPersonal] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
          const res = await locationService.getLocationAll();
          setOptionsLocation(res.data);
        })();
    }, []);

    useEffect(() => {
        if(payee.isFinish) {
            payeeService.addPayee(payee).then((res) => {
                console.log("save=", res.status)
                if (res.status === 200) {
                    onOkNewPayee(res.data);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }, [payee])

    const onSelectLocation = (values: any, key: any) => {
        setSelectLocation(key.props);
    };

    const onChangeIsPersonal = (event: any) => {
        console.log("value=", event.target.checked)
        setIsPersonal(event.target.checked)
    }

    const onFinish = () => {
        console.log("finish");
        const _payee: Payee = {...payee,
            id: 0,
            name : form.getFieldValue("name"),
            taxNo : form.getFieldValue("tax_no") ?? '',
            isPersonal : isPersonal ? 0 : 1,
            address : form.getFieldValue("address"),
            moo : form.getFieldValue("moo"),
            soi : form.getFieldValue("soi"),
            road : form.getFieldValue("road"),
            tambonCode : selectLocation?.tambonCode ?? '',
            tambonName : selectLocation?.tambon ?? '',
            amphurCode : selectLocation?.amphurCode ?? '',
            amphurName : selectLocation?.amphur ?? '',
            provinceCode : selectLocation?.provinceCode ?? '',
            provinceName : selectLocation?.province ?? '',
            zipcode : selectLocation?.zipcode ?? '',
            tel : form.getFieldValue("tel")?? '',
            useFlg : 0,
            isFinish: true,
        }

        onOkNewPayee(_payee);
    }


    return (
        <Modal
            title="New Payee"
            style={{ top: 70 }}
            visible={modalVisible}
            // onOk={handleOK}
            okButtonProps={{form:'newpayee-popup-form',  htmlType: 'submit'}}
            onCancel={() => onCancel()}
        >
            <Form
                form={form}
                layout="vertical"
                style={{ display: "flex", justifyContent: "center" }}
                id='newpayee-popup-form'  onFinish={onFinish}
            >
                <Space size={1} direction="vertical">
        
                    <Form.Item label="" name="is_personal">
                        <Checkbox  onChange={onChangeIsPersonal}>นิติบุคคล?</Checkbox>
                    </Form.Item>

                    <Form.Item label="ชื่อ-นามสกุล" name="name"
                        rules={[
                            { required: true, message: "กรุณาระบุชื่อ-นามสกุล!" },
                            {
                              message: "Please input your name",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Space size={10}>
                        <Form.Item label="เลขที่ผู้เสียภาษี" name="tax_no"
                            rules={[
                                { required: true, message: "กรุณาระบุเลขที่ผู้เสียภาษี!" },
                                {
                                  message: "Please input your name",
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="เบอร์โทร" name="tel">
                            <Input placeholder="" />
                        </Form.Item>
                    </Space>

                    <Space size={10}>
                        <Form.Item label="บ้านเลขที่" name="address">
                            <Input placeholder="บ้านเลขที่"/>
                        </Form.Item>
                        <Form.Item label="หมู่" name="moo">
                            <Input placeholder="หมู่" />
                        </Form.Item>
                    </Space>

                    <Space size={10}>
                        <Form.Item label="ซอย" name="soi">
                            <Input placeholder="ซอย"/>
                        </Form.Item>
                        <Form.Item label="ถนน" name="road">
                            <Input placeholder="ถนน" />
                        </Form.Item>
                    </Space>

                    <Form.Item
                        label="ที่อยู่"
                        name="addrPath"
                        rules={[
                        {
                        required: true,
                        message: "กรุณาระบุชื่อตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์!",
                        },
                        {
                        message: "Please input your address name",
                        },
                    ]}
                    >
                        <Select
                            placeholder="ตำบล/อำเภอ/จังหวัด/รหัสไปรษณีย์"
                            // style={{ width: "95%" }}
                            onSelect={onSelectLocation}
                            value={selectLocation?.addrPath}
                            // defaultValue={selectCompany}
                            filterOption={(input, option) => {
                                return (
                                    option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            showSearch
                        >
                            {optionsLocation.map((opt_value) => (
                                <Option 
                                    title={opt_value.addrPath} 
                                    key={opt_value.id} 
                                    value={opt_value.addrPath}
                                    props={opt_value}
                                >
                                    {opt_value.addrPath}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};

export default NewPayeePopup;
