import React from "react";
import { CloseCircleTwoTone, CheckCircleTwoTone, LockTwoTone, UnlockTwoTone } from "@ant-design/icons";


interface IProps {
  useFlg: boolean;
  style?: React.CSSProperties;
}

const UseFlgIcon: React.FC<IProps> = ({
  useFlg = false,
  children,
  style,
}) => {
  return (
    useFlg ? <UnlockTwoTone style={{fontSize: 20, ...style}} twoToneColor="#52c41a"/> : <LockTwoTone style={{fontSize: 20, ...style}} twoToneColor="#DF0101"/>
  );
};

export default UseFlgIcon;
