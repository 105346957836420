import http from "../../lib/http";
import configs from "src/constants/config";
// const url = "http://localhost:8080/bookType";
const url = `${configs.urlRoot}/bookType`;

const getBookTypeAll = () => {
    return http.get(url);
}

const getBookTypeById = (id: number) => {
    return http.get(url+"/"+id);
}

export default {
    getBookTypeAll,
    getBookTypeById
}