import http from "../../lib/http";
import configs  from 'src/constants/config';

// const url = "http://localhost:8080/book";
const url = `${configs.urlRoot}/book`;

const getBooks = () => {
    return http.get(url);
};

const getBookById = (id: number) => {
    return http.get(url+"/"+id);
}

const getBookItemsByBookId = (bookId: number) => {
    return http.get(url+"/bookItems?bookId="+bookId);
}

const getBookByCodeOrRefCode = (code: string) => {
    return http.get(url+"/byCodeOrRef?code="+code);
}

const getBookbyCondition = (code: string, companyId: number, payee_name: string, create_name: string, date: string,
    date_type: string, book_type: number, book_code: number) => {
    return http.get(url+"/byCondition?code="+code+"&company_id="+companyId+"&payee_name="+payee_name+
        "&create_username="+create_name+"&date="+date+"&date_type="+date_type+
        "&book_type_id="+book_type+"&book_code_id="+book_code);
}

const addBook = (book : any) => {
    return http.post(url, book);
}

const editBook = (book : any) => {
    return http.put(url+"/"+book.id, book);
}

export default {
    getBooks,
    getBookById,
    getBookItemsByBookId,
    getBookByCodeOrRefCode,
    getBookbyCondition,
    addBook,
    editBook,
}