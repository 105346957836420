import React, { useEffect, useState } from 'react'
import {Button, Form, Input, message, Radio, Space, Table} from 'antd'
import { SearchOutlined, RightCircleOutlined, LogoutOutlined, RetweetOutlined } from '@ant-design/icons';
import { Contract } from 'src/domain/contract/contractType';
import contractService from 'src/services/contract/contractService';

import moment from 'moment';
import 'moment/locale/th';
import DFlex from '../../components/container/DFlex';

const dateFormat = "DD/MM/YYYY";

interface IProps {
    next: () => void;
    updateSelectContract: any;
    updateSelectPaymentType: any;
}

const FindContract : React.FC<IProps> = (props) => {
    const {next, updateSelectContract, updateSelectPaymentType} = props;
    const [form] = Form.useForm();
    const [contracts, setContrancts] = useState<Contract[]>([]);
    const [selectContId, setSelectContId] = useState<number>(0);
    const [paymentType, setPaymentType] = useState<string>();

    useEffect(() => {},[contracts]);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            findByParam();
        }
    }

    const findByParam = () => {
        const _contractNo = form.getFieldValue("contractNo")
        const _idCard = form.getFieldValue("idCard")

        if(_contractNo || _idCard) {
            contractService.getByParam(
                _contractNo === undefined ? "" : _contractNo,
                _idCard === undefined ? "" : _idCard).then(res => {
                if(res.status === 200) setContrancts(res.data);
            })
        } else {
            message.error('กรุณาระบุ เลขที่สัญญา หรือเลขที่บัตร อย่างใดอย่างหนึ่ง ก่อนกดค้นหา!!');
        }
    }

    const onChangeContract = (values: any) => {

    }

    const onFinish = (values: any) => {
        if(selectContId === 0) {
            message.error('กรุณาเลือกรายการที่ต้องการชำระเงิน ก่อนกด Next!!');
        } else {
            updateSelectContract(selectContId);
            updateSelectPaymentType(paymentType);
            next();
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> FindContract:", errorInfo);
    }

    const columns = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: Contract, index: number) => (
                index+1
            ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
            key: "contractNo",
        },
        {
            title: "เลขที่ใบคำขอสินเชื่อ",
            dataIndex: "applicationNo",
            key: "applicationNo",
        },
        {
            title: "ชื่อ-สกุล",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "วันที่สัญญา",
            dataIndex: "contractDate",
            key: "contractDate",
            render: (value: string) => value ? moment(value).format(dateFormat) : "",
        },
    ];

    return (
        <div>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                    <DFlex type="row" gap={20}>
                        <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                        >
                                <Input size='large' showCount maxLength={20} onKeyDown={handleKeyDown} />
                        </Form.Item>

                        <Form.Item
                                label="เลขที่บัตรประชาชน"
                                name="idCard"
                                key="idCard"
                        >
                                <Input size='large' showCount maxLength={13} onKeyDown={handleKeyDown} />
                        </Form.Item>
                    </DFlex>

                    <Form.Item
                        label=" ">
                        <Button
                                icon={<SearchOutlined/>}
                                size="large"
                                onClick={findByParam}>
                                ค้นหา
                        </Button>
                    </Form.Item>
                </div>

                <div style={{ display: "flex", flexDirection: "row", width:"100%", marginLeft:"45%"}}>

                </div>
                <Table
                    size='small'
                    dataSource={contracts}
                    columns={columns}

                    rowSelection={{
                        type: 'radio',
                        ...onChangeContract
                    }}
                    rowKey={(item: Contract) => item.id}
                    onChange={onChangeContract}
                    onRow={(record, rowIndex) => {
                            return {
                                onChange: event => {
                                    setSelectContId(record.id)
                                },
                            };
                        }}
                />

                <DFlex type="row" gap={20} justifyContent='flex-end' full style={{ marginTop: '20px'}}>
                <Form.Item>
                        <Button
                            size="large"
                            name="refinance"
                            value="refinance"
                            // type="primary" 
                            danger
                            icon={<RetweetOutlined />}
                            onClick={() => {
                                setPaymentType("refinance");
                                form.submit();
                            }}
                        >
                            ปิดสัญญา (ปรับโครงสร้างหนี้)
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            size="large"
                            name="close"
                            value="close"
                            type="primary" 
                            danger
                            icon={<LogoutOutlined />}
                            onClick={() => {
                                setPaymentType("close");
                                form.submit();
                            }}
                        >
                            ปิดสัญญา
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className='outline'
                            size="large"
                            name="next"
                            value="normal"
                            icon={<RightCircleOutlined />}
                            onClick={() => {
                                setPaymentType("normal");
                                form.submit();
                            }}
                        >
                            รับชำระ
                        </Button>
                    </Form.Item>
                </DFlex>
            </Form>
        </div>
    )
}

export default FindContract
