import React from 'react'
import { RouteComponentProps } from 'react-router'
import CampaignForm from './CampaignForm'

interface IParams {
    id: string;
}

const EditCampaign : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);

    return (
        <div>
            <CampaignForm id={_id} mode='EDIT' />
        </div>
    )
}

export default EditCampaign
