import React, { useEffect, useState } from 'react'
import { Button, Col, Descriptions, Modal, Popconfirm, Row, Space, Steps, Tabs, Typography, Form, Input } from 'antd'
import { RouteComponentProps, useHistory } from 'react-router';
import { FormOutlined, SendOutlined, LikeOutlined, DislikeOutlined , FileDoneOutlined, DeleteOutlined, CheckSquareOutlined, RollbackOutlined, CheckOutlined} from "@ant-design/icons";
import PageHeader from 'src/layouts/PageHeader'
import applicationService from "src/services/application/applicationService"
import { Application } from 'src/domain/application/applicationType';
import ViewPersonalApplication from './ViewPersonalApplication';
import { Content } from 'antd/lib/layout/layout';
import { Address } from 'src/domain/application/addressType';
import ViewOccupationApplication from './ViewOccupationApplication';
import ViewIncomeApplication from './ViewIncomeApplication';
import ViewLoanApplication from './ViewLoanApplication';
import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import classes from "./ListApplication.module.css";
import ViewApplicationMovement from './ViewApplicationMovement';
import RemarkModel from './RemarkModel';
import ViewApplicationResouce from './ViewApplicationResouce';
import { useAppSelector } from "src/store";
import loggingService from '../../services/security/loggingService';
import contractService from '../../services/contract/contractService';
import { message } from 'antd';
import messageUtils from '../../utils/messageUtils';
import DFlex from '../../components/container/DFlex';
import ViewGuarantorApplication from './ViewGuarantorApplication';

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;
const { Step } = Steps;
const dateFormat = 'DD/MM/YYYY';

interface IParams {
    id: string;
}

const ViewApplication : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _applicationId = Number(props.match.params.id);
    const [application, setApplication] = useState<Application>({} as Application);
    const [addressCurrent, setAddressCurrent] = useState<Address>({} as Address);
    const [addressOffice, setAddressOffice] = useState<Address>({} as Address);
    const [currentState, setCurrentState] = useState<number>(0);
    // const [_appId, _setAppId] = useState<number>(0);
    const history = useHistory();
    const { sessionUser } = useAppSelector((state) => state.auth);

    const [appStatus, setAppStatus] = useState<string>("");
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [rolePass, setRolePass] = useState<boolean>(false);
    const [roleApprove, setRoleApprove] = useState<boolean>(false);

    const showModal = (status: string) => {
        setAppStatus(status);
        setModalVisible(true);
    };

    const handleOK = (value: string) => {
        if(appStatus === 'submitApprove')
            onClickSubmitApproval(value);
        if(appStatus === 'approveCredit')
            onClickApproveCredit(value);
        else if(appStatus === 'disapproval')
            onClickDisapproval(value);
        else if(appStatus === 'sendDoc')
            onClickSendDoc(value);
        else if(appStatus === 'docPass')
            onClickDocPass(value);
        else if(appStatus === 'docReject')
            onClickDocReject(value);
      };

    const handleCancel = () => {
        setAppStatus("");
        setModalVisible(false);
    };

    useEffect(() => {
        // console.log("roles",sessionUser.roles);
        let id: number = Number(props.match.params.id);
        applicationService.byIdAndSecRole(id, sessionUser.userId).then(res=>{
        // applicationService.getApplicationById(id).then(res=>{
            // console.log(res.data);
            const _app = res.data;
            setApplication(_app);
            // _setAppId(_app.id);
            if(_app.applicationStatusId) {
                if(_app.applicationStatusCode === '00'
                    || _app.applicationStatusCode === '04') setCurrentState(0);
                else if(_app.applicationStatusCode === '03') setCurrentState(1);
                else if(_app.applicationStatusCode === '01') setCurrentState(2);
                // else if(_app.applicationStatusCode === '02') setCurrentState(2);
                else if(_app.applicationStatusCode === '99') setCurrentState(3);
                // else if(_app.applicationStatusCode === 'CC') setCurrentState(4);
            }

            for(let x=0; x<_app.addresses.length; x++) {
                const _addr = _app.addresses[x];
                if(_addr.addressType === "CURRENT") setAddressCurrent(_addr);
                else setAddressOffice(_addr);
            }

            setRoleApprove(_app.approve);
            setRolePass(_app.pass)

            loggingService.logging({
				event: "VIEW",
				target: "application",
				targetRef: _app.applicationNo,
				eventUserId: sessionUser.userId,
				eventUsername: sessionUser.username,
			});
        });
    }, []);

    useEffect(() => {}, [rolePass, roleApprove]);

    const onClickSendDoc = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "03", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'send-documents-to-verify, status:03',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '03' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickDocPass = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "01", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'documents-pass, status:01',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '01' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickDocReject = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "04", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'documents-reject, status:04',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '04' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickSubmitApproval = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "01", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'send-to-approve, status:01',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '01' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickApproveCredit = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "99", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                // log application update status 'APPROVE'
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'APPROVE',
                    systemRemark: 'approve, status:99',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '99' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });

                contractService.getContractByApplicationId(application.id!).then((res) => {
                    if (res.status === 200) {
                        const contactNo = res.data.body.contractNo;

                        // log contract
                        loggingService.logging({
                            event: 'CREATE',
                            systemRemark: 'create contract',
                            eventUserId: sessionUser.userId,
                            eventUsername: sessionUser.username,
                            target: 'contract',
                            targetRef: contactNo
                        });
                    } else {
                        message.error(res.data.message);
                    }
                });

                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickDisapproval = (e: string) => {
        applicationService.updateApplicationStatus(_applicationId, "02", e, sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Save success !!!")

                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'dis-approve, status:02',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: '02' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });
                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err))
        });
    }

    const onClickDelete = (e: any) => {
        // const _appId = application.id ? application.id : 0;
        // console.log("_appId", _appId)
        applicationService.updateApplicationStatus(_applicationId, "CC", "ลบ application", sessionUser.userId, sessionUser.username, sessionUser.branch).then((res) => {
            if (res.status === 200) {
                messageUtils.successMessage("Delete success !!!")

                loggingService.logging({
                    event: 'DELETE',
                    systemRemark: 'delete-approve, status:CC',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    eventPayload: JSON.stringify({ applicationNo: application.applicationNo, status: 'CC' }),
                    target: 'application',
                    targetRef: application.applicationNo
                });

                history.goBack();
            }
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err));
        });
    }

    const cancel = () => {

    }

    return (
        <>
            <PageHeader title={'APP NO. '+application.applicationNo} onClose={() => history.push("/app/application")}>
                <Space>
                    {/* {application.applicationStatusCode === '00' ?
                        <Button
                            size="large"
                            icon={<SendOutlined />}
                            // onClick={onClickSubmitApproval}
                            onClick={() => showModal("submitApprove")}
                            >
                            ส่งอนุมัติ
                        </Button>
                    :""} */}
                    {(application.applicationStatusCode === '00' ||
                    application.applicationStatusCode === '04') ?
                        <Button
                            size="large"
                            icon={<SendOutlined />}
                            onClick={() => showModal("sendDoc")}
                            >
                            ส่งเอกสาร
                        </Button>
                    :""}
                    {application.applicationStatusCode === '03' && rolePass &&
                    <Space>
                        <Button
                            size="large"
                            icon={<CheckOutlined />}
                            onClick={() => showModal("docPass")}
                            >
                            ผ่าน
                        </Button>
                        <Button
                            size="large"
                            icon={<RollbackOutlined />}
                            onClick={() => showModal("docReject")}
                            >
                            ไม่ผ่าน
                        </Button>
                    </Space>
                    }
                    {application.applicationStatusCode === '01' && roleApprove ?
                    <Button
                        size="large"
                        icon={<LikeOutlined />}
                        type="primary"
                        // onClick={onClickApproveCredit}
                        onClick={() => showModal("approveCredit")}
                        >
                        อนุมัติ
                    </Button>
                    :""}
                    {application.applicationStatusCode === '01' && roleApprove ?
                    <Button
                        size="large"
                        type="primary"
                        danger
                        icon={<DislikeOutlined />}
                        // onClick={onClickDisapproval}
                        onClick={() => showModal("disapproval")}
                        >
                        ไม่อนุมัติ
                    </Button>
                    :""}
                    {(application.applicationStatusCode === '00' || application.applicationStatusCode === '04') ?
                    <Button
                        size="large"
                        icon={<FormOutlined />}
                        onClick={() => history.push("/app/application/edit/" + _applicationId)}
                        >
                        แก้ไข
                    </Button>
                    :""}
                    {(application.applicationStatusCode === '00' ||
                        application.applicationStatusCode === '04') ?
                    <Popconfirm
                        title="ต้องการลบใบคำขอสินเชื่อใช่หรือไม่?"
                        onConfirm={onClickDelete}
                        onCancel={cancel}
                        okText="ใช่"
                        cancelText="ไม่"
                    >
                    <Button
                        size="large"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                        // onClick={onClickDelete}
                        >
                        ลบ
                    </Button>
                    </Popconfirm>
                   :""}
                    {/* <Button
                        size="small"
                        icon={<CloseSquareOutlined />}
                        onClick={() => history.goBack()}
                        >
                        Cancel
                </Button> */}
                </Space>
            </PageHeader>
            <Content className="app-page-content">

							<DFlex type="row" justifyContent='space-between' alignItems='flex-start' style={{ marginBottom: '20px'}}>
								<div style={{ flex: 1, maxWidth: '600px'}}>
									{(application.applicationStatusCode !== '02' && application.applicationStatusCode !== 'CC') ?
											<Steps current={currentState} >
												<Step title="สร้าง" icon={<FormOutlined />} />
												<Step title="รอตรวจสอบ" icon={<FileDoneOutlined />} />
												<Step title="รออนุมัติ" icon={<SendOutlined />} />
												<Step title="อนุมัติ" icon={<LikeOutlined  />} />
												</Steps>
											:
											<label style={{fontWeight: "bold", fontSize:"medium", color:"red"}}>
												สถานะ : {application.applicationStatusName}
											</label>
									}
								</div>
								<div style={{ width: '300px'}}>
									<Descriptions layout="horizontal" column={1} style={{marginLeft:30}}>
													{application.contractId ?
															<Descriptions.Item
																	label="เลขที่สัญญา "
																	labelStyle={{ width: '100px',display: 'flex', justifyContent:'right', textAlign: "right", fontWeight: 600, fontSize:"medium"}}
																	contentStyle={{ display: 'flex', justifyContent:'right'}}
															>
																<label style={{fontWeight: 600, fontSize:"medium"}}>{application.contractNo}</label>
															</Descriptions.Item>
													:""}

													<Descriptions.Item
															label="วันที่ "
															labelStyle={{ width: '100px',display: 'flex', justifyContent:'right', textAlign: "right", fontWeight: 600, fontSize:"medium"}}
															contentStyle={{ display: 'flex', justifyContent:'right'}}
													>
															<label style={{fontWeight: 600, fontSize:"medium"}}>{moment(application.applicationDate).format(dateFormat)}</label>
													</Descriptions.Item>

									</Descriptions>
								</div>
							</DFlex>

                {/* <Descriptions
                    size="middle"
                    layout='vertical'
                >
                    <Descriptions.Item span={2}>
                        {(application.applicationStatusCode !== '02' && application.applicationStatusCode !== 'CC') ?
                            <Steps current={currentState} >
                                <Step title="สร้าง" icon={<FormOutlined />} />
                                <Step title="รอตรวจสอบ" icon={<FileDoneOutlined />} />
                                <Step title="รออนุมัติ" icon={<SendOutlined />} />
                                <Step title="อนุมัติ" icon={<LikeOutlined  />} />
                            </Steps>
                        :
                            <label style={{fontWeight: "bold", fontSize:"medium", color:"red"}}>
                                สถานะ : {application.applicationStatusName}
                            </label>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item
                        style={{marginLeft:50}}
                        label=""
                    >
                        <Descriptions layout="horizontal" column={1} style={{marginLeft:30}}>
                            <Descriptions.Item
                                label="วันที่ "
                                labelStyle={{ textAlign: "right", fontWeight: "bold", fontSize:"medium"}}
                            >
                                <label style={{fontWeight: "bold", fontSize:"medium"}}>{moment(application.applicationDate).format(dateFormat)}</label>
                            </Descriptions.Item>
                            {application.contractId ?
                                <Descriptions.Item
                                    label="เลขที่สัญญา "
                                    labelStyle={{ textAlign: "right", fontWeight: "bold", fontSize:"medium"}}
                                >
                                <label style={{fontWeight: "bold", fontSize:"medium"}}>{application.contractNo}</label>
                                </Descriptions.Item>
                            :""}
                        </Descriptions>
                    </Descriptions.Item>
                </Descriptions> */}

                {/* <Row>
                    <Col offset={17} style={{ marginTop:6, fontWeight: "bold" }}>
                        <label style={{fontWeight: "bold", fontSize:"medium"}}>วันที่ : {moment(application.applicationDate).format(dateFormat)}</label>
                    </Col>
                    {application.contractId ?
                        <Col offset={17} style={{ marginTop:6, fontWeight: "bold" }}>
                            <label style={{fontWeight: "bold", fontSize:"medium"}}>เลขที่สัญญา : {application.contractNo}</label>
                        </Col>
                    :""
                    }
                    <Col span={15} style={{ marginTop:16 }}>
                        {(application.applicationStatusCode !== '02' && application.applicationStatusCode !== 'CC') ?
                        <Steps current={currentState} >
                            <Step title="สร้าง" icon={<FormOutlined />} />
                            <Step title="รออนุมัติ" icon={<SendOutlined />} />
                            <Step title="อนุมัติ" icon={<LikeOutlined  />} />
                            // <Step title="ไม่อนุมัติ" icon={<DislikeOutlined   />} />
                            // <Step title="ยกเลิก" icon={<CloseSquareOutlined  />} />
                        </Steps>
                        :
                        <Typography>
                            <Title level={3}>สถานะ : {application.applicationStatusName}</Title>
                        </Typography>
                        }
                    </Col>
                </Row> */}

                <Tabs defaultActiveKey="1">
                    <TabPane tab="ข้อมูลส่วนตัว" key="1" >
                        <div className={classes.dataPanel}>
                            <ViewPersonalApplication application={application} addressCurrent={addressCurrent}/>
                        </div>
                    </TabPane>
                    <TabPane tab="อาชีพ/สถานที่ทำงาน" key="2">
                        <div className={classes.dataPanel}>
                            <ViewOccupationApplication addressOffice={addressOffice}/>
                        </div>
                    </TabPane>
                    <TabPane tab="รายได้" key="3">
                        <div className={classes.dataPanel}>
                            <ViewIncomeApplication application={application}/>
                        </div>
                    </TabPane>
                    <TabPane tab="วงเงินกู้" key="4">
                        <div className={classes.dataPanel}>
                            <ViewLoanApplication application={application}/>
                        </div>
                    </TabPane>

                    <TabPane tab="ผู้ค้ำ" key="5">
                        <div className={classes.dataPanel}>
                            <ViewGuarantorApplication application={application}/>
                        </div>
                    </TabPane>

                    <TabPane tab="ไฟล์แนบ" key="6">
                        <div className={classes.dataPanel}>
                            <ViewApplicationResouce id={_applicationId} />
                        </div>
                    </TabPane>


                    {/* <TabPane tab="ไฟล์แนบ" key="6">
                        <div className={classes.dataPanel}>
                            <ViewApplicationResouce id={_applicationId} />
                        </div>
                    </TabPane> */}
                    <TabPane tab="ความเคลื่อนไหว" key="7">
                        <div className={classes.dataPanel}>
                            <ViewApplicationMovement id={_applicationId} />
                        </div>
                    </TabPane>
                </Tabs>
            </Content>

            <RemarkModel
                modalVisible={modalVisible}
                onOK={handleOK}
                onCancel={() => handleCancel()}
            />
        </>
    )
}

export default ViewApplication
