import { Button, Upload, Steps, Table, Image } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {CloseCircleOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel';
import contractService from 'src/services/contract/contractService';
import { Contract } from 'src/domain/contract/contractType';
import { Application } from 'src/domain/application/applicationType';
import { Address } from 'src/domain/application/addressType';
import { ContractDebit } from 'src/domain/contract/contractDebitType';
import ResourceFileResponse from 'src/models/ResourceFileResponse';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import FileUpload from 'src/components/input/FileUpload';
import axios from 'axios';
import { UploadFile } from 'antd/lib/upload/interface';
import { Document, Page } from 'react-pdf';
import Configs from 'src/constants/config';


const Step = Steps.Step;

const ManageReport = () => {
    const history = useHistory();

    const [currentState, setCurrentState] = useState<number>(0);
    const [fileList, setFileList] = useState<Array<any>>([]);

    const [fileUpload, setFileUpload] = useState<string>();
    const [progress, setProgress] = useState<number>();
    const [imageRes, setImageRes] = useState<any>();
    const [pageNumber, setPageNumber] = useState<number>();
    const [numPages, setNumPages] = useState<number>();
    const props = {
        // name: "image",
        // data: {resourceFile: new ResourceFile("newFileName", "", "", "REPORT")},
        // // action: (file: RcFile) => {
        // //     console.log("action file", file);
        // //     return 'http://localhost:8080/file/upload'
        // // },
        // customRequest: (options: any) => {
        //     console.log("Custom Request");
        //     const { onSuccess, onError, file, onProgress } = options;
        //     const formData = new FormData();

        //     const httpConfig = {
        //         headers: { "content-type": "multipart/form-data" },
        //         onUploadProgress: (event: any) => {
        //             const percent = Math.floor((event.loaded / event.total) * 100);
        //             setProgress(percent);
        //             if (percent === 100) {
        //                 setTimeout(() => setProgress(0), 1000);
        //             }
        //             onProgress({ percent: (event.loaded / event.total) * 100 });
        //         }
        //     };

        //     formData.append("image", file);
        //     axios.post("http://localhost:8080/file/upload", formData, httpConfig).then((res: any) => {
        //         onSuccess(file);
        //         console.log(res);
        //     }).catch((err: any)=>{
        //         const error = new Error('Some error');
        //         onError({event:error});
        //     });

        // },
        beforeUpload: (file: any) => {
            console.log("beforeUpload file: ", file);
            return checkExtension(file.name) ? true : Upload.LIST_IGNORE;
        },
        // onChange({ file, fileList }: {file: any, fileList: any}) {
        onChange(res: UploadChangeParam) {
            // console.log("onChange: ", res);
            console.log("file.status: ", res.file.status);
            switch (res.file.status) {
                case "uploading":
                    // nextState.selectedFileList = [info.file];
                    break;
                  case "done":
                    // nextState.selectedFile = info.file;
                    // nextState.selectedFileList = [info.file];
                    console.log("res: ", res);
                    setFileList(res.fileList);
                    break;
            
                  default:
                    // error or removed
                    // nextState.selectedFile = null;
                    // nextState.selectedFileList = [];
                    setFileList(res.fileList);
            }

            // if (res.file.status !== 'uploading') {
            //     console.log("file: ", res.file, "fileList: ", fileList);
            //     setFileList(fileList);
            // }
        },
        defaultFileList: [

        ],
    };
    const extall = "jpg ,jpeg ,gif ,png, pdf";

    const uploadAction = () => {
        
    }
    const next = () => {
        setCurrentState((prev) => prev + 1);
    };
    const prev = () => {
        setCurrentState((prev) => prev - 1);
    };
    const changeSteps = (curr: number) => {
        setCurrentState(curr);
    };
    
    const onFileChange = (evt: any) => {
        console.log("onFileChange");
        let isPermission = checkExtension(evt.target.value)
        console.log("checkExtension: ", isPermission);
        if(isPermission){
            setFileUpload(evt.target.value);
        }else{
            setFileUpload("");
        }

    }

    const checkExtension = (filename: string) => {
        if(filename){
            let extList = filename.split('.');
            if(extList){
                let extOri = extList.pop();
                if(extOri){
                    let ext:string = extOri.toLowerCase();
                    if(extall.indexOf(ext) < 0)
                    {
                        alert('Extension support : ' + extall);
                        return false;
                    }
                    return true;
                }
            }
        }
        return false;
    }

    const confirm = () => {

    }
 
    const onUploaded = (uploadStatus:string, resourceFile: ResourceFileResponse, file: UploadFile) => {
        if(uploadStatus == 'success'){
            console.log("success resourceFile: ", resourceFile);
        }else{
            console.log("error err: ", resourceFile);
        }
    }

    const onDocumentLoadSuccess = ({ numPages } : { numPages: number }) => {
        console.log("onDocumentLoadSuccess: ", numPages);
        setNumPages(numPages);
    }

    const onRemoved = (file: {name: string, status: string, uid: number, url: string}) => {
        
    }

    const steps = [
        {
            title: "อัพโหลดเทมเพลต",
            // content: <FindContract next={next} updateSelectContract={updateSelectContract} />,
            content: 
                <div>
                    <FileUpload onUploaded={onUploaded} onRemoved={onRemoved} acceptExtension={extall}/>
                    {
                        fileList.length < 1 &&
                        <span style={{color: 'red'}}>เฉพาะไฟล์นามสกุล: {extall}</span>
                    }
                    <div>
                        <Button onClick={next}>Next</Button>
                    </div>
                </div>
        },
        {
            title: "รายละเอียด",
            // content: <ContractDetail next={next} application={application} address={address} contractDebit={contractDebit} />,
            content: 
            <div>
                <div>ชื่อรายงาน</div>
                <div>รายละเอียด</div>
                <div>
                    <Button onClick={prev}>Back</Button>&nbsp;
                    <Button onClick={next}>Next</Button>
                </div>
            </div>
        },
        {
            title: "Preview",
            // content: <PaymentDetail />,
            content: 
                <div>
                    <div>
                        <Document
                            file={`${Configs.urlRoot}/file/getResource/51`}
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p>Page {pageNumber} of {numPages}</p>
                    </div>
                    <div>
                        <Button onClick={prev}>Back</Button>&nbsp;
                        <Button onClick={next}>next</Button>
                    </div>
                </div>
        },
        {
            title: "ยืนยัน",
            // content: <PaymentDetail />,
            content: 
                <div>
                    ยืนยัน

                    <div>
                        <Button onClick={prev}>Back</Button>&nbsp;
                        <Button onClick={confirm}>Confirm</Button>
                    </div>
                </div>
        },
    ];

    return (
        <div>
            <PageHeader title="จัดการข้อมูลรายงาน">
                <Button
                    size="large"
                    icon={<CloseCircleOutlined />}
                    onClick={() => history.goBack()}
                >
                    Cancel
                </Button>
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <Panel style={{ marginLeft: "30px", width: "100%", padding: "40px" }}>
                    <Steps
                        current={currentState}
                        direction="horizontal"
                        onChange={(e) => changeSteps(e)}
                        style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        }}
                    >
                        {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                        ))}
                    </Steps>
                    <div style={{ width: "100%", padding: "40px" }}>
                        {steps[currentState].content}
                    </div>
                </Panel> 
                {/* {currentState > 0 ?
                    <Panel style={{marginLeft:"10px"}}>
                        <div style={{ display: "flex", width: "300px" }}>
                            <ShowContract contract={contract} contractDebit={contractDebit} />                
                        </div>
                    </Panel>
                : ""} */}
            </div>
        </div>
    )
}

export default ManageReport
