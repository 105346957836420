import React, { ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  // setSessionUserStore,
  getSessionUserFromLocal,
} from "src/store/authen/action";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  // const { sessionUser } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSessionUserFromLocal());

    const localSessionUser = localStorage.getItem("sessionUser");

    let user = null;
    if (localSessionUser) {
      user = JSON.parse(localSessionUser);
    }

    console.log("user", user);
    if (!user || !user.username ) {
      history.replace("/login");
    }

    // const username = localStorage.getItem("sessionUser.username");
    // console.log("use effect user : ", username);

    // if (!username) history.replace("/login");


  }, []);

  return <>{children}</>;
};

export default AuthGuard;
