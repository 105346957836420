import { Button, Col, DatePicker, Input, InputNumber, message, Modal, Radio, Row } from 'antd'
import {Form} from 'antd';
import PageHeader from 'src/layouts/PageHeader'
import React, { useEffect, useState } from 'react'
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from 'react-router';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';

import campaignService from 'src/services/master/campaignService';
import { Campaign } from 'src/domain/master/CampaignType';
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import TextArea from 'antd/lib/input/TextArea';
import { useAppSelector } from "src/store";
import { inputDecimalFormatter, inputDecimalPaser } from '../../utils/formatter';
import { ClassDetail } from 'src/domain/master/classDetailType';
import classDetailService from 'src/services/master/classDetailService';

const dateFormat = 'DD/MM/YYYY';

interface IProps {
    id: number;
    mode: string;
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

const CampaignForm: React.FC<IProps> = (props) => {
    const _id = props.id;
    const mode = props.mode;

    const { sessionUser } = useAppSelector((state) => state.auth);
    const history = useHistory();
    const [form] = Form.useForm();
    const [optionsPaymentCal, setOptionsPaymentCal] = useState<ClassDetail[]>(
        [] as ClassDetail[]
      );
    const [selectPaymentCal, setSelectPaymentCal] = useState<number>(80);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);

    const [canSave, setCanSave] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
          const res = await classDetailService.getByClassNo("32");
          setOptionsPaymentCal(res.data);
        })();
    }, []);

    useEffect(() => {
        if (mode === "EDIT") {
            const res = campaignService.getCampaignById(_id).then(res=>{
                setCampaign(res.data);
            });
        }
    }, []);

    useEffect(() => {

    }, [canSave]);

    useEffect(() => {
        form.setFieldsValue({
            campaign_code : campaign.campaignCode,
            campaign_name : campaign.campaignName,
            // start_date : campaign.startDate,
            start_date : campaign.startDate ? moment(campaign.startDate, 'YYYY-MM-DD') : "",
            end_date : campaign.endDate ? moment(campaign.endDate, 'YYYY-MM-DD') : "",
            rateUsageFee : campaign.rateUsageFee,
            rateStampDuty : campaign.rateStampDuty,
            rateAccruedInterest : campaign.rateAccruedInterest,
            paymentCal : campaign.paymentCalId,
            remark : campaign.remark,
        });

        if(campaign.paymentCalId) {
            setSelectPaymentCal(campaign.paymentCalId);
        }
    },[campaign]);

    const campaignCodeKeyUp = (value : any) => {
        const _code = form.getFieldValue("campaign_code");
        const res = campaignService.isExistCode(_code).then(res=>{
            if(res.data) {
                setCanSave(false);
                // message.error('Code "' + _code + '" is used', 2);
                form.setFields([
                    {
                      name: 'campaign_code',
                      errors: ['Code "' + _code + '" is used'],
                    },
                ]);
            } else {
                setCanSave(true);
            }
        });
    }

    const onChangeStartDate  = (value : any) => {
        console.log("start date", value ? value.format('YYYYMMDD') : "")
    }

    const onChangeEndDate  = (value : any) => {
        console.log("end date", value ? value.format('YYYYMMDD') : "")
    }

    const onChangePaymentCal = (values: any) => {
        setSelectPaymentCal(values.target.value);
    };

    const onFinish = async (values: any) => {
        // console.log("values ", values)
        const _campaign: Campaign  = {
            ...campaign,
            id : _id,
            campaignCode : values.campaign_code,
            campaignName : values.campaign_name,
            startDate : values.start_date.format('YYYYMMDD'),
            endDate : values.end_date.format('YYYYMMDD'),
            rateUsageFee : values.rateUsageFee,
            rateStampDuty : values.rateStampDuty,
            rateAccruedInterest : values.rateAccruedInterest,
            paymentCalId : selectPaymentCal,
            remark : values.remark,
            userId : sessionUser.userId,
            userName : sessionUser.username,
            savStation : sessionUser.branch,
        };
        setCampaign(_campaign);

        if (mode === "NEW") {
            await campaignService.addCampaign(_campaign).then(res => {
                // console.log("add id ", res.data.id)
                if(res.status === 200)
                    history.replace("/app/campaign/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        } else if(mode === "EDIT") {
            await campaignService.editCampaign(_campaign).then(res => {
                if(res.status === 200)
                    history.replace("/app/campaign/view/"+res.data.id);
            }).catch(err => {
                console.log(err)
            });
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed ==> NewCampaign:", errorInfo);
        // setTrigger(false);
    };

    return (
        <>
            <PageHeader
                title={mode + " CAMPAIGN"}
                onClose={() => history.goBack()}
            >
                {canSave ?
                    <Button
                        size="large"
                        type="primary"
                        danger
                        icon={<SaveOutlined />}
                        onClick={() => form.submit()}
                    >
                        Save
                    </Button>
                :""
                }
            </PageHeader>
            <Content className="app-page-content">
                <Form
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                >
                    <Panel>
                        <div style={{ margin: "10px 10px" }}>
                    {/* <Row style={{ margin: "10px 10px" }}>
                        <Col span="24"> */}
                            <Form.Item
                                label="CAMPAIGN CODE"
                                name="campaign_code"
                                key="campaign_code"
                                rules={[
                                    { required: true, message: "กรุณาระบุรหัส Campaign!" },
                                    {
                                    message: "Please input campaign code",
                                    },
                                ]}
                            >
                                <Input
                                    size='large' showCount maxLength={5}
                                    onKeyUp={campaignCodeKeyUp}
                                    readOnly={campaign.id ? true : false}
                                    style={{width:"30%"}}
                                />
                            </Form.Item>
                            <Form.Item
                                // {...layout}
                                label="CAMPAIGN"
                                name="campaign_name"
                                key="campaign_name"
                                rules={[
                                    { required: true, message: "กรุณาระบุชื่อ Campaign!" },
                                    {
                                    message: "Please input campaign code",
                                    },
                                ]}
                            >
                                <Input size='large' showCount maxLength={255} style={{width:"90%"}}/>
                            </Form.Item>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                    label="วันที่เริ่มต้น"
                                    name="start_date"
                                    key="start_date"
                                    rules={ [
                                        {
                                        type: 'object',
                                        required: true,
                                        message: 'กรุณาระบุวันที่เริ่มต้น!',
                                        whitespace: true,
                                        },
                                    ]}
                                    >
                                        <DatePicker
                                            locale={th_loc}
                                            onChange={onChangeStartDate}
                                            format={dateFormat}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="อัตราค่าธรรมเนียมการใช้วงเงิน %"
                                        name="rateUsageFee"
                                        key="rateUsageFee"
                                        rules={ [
                                            {
                                                type: 'number',
                                                required: true,
                                                message: 'กรุณาระบุอัตราค่าธรรมเนียมการใช้วงเงิน %!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={inputDecimalFormatter}
                                            parser={inputDecimalPaser}
                                            precision={2}
                                            style={{ width: 150, textAlign: "right", alignItems:"end"}}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="วันที่สิ้นสุด"
                                        name="end_date"
                                        key="end_date"
                                        rules={ [
                                            {
                                            type: 'object',
                                            required: true,
                                            message: 'กรุณาระบุวันที่สิ้นสุด!',
                                            whitespace: true,
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            locale={th_loc}
                                            onChange={onChangeEndDate}
                                            // defaultValue={moment(moment().format('L'), dateFormat)}
                                            format={dateFormat}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="อัตราอาการแสตมป์ %"
                                        name="rateStampDuty"
                                        key="rateStampDuty"
                                        rules={ [
                                            {
                                                type: 'number',
                                                required: true,
                                                message: 'กรุณาระบุอัตราอาการแสตมป์ %!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={inputDecimalFormatter}
                                            parser={inputDecimalPaser}
                                            precision={2}
                                            style={{ width: 150, textAlign: "right", alignItems:"end"}}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="วิธีการคำนวณ"
                                        name="paymentCal"
                                    >
                                        <Radio.Group
                                            onChange={onChangePaymentCal}
                                            value={selectPaymentCal}
                                            defaultValue={selectPaymentCal}
                                            size='large'
                                        >
                                            {optionsPaymentCal.map((opt_value) => (
                                            <Radio key={opt_value.id} value={opt_value.id}>
                                                {opt_value.name}
                                            </Radio>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="อัตราดอกเบี้ยค้างชำระ %"
                                        name="rateAccruedInterest"
                                        key="rateAccruedInterest"
                                        rules={ [
                                            {
                                                type: 'number',
                                                required: true,
                                                message: 'กรุณาระบุอัตราดอกเบี้ยค้างชำระ %!',
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            formatter={inputDecimalFormatter}
                                            parser={inputDecimalPaser}
                                            precision={2}
                                            style={{ width: 150, textAlign: "right", alignItems:"end"}}
                                            size='large'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            
                            <Form.Item
                                // {...layout}
                                label="หมายเหตุ"
                                name="remark"
                                key="remark"

                            >
                                <TextArea rows={3} cols={40} style={{width:"90%"}} size='large' showCount maxLength={255} />
                            </Form.Item>
                        {/* </Col>
                    </Row> */}
                    </div>
                    </Panel>
                </Form>
            </Content>
        </>
    );
};

export default CampaignForm
