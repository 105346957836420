import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/location";
const url = `${configs.urlRoot}/location`;

const getLocationAll = () => {
    const datas_param = http.get(url);
    console.log(datas_param);
    return datas_param;
}

const getLocationById = (id: number) => {
    return http.get(url+"/"+id);
}

export default {
    getLocationAll,
    getLocationById
}