import React, { useState } from 'react'
import PageHeader from '../../layouts/PageHeader';
import { Content } from 'antd/lib/layout/layout';
import { Alert, Button, Form, Modal, notification, Space, message } from 'antd';
import { Input } from 'antd';
import styles from './ChangePassword.module.css';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { stringify } from 'querystring';
import userService from '../../services/security/userService';
import useSessionUser from '../../hooks/useSessionUser';
import messageUtils from '../../utils/messageUtils';
import loggingService from '../../services/security/loggingService';



const ChangePassword = () => {
    const sessionUser = useSessionUser();

    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleFinish = async (values: any) => {
        setErrorMessage("");

        const { oldpassword, newpassword, confirmpassword} = values;
        console.log("finish", values, newpassword === confirmpassword);
        if (newpassword !== confirmpassword) {
            setErrorMessage("password not match");
            return;
        }

        try {
            const res = await userService.changePassword({
                userId: sessionUser.userId,
                oldPassword: oldpassword,
                newPassword: newpassword,
                confirmNewPassword: confirmpassword,
                actionBy: {
                    userId: sessionUser.userId,
                    username: sessionUser.username
                }
            })
            if(!res.data.status) {
                setErrorMessage(res.data.message);
            } else {
                messageUtils.successMessage('Change password success!!!');
                loggingService.logging({
                    event: 'UPDATE',
                    systemRemark: 'change-password',
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    target: 'user',
                    targetRef: sessionUser.username
                });
                form.setFieldsValue({
                    oldpassword: "",
                    newpassword: "",
                    confirmpassword: ""
                })
            }
        } catch(e) {
            message.error(JSON.stringify(e));
        }

    }

    const handleFailed = (errorInfo: ValidateErrorEntity<any>)  => {
        console.log("form error", errorInfo);
    }

    return (
        <div>
        <PageHeader title="Change Password" showSubtitle={false} />
        <Content className="app-page-content">
            <div className={styles.container}>
                {
                    errorMessage &&
                    <Alert
                        message="Error"
                        description={errorMessage}
                        type="error"
                        closable
                        style={{ marginBottom: '20px'}}
                    />
                }
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleFinish}
                    onFinishFailed={handleFailed}
                >
                    <Form.Item
                        label="Old Password"
                        name="oldpassword"
                        // rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input.Password size='large' showCount maxLength={20}/>
                    </Form.Item>

                    <Form.Item
                        label="New Password"
                        name="newpassword"
                        style={{marginTop: '35px'}}
                        rules={[{ required: true, min: 8 }]}
                    >
                        <Input.Password size='large'  showCount maxLength={20} />
                    </Form.Item>
                    <Form.Item
                        label="Confirm: New Password"
                        name="confirmpassword"
                        rules={[{ required: true, min: 8 }]}
                    >
                        <Input.Password size='large' showCount maxLength={20} />
                    </Form.Item>
                </Form>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginTop: '20px'
                }}>
                    <Button type="primary" size='large' onClick={() => form.submit()}>Save</Button>
                </div>
            </div>
        </Content>
        </div>
    )
}

export default ChangePassword