import { Descriptions } from 'antd'
import React from 'react'
import Panel from 'src/components/container/Panel'

interface IProps {
    amount: number;
    text: string;
    color:any;
}

const DBInterestView : React.FC<IProps> = (props) => {
    const {amount, text, color} = props;
  return (
    <div>
        <Panel style={{borderRadius:10, height:150}}>
            <Descriptions
                column={1}
                size="middle"
                labelStyle={{ width: "100%",  }}
                bordered
                
            >
                <Descriptions.Item
                    label="INTEREST"
                    labelStyle={{ textAlign: "left", backgroundColor:"#ffffff" }}
                >
                </Descriptions.Item>
                <Descriptions.Item
                    label={amount === undefined ? 0 : Number(Number(amount).toFixed(2)).toLocaleString('uth-TH')}
                    labelStyle={{ textAlign: "left", backgroundColor:"#ffffff", fontSize:"32px", fontWeight:600, color:color }}
                >
                </Descriptions.Item>

                <Descriptions.Item
                    label={text}
                    labelStyle={{ textAlign: "left", backgroundColor:"#ffffff" }}
                >
                </Descriptions.Item>
            </Descriptions>
        </Panel>
    </div>
  )
}

export default DBInterestView