import { Descriptions, Table } from 'antd';
import React, { useEffect, useState } from 'react'

import { CampaignDetail } from 'src/domain/master/CampaignDetailType';
import campaignService from 'src/services/master/campaignService';

interface IProps {
    headId: number;
}

const columns = [
    {
        title: "Mth",
        dataIndex: "month",
        key: "month",
    },
    {
        title: "Installment",
        dataIndex: "installment",
        key: "installment",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Interest Revenues",
        dataIndex: "interestRevenues",
        key: "interestRevenues",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Princ Repaid",
        dataIndex: "princPepaid",
        key: "princPepaid",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "Ending Bal.",
        dataIndex: "endingBal",
        key: "endingBal",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "CF",
        dataIndex: "cf",
        key: "cf",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "% ตัดต้น",
        dataIndex: "principlePer",
        key: "principlePer",
        align: 'right' as 'right',
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
]

const ListCampaignDetail : React.FC<IProps> = (props) => {
    const _campaignHeadId = props.headId;
    const [campaignDetails, setCampaignDetails] = useState<CampaignDetail[]>([] as CampaignDetail[]);

    useEffect(() => {
        if(_campaignHeadId) {
            (async () => { 
                const res = await campaignService.getCampaignDetailByHeadId(_campaignHeadId);
                setCampaignDetails(res.data);
            })();
        }
    }, []);

    return (
        <div>
            <Descriptions
                style={{ marginTop: "20px" }}
                // title="Interest Revenues Calculation"
                size="middle"
                labelStyle={{ width: 200, fontWeight: "bold" }}
                bordered
                layout="vertical"
            >
                <Descriptions.Item
                    label="Interest Revenues Calculation"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                >
                    <Table
                        columns={columns}
                        dataSource={campaignDetails}
                        size="small"
                        rowKey={(item: CampaignDetail) => item.id}
                        pagination={false}
                    />
                </Descriptions.Item>
            </Descriptions>
        </div>
    )
}

export default ListCampaignDetail
