import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../index";
import { SessionUser } from "./types";
import { Session } from "inspector";

interface AuthState {
  sessionUser: SessionUser;
}

const initialState: AuthState = {
  sessionUser: {userId:0, username: "", email: "" , roles: ["BRANCH_USER"], branch: "", displayName: "", scope: ""},
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // getSessionUser(state: AuthState): void {
    //   state.sessionUser = action.payload;
    // },
    getSessionUserFromLocal(
      state: AuthState,
      action: PayloadAction<SessionUser>
    ): void {
      // console.log("action payload", action.payload);
      if (action.payload.username !== "") {
        state.sessionUser = action.payload;
        console.log("set");
      } else {
        console.log("not set");
      }
    },

    setSessionuser(state: AuthState, action: PayloadAction<SessionUser>): void {
      console.log("sessionUser", action.payload);
      state.sessionUser = action.payload;
    },

    removeSessionUser(
      state: AuthState,
      action: PayloadAction<SessionUser>
    ): void {
      state.sessionUser = {} as SessionUser;
    },
  },
});

export const { reducer } = slice;

export const setSessionUser = slice.actions.setSessionuser;
export const getSessionUserFromLocal = slice.actions.getSessionUserFromLocal;
export const removeSessionUser = slice.actions.removeSessionUser;
