import { Button, Table, Radio, Modal, Form, Input, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, SearchOutlined, SaveOutlined } from "@ant-design/icons";

import classDetailService from 'src/services/master/classDetailService';
import { NcClass, NcClassUseFlg } from "src/domain/master/classType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import UseFlgIcon from 'src/components/container/UseFlgIcon';

const dateFormat = "DD/MM/YYYY";
// const { TabPane } = Tabs;

const ListClass: React.FC<NcClass> = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [classes, setClasses] = useState<NcClass[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const columns: ColumnsType<NcClass> = [
    {
      title: "ลำดับ",
      dataIndex: "id",
      key: "id",
      width: 90,
      render: (value: string, item: NcClass, index: number) => (
        ((currentPage - 1) * pageSize) + (index+1)
      ),
    },
    {
      title: "รหัส",
      dataIndex: "classNo",
      key: "classNo",
      width: 200,
      sorter: (a: NcClass, b: NcClass) => {return a.classNo.localeCompare(b.classNo)},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: NcClass, index: number) => (
        <Link to={`/app/master/class/manage/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "ชื่อข้อมูลหลัก",
      dataIndex: "className",
      key: "className",
      sorter: (a: NcClass, b: NcClass) => {return a.className.localeCompare(b.className)},
      sortDirections: ['ascend', "descend"],
      render: (value: string) => value,
    },
    {
      title: "การใช้งาน",
      dataIndex: "useFlg",
      key: "useFlg",
      width: 120,
      // align: "right" as "right",
      sorter: (a: NcClass, b: NcClass) => {return a.useFlg - b.useFlg},
      sortDirections: ['ascend', "descend"],
      render: (value: number) => {
        // console.log("Value: ", value, ", enumValue: ", NcBankUseFlg.USE, ", flag: ", (value == NcBankUseFlg.NOT_USE));
        return (
          <UseFlgIcon useFlg={value == NcClassUseFlg.USE}/>
            // value
        )
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await classDetailService.searchClass("", 2);
      setClasses(res.data);
      setLoading(false);
    })();
    form.setFieldsValue({
        bankKeyword: '',
        useFlag: 2
    });
  }, []);

  useEffect(() => {}, [classes]);

  const handleOk = async (value: {bankKeyword: string, useFlag: number}) => {
    setLoading(true);
    const res = await classDetailService.searchClass(value.bankKeyword, value.useFlag);
    setClasses(res.data);
    setCurrentPage(1);
    setLoading(false);
    setIsModalVisible(false);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  }
  const onUseFlatChange = (e: RadioChangeEvent) => {

  }

  const footerBtnList = [
    <Form.Item style={{textAlign: 'center'}}>
        <Button
            size="small"
            type="primary"
            name="per"
            icon={<SearchOutlined />}
            onClick={() => {
                form.submit();
            }}
        >
            ค้นหา
        </Button>
    </Form.Item>,
  ]
  const renderUseFlagRadio = () => {
    return(
        <Radio.Group onChange={onUseFlatChange}>
            <Radio value={NcClassUseFlg.USE}>ใช้งาน</Radio>
            <Radio value={NcClassUseFlg.NOT_USE}>ไม่ใช้งาน</Radio>
            <Radio value={NcClassUseFlg.SEARCH_ALL}>ทั้งหมด</Radio>
        </Radio.Group>
    )
  }
  const renderSearchModal = () => {
    return (
      <Modal title="ค้นหา" visible={isModalVisible} footer={footerBtnList} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
            <Form.Item name="bankKeyword">
                <Input 
                    placeholder="รหัส/ชื่อข้อมูลหลัก"
                    style={{marginLeft: "10px" }}
                    // disabled={showPrenameThaiEtc}
                />
            </Form.Item>
            <Form.Item name="useFlag">
                {renderUseFlagRadio()}
            </Form.Item>
        </Form>
      </Modal>
    );
  }
 
  return (
    <div>
      <PageHeader title="รายการข้อมูลหลัก">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/master/class/manage")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
            <div style={{marginRight: 5, marginBottom: 10, textAlign: 'right'}}>
                <Button
                    size="large"
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() => {setIsModalVisible(true);}}
                ></Button>
            </div>
            <Table
                columns={columns}
                dataSource={classes}
                size="small"
                rowKey={(item: NcClass) => item.id}
                pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                    setCurrentPage(page);
                }}}
            />
        </div>
        {renderSearchModal()}
      </Content>
    </div>
  );
};

export default ListClass
