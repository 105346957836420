import React from "react";
import classes from "./Panel.module.css";

interface IProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Panel: React.FC<IProps> = (props) => {
  return (
    <div style={props.style && props.style} className={classes.dataPanel}>
      {props.children}
    </div>
  );
};

export default Panel;
