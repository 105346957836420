import { Button, Table, Radio, Modal, Form, Input, RadioChangeEvent } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import { PlusOutlined, SearchOutlined, SaveOutlined, CloseCircleTwoTone, CheckCircleTwoTone } from "@ant-design/icons";

import bankService from 'src/services/master/bankService';
import { NcBank, NcBankUseFlg } from "src/domain/master/bankType";
import { Content } from 'antd/lib/layout/layout';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import UseFlgIcon from 'src/components/container/UseFlgIcon';

const dateFormat = "DD/MM/YYYY";
// const { TabPane } = Tabs;

const ListBank: React.FC<NcBank> = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [banks, setBanks] = useState<NcBank[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const columns: ColumnsType<NcBank> = [
    {
      title: "ลำดับ",
      dataIndex: "id",
      key: "id",
      width: '10%',
      render: (value: string, item: NcBank, index: number) => (
        ((currentPage - 1) * pageSize) + (index+1)
      ),
    },
    {
      title: "รหัส",
      dataIndex: "bankCode",
      key: "bankCode",
      width: "15%",
      sorter: (a: NcBank, b: NcBank) => {return a.bankCode.localeCompare(b.bankCode)},
      sortDirections: ['ascend', "descend"],
      render: (value: string, item: NcBank, index: number) => (
        <Link to={`/app/master/bank/manage/${item.id}`}>{value}</Link>
      ),
    },
    {
      title: "ชื่อธนาคาร",
      dataIndex: "bankName",
      key: "bankName",
      sorter: (a: NcBank, b: NcBank) => {return a.bankName.localeCompare(b.bankName)},
      sortDirections: ['ascend', "descend"],
      render: (value: string) => value,
    },
    {
      title: "การใช้งาน",
      dataIndex: "useFlg",
      key: "useFlg",
      width: "12%",
      sorter: (a: NcBank, b: NcBank) => {return a.useFlg - b.useFlg},
      sortDirections: ['ascend', "descend"],
      // align: "right" as "right",
      render: (value: number) => {
        // console.log("Value: ", value, ", enumValue: ", NcBankUseFlg.USE, ", flag: ", (value == NcBankUseFlg.NOT_USE));
        return (
            <UseFlgIcon useFlg={value == NcBankUseFlg.USE}/>
        )
      },
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await bankService.searchBank("", 2);
      setBanks(res.data);
      setLoading(false);
    })();
    form.setFieldsValue({
        bankKeyword: '',
        useFlag: 2
    });
  }, []);

  useEffect(() => {}, [banks]);

  const handleOk = async (value: {bankKeyword: string, useFlag: number}) => {
    setLoading(true);
    const res = await bankService.searchBank(value.bankKeyword, value.useFlag);
    setBanks(res.data);
    setCurrentPage(1);
    setLoading(false);
    setIsModalVisible(false);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  }
  const onUseFlatChange = (e: RadioChangeEvent) => {

  }

  const footerBtnList = [
    <Form.Item style={{textAlign: 'center'}}>
        <Button
            size="small"
            type="primary"
            name="per"
            icon={<SearchOutlined />}
            onClick={() => {
                form.submit();
            }}
        >
            ค้นหา
        </Button>
    </Form.Item>,
  ]
  const renderUseFlagRadio = () => {
    return(
        <Radio.Group onChange={onUseFlatChange}>
            <Radio value={NcBankUseFlg.USE}>ใช้งาน</Radio>
            <Radio value={NcBankUseFlg.NOT_USE}>ไม่ใช้งาน</Radio>
            <Radio value={NcBankUseFlg.SEARCH_ALL}>ทั้งหมด</Radio>
        </Radio.Group>
    )
  }
  const renderSearchModal = () => {
    return (
      <Modal title="ค้นหา" visible={isModalVisible} footer={footerBtnList} onCancel={handleCancel}>
        <Form form={form} onFinish={handleOk}>
            <Form.Item name="bankKeyword">
                <Input 
                    placeholder="รหัส/ชื่อธนาคาร"
                    style={{marginLeft: "10px" }}
                    // disabled={showPrenameThaiEtc}
                />
            </Form.Item>
            <Form.Item name="useFlag">
                {renderUseFlagRadio()}
            </Form.Item>
        </Form>
      </Modal>
    );
  }
 
  return (
    <div>
      <PageHeader title="รายการธนาคาร">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => history.push("/app/master/bank/manage")}
        >
          New
        </Button>
      </PageHeader>
      <Content className="app-page-content">
        <div>
            <div style={{marginRight: 5, marginBottom: 10, textAlign: 'right'}}>
                <Button
                    size="large"
                    shape='circle'
                    icon={<SearchOutlined />}
                    onClick={() => {setIsModalVisible(true);}}
                ></Button>
            </div>
            <Table
                columns={columns}
                dataSource={banks}
                size="small"
                rowKey={(item: NcBank) => item.id}
                pagination={{current: currentPage, pageSize: pageSize, onChange: (page: number) => {
                    setCurrentPage(page);
                }}}
            />
        </div>
        {renderSearchModal()}
      </Content>
    </div>
  );
};

export default ListBank
