import React from "react";
import { Route, Switch } from "react-router-dom";

import UserList from "./user/UserList";
import UserDetail from "./user/UserDetail";

const SecurityModule = () => {
  return (
    <Switch>
      <Route exact path="/app/security/user" component={UserList} />
      <Route exact path="/app/security/user/:userId" component={UserDetail} />
    </Switch>
  );
};

export default SecurityModule;
