import { Button, Tabs, Col, Row, Descriptions, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router';
import PageHeader from 'src/layouts/PageHeader'
import {FormOutlined, DollarCircleOutlined, FilePdfOutlined, DeleteOutlined} from "@ant-design/icons";
import { Content } from 'antd/lib/layout/layout';
import { Contract } from 'src/domain/contract/contractType';
import classes from "src/views/application/ListApplication.module.css";

import moment from 'moment';
import 'moment/locale/th';
import contractService from 'src/services/contract/contractService';
import ViewPersonalApplication from '../application/ViewPersonalApplication';
import ViewOccupationApplication from '../application/ViewOccupationApplication';
import ViewIncomeApplication from '../application/ViewIncomeApplication';
import ViewLoanApplication from '../application/ViewLoanApplication';
import ViewApplicationMovement from '../application/ViewApplicationMovement';
import { Application } from 'src/domain/application/applicationType';
import { Address } from 'src/domain/application/addressType';
import { IncomeSource } from 'src/domain/application/incomeSourceType';
import ListContractDebit from './ListContractDebit';
import ContractTranferPopup from './ContractTranferPopup';
import loggingService from '../../services/security/loggingService';
import useSessionUser from '../../hooks/useSessionUser';
import ViewContractTranferPopup from './ViewContractTranferPopup';
import ViewGuarantorApplication from '../application/ViewGuarantorApplication';
import RequestRemarkModel from '../banktransfer/RequestRemarkModel';
import messageUtils from 'src/utils/messageUtils';

const { TabPane } = Tabs;
const dateFormat = "DD/MM/YYYY";

interface IParams {
    id: string;
}

const ViewContract : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _contractId = Number(props.match.params.id);
    const history = useHistory();
    const sessionUser = useSessionUser();
    const [contract, setContract] = useState<Contract>({} as Contract);
    const [application, setApplication] = useState<Application>({} as Application);
    const [addressCurrent, setAddressCurrent] = useState<Address>({} as Address);
    const [addressOffice, setAddressOffice] = useState<Address>({} as Address);
    const [incomeNormal, setIncomeNormal] = useState<IncomeSource>({} as IncomeSource);
    const [incomeOther, setIncomeOther] = useState<IncomeSource>({} as IncomeSource);

    const [_applicationId, _setApplicationId] = useState<number>(0);
    const [_showEditContDate, _setShowEditContDate] = useState<boolean>(true);
    const [_showEditPayDate, _setShowEditPayDate] = useState<boolean>(true);

    const [modalVisible, setModalVisible] = useState<boolean>(false);
	const [modalViewVisible, setModalViewVisible] = useState<boolean>(false);
    const [modalRemarkVisible, setModalRemarkVisible] = useState<boolean>(false);

    useEffect(() => {
        contractService.getContractById(_contractId).then(res => {
            const _contract = res.data;
            setContract(_contract);
            if(_contract.contractDate) _setShowEditContDate(false);

            if(_contract.payDate || _contract.contractStatusId === 77) {
                _setShowEditPayDate(false);
            } else {
                if(_contract.contractNo.substring(_contract.contractNo.length-11).substring(0,2) === '99') {
                    _setShowEditPayDate(false);
                }
            }
            const _application = _contract.application;
            if(_application !== null) {
                setApplication(_application);
                _setApplicationId(_application.id);

                for(let x=0; x<_application.addresses.length; x++) {
                    const _addr = _application.addresses[x];
                    if(_addr.addressType === "CURRENT") setAddressCurrent(_addr);
                    else setAddressOffice(_addr);
                }
            }

            loggingService.logging({
                event: "VIEW",
                target: "contract",
                targetRef: res.data.contractNo,
                eventUserId: sessionUser.userId,
                eventUsername: sessionUser.username,
            });

            // for(let x=0; x<application.incomeSource.length; x++) {
            //     const _inc = application.incomeSource[x];
            //     console.log("_inc", _inc)
            //     if(_inc.incomeType === "NORMAL") setIncomeNormal(_inc);
            //     else setIncomeOther(_inc);
            // }
        });
    }, []);

    useEffect(() => {}, [_showEditContDate, _showEditPayDate]);

    const showModal = () => {
        setModalVisible(true);
    }

    const onClickCancel = async (remark: string) => {
        setModalRemarkVisible(false);

        const _contract : Contract = {
            ...contract,
            remark : remark,
            cancelUserId : sessionUser.userId,
            cancelUsername : sessionUser.username,
            savStation : sessionUser.branch
        }
        
        await contractService.cancelContract(_contract).then((res) => {
            if(res.data.status) {
                messageUtils.successMessage("Cancel success !!!")

                loggingService.logging({
                    event: 'DELETE',
                    systemRemark: 'cancel contract :' + remark,
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    target: 'contract',
                    targetRef: _contract.contractNo
                });

                history.goBack();
            }            
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err));
        });
    }

    return (
        <>
            <PageHeader title="NIMCAP - สัญญาสินเชื่อส่วนบุคคล" onClose={() => history.push("/app/contract")}>
                <Space>
                    {
                        !(_showEditContDate) ?
                            <Button size="large" icon={<FilePdfOutlined />} href={contractService.urlContractPeriodReport(_contractId)}>
                                พิมพ์งวด
                            </Button>
                        : null
                    }

                    {_showEditPayDate && (!_showEditContDate) ?
                    <Button
                        size="large"
                        icon={<DollarCircleOutlined />}
                        // onClick={() => history.push("/app/contract/editPayDate/" + _contractId)}
                        onClick={() => showModal()}
                        >
                        บันทึกโอนเงิน
                    </Button>
                    :""}
                    {_showEditContDate ?
                    <Button
                        size="large"
                        icon={<FormOutlined />}
                        onClick={() => history.push("/app/contract/edit/" + _contractId)}
                        >
                        แก้ไขวันที่สัญญา
                    </Button>
                    :""}
                    {contract.manageable && (
                        <Button
                            size="large"
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => setModalRemarkVisible(true)}
                        >
                            ยกเลิกสัญญา
                        </Button>
                    )}
                </Space>
            </PageHeader>
            <Content className="app-page-content">
                <Row>
                    {/* <Col offset={17} style={{ fontWeight: "bold" }}>
                        <label>เลขที่ : </label>{contract.contractNo}
                    </Col>
                    <Col offset={17} style={{ marginTop:6, fontWeight: "bold" }}>
                        <label>วันที่ : </label>{contract.contractDate ? moment(contract.contractDate).format(dateFormat) : ""}
                    </Col> */}
                </Row>
                <Descriptions
                    style={{ marginTop: "20px" }}
                    column={2}
                    size="middle"
                    labelStyle={{ width: 200, fontWeight: "bold" }}
                    bordered
                >
                    <Descriptions.Item
                        label="เลขที่สัญญา :"
                        labelStyle={{ textAlign: "right" }}
                    >
                        {contract.contractNo}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="เลขที่ใบคำขอสินเชื่อ :"
                        labelStyle={{ textAlign: "right" }}
                    >
                        {contract.applicationNo}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วันที่สัญญา :"
                        labelStyle={{ textAlign: "right" }}
                    >
                        {contract.contractDate ? moment(contract.contractDate).format(dateFormat) : ""}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วันที่อนุมัติ :"
                        labelStyle={{ textAlign: "right" }}
                    >
                        {moment(contract.approveDate).format(dateFormat)}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="วันที่โอนเงิน :"
                        labelStyle={{ textAlign: "right" }}
                    >
                        {/* {contract.payDate ? moment(contract.payDate).format(dateFormat) : ""} */}
                        <a onClick={() => contract.payDate ? setModalViewVisible(true) : ""}>{contract.payDate ? moment(contract.payDate).format(dateFormat) : ""}</a>
	                     </Descriptions.Item>
                    <Descriptions.Item
                        label=""
                        labelStyle={{ textAlign: "right" }}
                    >
                        {contract.contractStatusId === 51 && 
                            <label style={{ color:'red', fontWeight: "bold" }}>ปิดสัญญา</label>
                        }
                        {contract.contractStatusId === 76 && 
                            <label style={{ color:'red', fontWeight: "bold" }}>ปิดสัญญา(ปรับโครงสร้างหนี้)</label>
                        }
                        {contract.contractStatusId === 77 && 
                            <label style={{ color:'red', fontWeight: "bold" }}>ยกเลิกสัญญาแล้ว</label>
                        }
                    </Descriptions.Item>
                    <Descriptions.Item
                        label="ผู้ขอสินเชื่อ :"
                        labelStyle={{ textAlign: "right" }}
                        span={2}
                    >
                        {application.nameThai}
                    </Descriptions.Item>
                </Descriptions>

                <Tabs defaultActiveKey="0">
                    <TabPane tab="ตารางผ่อนชำระ" key="0" >
                        <div className={classes.dataPanel}>
                            <ListContractDebit id={_contractId} />
                        </div>
                    </TabPane>
                    <TabPane tab="ข้อมูลส่วนตัว" key="1" >
                        <div className={classes.dataPanel}>
                            <ViewPersonalApplication application={application} addressCurrent={addressCurrent}/>
                        </div>
                    </TabPane>
                    <TabPane tab="อาชีพ/สถานที่ทำงาน" key="2">
                        <div className={classes.dataPanel}>
                            <ViewOccupationApplication addressOffice={addressOffice}/>
                        </div>
                    </TabPane>
                    <TabPane tab="รายได้" key="3">
                        <div className={classes.dataPanel}>
                            <ViewIncomeApplication application={application}/>
                        </div>
                    </TabPane>
                    <TabPane tab="วงเงินกู้" key="4">
                        <div className={classes.dataPanel}>
                            <ViewLoanApplication application={application}/>
                        </div>
                    </TabPane>
                    <TabPane tab="ผู้ค้ำ" key="5">
                        <div className={classes.dataPanel}>
                            <ViewGuarantorApplication application={application}/>
                        </div>
                    </TabPane>
                    {/* <TabPane tab="ไฟล์แนบ" key="6">
                        <div className={classes.dataPanel}>

                        </div>
                    </TabPane> */}
                    <TabPane tab="ความเคลื่อนไหว" key="7">
                        <div className={classes.dataPanel}>
                            <ViewApplicationMovement id={_applicationId} />
                        </div>
                    </TabPane>
                </Tabs>
            </Content>

            <ContractTranferPopup
                modalVisible={modalVisible}
                contractId={_contractId}
                onCancel={() => setModalVisible(false)}
            />

		    <ViewContractTranferPopup
                transferId={contract.contractTransferId}
                modalViewVisible={modalViewVisible}
                onCancel={() => setModalViewVisible(false)}
            />

            <RequestRemarkModel
                modalVisible={modalRemarkVisible}
                onOK={onClickCancel}
                onCancel={() => setModalRemarkVisible(false)}
            />
        </>
    )
}

export default ViewContract
