import http, { authConfig } from "../../lib/http";
import configs from "src/constants/config";
import { PaymentModel } from "src/domain/payment/paymentModelType";
import { Payment } from "src/domain/payment/paymentType";

// const url = "http://localhost:8080/payment";
const url = `${configs.urlRoot}/payment`;

const addPayment = (model : PaymentModel) => {
    return http.post(url, model);
}

const cancelPayment = (model: Payment) => {
    return http.put(url+"/cancelPayment", model);
}

const getPaymentById = (id: number) => {
    return http.get(url+"/"+id);
}

const getForViewPayment = (paymentId: number) => {
let params = {paymentId: paymentId};
return http.post(`${url}/view`, {}, {params: params});
}

const getPaymentByNo = (no: string) => {
    return http.get(url+"/byNo?paymentNo="+no);
}

const getByStation = (station: string) => {
    return http.get(url + "/byStation?station="+station);
}

const getDataForPayment = (contractId: number, paymentInstallmentType: string) => {
    let paramsData = {contractId, paymentInstallmentType};
    return http.post(`${url}/contract`, {}, {params: paramsData});
}

const calculateOverDue = (model: PaymentModel) => {
    return http.post(url + "/calculateOverDue", model);
}

const getByDate = (startDate: string, endDate: string, contractNo: string) => {
    return http.get(url + "/byDate?startDate="+startDate+"&endDate="+endDate + "&contractNo="+contractNo, authConfig());
}

const searchForReport = (branchId: number, contractNo: string, no: string,
    name: string,startDate: string, endDate: string,
) => {
    let searchData = {branchId: branchId, contractNo: contractNo, cardNo: no,name: name,
                    startDate: startDate, endDate: endDate,
                };
    return http.post(`${url}/searchForReport`, {}, {params: searchData});
}

export default {
    addPayment,
    cancelPayment,
    getPaymentById,
    getForViewPayment,
    getPaymentByNo,
    getByStation,
    getByDate,
    getDataForPayment,
    calculateOverDue,
    searchForReport,
}