import http from "../../lib/http";
import configs from "src/constants/config";

const url = `${configs.urlRoot}/eventlog`;

export type EventLogType =
	| "LOGIN"
	| "LOGOUT"
	| "VIEW"
	| "CREATE"
	| "UPDATE"
	| "DELETE"
	| "PRINT"
  | "IMPORT"
	| "EXPORT"
	| "APPROVE"
  | "ATTACH"
	| "MENU";

export interface EventLog {
	event: EventLogType;
	target?: string;
	targetRef?: string;

	systemRemark?: string;

	eventPayload?: string;
	eventDate?: string;
	eventTime?: string;
	eventUserId: number;
	eventUsername: string;
}

const logging = async (eventLog: EventLog) => {
	return http.post(url + "/create", eventLog);
};

export default {
	logging,
};
