import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "src/views/Home";
import LoginForm from "src/views/LoginForm";
import ModernAppLayout from "./layouts/ModernAppLayout";
import AppRoute from "./views/AppRoute";
import './App.css';
import LoginFormModern from "./views/LoginFormModern";

const App: React.FC = () => {

  if (process.env.REACT_APP_SHOWLOG !== 'TRUE') {
    console.log = () => {}
  }


  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={LoginFormModern} />
        {/* <Route exact path="/login" component={LoginForm} /> */}
        {/* <Route path="/modern" component={ModernAppLayout} /> */}
        <Route path="/app" component={AppRoute} />
        {/* <Route path="/products" component={Products} />
        <Route path="/tests/users"  render={(renderProps) => (<AppLayout><UserList /></AppLayout>)} /> */}
        {/* <Redirect path="/*" to="/" /> */}
        {/* <Redirect path="*" to="/login" /> */}
        <Redirect path="*" to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
