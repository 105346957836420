class ResourceFile {
    id: number = -1;
	filename: string = "";
	directory: string = "";
	extension: string = "";
	fileType: string = "";
    constructor(id: number, filename: string, directory: string, extension: string, fileType: string){
        this.id = id;
        this.filename = filename;
        this.directory = directory;
        this.extension = extension;
        this.fileType = fileType;
    }
}

export default ResourceFile;