import { Descriptions, Table } from 'antd';
import React from 'react'
import { ApplicationLoan } from 'src/domain/application/applicationLoanType';
import { Application } from 'src/domain/application/applicationType';
import { CampaignDetail } from 'src/domain/master/CampaignDetailType';

interface IProps {
    applicationLoan: ApplicationLoan;
    application: Application;
}

const ViewLoanComponent : React.FC<IProps> = (props) => {
    const {applicationLoan, application} = props;

    const columns = [
        {
            title: "Mth",
            dataIndex: "month",
            key: "month",
        },
        {
            title: "Installment",
            dataIndex: "installment",
            key: "installment",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Interest Revenues",
            dataIndex: "interestRevenues",
            key: "interestRevenues",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Princ Repaid",
            dataIndex: "princPepaid",
            key: "princPepaid",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "Ending Bal.",
            dataIndex: "endingBal",
            key: "endingBal",
            align: 'right' as 'right',
            render : (value : number) => (
                // Number(Number(value).toFixed(2)).toLocaleString('th-TH')
                new Intl.NumberFormat('th-TH', {
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }).format(value)
            )
        },
        {
            title: "CF",
            dataIndex: "cf",
            key: "cf",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
        {
            title: "% ตัดต้น",
            dataIndex: "principlePer",
            key: "principlePer",
            align: 'right' as 'right',
            render : (value : number) => (
                Number(Number(value).toFixed(2)).toLocaleString('th-TH')
            )
        },
    ]

  return (
    <div style={{ padding: "0px 20px" }}>
        <Descriptions
            style={{ marginTop: "20px" }}
            column={2}
            // title="Personal Information"
            size="middle"
            labelStyle={{ width: 200,  }}
            bordered
        >
            <Descriptions.Item
                label="จำนวนเงินที่ขอกู้ :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >

                {Number(Number(applicationLoan.loanAmt).toFixed(2)).toLocaleString('uth-TH')} <label>บาท</label>
            </Descriptions.Item>

            <Descriptions.Item
                label="ระยะเวลาผ่อนชำระ :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {applicationLoan.loanInstallment} <label>เดือน</label>
            </Descriptions.Item>

            <Descriptions.Item
                label="CAMPAIGN :"
                // span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {applicationLoan.campaignName}
            </Descriptions.Item>

            <Descriptions.Item
                label="EFFECTIVE INTEREST RATE % (ทั้งหมด) :"
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {Number(Number(applicationLoan.effInterestAnnum).toFixed(2)).toLocaleString('uth-TH')}
            </Descriptions.Item>

            <Descriptions.Item
                label="ชื่อบัญชี :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {application.accountName}
            </Descriptions.Item>

            <Descriptions.Item
                label="ธนาคาร :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {application.bankCode} {application.bankName}
            </Descriptions.Item>

            <Descriptions.Item
                label="เลขที่บัญชี :"
                span={2}
                labelStyle={{ textAlign: "right", backgroundColor:"#ffffff" }}
            >
                {application.bankAccountNo}
            </Descriptions.Item>
        </Descriptions>

                <Table
                    columns={columns}
                    dataSource={applicationLoan.campaignDetails}
                    size="small"
                    rowKey={(item: CampaignDetail) => item.id}
                    pagination={false}
                    style={{ marginTop: "20px" }}
                />
        
    </div>
  )
}

export default ViewLoanComponent