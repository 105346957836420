import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AccountForm from './AccountForm';

interface IParams {
    id: string;
}

const EditAccount: React.FC<RouteComponentProps<IParams>> = (props) => {
    const _id = Number(props.match.params.id);

  return (
    <div>
        <AccountForm id={_id} mode={'EDIT'} />
    </div>
);
};

export default EditAccount;
