import React from "react";
import Panel from "../../../components/container/Panel";
import DescriptionBox from "./DescriptionBox";

const UserAccountPane = () => {
  return (
    <Panel style={{ minHeight: 400 }}>
      <DescriptionBox />
    </Panel>
  );
};

export default UserAccountPane;
