import { Button, Form, Space, Table, DatePicker, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import PageHeader from 'src/layouts/PageHeader';
import {PlusOutlined} from "@ant-design/icons"
import { Content } from 'antd/lib/layout/layout';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import paymentService from 'src/services/payment/paymentService';
import { Payment } from 'src/domain/payment/paymentType';
import { Link } from 'react-router-dom';
import {SearchOutlined} from '@ant-design/icons';
import Panel from 'src/components/container/Panel';
import { ColumnsType } from 'antd/lib/table';
import UseFlgIcon from 'src/components/container/UseFlgIcon';
import DFlex from '../../components/container/DFlex';

const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

// .table-font {
//     color: blue
// }

const ListPayment = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [payments, setPayments] = useState<Payment[]>([]);

    const defaultDate = {
        paymentDate: [moment().startOf('month'), moment().endOf('month')]
    };

    const [offset, setOffset] = useState<number>(0);

    const handlePageChange = (page:number, pageSize: number) => {
      console.log('page property', page, pageSize);
      setOffset((page-1) * pageSize);
    }

    useEffect(() => {
        (async () => {
        //   const res = await paymentService.getByStation("11");
            const _paymentDate = form.getFieldValue("paymentDateNo")
          const res = await paymentService.getByDate(
            moment() === undefined ? "" : moment().startOf('month').format('YYYYMMDD'),
            moment() === undefined ? "" : moment().endOf('month').format('YYYYMMDD'),
            "");
          setPayments(res.data);
        })();
    },[]);

    useEffect(() => {}, [payments]);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const code_search = form.getFieldValue("contractNo")
            if(code_search) {
                (async () => {
                    const res = await paymentService.getByDate(
                        "",
                        "",
                        code_search);
                    setPayments(res.data);
                })();
            }
        }
    }

    const onFinish = (values: any) => {
        const _paymentDate = values.paymentDate;
        const code_search = values.contractNo;
        if(_paymentDate) {
            (async () => {
                const res = await paymentService.getByDate(
                    _paymentDate === undefined ? "" : _paymentDate[0].format('YYYYMMDD'),
                    _paymentDate === undefined ? "" : _paymentDate[1].format('YYYYMMDD'),
                    code_search === undefined ? "" : code_search);
                setPayments(res.data);
            })();
        }
    }

    const onRowSummary = () => {

        if(payments !== undefined) {
            let total = 0;

            if(payments !== null && payments.length > 0) {
                // payments.forEach(({paymentAmount}) => {
                //     total = total + (paymentAmount ? paymentAmount : 0);
                // });
                payments.forEach((value: Payment, index: number) => {
                    if(value.useFlg !== -1)
                    total = total + (value.paymentAmount ? value.paymentAmount : 0);
                });
            }

            return (
                <>
                <Table.Summary.Row style={{fontSize:"16px", fontWeight:'bold'}}>
                    <Table.Summary.Cell index={0} colSpan={2} align={'right'}>รวมจำนวน</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{(payments !== null && payments.length > 0) ? payments.length : 0}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} >รายการ</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} align={'right'}>รวมเงิน (ไม่รวมรายการที่ยกเลิก)</Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align={'right'}>{Number(Number(total).toFixed(2)).toLocaleString('th-TH')}</Table.Summary.Cell>
                    <Table.Summary.Cell index={0} colSpan={2}>บาท</Table.Summary.Cell>
                </Table.Summary.Row>
                </>
            );
        }
    }

    const columns: ColumnsType<Payment> = [
        {
            title: "ลำดับ",
            dataIndex: "id",
            key: "id",
            render: (value: string, item: Payment, index: number) => (
                offset + index+ 1
            ),
        },
        {
            title: "วันที่รับชำระ",
            dataIndex: "paymentDate",
            key: "paymentDate",
            render: (value: string) => moment(value).format(dateFormat),
            sorter: {
                compare: (a: Payment, b: Payment) => moment(a.paymentDate).unix()-moment(b.paymentDate).unix(),
                multiple: 1 },
            defaultSortOrder: 'ascend',
        },
        {
            title: "เลขที่รับชำระ",
            dataIndex: "paymentNo",
            key: "paymentNo",
            render: (value: string, item: Payment, index: number) => (
                <Link to={`/app/payment/view/${item.id}`}>{value}</Link>
              ),
        },
        {
            title: "เลขที่สัญญา",
            dataIndex: "contractNo",
            key: "contractNo",
        },
        {
            title: "ผู้ขอสินเชื่อ",
            dataIndex: "name",
            key: "name",
            sorter: {
                compare: (a: Payment, b: Payment) => a.name.localeCompare(b.name),
                multiple: 2 },
        },
        {
            title: "ยอดเงิน",
            dataIndex: "paymentAmount",
            key: "paymentAmount",
            align: "right" as "right",
            render: (value: number) =>
                Number(Number(value).toFixed(2)).toLocaleString("th-TH"),
            sorter: {
                compare: (a: Payment, b: Payment) => a.paymentAmount - b.paymentAmount,
                multiple: 2 },
        },
        {
            title: "สาขา",
            dataIndex: "paymentStation",
            key: "paymentStation",
        },
        {
            title: "ผู้รับเงิน",
            dataIndex: "createUserName",
            key: "createUserName",
        },
        {
            title: "สถานะ",
            dataIndex: "useFlg",
            key: "useFlg",
            width: "5%",
            render: (value: number) => {
              return (
                  <UseFlgIcon useFlg={value === -1 ? false : true}/>
              )
            },
          },
    ];

    return (
        <div>
            <PageHeader title="Payment List" showSubtitle={false} >
                <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => history.push("/app/payment/new")}
                >
                New
                </Button>
            </PageHeader>
            <Content className="app-page-content">
						<Form
                    form={form}
                    size="large"
                    name="basic"
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={defaultDate}
                    // onFinishFailed={onFinishFailed}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent:'space-between',
                                  alignItems:"center", textAlign:"center", width:"100%",
                                  marginBottom: '10px',  }}>
                      <DFlex type="row" gap={20}>
                        <Form.Item
                            label="วันที่รับชำระ"
                            name="paymentDate"
                            key="paymentDate"
                        >
                            <RangePicker locale={th_loc} format={dateFormat} />
                        </Form.Item>

                        <Form.Item
                            label="เลขที่สัญญา"
                            name="contractNo"
                            key="contractNo"
                        >
                            <Input placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleKeyDown}/>
                        </Form.Item>
                      </DFlex>

                      <Form.Item
                          label=""
                      >
                        <Button
                            icon={<SearchOutlined/>}
                            size="large"
                            onClick={() => {
                            form.submit();
                            }}
                        >
                        ค้นหา
                        </Button>
                      </Form.Item>

                    </div>
                </Form>
                <Panel>
                <div>
										<h4>รายการ</h4>
                    <Table
                    columns={columns}
                    dataSource={payments}
                    size="small"
                    rowKey={(item: Payment) => item.paymentNo}
                    summary={onRowSummary}
                    pagination={{
                        onChange: handlePageChange,
                        pageSize: 10,
                    }}
                    />
                </div>
                </Panel>
            </Content>
        </div>
    )
}

export default ListPayment
