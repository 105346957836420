import { Descriptions } from 'antd'
import React from 'react'
import Panel from 'src/components/container/Panel';

interface IProps {
    text: string;
    qty: number;
    amount: number;
}

const DBDocumentView : React.FC<IProps> = (props) => {
    const {text, qty, amount} = props;
  return (
    <div>
        <Panel style={{borderRadius:10, height:150}}>
            <Descriptions
                column={1}
                size="middle"
                labelStyle={{ width: 200,  }}
                bordered
            >
                <Descriptions.Item
                    label={text}
                    labelStyle={{ textAlign: "left", backgroundColor:"#ffffff" }}
                >
                    <div style={{ textAlign: "right", width:"100%", fontSize:"32px", fontWeight:600, color:'black'}}>
                        {qty === undefined ? 0 : Number(Number(qty).toFixed(2)).toLocaleString('th-TH')}
                    </div>
                </Descriptions.Item>
                <Descriptions.Item
                    label={amount === undefined ? 0 : Number(Number(amount).toFixed(2)).toLocaleString('uth-TH')}
                    labelStyle={{ textAlign: "left", backgroundColor:"#ffffff", fontSize:"32px", fontWeight:600, color:'black' }}
                >
                    <div style={{ textAlign: "right", width:"100%"}}>
                        บาท
                    </div>
                </Descriptions.Item>
            </Descriptions>
        </Panel>
    </div>
  )
}

export default DBDocumentView