import { Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { Payment } from 'src/domain/payment/paymentType';
import PageHeader from 'src/layouts/PageHeader'
import {DeleteOutlined} from '@ant-design/icons'
import { Content } from 'antd/lib/layout/layout';
import Panel from 'src/components/container/Panel';
import ViewPaymentContract from './ViewPaymentContract';
import ViewPaymentDetail from './ViewPaymentDetail';
import paymentService from 'src/services/payment/paymentService';
import { PaymentDetail } from 'src/domain/payment/paymentDetailType';
import depositService from 'src/services/payment/depositService';
import { DepositDetail } from 'src/domain/payment/depositDetailType';
import { Contract } from 'src/domain/contract/contractType';
import { Campaign } from 'src/domain/master/CampaignType';
import { CampaignHead } from 'src/domain/master/CampaignHeadType';
import contractService from 'src/services/contract/contractService';
import campaignService from 'src/services/master/campaignService';
import campaignHeadService from 'src/services/master/campaignHeadService';
import { Application } from 'src/domain/application/applicationType';
import applicationService from 'src/services/application/applicationService';
import loggingService from 'src/services/security/loggingService';
import useSessionUser from 'src/hooks/useSessionUser';
import RequestRemarkModel from '../banktransfer/RequestRemarkModel';
import messageUtils from 'src/utils/messageUtils';
import cancelImg from "../assets/images/cancel.jpg";

interface IParams {
    id: string;
}

const ViewPayment : React.FC<RouteComponentProps<IParams>> = (props) => {
    const _paymentId = Number(props.match.params.id);
    const history = useHistory();
    const sessionUser = useSessionUser();

    const [payment, setPayment] = useState<Payment>({} as Payment);
    const [paymentDetails, setPaymentDetails] = useState<PaymentDetail[]>([]);
    const [itemDeposit, setItemDeposit] = useState<PaymentDetail>({} as PaymentDetail);
    const [depositDetails, setDepositDetails] = useState<DepositDetail[]>([]);

    const [application, setApplication] = useState<Application>({} as Application);
    const [contract, setContract] = useState<Contract>({} as Contract);
    const [campaign, setCampaign] = useState<Campaign>({} as Campaign);
    const [campaignHead, setCampaignHead] = useState<CampaignHead>({} as CampaignHead);

    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {},[canDelete]);

    useEffect(() => {
        paymentService.getForViewPayment(_paymentId).then(res => {
            const _payment = res.data;
            
            if(_payment) {
                for(let x=0; x<_payment.paymentDetails.length; x++) {
                    const _detail = _payment.paymentDetails[x];
                    if(_detail.paymentItemId === 48) setItemDeposit(_detail);
                    else paymentDetails.push(_detail);
                }
            }
            setPayment(_payment);
            
            setDepositDetails(_payment.depositDetails);
            setCampaign(_payment.campaign);
            setCampaignHead(_payment.campaignHead);

            const _contract = _payment.contract;
            if(_contract) {
                if(_contract && _contract.applicationNo) {
                    applicationService.getApplicationByNo(_contract.applicationNo).then(res => {
                        setApplication(res.data);
                    })
                }

                setContract(_contract);
            };
            setCanDelete(_payment.canDelete);

            loggingService.logging({
                event: "VIEW",
                target: "payment",
                targetRef: res.data.paymentNo,
                eventUserId: sessionUser.userId,
                eventUsername: sessionUser.username,
            });
        });
    }, []);

    

    const onClickCancel = async (remark: string) => {
        setModalVisible(false);

        const _payment : Payment = {
            ...payment,
            remark : remark,
            cancelUserId : sessionUser.userId,
            cancelUsername : sessionUser.username,
            savStation : sessionUser.branch
        }
        await paymentService.cancelPayment(_payment).then((res) => {
            if(res.data.status) {
                messageUtils.successMessage("Cancel success !!!")

                loggingService.logging({
                    event: 'DELETE',
                    systemRemark: 'cancel payment :' + remark,
                    eventUserId: sessionUser.userId,
                    eventUsername: sessionUser.username,
                    target: 'payment',
                    targetRef: _payment.paymentNo
                });

                history.goBack();
            }            
        }).catch((err) => {
            console.log(err);
            messageUtils.errorMessage(JSON.stringify(err));
        });
    }

    return (
        <>
            <PageHeader title={'PAYMENT NO. '+payment.paymentNo + (payment.useFlg == -1 ? " **เอกสารยกเลิกแล้ว**" : "")} onClose={() => history.push("/app/payment")}>
                {canDelete &&
                    <Button
                        size="large"
                        type='primary'
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => setModalVisible(true)}
                    >
                        ยกเลิกรับชำระ
                    </Button>
                }
            </PageHeader>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginLeft: "30px", width: "100%",}}>
                    <ViewPaymentDetail 
                        payment={payment} 
                        paymentDetails={paymentDetails} 
                        itemDeposit={itemDeposit}
                        depositDetails={depositDetails}
                        application={application}
                    />
                </div>
                <Panel style={{marginLeft:"10px"}}>
                    <div style={{ display: "flex", width: "300px" }}>
                        <ViewPaymentContract 
                            contract={contract} 
                            campaign = {campaign}
                            campaignHead={campaignHead}
                        />
                    </div>
                </Panel>
            </div>

            <RequestRemarkModel
                modalVisible={modalVisible}
                onOK={onClickCancel}
                onCancel={() => setModalVisible(false)}
            />
        </>
    )
}

export default ViewPayment
