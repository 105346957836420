import { log } from "console";
import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/campaignHead";
const url = `${configs.urlRoot}/campaignHead`;

const addCampaignHead = (camp : any) => {
    return http.post(url, camp);
} 

const editCampaignHead = (camp: any) => {
    return http.put(url+"/update", camp);
}

const getCampaignHeadByCampId = (campId: number) => {
    return http.get(url+"/ByCampId?campId="+campId);
}

const getCampaignHeadById = (id: number) => {
    return http.get(url+"/"+id);
}

const getLoanAmtByCurrDate = () => {
    return http.get(url+"/loanAmount");
}

const getTenorByLoanAmtAndCurrentDate = (amt: number) => {
    return http.get(url+"/byLoanAmt/"+amt);
}

const getLoanAmtByCampaignId = (campaignId: number) => {
    return http.get(url+"/loanAmount/"+campaignId);
}

const getTenorByLoanAmtAndCampaignId = (campId: number, amt: number) => {
    return http.get(url+"/tenor?campaignId="+campId+ "&loanAmt="+amt);
}

const calculate = (amt: number, period: number, interest: number) => {
    return http.get(url+"/calculate?loanAmt="+amt+ "&tenor="+period+ "&flatInterest="+interest);
}
 
const isExist = (amt: number, period: number, interest: number, id: number, campId: number) => {
    return http.get(url+"/isExist?loanAmt="+amt+ "&tenor="+period+ "&flatInterest="+interest+ "&id="+id + "&campId="+campId);
}

export default {
    addCampaignHead,
    editCampaignHead,
    getCampaignHeadByCampId,
    getCampaignHeadById,
    getLoanAmtByCurrDate,
    getTenorByLoanAmtAndCurrentDate,
    getLoanAmtByCampaignId,
    getTenorByLoanAmtAndCampaignId,
    calculate,
    isExist
}