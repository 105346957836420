import http from "../../lib/http";
import configs from "src/constants/config";

const url = `${configs.urlRoot}/contractredebt`;

const getRedebtById = (id: number) => {
    return http.get(url+"/"+id);
}

const searchRedebtLists = (contractNo: string, startDate: string, endDate: string) => {
    let params = {contractNo: contractNo, startDate: startDate, endDate: endDate};
    return http.post(`${url}/listSearch`, {}, {params: params});
}

const getDataAddNew = (contractNo: string) => {
    let params = {contractNo: contractNo};
    return http.post(`${url}/dataAddNew`, {}, {params: params});
}

const calculateRedebt = (redebt: any) => {
    return http.post(`${url}/calculateRedebt`, redebt);
}

const addRedebt = (redebt : any) => {
    return http.post(url, redebt);
} 

const updateRedebt = (redebt : any) => {
    return http.put(url, redebt);
}

const deleteRedebt = (redebt : any) => {
    return http.put(`${url}/delete`, redebt);
}

export default {
    getRedebtById,
    searchRedebtLists,
    getDataAddNew,
    calculateRedebt,
    addRedebt,
    updateRedebt,
    deleteRedebt
}