import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../index";
import { Payment } from "src/domain/payment/paymentType";
import { PaymentDetail } from "src/domain/payment/paymentDetailType";
import paymentService from "src/services/payment/paymentService";
import { DepositDetail } from "src/domain/payment/depositDetailType";

interface PaymentState {
  payment: Payment;
  paymentDetails : PaymentDetail[];
  itemDeposit : PaymentDetail;
  depositDetails: DepositDetail[];
}

const initialState: PaymentState = {
  payment: {} as Payment,
  paymentDetails: [],
  itemDeposit : {} as PaymentDetail,
  depositDetails: [],
};

const slice = createSlice({
  name: "payment",
  initialState: initialState,
  reducers: {
    // getSessionUser(state: AuthState): void {
    //   state.sessionUser = action.payload;
    // },
    getPayment(
      state: PaymentState,
      action: PayloadAction<{payment: Payment;
        paymentDetails : PaymentDetail[];
        itemDeposit : PaymentDetail;
        depositDetails: DepositDetail[];}>
    ): void {
      console.log("action payload", action.payload);
      state.payment = action.payload.payment;
      state.paymentDetails = action.payload.paymentDetails;
      state.itemDeposit = action.payload.itemDeposit;
      state.depositDetails = action.payload.depositDetails;
      
    },

  },
});

export const { reducer } = slice;

export const getPayment = slice.actions.getPayment;
