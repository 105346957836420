import { User } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import UserList from "../../views/security/user/UserList";

interface UserState {
  user: User;
  users: User[];
  processing: boolean;
}

const initialState: UserState = {
  user: {} as User,
  users: [],
  processing: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    processingUser: (state: UserState, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
      console.log("action loader ", action.payload);
    },
    createUser: (state: UserState, action: PayloadAction<User>) => {
      console.log("create user action", action);
      state.users.push(action.payload);
    },
    setCurrentUser: (state: UserState, action: PayloadAction<User>) => {
      state.user = action.payload;
      console.log("action loader");
    },
  },
});

export const { reducer } = userSlice;

export const setCurrentUserAction = userSlice.actions.setCurrentUser;
export const createUserAction = userSlice.actions.createUser;
export const processingUserAction = userSlice.actions.processingUser;
