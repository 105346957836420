import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
} from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  setSessionUser,
  getSessionUserFromLocal,
} from "src/store/authen/action";
import "./Login.css";

import classes from "./LoginFormModern.module.css";
import logoTitle from '../assets/images/logo.jpg';

import preval from 'preval.macro';
import moment from "moment";
import loginService from "../services/security/loginService";
import loggingService from "../services/security/loggingService";
import DFlex from "../components/container/DFlex";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function validate(status: boolean): string {
  if (!status) {
    return "Incorrect username or password";
  }

  return "";
}

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
const tmp = moment(dateTimeStamp.trim(), "M/D/YYYY, H:mm:ss a") // 7/11/2022, 5:10:35 PM

const timeSplit = dateTimeStamp.split(' ');
const year = tmp.year();
const month = tmp.month();
const day = tmp.date();
const hour =  timeSplit[2] === "PM" && tmp.hour() <= 11 ?  tmp.hour() + 12 : tmp.hour();
const minute = tmp.minute();
const second = tmp.second();
console.log(year, month, day, hour, minute, second);
const buildDate = moment({ year, month, day, hour, minute, second});

const buildLabel = process.env.REACT_APP_BUILD_LABEL ? process.env.REACT_APP_BUILD_LABEL : "Build" + " : ";

const LoginFormModern = () => {
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sessionUser } = useAppSelector((state) => state.auth);
  const [loadding, setLoading] = useState<boolean>(false);

  useEffect(() => {

    setLoading(false);

    console.log("use effect login form modern", history.location.pathname);
    const pathname = history.location.pathname;

  }, []);

  useEffect(() => {
    dispatch(getSessionUserFromLocal());
  }, [dispatch])


  useEffect(() => {
    console.log("sessionUser:useEffect", sessionUser);
    if (sessionUser.username) {
      history.replace("/app");
    }
  }, [sessionUser])

  const onFinish = async (values: any) => {
    console.log("form", values);
    const { username, password } = values;

    setLoading(true);

    try {
      const res = await loginService.login(username, password);
      if(!res.data.status) {
        setError(res.data.message);
        return;
      }

      const sessionUser = res.data.body;
      console.log(sessionUser);


      loggingService.logging({event: "LOGIN", eventUserId: sessionUser.userId, eventUsername: sessionUser.username}).then(res => {
        console.log("log");
      })

      dispatch(setSessionUser(sessionUser));
    } catch (err) {
      setError(JSON.stringify(err));
    } finally {
      setLoading(false);
    }

  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    setLoading(false);
  };

  const onClose = () => {
    setError("");
  }

  return (
    <>
      {/* {console.log("login in form useEffect", sessionUser.username, "---")}
      {sessionUser.username ? history.replace("/app") : null} */}
      <div
        className={classes.bg}
        style={{display: 'flex',
        flexDirection:'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        // backgroundColor: '#9c0005'
        // backgroundColor: '#002281'
        }}>
        <div className={classes.login} >
            <div className={classes.header}>
              <div className={classes.headLogo}>
                <img src={logoTitle} className={classes.logo}  />
              </div>
              <div className={classes.headerTitleWrapper}>
                <div className={classes.headerTitle}>Log in</div>
                <div className={classes.headerSubTitle}>นิ่มแคปปิตอล บริการสินเชื่อ</div>
              </div>
            </div>
            <div className={classes.content}>
            {error && (
              <Alert
                message="Error"
                description={error}
                type="error"
                closable
                onClose={onClose}
              />
            )}
            <br />
            <Form
              // {...layout}
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input size="large" style={{width: '100%'}} placeholder="Username" maxLength={30}/>
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}

              >
                <Input.Password size="large" style={{width: '100%'}} placeholder="Password" maxLength={30}/>
              </Form.Item>

              {/* <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}
              <div  style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '30px'}}>
                <Button type="primary" htmlType="submit" size="large" loading={loadding}>
                  Login
                </Button>
              </div>
            </Form>
            </div>
        </div>
        <DFlex type="row" full justifyContent="center" >
          <DFlex className={classes.version} type="row" justifyContent="flex-start" >
            <span style={{marginTop: '10px', marginLeft: '10px', color: '#aaa', fontSize: '11px'}}>{buildLabel} : {buildDate.format("YYYYMMDD - HHmmss")}</span>
          </DFlex>
        </DFlex>

      </div>
    </>
  );
};

export default LoginFormModern;
