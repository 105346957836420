import { ThunkPromiseAction } from "..";
import userService from "../../services/security/userService";
import { addLogAction } from "../log/slice";
import { createUserAction, processingUserAction } from "./slice";
import { User } from "./types";

export interface CreateUserCommand {
  username: string;
  password: string;
  confirmPassword: string;
  name: string;
  email: string;
}

export interface UserFilter {}

export const listUser: ThunkPromiseAction<UserFilter, User> =
  (dispatch, state) => (command) => {
    return new Promise<User>((resolve, reject) => {});
  };

export const createUser: ThunkPromiseAction<CreateUserCommand, number> =
  (dispatch, state) => (command) => {
    return new Promise<number>(async (resolve, reject) => {
      console.log("command : ", command);
      dispatch(processingUserAction(true));
      try {
        const createdUser: any = await userService.createUser(command);
        const user: User = {
          username: createdUser.username,
          name: createdUser.displayName,
          email: createdUser.emailAddress,
        };
        console.log("state : ", state.userState.users);
        setTimeout(() => {
          dispatch(createUserAction(user));
          dispatch(processingUserAction(false));
          dispatch(addLogAction("user created"));
          resolve(1);
        }, 5000);
      } catch (err: any) {
        console.log("err : ", err);
        dispatch(processingUserAction(false));
      }
    });
  };

// export const createUser = (command: CreateUserCommand): AppThunk<number> => {
//   return async (dispatch, getState): Promise<number> => {
//     return new Promise<number>((resolve: any, reject: any) => {
//       dispatch(processingUserAction(true));
//       const user: User = {
//         username: command.username,
//         name: command.name,
//         email: command.email,
//       };
//       console.log("state : ", getState().userState.users);
//       console.log("create user log");

//       setTimeout(() => {
//         dispatch(createUserAction(user));
//         dispatch(processingUserAction(false));
//         dispatch(addLogAction("user created"));
//         resolve(1);
//         // callback && callback();
//       }, 2000);
//     });
//   };
// };
