import http from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/applicationMovement";
const url = `${configs.urlRoot}/applicationMovement`;

const getMovementByApplicationId = (appId: number) => {
    return http.get(url+"/movement/"+appId);
}

export default {
    getMovementByApplicationId,
}