import { Button, Form, Select, DatePicker, Input, Space, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react'
import Panel from 'src/components/container/Panel';
import PageHeader from 'src/layouts/PageHeader';
import { SearchOutlined, FileExcelFilled } from "@ant-design/icons";
import { Branch } from 'src/domain/master/BranchType';
import BranchService from 'src/services/master/BranchService';

import moment from 'moment';
import 'moment/locale/th';
import th_loc from 'antd/es/date-picker/locale/th_TH';
import { ContractForReport } from 'src/domain/contract/contractForReportType';
import contractService from 'src/services/contract/contractService';
import { Excel } from 'antd-table-saveas-excel';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD/MM/YYYY";

const columns = [
    {
        title: "เลขที่ใบคำขอ",
        dataIndex: "applicationNo",
    },
    {
        title: "วันที่ใบคำขอ",
        dataIndex: "applicationDate",
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "contractNo",
    },
    {
        title: "วันที่สัญญา",
        dataIndex: "contractDate",
    },
    {
        title: "เลขที่บัตร",
        dataIndex: "idCard",
        // render: (value: string, item: Contract, index: number) => (
        //     // item.nationalityId === 73 ? item.idCard : item.workPermitNo
        // ),
    },
    {
        title: "คำนำหน้า",
        dataIndex: "prenameThaiName",
        // render: (value: string, item: Application, index: number) => (
        //     item.prenameThaiId === 8 ? item.prenameEtcThai : item.prenameThaiName
        // ),
    },
    {
        title: "ชื่อ-นามสกุล",
        dataIndex: "name",
    },
    {
        title: "วงเงินกู้",
        dataIndex: "loanAmt",
        align: "right" as "right",
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "จำนวนงวด",
        dataIndex: "loanInstallment",
        align: "right" as "right",
    },
    {
        title: "จ่ายต่องวด",
        dataIndex: "installment",
        align: "right" as "right",
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "อากรแสตมป์",
        dataIndex: "stampDuty",
        align: "right" as "right",
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "รวมเงินต้น",
        dataIndex: "sumPrincPepaid",
        align: "right" as "right",
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "รวมดอกเบี้ย",
        dataIndex: "sumInterestRevenues",
        align: "right" as "right",
        render : (value : number) => (
            Number(Number(value).toFixed(2)).toLocaleString('th-TH')
        )
    },
    {
        title: "เลขที่บัญชี",
        dataIndex: "bankAccountNo",
    },
    {
        title: "ธนาคาร",
        dataIndex: "bankName",
    },
    {
        title: "สถานะใบคำร้อง",
        dataIndex: "applicationStatusName",
    },
];

const columnsExport = [
    {
        title: "เลขที่ใบคำขอ",
        dataIndex: "applicationNo",
    },
    {
        title: "วันที่ใบคำขอ",
        dataIndex: "applicationDate",
    },
    {
        title: "เลขที่สัญญา",
        dataIndex: "contractNo",
    },
    {
        title: "วันที่สัญญา",
        dataIndex: "contractDate",
    },
    {
        title: "เลขที่บัตร",
        dataIndex: "idCard",
    },
    {
        title: "คำนำหน้า",
        dataIndex: "prenameThaiName",
    },
    {
        title: "ชื่อ-นามสกุล",
        dataIndex: "name",
    },
    {
        title: "วงเงินกู้",
        dataIndex: "loanAmt",
    },
    {
        title: "จำนวนงวด",
        dataIndex: "loanInstallment",
    },
    {
        title: "จ่ายต่องวด",
        dataIndex: "installment",
    },
    {
        title: "อากรแสตมป์",
        dataIndex: "stampDuty",
    },
    {
        title: "รวมเงินต้น",
        dataIndex: "sumPrincPepaid",
    },
    {
        title: "รวมดอกเบี้ย",
        dataIndex: "sumInterestRevenues",
    },
    {
        title: "เลขที่บัญชี",
        dataIndex: "bankAccountNo",
    },
    {
        title: "ธนาคาร",
        dataIndex: "bankName",
    },
    {
        title: "สถานะใบคำร้อง",
        dataIndex: "applicationStatusName",
    },
    {
        title: "วันที่สิ้นสุดสัญญา",
        dataIndex: "endDueDate",
    },
];

const ListContractReport : React.FC<ContractForReport> = () => {
    const [form] = Form.useForm();

    const [optionsBranch, setOptionsBranch] = useState<Branch[]>([] as Branch[]);
    const [contracts, setContracts] = useState<ContractForReport[]>([]);

    const defaultDate = {
        contractDate: [moment().startOf('month'), moment().endOf('month')]
    };

    useEffect(() => {}, [contracts]);

    useEffect(() => {
        (async () => {
          const res = await BranchService.getBranchUseAll();
          setOptionsBranch(res.data);
        })();
    }, []);

    const handleContractNoKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("contractNo");
            if(_search) {
                (async () => {
                    const res = await contractService.searchForReport(0, _search, "", "", "", "");
                    setContracts(res.data);
                })();
            }
        }
    }

    const handleIdCardKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            const _search = form.getFieldValue("idCard");
            if(_search) {
                (async () => {
                    const res = await contractService.searchForReport(0, "", _search, "", "", "");
                    setContracts(res.data);
                })();
            }
        }
    }

    const handleNameKeyDown = (event: any) => {
    }

    const onFinish = (values: any) => {
        const _date = values.contractDate;
        const _branchId = values.branchCode ?? 0;
        const _contractNo = values.contractNo;
        const _name = values.name;
        const _idcard = values.idCard;

        (async () => {
            const res = await contractService.searchForReport(
                _branchId,
                _contractNo === undefined ? "" : _contractNo,
                _idcard === undefined ? "" : _idcard,
                _name === undefined ? "" : _name,
                _date ? _date[0].format('YYYYMMDD') : "" ,
                _date ? _date[1].format('YYYYMMDD') : "" 
            );
            setContracts(res.data);
        })();
    }

    const onClickReset = () => {
        form.setFieldsValue({
            contractDate: [moment().startOf('month'), moment().endOf('month')],
            branchCode: null,
            contractNo: "",
            idCard: "",
            name: ""
        });

        setContracts([] as ContractForReport[]);
    }

    const onClickExport = () => {
        const excel = new Excel();
            excel
            .addSheet("export")
            .addColumns(columnsExport)
            .addDataSource(contracts)
            .saveAs("contracts_report.xlsx");
    }

  return (
    <div>
        <PageHeader title="รายงานการออกสัญญา" showSubtitle={false} >
            <Button
                size="large"
                icon={<FileExcelFilled />}
                onClick={() => onClickExport()}
                value="EXCEL"
            >
                ส่งออก Excel
            </Button>
        </PageHeader>
        <Content className="app-page-content">
            <Panel>
                <Form
                  form={form}
                  name="basic"
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={defaultDate}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "30%"}}>
                            <Form.Item
                                label="สาขา"
                                name="branchCode"
                                key="branchCode"
                            >
                                <Select
                                    placeholder="เลือกสาขา"
                                    // style={{ width: 470 }}
                                    size='large'
                                >
                                    {optionsBranch.map((values) => (
                                    <Option key={values.id} value={values.id} props={values}>
                                        {values.branchCode}
                                        {values.branchName}
                                    </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "60%"}}>
                            <Form.Item
                                label="วันที่สัญญา"
                                name="contractDate"
                                key="contractDate"
                            >
                                <RangePicker size="large" locale={th_loc} format={dateFormat} style={{width:450}} />
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "10%"}}>

                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{marginLeft: "20px",width: "30%"}}>
                            <Form.Item
                                label="เลขที่สัญญา"
                                name="contractNo"
                                key="contractNo"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleContractNoKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "25%"}}>
                            <Form.Item
                                label="เลขที่บัตรประชาชน"
                                name="idCard"
                                key="idCard"
                            >
                                <Input size="large" placeholder='กด Enter เพื่อค้นหา' onKeyDown={handleIdCardKeyDown}/>
                            </Form.Item>
                        </div>
                        <div style={{marginLeft: "20px",width: "25%"}}>
                            <Form.Item
                                label="ชื่อผู้ขอสินเชื่อ"
                                name="name"
                                key="name"
                            >
                                <Input size="large"
                                    // onKeyDown={handleNameKeyDown}
                                />
                            </Form.Item>
                        </div>
                        <div style={{width: "20%",alignItems:'center'}}>
                            <Form.Item
                                label=" "
                            >
                                <Space size={'large'} style={{marginLeft: 20}}>
                                <Button
                                    size="large"
                                    icon={<SearchOutlined/>}
                                    onClick={() => {
                                        form.submit();
                                    }}
                                    >
                                    ค้นหา
                                </Button>
                                <Button type="link" onClick={onClickReset}>
                                    Reset
                                </Button>
                                </Space>
                            </Form.Item>
                        </div>
                    </div>
                </Form>

                <Table
                  columns={columns}
                  dataSource={contracts}
                  size="small"
                  rowKey={(item: ContractForReport) => item.contractNo}
                  scroll={{ x: true }}
                />
            </Panel>
        </Content>

    </div>
  )
}

export default ListContractReport