import { Button, Form, Input, Modal } from 'antd';
import React from 'react';

interface IProps {
    modalVisible: boolean;
    onCancel: () => void;
    onSearch: (value: string) => void;
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 12 },
};

const SearchAccountPopup: React.FC<IProps> = (props) => {
    const {modalVisible, onCancel, onSearch} = props;
    const [form] = Form.useForm();

    const onSearchText = () => {
        const text_search = form.getFieldValue("text_search")
        onSearch(text_search)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            onSearchText();
        }
    }

    return (
        <div>
            <Modal
                title="ค้นหารายการบัญชี"
                style={{ top: 110 }}
                visible={modalVisible}
                onCancel={() => onCancel()}
                footer={[
                    <Button key="search" onClick={() => onSearchText()}>
                        ค้นหา
                    </Button>
                ]}
            >
                <Form
                    {...layout}
                    form={form}
                    name="basic"
                    layout="horizontal"
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="รหัส/ชื่อ"
                        name="text_search"
                    >
                        <Input placeholder='รหัส/ชื่อ' onKeyDown={handleKeyDown} />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default SearchAccountPopup;
