import http, { authConfig } from "../../lib/http";
import configs from "src/constants/config";

// const url = "http://localhost:8080/application";
// const url = "http://192.168.1.37:8080/application";
const url = `${configs.urlRoot}/application`;

const getApplications = () => {
    return http.get(url);
};

const getApplicationById = (id: number) => {
    return http.get(url+"/"+id);
}

const byIdAndSecRole = (id: number, userId: number) => {
    let search = {id, userId};
    return http.post(`${url}/id`, {}, {params: search});
}

const getApplicationByNo = (no: string) => {
    return http.get(url+"/byNo?no="+no);
}

const getApplicationByStatus = (statusId: number) => {
    console.log("getApplications: ", (url+"/byStatus?statusId="+statusId+"&orderBy=application_no"))
    return http.get(url+"/byStatus?statusId="+statusId+"&orderBy=application_no", authConfig());
}

const getByCondition = (statusId: number, startDate: string, endDate: string,
    name: string, no: string, applicationNo: string, branchId: number) => {
    let searchData = {statusId: statusId, startDate: startDate, endDate: endDate,
                        name: name, cardNo: no, applicationNo: applicationNo, branchId: branchId};

    return http.post(`${url}/getByCondition`,  {}, {
        params: searchData,
        ...authConfig()
        }
    );
}

const addApplication = (app : any) => {
    console.log("application : ", app);
    return http.post(url, app);
}

const updateApplication = (app : any) => {
    return http.put(url, app);
}

const updateApplicationStatus = (appId : number, statusCode: string, remark: string, userId: number, username: string, savStation: string) => {
    let updateData = {id: appId, applicationStatusCode: statusCode, remark: remark,
                        userId: userId, userName: username, savStation: savStation
                     }
    return http.put(url+"/updateStatus", updateData);
}

const updateAttatchFile = (applicationId:number, idcardResourceId: number | null, bookBankResourceId: number | null) => {
    return http.post(`${url}/updateAttatchFile`, {applicationId: applicationId, idcardResourceId: idcardResourceId, bookBankResourceId: bookBankResourceId});
}

const getApplicstionResourceByApplicationId = (applicationId:number) => {
    console.log("url", `${url}/getApplicationResource/${applicationId}`);
    return http.get(`${url}/getApplicationResource/${applicationId}`)
}

const removeResource = (resourceId:number) => {
    return http.post(`${configs.urlRoot}/file/removeApplicationResource`, {resourceId: resourceId})
}

export default {
    getApplications,
    getApplicationById,
    byIdAndSecRole,
    getApplicationByNo,
    getApplicationByStatus,
    getByCondition,
    addApplication,
    updateApplication,
    updateApplicationStatus,
    updateAttatchFile,
    getApplicstionResourceByApplicationId,
    removeResource,
}